import React from "react";
import {Button, Col, Row} from "reactstrap";
import MainInfo from "./mainInfo";
import Activities from "./activities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import BackToTop from "../../../backToTop";
import DisabledAlert from "./disabledAlert";
import LeadTagFilterProvider from "../../../providers/LeadTagFilterProvider";


export default function Lead() {
    const navigate = useNavigate()


    return <LeadTagFilterProvider>
        <Row className="mb-4">
            <Col>
                <Button color="primary" className="text-white" onClick={() => {
                    navigate('../')
                }}>
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
                    BACK TO MANAGE LEADS
                </Button>
            </Col>
        </Row>
        <DisabledAlert/>
        <Row className="mt-4">
            <Col md={4} sm={12} className="mb-5">
                <MainInfo/>
            </Col>
            <Col>
                <Activities/>
            </Col>
        </Row>
        <BackToTop/>
    </LeadTagFilterProvider>
}