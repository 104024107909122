import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {useLoggedInApi} from "../../api";
import {useMutation} from "react-query";
import {toast} from "react-toastify";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Compress from 'compress.js'


function PhotoUpload(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [localImage, setLocalImage] = useState();
    const api = useLoggedInApi()
    const compress = new Compress()

    const mutation = useMutation(data => {
        const resource = api.resource(props.resourceName)
        const formData = new FormData()
        formData.append(
            'upload',
            Compress.convertBase64ToFile(data.upload.data, data.upload.ext),
            data.upload.alt
        )
        const promise = resource.patchItem(props.resourcePath, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data
        })

        toast.promise(promise, props.toastDetail)
        return promise
    }, {
        onSuccess: data => {
            setLocalImage(null)
            setIsOpen(false)
            if (props.onSuccess) {
                props.onSuccess(data)
            }
        }
    })

    const displayImage = localImage ? URL.createObjectURL(Compress.convertBase64ToFile(localImage.data)) : props.defaultImage

    return <>
        {
            React.cloneElement(
                props.children,
                {onClick: () => setIsOpen(true)}
            )
        }
        <Modal isOpen={isOpen} centered toggle={mutation.isLoading ? () => {
        } : () => setIsOpen(false)} scrollable={true}>
            <ModalHeader>
                {props.modalTitle}
            </ModalHeader>
            <ModalBody className="text-center p-5 mt-3 mb-3">
                {
                    displayImage && <img
                        alt={"New " + props.modalTitle}
                        src={displayImage}
                        className={(props.className ? props.className : "") + " bg-white img-fluid"}/>
                }

                {!!props.explanation ? <div className={"mt-5"}>{props.explanation}</div> : null}
                <input
                    className="mt-3"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={e => {
                        const compressPromise = compress.compress([e.target.files[0]], {
                            size: 2, // the max size in MB, defaults to 2MB
                            quality: 1, // the quality of the image, max is 1,
                            maxWidth: 3840, // the max width of the output image, defaults to 1920px
                            maxHeight: 3840, // the max height of the output image, defaults to 1920px
                            resize: true // defaults to true, set false if you do not want to resize the image width and height
                        }).then(resizedImages => {
                            setLocalImage(resizedImages[0])
                        })
                        toast.promise(compressPromise, {
                            pending: "Preparing image",
                            success: "Image processed",
                            error: "Cannot prepare image for upload"
                        })
                    }}
                />
                <Row className="mt-3">
                    <Col className="small">
                        Maximum file size: <strong>2MB</strong><br/>
                        Recommended image resolution: <strong>{props.recommendedResolution || "1920x1080px"}</strong>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    disabled={!localImage}
                    onClick={() => mutation.mutate({upload: localImage})}>
                    Save
                </Button>
                <Button color="info-outline" disabled={mutation.isLoading}
                        onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </>
}

PhotoUpload.propTypes = {
    explanation: PropTypes.string,
    recommendedResolution: PropTypes.string,
    resourceName: PropTypes.string.isRequired,
    resourcePath: PropTypes.string.isRequired,
    toastDetail: PropTypes.shape({
        pending: PropTypes.string.isRequired,
        error: PropTypes.string.isRequired,
        success: PropTypes.string.isRequired
    }).isRequired,
    onSuccess: PropTypes.func,
    modalTitle: PropTypes.string.isRequired,
    defaultImage: PropTypes.string
}

export default PhotoUpload;