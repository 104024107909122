import React, {useState} from "react";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import Detail from "./index";


export default function AddPropertyListingButton({onCreate, closeOnCreate, defaultData, label}) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [clickCount, setClickCount] = useState(0)

    return <>
        <Button color="info-outline" onClick={() => {
            setIsModalOpen(true)
            setClickCount(clickCount + 1)
        }}>
            <FontAwesomeIcon icon={faCirclePlus}/>{" "}
            {label ? label : "ADD PROPERTY LISTING"}
        </Button>

        {
            <Detail
                key={"add-" + clickCount.toString()}
                defaultData={defaultData}
                isOpen={isModalOpen}
                toggle={() => {
                    setIsModalOpen(false)
                }}

                onCreate={newData => {
                    onCreate(newData)
                    if (closeOnCreate) {
                        setIsModalOpen(false)
                    }
                }}
            />
        }
    </>
}