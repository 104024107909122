import React from "react";
import {Col, Row} from "reactstrap";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import SampleComputationEdit from "./sampleComputationEdit";
import NewTemplateModal from "./newTemplateModal";
import PropertyGroupAsTag from "../../../../commonComponents/propertyGroupAsTag";

import {usePropertyGroups} from "../../../../commonComponents/hooks";


export default function SampleComputationTemplates() {
    const {data: propertyGroups, isLoading: propertyGroupsLoading} = usePropertyGroups({
        params: {include_inactive: true}
    })
    const propertyGroupById = propertyGroups?.reduce((acc, group) => {
        acc[group.id] = group
        return acc
    }, {})

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Sample Computation Templates</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Add and manage XLSX templates that are used to generate sample computations.{" "}
            </Col>
        </Row>

        <Row className="mt-5">
            <Col className={"text-end"}>
                <NewTemplateModal/>
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Sample Computation Templates"}
            apiResourceName={"properties/templates"}
            columnDefinition={[
                {
                    name: "Title",
                    selector: row => row.title,
                    sortable: true,
                    sortField: "title",
                },
                {
                    name: "Property Group",
                    selector: row => row.property_group_name,
                    format: row => {
                        if (propertyGroupById) {
                            const group = propertyGroupById[row.property_group]
                            if (group) {
                                return <PropertyGroupAsTag propertyGroup={group}/>
                            }
                        }
                        return row.property_group_name
                    },
                    sortable: true,
                    sortField: "property_group__name",
                },
                {
                    name: "File",
                    selector: row => row.template,
                    format: row => <a href={row.template} target="_blank" rel="noreferrer"
                                      className="btn btn-link btn-sm">
                        <FontAwesomeIcon icon={faFileExcel}/>{' '}
                        <small>Download</small>
                    </a>,
                    sortable: false,
                },
                {
                    name: "Datetime Created",
                    selector: row => row.datetime_added,
                    sortable: true,
                    sortField: "datetime_added",
                    format: row => new Date(row.datetime_added).toLocaleString(),
                }
            ]}


            dataTableProps={{
                expandableRows: true,
                expandableRowsComponent: SampleComputationEdit
            }}
        />
    </>
}