import React from "react";
import {Route} from "react-router-dom";
import FormAdmin from "./index";
import LeadProfileManagement from "./leadProfileManagement";


const Routes = (<Route exact path="form-admin" element={<FormAdmin/>}>
    <Route index element={<LeadProfileManagement/>}/>
</Route>)

export default Routes