import React, {useEffect, useState} from "react";
import {Col, FormFeedback, Input, Row} from "reactstrap";
import * as PropTypes from "prop-types";


const passesPattern = (pattern, value) => {
    const re = new RegExp(pattern);
    return re.test(value)
}


export default function InputRow(props) {
    const [hadFocus, setHadFocus] = useState(false);
    const [shouldCheck, setShouldCheck] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false)

    const noValueRequired = props.required && !props.value;
    const pattern = props.pattern;
    const patternError = pattern && props.value && !passesPattern(pattern, props.value);

    const hasError = noValueRequired || patternError;
    useEffect(() => {
        console.log("effect hasError", hasError)
        if (props.setHasError) {
            props.setHasError(hasError)
        }
        if (hasError) {
            setShowToolTip(true)
        }
    }, [hasError, patternError])

    useEffect(() => {
        !!props.errorMessage && setShowToolTip(true)
    }, [props.errorMessage])

    const showError = hadFocus && shouldCheck && (hasError || props.errorMessage);

    const classNames = [
        (props.className ? props.className : ""),
        "flex-fill",
        (showError ? "error" : "")
    ].join(' ')

    useEffect(() => {
        // auto check field after 3 second idle time
        setShouldCheck(false)
        const delayDebounceFn = setTimeout(() => {
            setShouldCheck(true)
            setShowToolTip(true)
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [props.value])

    useEffect(() => {
        // auto hide tooltip after 3 seconds
        if (showToolTip && !props.staticError) {
            const delayDebounceFn = setTimeout(() => {
                setShowToolTip(false)
            }, 3000)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [showToolTip, shouldCheck, props.errorMessage])


    const renderProps = {
        ...props
    }
    delete renderProps["invalidPatternError"]
    delete renderProps["setHasError"]
    delete renderProps['staticError']
    delete renderProps['errorMessage']

    const inputRender = props.renderer ? props.renderer({
        ...renderProps,
        className: classNames
    }) : <Input
        {...renderProps}
        invalid={showError}
        className={classNames}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onFocus={() => setHadFocus(true)}
        onBlur={() => setShouldCheck(true)}
    />

    const errorFeedback = <FormFeedback tooltip className={props.feedbackClassName ? props.feedbackClassName : ""}>
        {noValueRequired && <>{props.label} is required.</>}
        {patternError && <>{props.invalidPatternError ? props.invalidPatternError : "Invalid format."}</>}

        {props.errorMessage}
    </FormFeedback>

    if (props.type === "textarea") {
        return <Row className="mt-1 mb-1">
            <Col md={12}>
                {props.label} {props.required && "*"}
            </Col>
            <Col md={12}>
                {inputRender}
                {
                    showToolTip && errorFeedback
                }
            </Col>
        </Row>
    }

    return <Row className="input-row position-relative">
        <Col md={4} className="ps-md-4 align-items-center d-flex">
            {props.label} {props.required && "*"}
        </Col>
        <Col className="p-0 d-flex align-items-center">
            {inputRender}

            {
                showToolTip && errorFeedback
            }
        </Col>
    </Row>;
}

InputRow.propTypes = {
    value: PropTypes.any,
    renderer: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    setHasError: PropTypes.func,
    errorMessage: PropTypes.string,
    invalidPatternError: PropTypes.string,
    staticError: PropTypes.bool,
    feedbackClassName: PropTypes.string
};
