import React from "react";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TagDisplay from "../../../../../../commonComponents/tagDisplay";
import {useLoggedInApi} from "../../../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import TagColorPickerModal from "../../../../../../commonComponents/tagColorPickerModal";
import ExpirationPickerModal from "../../../../../../commonComponents/expirationPickerModal";
import {daysToNumberAndType} from "../../../../../../commonComponents/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeDropper} from "@fortawesome/free-solid-svg-icons";
import TagConditionModal from "./tagConditionModal";
import SelectExtraInfoSourceModal from "./selectExtraInfoSourceModal";

import {usePermissionContextCheck} from "../../../../../../commonComponents/hooks";


const TAG_DISABLED_COLOR = 'light-gray';


export default function TagEditModal({isOpen, toggle, data, tagGroupData}) {
    const canDeleteTag = usePermissionContextCheck('tagform.delete_tag')

    const [localData, setLocalData] = React.useState({
        ...data
    })

    const [colorPickerOpen, setColorPickerOpen] = React.useState(false)
    const [expirationPickerOpen, setExpirationPickerOpen] = React.useState(false)
    const [tagConditionPickerOpen, setTagConditionPickerOpen] = React.useState(false)
    const [selectExtraInfoModalOpen, setSelectExtraInfoModalOpen] = React.useState(false)

    const fieldsChanged = React.useMemo(() => {
        if (!data) return [...Object.keys(localData)]
        return [...Object.keys(localData).filter(key => localData[key] !== data[key])]
    }, [localData, data])
    const fieldsComplete = React.useMemo(() => {
        return !!localData.name && !!localData.description
    }, [localData])

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const saveMutation = useMutation((data) => {
        const tagResource = api.resource('tag/tag')
        const isCreate = !data.id;
        if (!isCreate) {
            const changedData = fieldsChanged.reduce((acc, key) => {
                acc[key] = data[key]
                return acc
            }, {})
            const updatePromise = tagResource.patchItem(data.id, changedData).then(response => {
                return response.data
            })
            toast.promise(updatePromise, {
                pending: 'Updating Tag...',
                success: 'Tag Updated',
                error: 'Error Updating Tag'
            })
            return updatePromise;
        } else {
            const createPromise = tagResource.create(data).then(response => {
                return response.data
            })
            toast.promise(createPromise, {
                pending: 'Creating Tag...',
                success: 'Tag Created',
                error: 'Error Creating Tag'
            })
            return createPromise;
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['tagGroup'])
            toggle()
        }
    })

    const DEFAULT_EXPIRATION = 30;
    const expirationDisplay = React.useMemo(() => {
        const expiration = localData.tag_expiration || DEFAULT_EXPIRATION
        const {number, type} = daysToNumberAndType(expiration)
        return `${number} ${type.toUpperCase()}`
    }, [localData.tag_expiration])


    const toggleAllowDuplicateTag = () => {
        setLocalData(prevData => {
            if (!prevData.multiple_source) {
                return {
                    ...prevData,
                    multiple_source: "text",
                    auto_tag_new: false,
                    auto_tag_if_taggroup_added: null,
                    auto_tag_if_tag_added: null,
                }
            } else {
                return {
                    ...prevData,
                    multiple_source: null
                }
            }

        })
    }


    const {allowAutoTag, autoTagDisableReason} = React.useMemo(() => {
        if (!!localData.multiple_source) {
            return {
                allowAutoTag: false,
                autoTagDisableReason: <>
                    NOTE: Allowing duplicate tags will prevent the tag from being automatically applied.
                    Duplicate tags require manual input.
                </>,
            }
        }
        if (tagGroupData.is_unique) {
            const uniqueAutoTagOffendingTag = tagGroupData.tags.find(
                tag => (tag.auto_tag_if_tag_added || tag.auto_tag_if_taggroup_added || tag.auto_tag_new) && tag.id !== localData.id
            )
            if (!!uniqueAutoTagOffendingTag) {

                return {
                    allowAutoTag: false,
                    autoTagDisableReason: <>
                        NOTE: This tag is part of a unique tag group. {" "}
                        <TagDisplay color={uniqueAutoTagOffendingTag.color}>
                            {uniqueAutoTagOffendingTag.name}
                        </TagDisplay>
                        is already set to auto tag. Only one tag in a unique tag group can be set to auto tag.
                    </>,
                }
            }
        }
        if (tagGroupData.is_sequence) {
            return {
                allowAutoTag: false,
                autoTagDisableReason: <>
                    NOTE: This tag is part of a sequence tag group. Sequence tag groups cannot be set to auto tag.
                </>,
            }
        }

        return {
            allowAutoTag: true,
            autoTagDisableReason: null,
        }

    }, [localData, tagGroupData.is_unique, tagGroupData.tags])

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row>
                <Col>
                    <h3>Tag Settings</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Label className="lineheight-1">Tag name</Label>
                    <Input
                        value={localData.name}
                        onChange={(e) => {
                            setLocalData({...localData, name: e.target.value})
                        }}
                        type="text" placeholder="Enter Tag name"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Label className="lineheight-1">Tag Description</Label>
                    <Input
                        value={localData.description}
                        onChange={(e) => {
                            setLocalData({...localData, description: e.target.value})
                        }}
                        type="textarea" placeholder="Enter Tag description"/>
                </Col>
            </Row>
            <Row className="mt-3  border-top pt-2">
                <Col>
                    <TagDisplay
                        color={localData.color}
                        textColor={localData.text_color}
                        onClick={() => {
                            setColorPickerOpen(true)
                        }}>
                        <FontAwesomeIcon icon={faEyeDropper}/> SET TAG COLOR
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3 border-top pt-2">
                <Col>
                    <TagDisplay
                        color={localData.accept_datetime ? 'darkseagreen' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                accept_datetime: !prevData.accept_datetime,
                                send_reminder: false,
                            }))
                        }}>
                        ACCEPT DATE/TIME
                    </TagDisplay>
                    <TagDisplay
                        color={localData.send_reminder ? 'darkseagreen' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                send_reminder: !prevData.send_reminder,
                                accept_datetime: !prevData.send_reminder ? true : prevData.accept_datetime,
                            }))
                        }}
                    >
                        SEND EMAIL REMINDER
                    </TagDisplay>
                    <TagDisplay
                        color={localData.accept_datetime ? TAG_DISABLED_COLOR : 'darkseagreen'}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                accept_datetime: !prevData.accept_datetime,
                                send_reminder: false,
                            }))
                        }}
                    >
                        NO DATE/TIME
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3 border-top pt-2">
                <Col>
                    <TagDisplay
                        color={localData.accept_text ? 'plum' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                accept_text: !prevData.accept_text,
                            }))
                        }}>
                        ACCEPT MORE INFORMATION
                    </TagDisplay>
                    <TagDisplay
                        color={localData.accept_text ? TAG_DISABLED_COLOR : 'plum'}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                accept_text: !prevData.accept_text,
                            }))
                        }}
                    >
                        NO ADDITIONAL INFORMATION
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>

                    {
                        tagGroupData.is_unique && <p className="lineheight-1">
                            <small className="text-red">
                                NOTE: This tag is part of a unique tag group. No duplicate tags will be allowed.
                            </small>
                        </p>
                    }
                    {
                        !tagGroupData.is_unique && <TagDisplay
                            color={!!localData.multiple_source ? 'rosybrown' : TAG_DISABLED_COLOR}
                            onClick={() => {
                                setSelectExtraInfoModalOpen(true)
                            }}>
                            ALLOW DUPLICATE TAGS
                        </TagDisplay>
                    }
                    <TagDisplay
                        color={!!localData.multiple_source ? TAG_DISABLED_COLOR : 'rosybrown'}
                        onClick={toggleAllowDuplicateTag}
                    >
                        NO DUPLICATE TAGS
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>
                    <TagDisplay
                        color={!!localData.tag_expiration ? 'peru' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            if (!localData.tag_expiration) {
                                setLocalData({
                                    ...localData,
                                    tag_expiration: DEFAULT_EXPIRATION,
                                })
                            }
                            setExpirationPickerOpen(true)
                        }}
                    >
                        TAG EXPIRES | {expirationDisplay}
                    </TagDisplay>
                    <TagDisplay
                        color={!!localData.tag_expiration ? TAG_DISABLED_COLOR : 'peru'}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                tag_expiration: !!prevData.tag_expiration ? null : DEFAULT_EXPIRATION,
                            }))
                        }}
                    >
                        NO EXPIRY
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>
                    {
                        !allowAutoTag && <p className="lineheight-1">
                            <small className="text-red">
                                {autoTagDisableReason}
                            </small>
                        </p>
                    }
                    {
                        allowAutoTag && <TagDisplay
                            color={(
                                localData.auto_tag_new || !!localData.auto_tag_if_tag_added || !!localData.auto_tag_if_taggroup_added
                            ) ? 'burlywood' : TAG_DISABLED_COLOR}
                            onClick={() => {
                                setTagConditionPickerOpen(true)
                                if (!localData.auto_tag_new && !localData.auto_tag_if_tag_added && !localData.auto_tag_if_taggroup_added) {
                                    setLocalData(prevData => ({
                                        ...prevData,
                                        auto_tag_new: true,
                                    }))
                                }
                            }}>
                            SET CONDITION > {}
                            {
                                (localData.auto_tag_new || (!localData.auto_tag_if_tag_added && !localData.auto_tag_if_taggroup_added))
                                && "ALL NEW LEADS"
                            }
                            {
                                !!localData.auto_tag_if_tag_added && "TAG ADDED"
                            }
                            {
                                !!localData.auto_tag_if_taggroup_added && "TAG GROUP ADDED"
                            }
                        </TagDisplay>
                    }
                    <TagDisplay
                        color={(
                            !localData.auto_tag_new && !localData.auto_tag_if_tag_added && !localData.auto_tag_if_taggroup_added
                        ) ? 'burlywood' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                auto_tag_new: false,
                                auto_tag_if_tag_added: null,
                                auto_tag_if_taggroup_added: null,
                            }))
                        }}
                    >
                        NO AUTO TAG
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>
                    <TagDisplay
                        color={localData.display_on_list ? 'darkkhaki' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                display_on_list: !prevData.display_on_list,
                            }))
                        }}
                    >
                        SHOW IN SUMMARY PAGE
                    </TagDisplay>
                    <TagDisplay
                        color={localData.display_on_list ? TAG_DISABLED_COLOR : 'darkkhaki'}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                display_on_list: !prevData.display_on_list,
                            }))
                        }}
                    >
                        HIDE FROM SUMMARY PAGE
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>
                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={localData.is_active ? 'gray' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_active: !prevData.is_active,
                            }))
                        }}
                    >
                        VISIBLE
                    </TagDisplay>
                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={localData.is_active ? TAG_DISABLED_COLOR : 'gray'}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_active: !prevData.is_active,
                            }))
                        }}
                    >
                        HIDDEN/DISABLED
                    </TagDisplay>
                </Col>
            </Row>

            <Row className="mt-3 border-top pt-2">
                <Col>

                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={!localData.is_permanent ? 'gray' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_permanent: !prevData.is_permanent,
                            }))
                        }}
                    >
                        CLEARABLE
                    </TagDisplay>
                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={localData.is_permanent ? 'gray' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_permanent: !prevData.is_permanent,
                            }))
                        }}
                    >
                        PERMANENT
                    </TagDisplay>
                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={(localData.is_permanent  && localData.permanent_removable_by_employee) ? 'gray' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_permanent: true,
                                permanent_removable_by_employee: !prevData.permanent_removable_by_employee,
                            }))
                        }}
                    >
                        CLEARABLE BY EMPLOYEES
                    </TagDisplay>
                    <TagDisplay
                        disabled={!canDeleteTag}
                        color={(localData.is_permanent && localData.permanent_removable_by_creator) ? 'gray' : TAG_DISABLED_COLOR}
                        onClick={() => {
                            setLocalData(prevData => ({
                                ...prevData,
                                is_permanent: true,
                                permanent_removable_by_creator: !prevData.permanent_removable_by_creator,
                            }))
                        }}
                    >
                        CLEARABLE BY TAGGER
                    </TagDisplay>

                </Col>
            </Row>

            {
                colorPickerOpen && <TagColorPickerModal
                    isOpen={colorPickerOpen}
                    toggle={() => {
                        setColorPickerOpen(false)
                    }}
                    setColor={(color) => {
                        setLocalData(prevData => ({
                            ...prevData,
                            color: color
                        }))
                    }}
                    color={localData.color || '#666666'}
                    setTextColor={(textColor) => {
                        setLocalData(prevData => ({
                            ...prevData,
                            text_color: textColor
                        }))
                    }}
                    textColor={localData.text_color || '#FFFFFF'}
                />
            }
            {
                expirationPickerOpen && <ExpirationPickerModal
                    isOpen={expirationPickerOpen}
                    toggle={() => {
                        setExpirationPickerOpen(false)
                    }}
                    expiration={localData.tag_expiration}
                    setExpiration={(expiration) => {
                        setLocalData(prevData => ({
                            ...prevData,
                            tag_expiration: expiration
                        }))
                    }}/>
            }
            {
                tagConditionPickerOpen && <TagConditionModal
                    isOpen={tagConditionPickerOpen}
                    toggle={() => {
                        setTagConditionPickerOpen(false)
                    }}
                    condition={{
                        auto_tag_new: localData.auto_tag_new,
                        auto_tag_if_taggroup_added: localData.auto_tag_if_taggroup_added,
                        auto_tag_if_tag_added: localData.auto_tag_if_tag_added,
                    }}
                    setCondition={(condition) => {
                        setLocalData(prevData => ({
                            ...prevData,
                            ...condition
                        }))
                    }}
                    tagData={localData}
                />
            }
            {
                selectExtraInfoModalOpen && <SelectExtraInfoSourceModal
                    isOpen={selectExtraInfoModalOpen}
                    toggle={() => {
                        setSelectExtraInfoModalOpen(false)
                    }}
                    sourceData={{
                        multiple_source: localData.multiple_source,
                        source_model_name: localData.source_model_name,
                        source_object_id: localData.source_object_id,
                    }}
                    setSourceData={(newSourceData) => {
                        setLocalData(prevData => ({
                            ...prevData,
                            ...newSourceData
                        }))
                    }}
                    />
            }
        </ModalBody>
        <ModalFooter>

            <Row className="">
                <Col className="text-end">
                    <Button
                        onClick={() => {
                            saveMutation.mutate(localData)
                        }}
                        color="info" disabled={!fieldsComplete || fieldsChanged.length <= 0}>
                        SAVE TAG
                    </Button>
                </Col>

            </Row>
        </ModalFooter>
    </Modal>
}