import React from "react";
import TagDisplay from "../../../../../tagDisplay";
import {Spinner} from "reactstrap";
import TagFilterSelectorModal from "./tagFilterSelectorModal";
import {mergeTagData, updateProfileTagData} from "./constants";
import {usePropertyGroups, useTagGroups} from "../../../../../hooks";


export default function TagFilter({activeTagIds, setActiveTagIds, showSpecialTagGroup, showPropertyGroups}) {
    const {data: propertyGroups, isLoading: isLoadingPropertyGroups} = usePropertyGroups({enabled: showPropertyGroups})
    const {data: tagGroups, isLoading: isLoadingTagGroups} = useTagGroups({})
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const propertyGroupIds = propertyGroups?.map(row => row.id) || []

    if (isLoadingTagGroups) {
        return <Spinner size={"sm"}/>
    }

    const tags = tagGroups.reduce((acc, row) => {
        acc.push(...row.tags)
        return acc
    }, [])


    const showTagSelector = () => {
        setIsModalOpen(true)
    }

    const setSelectedTags = (newSelectedTags) => {
        setActiveTagIds(newSelectedTags.filter(row => !row._delete).map(row => row.tag))
    }
    const selectedTags = [
        ...tags.filter(row => {
            return activeTagIds.includes(row.id)
        }).map(tag => {
            return {
                id: tag.id,
                tag: tag.id,
                tag_data: tag
            }
        }),
        ...propertyGroupIds.filter(row => {
            return activeTagIds.includes(`property-${row}`)
        }).map(propertyId => {
            return {
                id: `property-${propertyId}`,
                tag: `property-${propertyId}`,
                tag_data: {
                    id: `property-${propertyId}`,
                    tag_group: 'property',
                    name: propertyGroups.find(row => row.id === propertyId).name,
                    color: propertyGroups.find(row => row.id === propertyId).color,
                    text_color: propertyGroups.find(row => row.id === propertyId).text_color,
                }
            }
        })
    ]


    if (activeTagIds.includes('merge')) {
        selectedTags.push(mergeTagData)
    }
    if (activeTagIds.includes('update_profile')) {
        selectedTags.push(updateProfileTagData)
    }


    return <>
        {
            ((activeTagIds.length === 0 || activeTagIds.length === tags.length)) ?
                <TagDisplay color={"#cdcdcd"} className="mx-2" style={{color: "#666"}} onClick={showTagSelector}>
                    ALL TAGS
                </TagDisplay> :
                <>

                    {
                        activeTagIds.map(tagId => {
                            if (tagId === 'update_profile') {
                                return <TagDisplay
                                    key={tagId}
                                    color={"#b0c4de"}
                                    className=""
                                    onClick={showTagSelector}
                                    onDelete={() => setActiveTagIds(activeTagIds.filter(row => row !== tagId))}
                                >
                                    UPDATED PROFILE
                                </TagDisplay>
                            }
                            if (tagId === 'merge') {
                                return <TagDisplay
                                    key={tagId}
                                    color={"#b0c4de"}
                                    className=""
                                    onClick={showTagSelector}
                                    onDelete={() => setActiveTagIds(activeTagIds.filter(row => row !== tagId))}
                                >
                                    MERGE
                                </TagDisplay>
                            }
                            // check if tagId starts with property
                            if (tagId.toString().includes('property')) {
                                const propertyId = parseInt(tagId.split('-')[1])
                                const property = propertyGroups.find(row => row.id === propertyId)
                                if (!property) {
                                    return `Property not found ${propertyId}`
                                }
                                return <TagDisplay
                                    key={tagId}
                                    description={property.description}
                                    textColor={property.text_color}
                                    color={property.color}
                                    onClick={showTagSelector}
                                    onDelete={() => setActiveTagIds(activeTagIds.filter(row => row !== tagId))}
                                >
                                    {property.name}
                                </TagDisplay>
                            }
                            const tag = tags.find(row => row.id === tagId)
                            if (!tag) {
                                return `Tag not found ${tagId}`
                            }
                            return <TagDisplay
                                key={tagId}
                                description={tag.description}
                                textColor={tag.text_color}
                                color={tag.color}
                                className=""
                                onClick={showTagSelector}
                                onDelete={() => setActiveTagIds(activeTagIds.filter(row => row !== tagId))}
                            >
                                {tag.name}
                            </TagDisplay>
                        })
                    }
                </>
        }

        <TagFilterSelectorModal
            setSelectedTags={setSelectedTags}
            selectedTags={selectedTags}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            showSpecialTagGroup={showSpecialTagGroup}
            showPropertyGroups={showPropertyGroups}
        />
    </>
}