import React from "react";
import axios from "axios";
import {Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {useQuery} from "react-query";
import {PRIVACY_POLICY_ID} from "../../constants";
import {toast} from "react-toastify";

export default function PrivacyPolicyModal(props) {
    const privacyResult = useQuery(['privacy-policy'], () => {
        return axios.get(`view-privacy-policy/${PRIVACY_POLICY_ID}/`).then(response => {
            return response.data.content
        })
    }, {
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        onError: (err) => {
            console.error(err)
            toast.error("Cannot fetch privacy policy.")
            throw err
        }
    })

    return <Modal
        isOpen={props.isOpen}
        size={"xl"}
        toggle={props.toggle}
        centered
        scrollable
    >
        <ModalHeader toggle={props.toggle}>
            Privacy Policy
        </ModalHeader>
        <ModalBody className="overflow-scroll">
            {
                privacyResult.isLoading ?
                    <div className="text-center"><Spinner/></div> :
                    (
                        privacyResult.isSuccess ?
                            <div dangerouslySetInnerHTML={{__html: privacyResult.data}}/> :
                            <div className="text-center text-danger">Cannot load privacy policy.</div>
                    )
            }

        </ModalBody>
    </Modal>
}