import React from "react";
import {useLoggedInApi} from "../../../../api";
import {useQuery, useQueryClient} from "react-query";
import {Button, Card, CardBody, CardDeck, CardFooter, Col, Input, Label, Row} from "reactstrap";
import moment from "moment";
import Inquiry from "./components/inquiry";

export default function InquiryList() {
    const api = useLoggedInApi()
    const [selectIsResolved, setSelectIsResolved] = React.useState(false)

    const queryClient = useQueryClient()
    const {isLoading, data} = useQuery(
        ["inquiry", {isResolved: selectIsResolved}],
        () => {
            const resource = api.resource('help-inquiry')
            return resource.list({
                params: {
                    is_resolved: selectIsResolved
                }
            }).then(res => res.data)
        },
        {
            staleTime: 1000 * 60 * 60 * 24,
            onSuccess: (data) => {
                data.forEach((inquiry) => {
                    queryClient.setQueryData(["inquiry", inquiry.id], inquiry)
                })
            }
        }
    )

    if (isLoading) {
        return <div>Loading...</div>
    }

    const selectedBorder = "border-1 border-bottom-0 border-dark border-opacity-10 rounded-top border text-decoration-underline"
    const unselectedBorder = "border-1 border-bottom border-dark border-opacity-10 rounded-top border-0"

    return <>
        <h1>Inquiry List</h1>
        <Row>
            <Col className={`p-3 text-center pointer-cursor ${selectIsResolved ? unselectedBorder : selectedBorder}`} onClick={() => {
                setSelectIsResolved(false)
            }}>
                <h5>
                    Unresolved
                </h5>
            </Col>
            <Col className={`p-3 text-center pointer-cursor ${selectIsResolved ? selectedBorder : unselectedBorder}`} onClick={() => {
                setSelectIsResolved(true)
            }}>
                <h5>
                    Resolved
                </h5>
            </Col>
        </Row>
        <Row className="my-3 g-4 row-cols-1 row-cols-md-2">
            {
                data.map((inquiry, index) => {
                    return <Col key={inquiry.id}>
                        <Inquiry inquiryId={inquiry.id} defaultResolved={inquiry.is_resolved}/>
                    </Col>
                })
            }
        </Row>
    </>
}