import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import AdminApp from "./AdminApp";
import ServerAliveWrapper from "./commonComponents/serverAliveWrapper";


export default function App() {
    const queryClient = new QueryClient()

    return <>
        <QueryClientProvider client={queryClient}>
            <ServerAliveWrapper>
                <AdminApp/>
            </ServerAliveWrapper>
        </QueryClientProvider>
    </>
}