import React from "react";
import {FormFeedback, Input, Label} from "reactstrap";
import EmailField from "../common/emailField";

export default function FormFieldFactory({value, setValue, fieldType, label, required}) {
    const [hadFocus, setHadFocus] = React.useState(false)
    const labelDisplay = `${label}${required ? "*" : ""}`;
    const inputType = React.useMemo(() => {
        if (label.toLowerCase() === 'email address') {
            return 'email'
        }
        switch (fieldType) {
            case "text_long":
                return "textarea";
            case "text":
            case "number":
            case "date":
            case "tag":
                return fieldType;
            default:
                return null;
        }
    }, [fieldType, label]);

    if (!inputType) {
        return <i>Unknown field type: {fieldType} for <strong>{labelDisplay}</strong></i>
    }

    if (inputType === "email") {
        return <>
            <Label className="text-muted small lineheight-1">{labelDisplay}</Label>
            <EmailField
                onFocus={() => setHadFocus(true)}
                type={inputType} value={value || ""} onChange={(e) => setValue(e.target.value)}
                invalid={required && !value && hadFocus}
            />
            {required && <FormFeedback>
                This field is required.
            </FormFeedback>}
        </>
    }

    return (
        <>
            <Label className="text-muted small lineheight-1">{labelDisplay}</Label>
            <Input
                // onFocus={() => setHadFocus(true)}
                onBlur={() => setHadFocus(true)}
                invalid={required && !value && hadFocus}
                type={inputType} value={value || ""} onChange={(e) => setValue(e.target.value)}/>
            {required && <FormFeedback>
                This field is required.
            </FormFeedback>}
        </>
    );
}