import React from "react";
import * as PropTypes from "prop-types";
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {useNavigate} from "react-router-dom";

function InfoCard(props) {
    const navigate = useNavigate()
    return <Card
        color={props.color}
        className={"text-white " + (props.navigateTo ? "pointer-cursor" : "")}
        style={(!!props.color && props.color[0] === '#') ? {backgroundColor: props.color} : null}>
        <CardBody className="text-center" onClick={() => {
            if (props.navigateTo) {
                navigate(props.navigateTo)
            }
        }}>
            <Row>
                <Col>{props.label}</Col>
            </Row>

            <Row className="pt-2">
                <Col>
                    {
                        props.isLoading ? <Spinner/> : <p className="info-card-value">
                            {props.value}
                        </p>
                    }
                </Col>
            </Row>
        </CardBody>
    </Card>
}

InfoCard.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    value: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.number
    ]),
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    navigateTo: PropTypes.string
}

export default InfoCard