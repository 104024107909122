import React from "react";
import InputRow from "../inputRow";
import countryList from "./countries.json";
import cityMap from "./ph_cities.json"
import CreatableSelect from "react-select/creatable";
import {Col, Row} from "reactstrap";

export default function ContactInfo({profileData, isProfileSaving, updateErrorStatus, setProfileData, emailAlreadyUsed}) {
    const getOptionsAndSelectedOption = (value, valueList) => {
        const options = valueList.sort((a, b) => {
            if (a === b) return 0
            if (a === "Philippines") return -1
            if (b === "Philippines") return 1

            if (a === "NCR") return -1
            if (b === "NCR") return 1
            return a > b ? 1 : -1
        }).map(row => ({label: row, value: row}))
        const possibleOptions = options.filter(o => o.value === value)[0]
        const selectedOption = value ? possibleOptions : null
        if (!!value && !selectedOption) {
            const newOption = {label: value, value}
            options.push(newOption)
            return [options, newOption]
        }
        return [options, selectedOption]
    }

    const setProfileValue = (key, newValue) => {
        const newProfileData = {
            ...profileData,
            profile: {
                ...profileData.profile,
                [key]: newValue
            },
        }

        if (key === "address_region") {
            newProfileData.profile.address_province = null
            newProfileData.profile.address_city = null
        }
        if (key === "address_province") {
            newProfileData.profile.address_city = null
        }

        setProfileData(newProfileData)
    }

    const [countryOptions, selectedCountryOption] = getOptionsAndSelectedOption(profileData.profile.address_country, countryList.map(row => row.name))
    const [regionOptions, selectedRegionOption] = getOptionsAndSelectedOption(
        profileData.profile.address_region,
        profileData.profile.address_country === "Philippines" ? Object.keys(cityMap) : []
    )
    const [provinceOptions, selectedProvinceOption] = getOptionsAndSelectedOption(
        profileData.profile.address_province,
        profileData.profile.address_region ? Object.keys(cityMap[profileData.profile.address_region]) : []
    )

    const [cityOptions, selectedCityOption] = getOptionsAndSelectedOption(
        profileData.profile.address_city,
        profileData.profile.address_province ? cityMap[profileData.profile.address_region][profileData.profile.address_province] : []
    )
    return <>

        <h5 className="mt-5">Contact Information</h5>
        <Row>
            <Col md={6}>
                <InputRow
                    required={false}
                    readOnly={false}
                    label={"Country"}
                    value={selectedCountryOption}
                    setHasError={hasError => updateErrorStatus('address_country', hasError)}
                    onChange={newOption => setProfileValue('address_country', newOption ? newOption.value : null)}
                    onCreateOption={newOption => setProfileValue('address_country', newOption)}
                    renderer={renderProps => <CreatableSelect
                        {...renderProps}
                        placeholder={"Select country"}
                        // styles={REACT_SELECT_STYLES}
                        options={countryOptions}
                    />}
                />
            </Col>
            <Col md={6}>

                <InputRow
                    required={false}
                    readOnly={false}
                    label={"Region"}
                    value={selectedRegionOption}
                    setHasError={hasError => updateErrorStatus('address_region', hasError)}
                    onChange={newOption => setProfileValue('address_region', newOption ? newOption.value : null)}
                    onCreateOption={newOption => setProfileValue('address_region', newOption)}
                    renderer={renderProps => <CreatableSelect
                        {...renderProps}
                        isDisabled={profileData.profile.address_country !== "Philippines" || !profileData.profile.address_country}
                        placeholder={"Select region"}
                        // styles={REACT_SELECT_STYLES}
                        options={regionOptions}
                    />}
                />
            </Col>

        </Row>


        <Row>
            <Col md={6}>
        <InputRow
            required={false}
            readOnly={false}
            label={"Province"}
            value={selectedProvinceOption}
            setHasError={hasError => updateErrorStatus('address_province', hasError)}
            onChange={newOption => setProfileValue('address_province', newOption ? newOption.value : null)}
            onCreateOption={newOption => setProfileValue('address_province', newOption)}
            renderer={renderProps => <CreatableSelect
                {...renderProps}
                isDisabled={profileData.profile.address_country !== "Philippines" || !profileData.profile.address_country || !profileData.profile.address_region}
                placeholder={"Select province"}
                // styles={REACT_SELECT_STYLES}
                options={provinceOptions}
            />}
        />
            </Col>
            <Col md={6}>
                <InputRow
                    required={false}
                    readOnly={false}
                    label={"City"}
                    value={selectedCityOption}
                    setHasError={hasError => updateErrorStatus('address_city', hasError)}
                    onChange={newOption => setProfileValue('address_city', newOption ? newOption.value : null)}
                    onCreateOption={newOption => setProfileValue('address_city', newOption)}
                    renderer={renderProps => <CreatableSelect
                        {...renderProps}
                        isDisabled={profileData.profile.address_country !== "Philippines" || !profileData.profile.address_country || !profileData.profile.address_region || !profileData.profile.address_province}
                        placeholder={"Select city"}
                        // styles={REACT_SELECT_STYLES}
                        options={cityOptions}
                    />}
                />
            </Col>

        </Row>
        <InputRow
            label={"Address Detail"}
            value={profileData.profile.address_details}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('address_details', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    address_details: newValue
                }
            })}/>
        <InputRow
            label={"Phone Number"}
            value={profileData.profile.phone_number}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('phone_number', hasError)}

            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    phone_number: newValue
                }
            })}/>
        <InputRow
            required
            label={"Mobile Number"}
            value={profileData.profile.mobile_number}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('mobile_number', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    mobile_number: newValue
                }
            })}/>

        {/* TODO: check if email address is already used. */}
        {/* TODO: properly handle errors coming from the API */}
        <InputRow
            required
            label={"Email Address"}
            value={profileData.user.email}
            disabled={isProfileSaving}
            pattern={"^\\w+([\\.\+-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"}
            setHasError={hasError => updateErrorStatus('email', hasError)}
            errorMessage={emailAlreadyUsed ? "Already used": null}
            onChange={newValue => setProfileData({
                ...profileData,
                user: {
                    ...profileData.user,
                    email: newValue
                }
            })}/>

    </>
}