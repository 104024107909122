import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import TagGroupTagSelector from "../../common/tagGroupTagSelector";
import TagDisplay from "../../../../../tagDisplay";
import {mergeTagData, specialTagGroup, updateProfileTagData} from "./constants";
import {usePropertyGroups, useTagGroups} from "../../../../../hooks";


export default function TagFilterSelectorModal(
    {
        isOpen,
        toggle,
        selectedTags,
        setSelectedTags,
        showSpecialTagGroup,
        showPropertyGroups
    }
) {
    const {data: tagGroupData, isLoading} = useTagGroups({});
    const {data: propertyGroups, isLoading: isLoadingPropertyGroups} = usePropertyGroups({enabled: showPropertyGroups})

    const propertyTags = propertyGroups?.map(propertyGroup => {
        return {
            is_active: true,
            id: `property-${propertyGroup.id}`,
            tag_group: 'property',
            name: propertyGroup.name,
            color: propertyGroup.color,
            text_color: propertyGroup.text_color,
        }
    })

    const propertyTagGroup = {
        id: 'property',
        name: 'Properties',
        is_active: true,
        tags: propertyTags || [],
    }

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row className="border-bottom">
                <Col>
                    <h3>Select tags to show</h3>
                </Col>
            </Row>

            {
                isLoading ?
                    <>
                        <Spinner/>
                    </> :
                    <Row>
                        <Col className="mx-2">
                            {
                                showPropertyGroups && <TagGroupTagSelector
                                    tagGroup={propertyTagGroup}
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}
                                    doNotFollowRules={true}
                                />
                            }
                            {
                                showSpecialTagGroup && <TagGroupTagSelector
                                    tagGroup={specialTagGroup}
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}
                                    doNotFollowRules={true}
                                />
                            }
                            {
                                tagGroupData?.map(row => {
                                    return <TagGroupTagSelector
                                        key={row.id}
                                        tagGroup={row}
                                        selectedTags={selectedTags}
                                        setSelectedTags={setSelectedTags}
                                        doNotFollowRules={true}
                                    />
                                })
                            }
                        </Col>
                    </Row>
            }

        </ModalBody>
        <ModalFooter>
            <Button color="primary" className="ms-4" onClick={() => {
                toggle()
            }}>
                OK
            </Button>
        </ModalFooter>
    </Modal>
}