import React, {useContext} from "react";
import {useEmployees} from "../../../../../api/hooks";
import NestedEmployee from "./nestedEmployee";
import {ProfileContext} from "../../../../../commonComponents/contexts";


export default function Organization({toggleView}) {
    const {profile} = useContext(ProfileContext)

    const {data: employees} = useEmployees({
        params: {
            page_size: 1000,
        }
    })

    const sortedEmployees = employees?.results?.sort((a, b) => {
        return a.active_group_employee_id.localeCompare(b.active_group_employee_id)
    }) || []

    const topManagers = sortedEmployees?.filter((employee) => {
        return employee.manager === null || !employee.manager_is_active || employee.manager === profile.user_id
            // && employees.results.some((subEmployee) => subEmployee.manager === employee.user_id)
    }) || []

    return <div>
        <p>Better view for users who are managed by other users.</p>
        <ul style={{listStyleType: 'none'}}>
            {
                topManagers.map((employee) => <NestedEmployee
                    key={employee.user_id}
                    employee={employee}
                    employees={sortedEmployees}
                />)
            }
        </ul>
    </div>

}