import React from "react";
import {Col, Row} from "reactstrap";
import Select from "react-select";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";
import InputRangeSelector from "../../../../commonComponents/inputRangeSelector";

export default function Filter({filters, setFilters}) {
    const fromDate = filters["from"]
    const toDate = filters["to"]
    const setFromDate = newDate => {
        setFilters(prevData => ({
            ...prevData,
            fromDate: newDate
        }))
    }
    const setToDate = newDate => {
        setFilters(prevDate=> ({
            ...prevDate,
            toDate: newDate
        }))
    }

    const api = useLoggedInApi()
    const query = useQuery(['properties', 'report', 'group_and_region'], () => {
        const resource = api.resource('properties/groups')
        return resource.getItem('group_and_region').then(response => response.data)
    })

    const groups = query.data?.filter(row => {
        if (!!filters.region) {
            return row[2] === filters.region
        }
        return true
    }).map(row => {
        return {
            label: row[1],
            value: row[0]
        }
    }).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    console.log(filters, query.data)
    const regions = query.data?.filter(row => {
        if (!!filters.pk) {
            return row[0] === filters.pk
        }
        return true
    }).map(row => row[2]).reduce((acc, region) => {
        if (!acc.includes(region)) {
            acc.push(region)
        }
        return acc
    }, []).sort().map(region => {
        return {
            label: region,
            value: region
        }
    })

    return <div  className="border-light border-1 border-primary border">
        <Row className="p-4">
            <Col md={6} sm={12}>
                Property Group
                <Select
                    placeholder={"All"}
                    isClearable={true}
                    options={groups}
                    onChange={newOption => {
                        if (newOption) {
                            setFilters({...filters, pk: newOption.value})
                        } else {
                            setFilters({...filters, pk: undefined})
                        }
                    }}
                />
            </Col>
            <Col md={6} sm={12}>
                Region
                <Select
                    placeholder={"All"}
                    isClearable={true}
                    options={regions}
                    onChange={newOption => {
                        if (newOption) {
                            setFilters({...filters, region: newOption.value})
                        } else {
                            setFilters({...filters, region: undefined})
                        }
                    }}
                />
            </Col>

            <Col md={12} sm={12}>
                <InputRangeSelector
                    fromDate={fromDate} setFromDate={setFromDate}
                    toDate={toDate} setToDate={setToDate}
                />
            </Col>

        </Row>
    </div>
}