import React from "react";
import InputRow from "../inputRow";
import {useEmployeeManagers, usePermissionContextCheck} from "../../commonComponents/hooks";
import {ProfileContext} from "../../commonComponents/contexts";


export default function Manager(
    {profileData, setProfileData, isProfileSaving, readOnly=false}
) {
    const canEditManager = usePermissionContextCheck('employee.change_employeeprofile_manager')
    const {profile} = React.useContext(ProfileContext)
    const user_id = profile?.user_id

    const {data: managers, isLoading} = useEmployeeManagers({
        params: {limit: 1000},
        queryOptions: {
            enabled: canEditManager && !readOnly,
            refetchOnWindowFocus: false,
            refetchOnMount: false
        }
    })

    const managerOptions = managers?.filter(manager => manager.user_id !== profileData.user_id).map(manager => {
        return {
            label: `${manager.user.first_name} ${manager.user.last_name} (${manager.active_group_employee_id})`,
            value: manager.user_id
        }
    })

    const noValueOption = React.useMemo(() => {
        if (user_id === profileData.manager && !managerOptions?.find(manager => manager.user_id === user_id)) return <option value={profileData.manager}>You</option>
        if (!managerOptions || managerOptions.length === 0) return <option value={""}>No users available</option>
        return <option value={""}>Select User</option>
    })

    if (!canEditManager && !profileData.manager) {
        return null
    }


    return <>
        <InputRow
            disabled={!canEditManager || readOnly}
            label={"Reports to (optional)"}
            value={profileData.manager}
            type={"select"}
            onChange={newValue => setProfileData({...profileData, manager: newValue})}
        >
            {noValueOption}
            {managerOptions?.map((option, index) => {
                return <option key={index} value={option.value}>{option.label}</option>
            })}
        </InputRow>
    </>
}