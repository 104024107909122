import React from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {Button, Col, Row, Spinner} from "reactstrap";
import Field from "./field";
import {ReactSortable} from "react-sortablejs";
import {dictChangedValues, dictEqualShallow} from "../../../../commonComponents/util";
import {toast} from "react-toastify";
import EditLeadFieldModal from "./editLeadFieldModal";
import PermissionRequiredComponent from "../../../../commonComponents/permissionRequiredComponent";
import {useLeadForm, usePermissionContextCheck} from "../../../../commonComponents/hooks";


export default function LeadProfileManagement() {
    const canEditFormField = usePermissionContextCheck('tagform.change_formfield')
    const [localFields, setLocalFields] = React.useState([])
    const [createModalOpen, setCreateModalOpen] = React.useState(false)


    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const {data: leadFormData, isLoading: isLeadFormLoading} = useLeadForm()

    const fields = leadFormData?.form_fields

    React.useEffect(() => {
        if (fields) {
            setLocalFields(fields)
        }
    }, [fields])

    const sortedLocalFields = React.useMemo(() => {
        return localFields.sort((a, b) => a.order - b.order)
    }, [localFields])

    const setSortedLocalFields = (newFields) => {
        //     update order field for each field
        const readOnlyFields = newFields.filter(field => !field.is_modifiable)
        const editableFields = newFields.filter(field => field.is_modifiable)
        const newLocalFields = [...readOnlyFields, ...editableFields].map((field, index) => {
            return {...field, order: index + 1}
        })
        setLocalFields(newLocalFields)
    }

    const updateField = (fieldIndex, newFieldData) => {
        const newLocalFields = localFields.map((localField, localFieldIndex) => {
            if (localFieldIndex === fieldIndex) {
                return {...localField, ...newFieldData}
            }
            return localField
        })
        setLocalFields(newLocalFields)
    }

    const getChangedFields = (newFields) => {
        return newFields.filter(field => {
            return !!field.id
        }).filter(field => {
            // compare with leadFormData.fields
            const originalField = leadFormData.form_fields.find(row => row.id === field.id)
            return !dictEqualShallow(originalField, field)
        }).reduce((acc, field) => {
            // return only changed fields
            const originalField = leadFormData.form_fields.find(row => row.id === field.id)

            const changedValues = dictChangedValues(originalField, field)
            acc[field.id] = changedValues
            return acc
        }, {})
    }

    const getCreateFields = (newFields) => {
        return newFields.filter(field => !field.id)
    }

    const saveMutation = useMutation((newFields) => {
        const fieldResource = api.resource('form/field')
        const changedFields = getChangedFields(newFields)

        const updatePromises = Object.keys(changedFields).map((fieldId) => {
            const changedData = changedFields[fieldId]
            return fieldResource.patchItem(fieldId, changedData)
        })

        if (Object.keys(changedFields).length > 0) {
            toast.promise(Promise.all(updatePromises), {
                pending: "Updating fields...",
                error: "Error updating fields.",
                success: "Updated existing fields."
            })
        }

        const createFields = getCreateFields(newFields)
        const createPromises = createFields.map(field => {
            return fieldResource.create(field)
        })

        if (createFields.length > 0) {
            toast.promise(Promise.all(createPromises), {
                pending: "Adding new fields...",
                error: "Error creating fields.",
                success: "Added new fields."
            })
        }

        const promises = Promise.all(
            [...updatePromises, ...createPromises]
        )

        return promises
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['form', 'lead'])
        }
    })


    const createField = (newField) => {
        setLocalFields([
            ...localFields,
            {
                ...newField,
                order: localFields.length + 1,
                form: leadFormData.id,
                is_active: true,
                is_modifiable: true
            }
        ])
    }

    const hasChanges = React.useMemo(() => {
        const changedFields = getChangedFields(localFields)
        const createFields = getCreateFields(localFields)
        return Object.keys(changedFields).length > 0 || createFields.length > 0
    }, [localFields])

    const usedTagGroups = React.useMemo(() => {
        return localFields.filter(field => field.field_type === "tag").map(field => field.tag_group)
    }, [localFields])


    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Leads Profile Settings</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Manage the profile settings of leads.
            </Col>
        </Row>
        {
            isLeadFormLoading ?
                <Row className="text-center my-5">
                    <Col>
                        <Spinner/>
                    </Col>
                </Row> :
                <>
                    <ReactSortable
                        disabled={!canEditFormField}
                        list={sortedLocalFields}
                        setList={setSortedLocalFields}
                        handle={".sort-icon"}
                        filter={".read-only"}
                    >
                        {
                            sortedLocalFields?.map((field, fieldIndex) => <Field
                                usedTagGroups={usedTagGroups}
                                readOnly={!field.is_modifiable}
                                field={field} key={field.name + "|" + field.id}
                                setField={(newFieldData) => {
                                    updateField(fieldIndex, newFieldData)
                                }}
                            />)
                        }
                    </ReactSortable>

                    {
                        createModalOpen && <EditLeadFieldModal
                            field={{
                                field_type: "text",
                                is_active: true
                            }}
                            setField={createField}
                            toggle={() => setCreateModalOpen(!createModalOpen)}
                            isOpen={createModalOpen}
                            usedTagGroups={usedTagGroups}
                        />
                    }
                    <PermissionRequiredComponent requiredPermissions={['tagform.add_formfield']}>
                        <Row className="border-bottom pb-4">
                            <Col className="mt-4 text-end">
                                <Button color="info text-white" onClick={() => {
                                    setCreateModalOpen(true)
                                }}>
                                    ADD LEAD INFORMATION
                                </Button>

                            </Col>
                        </Row>
                    </PermissionRequiredComponent>
                    <Row className={`mb-5 ${hasChanges ? "sticky-bottom" : ""}`}>
                        <Col className="mt-4 text-end pb-2 bg-white">
                            {hasChanges && <span className="text-muted me-3">You have unsaved changes.</span>}
                            <Button
                                disabled={!hasChanges}
                                color="primary text-white" onClick={() => {
                                saveMutation.mutate(localFields)
                            }}>
                                SAVE SETTINGS
                            </Button>

                        </Col>
                    </Row>
                </>
        }
    </>
}