import React from "react";
import DataTable from "react-data-table-component";
import EditModal, {DEFAULT_LEAD_DATA} from "../lead/editModal";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil, faWarning, faX} from "@fortawesome/free-solid-svg-icons";
import TagFormDisplay from "../lead/common/tagFormDisplay";
import PropertyGroupSelectorAsTag from "../../../propertyGroupSelectorAsTag";
import moment from "moment";
import {useLeadForm, useSalesPersonOptions, useTagGroups} from "../../../hooks";


const getValue = (row, field) => {
    if (field.field_type === "tag") {
        const formTags = row.form_tags.filter(formTag => {
            return formTag.tag_data.tag_group === field.tag_group
        })
        return formTags.length > 0 ? formTags : undefined
    }
    if (field.is_direct_property) {
        return row[field.property_name]
    }

    const formAnswer = row.form_answers.find(answer => answer.form_field === field.id)
    if (!formAnswer) {
        return undefined;
    }

    const value = formAnswer?.answer
    if (field.field_type === 'date') {
        return value ? new Date(value).toLocaleDateString() : ''
    }
    return value
}

const getIsValueValid = (row, field) => {
    const value = getValue(row, field)
    if (field.is_required) {
        if (field.field_type === "tag") {
            if (!value || value.length === 0) {
                return false
            }
        }
        return value !== undefined && value !== null && value !== ""
    }

    if (value) {
        if (field.field_type === 'date') {
            return moment(value).isValid()
        } else if (field.field_type === 'number') {
            return !isNaN(value)
        }
    }
    return true
}

const getDisplayValue = (row, field) => {
    const value = getValue(row, field)
    if (field.field_type === "tag") {
        return value?.map(tagForm => {
            return <TagFormDisplay tagForm={tagForm} isColorActive={true}/>
        })
    }
    return <span title={value}>
        {value}
    </span>
}

export default function EditableLeadTable({data, saveData, isLoadingData, isSaving}) {
    const [localData, setLocalData] = React.useState([])
    const [editingIndex, setEditingIndex] = React.useState(null)

    const {data: tagGroups, isLoading: isLoadingTagGroups} = useTagGroups({})
    const {data: leadForm, isLoading: isLoadingLeadForm} = useLeadForm()
    const {
        ungroupedOptions: ungroupedSalesPersonOptions,
        isLoading: isSalesPersonOptionsLoading
    } = useSalesPersonOptions()

    const sortedFields = React.useMemo(() => {
        const fields = leadForm?.form_fields || []
        return fields.sort((a, b) => a.order - b.order).filter(field => {
            return field.is_active
        }).map(field => {
            const nameAsLabel = field.name.toLowerCase().replace(" ", "_")
            return {
                ...field,
                property_name: nameAsLabel,
                is_direct_property: DEFAULT_LEAD_DATA[nameAsLabel] !== undefined
            }
        })
    }, [leadForm])

    React.useEffect(() => {
        const tagGroupById = tagGroups?.reduce((acc, tagGroup) => {
            acc[tagGroup.id] = tagGroup
            return acc
        }, {});

        if (!tagGroupById) {
            return
        }

        const tmpData = (data || []).map((row, index) => {
            const leadData = {
                index: index,
                ...JSON.parse(JSON.stringify(DEFAULT_LEAD_DATA)),
                original: row,
                is_included: true
            }

            const addTag = (tag, moreInfo) => {
                leadData.form_tags.push({
                    id: Date.now(),
                    __isNew: true,
                    tag_data: tag,
                    tag: tag.id,
                    ...(moreInfo || {})
                })
            }
            sortedFields.forEach(field => {
                const rawValue = row[field.name]

                if (field.field_type === "tag") {
                    const tagGroup = tagGroupById[field.tag_group]

                    if (rawValue === undefined || rawValue === null || rawValue === "") {
                        if (field.is_required) {
                            const defaultTagId = parseInt(field.default_value)
                            const defaultTag = tagGroup?.tags.find(tag => tag.id === defaultTagId)
                            if (defaultTag) {
                                addTag(defaultTag)
                                return
                            }
                        }
                        return
                    }

                    const tag = tagGroup?.tags.find(tag => tag.name === rawValue)

                    if (!tag) {
                        // special case if tagGroup is Source and no tag is found
                        if (tagGroup?.name === 'Source') {
                            const manualTag = tagGroup.tags.find(tag => tag.system_identifier === 'source_manual');
                            if (manualTag) {
                                addTag(manualTag, {
                                    source_text: rawValue
                                })
                                return
                            }
                        }
                        return
                    }
                    addTag(tag)
                    return
                }
                if (field.is_direct_property) {
                    leadData[field.property_name] = rawValue
                    return
                }

                if (!rawValue && field.is_required) {
                    leadData.form_answers.push({
                        form_field: field.id,
                        answer: field.default_value
                    })
                } else if (!!rawValue) {
                    // set as form answer
                    leadData.form_answers.push({
                        form_field: field.id,
                        answer: rawValue
                    })
                }

            })

            const rawSalesProfile = row['Sales Person/Broker']
            // find the id in between brackets
            const salesProfileId = rawSalesProfile?.match(/\[(\d+)]/)?.[0].replace(/\[|\]/g, "")
            if (salesProfileId) {
                leadData.sales_profile = parseInt(salesProfileId)
            }

            const rawPropertyGroups = row['Property Group']
            const propertyGroupId = rawPropertyGroups?.match(/\[(\d+)]/)?.[0].replace(/\[|\]/g, "")
            if (propertyGroupId) {
                leadData.property_groups = [parseInt(propertyGroupId)]
            }

            return leadData
        })
        setLocalData(tmpData)
    }, [data, sortedFields, tagGroups])

    const validatedData = React.useMemo(() => {
        return localData.map(row => {
            // check if there is a sales_profile
            const fieldValidity = {
                ...sortedFields.reduce((acc, field) => {
                    acc[field.id] = getIsValueValid(row, field)
                    return acc
                }, {})
            }
            if (!row.sales_profile) {
                fieldValidity['Sales Person/Broker'] = false
            }

            const tempRow = {
                is_valid: Object.keys(fieldValidity).every(key => fieldValidity[key]),
                fieldValidity: fieldValidity,
                ...row
            }

            if (!tempRow.is_valid) {
                tempRow.is_included = false
            }
            return tempRow
        })
    }, [localData, sortedFields])

    const dataForApi = React.useMemo(() => {
        return validatedData.filter(row => row.is_included).map(row => {
            const tmp = {
                ...row,
                form_tags: row.form_tags.map(tagForm => {
                    const tmpTagForm = {
                        ...tagForm
                    }
                    delete tmpTagForm.id
                    delete tmpTagForm.__isNew
                    delete tmpTagForm.tag_data
                    return tmpTagForm
                })
            }
            delete tmp.original
            delete tmp.fieldValidity
            return tmp
        })
    }, [validatedData])

    return <div>
        <DataTable
            loading={isLoadingLeadForm || isLoadingTagGroups || isSalesPersonOptionsLoading || isLoadingData}
            data={validatedData}
            striped
            responsive
            pagination
            columns={[
                {
                    name: '',
                    selector: row => row.index,
                    sortable: false,
                    format: row => {
                        return <>
                            <Input
                                type={"checkbox"}
                                disabled={!row.is_valid || isSaving}
                                checked={row.is_included}
                                onChange={e => {
                                    setLocalData(prevState => {
                                        return prevState.map(stateRow => {
                                            if (row.index === stateRow.index) {
                                                return {
                                                    ...stateRow,
                                                    is_included: e.target.checked
                                                }
                                            }
                                            return stateRow
                                        })
                                    })
                                }}
                                className="me-2"
                            />
                            <Label check>Include</Label>
                            <br/>

                            <Button
                                disabled={isSaving}
                                onClick={() => {
                                    setEditingIndex(row.index)
                                }}
                                color="link" size={"sm"}
                            >
                                <FontAwesomeIcon icon={faPencil} className="me-2" size="sm"/>
                                <small>Edit Row</small>
                            </Button>
                        </>
                    },
                    width: 'auto',
                    // button: true
                },
                {
                    name: 'Is Valid',
                    selector: row => row.is_valid,
                    sortable: true,
                    format: row => {
                        return <FontAwesomeIcon icon={row.is_valid ? faCheck : faX}
                                                className={row.is_valid ? "text-success" : "text-danger"}/>
                    },
                    center: true,
                    width: 'auto',
                    compact: true,
                },
                ...sortedFields.map(field => {
                    return {
                        name: field.name,
                        selector: row => {
                            return getValue(row, field)
                        },
                        format: row => {
                            const displayValue = getDisplayValue(row, field)

                            if (row.fieldValidity[field.id] === false) {
                                return <span className="text-danger">
                                    <FontAwesomeIcon icon={faWarning} className="me-2"/>
                                    {displayValue}
                                </span>
                            }
                            return displayValue
                        },
                        sortable: true,
                        // width: 'auto',
                        wrap: field.field_type === 'tag' || field.field_type === 'text_long'

                    }
                }),
                {
                    name: 'Property Group',
                    selector: row => {
                        return <PropertyGroupSelectorAsTag
                            isMulti={true}
                            propertyGroups={row?.property_groups}
                            readOnly={true}
                            showNotSelected={false}
                        />
                    },
                    wrap: true

                },
                {
                    name: 'Sales Person/Broker',
                    selector: row => {
                        return row.sales_profile || '-'
                    },
                    format: row => {
                        const salesPerson = ungroupedSalesPersonOptions.find(option => option.value === row.sales_profile)
                        if (!salesPerson) {
                            return <FontAwesomeIcon icon={faWarning} className="text-danger"/>
                        }
                        if (salesPerson) {
                            return <span title={salesPerson.label}>{salesPerson.label}</span>
                        }
                        return '-'
                    }

                }
            ]}
        />

        <Row className="mt-4">
            <Col className="text-end">

                <Button
                    disabled={dataForApi.length === 0 || isSaving}
                    color={"primary"} onClick={() => {
                    saveData(dataForApi)
                }}
                >
                    IMPORT {dataForApi.length} LEAD{dataForApi.length > 1 ? 'S' : ''}
                </Button>
            </Col>
        </Row>

        {
            editingIndex !== null && <EditModal
                isOpen={editingIndex !== null}
                key={editingIndex}
                toggle={() => {
                    setEditingIndex(null)
                }}
                defaultData={localData.find(row => row.index === editingIndex)}
                saveToApi={false}
                fullUpdateFn={(newData) => {
                    setLocalData(prevState => {
                        return prevState.map(row => {
                            if (row.index === editingIndex) {
                                return {
                                    ...row,
                                    ...newData
                                }
                            }
                            return row
                        })
                    })
                    setEditingIndex(null)
                }}
            />
        }
    </div>
}