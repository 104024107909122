import React from "react";
import Presets from "./presets";
import {Col, Input, Label, Row} from "reactstrap";


export default function DateRangeFilter({dateRange, setDateRange}) {
    return <>
        <Col>
            <Label className="small">RANGE PRESETS</Label>
            <Presets dateRange={dateRange} setDateRange={setDateRange}/>
        </Col>
        <Col>
            <Label className="small">FROM</Label>
            <Input
                className="border-0"
                size="sm"
                type="date"
                value={dateRange?.from || ""}
                onChange={(e) => {
                    if (dateRange.to && e.target.value > dateRange.to) {
                        setDateRange({...dateRange, from: e.target.value, to: e.target.value})
                    } else {
                        setDateRange({...dateRange, from: e.target.value})
                    }
                }}/>
        </Col>
        <Col>
            <Label className="small">TO</Label>
            <Input
                className="border-0"
                size="sm"
                type="date"
                value={dateRange?.to || ""}
                onChange={(e) => {
                    if (dateRange.from && e.target.value < dateRange.from) {
                        setDateRange({...dateRange, from: e.target.value, to: e.target.value})
                    } else {
                        setDateRange({...dateRange, to: e.target.value})
                    }
                }}/>

        </Col>
    </>
}