import React, {useState} from "react";
import {useLoggedInApi} from "../api";
import {REACT_SELECT_STYLES} from "../constants";
import Select from "react-select";
import {useQuery} from "react-query";
import * as PropTypes from 'prop-types';

import {usePropertyGroups} from "./hooks";


function PropertyGroupSelector(props) {
    const [isSaving, setIsSaving] = useState(false)

    const propertyGroupsQuery = usePropertyGroups({})

    const options = propertyGroupsQuery.isSuccess ? [
        ...propertyGroupsQuery.data.map(row => {
            return {
                label: row.name,
                value: row.id,
                data: row
            }
        })
    ] : []
    const selectedOptions = props.isMulti ?
        (
            props.propertyGroups?.length > 0 ? [
                ...options.filter(o => props.propertyGroups.indexOf(o.value) > -1)
            ] : []
        ) :
        (
            !!props.propertyGroups ? [
                ...options.filter(o => props.propertyGroups === o.value)
            ][0] : null
        )

    if (props.readOnly) {
        if (selectedOptions.length === 0) {
            return <small><i>No property group selected</i></small>
        }
        return <strong>
            <small>
                {selectedOptions.map(o => o.label).join(", ")}
            </small>
        </strong>
    }

    return <Select
        {...props}
        placeholder="Choose a property group"
        isMulti={props.isMulti}
        styles={REACT_SELECT_STYLES}
        value={selectedOptions}
        options={options}
        isDisabled={isSaving}
        isLoading={propertyGroupsQuery.isLoading}
        onChange={
            newOptions => {
                if (props.setPropertyGroups) {
                    if (props.isSetSaving) {
                        setIsSaving(true)
                        const setValuePromise = props.setPropertyGroups(
                            props.isMulti ? [...newOptions.map(o => o.value)] : newOptions.value
                        )
                        setValuePromise.finally(() => {
                            setIsSaving(false)
                        })
                    } else {
                        props.setPropertyGroups(
                            props.isMulti ? [...newOptions.map(o => o.value)] : newOptions.value
                        )
                    }
                }
            }
        }
        filterOption={(option, rawInput) => {
            const filterInput = rawInput.toLowerCase()
            const searchableInput = (option.data.data.name + " " + option.data.data.address).toLowerCase()

            return searchableInput.includes(filterInput)
        }}
    />

}

PropertyGroupSelector.propTypes = {
    propertyGroups: PropTypes.arrayOf(PropTypes.number),
    setPropertyGroups: PropTypes.func,
    isSetSaving: PropTypes.bool,
    readOnly: PropTypes.bool,
    isMulti: PropTypes.bool
}

export default PropertyGroupSelector