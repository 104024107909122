import React from "react";
import InputRow from "../inputRow";
import {useLoggedInApi} from "../../api";
import {useQuery} from "react-query";
import {Input, InputGroup, InputGroupText, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faWarning, faX} from "@fortawesome/free-solid-svg-icons";
import useDebounce from "../../commonComponents/util";


export default function IdInput(
    {
        canEditID, isEmployee, profileData, setProfileData, originalProfileData,
        updateErrorStatus
    }
) {
    const userId = profileData.user.id;
    const key = isEmployee ? "active_group_employee_id" : "active_group_seller_id";
    const activeGroupId = isEmployee ? profileData[key] : profileData[key]
    const originalActiveGroupId = isEmployee ? originalProfileData[key] : originalProfileData[key]

    const debouncedActiveGroupId = useDebounce(activeGroupId, !activeGroupId ? 2000 : 500)

    const api = useLoggedInApi()
    const queryKey = ["id", userId, debouncedActiveGroupId]

    const setId = (newId) => {
        if (isEmployee) {
            setProfileData({...profileData, [key]: newId})
        } else {
            setProfileData({...profileData, [key]: newId})
        }
    }

    const {data: userIdResponse, isLoading: isChecking} = useQuery(queryKey, () => {
        const resource = api.resource('suggest_user_id')
        return resource.list({
            params: {
                id: debouncedActiveGroupId,
                for: userId
            }
        }).then(response => response.data)
    }, {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
    })

    React.useEffect(() => {
        if (!userIdResponse) {
            return
        }
        if (!userIdResponse.is_ok_to_use) {
            updateErrorStatus(key, "ID is already in use. Please change.")
        } else if (userIdResponse.is_ok_to_use) {
            updateErrorStatus(key, undefined)
        }

        if (!originalActiveGroupId && userIdResponse.suggested_id) {
            setId(userIdResponse.suggested_id)
        }
    }, [key, activeGroupId, userIdResponse?.is_ok_to_use, userIdResponse?.suggested_id, originalActiveGroupId])

    const hasValidId = !!activeGroupId
    const canUseId = hasValidId && userIdResponse && userIdResponse.is_ok_to_use

    const errorReason = hasValidId ? (
        !canUseId ? "ID is already in use" : ""
    ) : "ID is required"


    return <>
        <InputRow
            required
            disabled={!canEditID}
            className="font-bold"
            label={"Active Group " + (isEmployee ? "Employee" : "Seller") + " ID"}
            value={activeGroupId}
            // onChange={setId}
            renderer={(renderProps) => {
                return <InputGroup>
                    <Input
                        {...renderProps}
                        onChange={(e) => {
                            setId(e.target.value)
                            if (!e.target.value) {
                                updateErrorStatus(key, "ID is required")
                            }
                        }}
                        maxLength={20}
                    />
                    {
                        (isChecking) && <InputGroupText className="bg-info">
                            <Spinner size={"sm"}/>
                        </InputGroupText>
                    }

                    {
                        (!isChecking) && <InputGroupText
                            title={errorReason}
                            className={canUseId ? "bg-success text-white" : "bg-danger text-white"}>
                            <FontAwesomeIcon icon={canUseId ? faCheck : faWarning}/>
                        </InputGroupText>
                    }
                </InputGroup>
            }
            }
        />
    </>
}