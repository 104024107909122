import React, {useState} from "react";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import {Button, Col, Input, Row} from "reactstrap";
import {useQueryClient} from "react-query";


export default function NewInquiry({site}) {
    const [message, setMessage] = useState('')
    const [isSending, setIsSending] = useState(false)

    const api = useLoggedInApi()
    const helpInquiryApi = api.resource('help-inquiry')

    const queryClient = useQueryClient()


    const sendMessage = () =>  {
        setIsSending(true)
        const helpInquiryPromise = helpInquiryApi.create({message: message, site: site}).then(response => {
            queryClient.setQueryData(["my-inquiries"], existingData => {
                return [...existingData, response.data]
            })
            setMessage("")
            toast.success("Inquiry sent. Created ticket #" + response.data.id.toString() + ".")
        }).finally(() => {
            setIsSending(false)
        })
        toast.promise(helpInquiryPromise, {
            pending: "Sending inquiry...",
            error: "Something went wrong. Please contact administrator."
        })
    }

    return <>

        <Row className="mt-4">
            <Col>
                <h4>Need More Help?</h4>
            </Col>
        </Row>

        <Row>
            <Col>
                <Input
                    placeholder={"Send us a message"}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    type="textarea" className="" rows={10}/>
            </Col>
        </Row>


        <Row className="my-4">
            <Col className="text-end">
                <Button
                    onClick={sendMessage}
                    color="primary" className="text-white" disabled={!message || isSending}>
                    { isSending ? "SENDING..." : "SEND MESSAGE" }
                </Button>
            </Col>
        </Row>
    </>

}