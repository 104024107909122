import React from "react";
import {useLoggedInApi} from "../api";
import Select from "react-select";
import {useQuery} from "react-query";
import {useTagGroup, useActiveTags} from "./fetchers/tags";
import {Badge} from "reactstrap";
import TagDisplay from "./tagDisplay";


const TagOption = ({innerProps, data, ...rest}) => {
    // const data = props.data
    return <div {...rest}>
        <TagDisplay color={data.color} {...innerProps}>
            {data.label}
        </TagDisplay>

    </div>
}

export default function TagSelector({selected, onSelect, excludeTagGroupIds, excludeIds}) {
    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroup({params: {active_only: true}})

    const options = tagGroupsData?.filter(row => {
        return !excludeTagGroupIds?.includes(row.id)
    }).map(row => {
        return {
            label: row.name,
            options: row.tags?.filter(tag => {
                return !excludeIds?.includes(tag.id)
            }).map(tag => {
                return {
                    value: tag.id,
                    label: tag.name,
                    color: tag.color
                }
            })
        }
    })

    const selectedOption = options?.reduce((acc, optionGroup) => {
        acc = [
            ...acc,
            ...optionGroup.options
        ]
        return acc
    }, []).find(row => row.value === selected)

    return <Select
        // components={{Option: TagOption}}
        options={options}
        value={selectedOption}
        onChange={(newValue) => {
            onSelect(newValue.value)
        }}
        placeholder={"Select Tag"}
    />
}