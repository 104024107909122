import React, {useMemo, useState} from "react";
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TagDisplay from "../../../../../../commonComponents/tagDisplay";
import TagGroupSelector from "../../../../../../commonComponents/tagGroupSelector";
import TagSelector from "../../../../../../commonComponents/tagSelector";

export default function TagConditionModal({isOpen, toggle, condition, setCondition, tagData}) {
    const {conditionType, conditionId} = useMemo(() => {
        if (condition.auto_tag_if_tag_added) {
            return {
                conditionType: 'tag',
                conditionId: condition.auto_tag_if_tag_added
            }
        } else if (condition.auto_tag_if_taggroup_added) {
            return {
                conditionType: 'tag-group',
                conditionId: condition.auto_tag_if_taggroup_added
            }
        }
        return {
            conditionType: 'new',
            conditionId: null
        }
    }, [condition])

    const [localConditionType, setLocalConditionType] = useState(conditionType)
    const [localConditionId, setLocalConditionId] = useState(conditionId)

    React.useEffect(() => {
        setLocalConditionType(conditionType)
        setLocalConditionId(conditionId)
    }, [conditionType, conditionId])

    const localCondition = useMemo(() => {
        const defaultCondition = {
            auto_tag_new: false,
            auto_tag_if_tag_added: null,
            auto_tag_if_taggroup_added: null
        }
        if (localConditionType === 'new') {
            return {
                ...defaultCondition,
                auto_tag_new: true
            }
        } else if (localConditionType === 'tag-group') {
            return {
                ...defaultCondition,
                auto_tag_if_taggroup_added: localConditionId
            }
        } else if (localConditionType === 'tag') {
            return {
                ...defaultCondition,
                auto_tag_if_tag_added: localConditionId,
            }
        }
    }, [localConditionType, localConditionId])

    const canSave = localCondition.auto_tag_new || !!localCondition.auto_tag_if_tag_added || !!localCondition.auto_tag_if_taggroup_added

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody

            style={{minHeight: "20em"}}>
            <Row>
                <Col>
                    <h5>Set Auto Tag Condition</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TagDisplay
                        onClick={() => {
                            setLocalConditionType('new')
                            setLocalConditionId(null)
                        }}
                        color={localConditionType === 'new' ? 'green' : 'light-gray'}
                    >
                        NEW LEAD
                    </TagDisplay>
                    <TagDisplay
                        onClick={() => {
                            if (localConditionType !== 'tag-group') {
                                setLocalConditionId(null)
                            }
                            setLocalConditionType('tag-group')
                        }}
                        color={localConditionType === 'tag-group' ? 'green' : 'light-gray'}
                    >
                        TAG GROUP
                    </TagDisplay>
                    <TagDisplay
                        onClick={() => {
                            if (localConditionType !== 'tag') {
                                setLocalConditionId(null)
                            }
                            setLocalConditionType('tag')
                        }}
                        color={localConditionType === 'tag' ? 'green' : 'light-gray'}
                    >
                        TAG
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {
                        localConditionType === "new" && <>
                            <small>
                                This tag will automatically be applied to all new leads.
                            </small>
                        </>
                    }
                    {
                        localConditionType === "tag-group" && <>
                            <TagGroupSelector
                                selected={localConditionId}
                                onSelect={(tagGroupId) => {
                                    setLocalConditionId(tagGroupId)
                                }}
                                excludeIds={[tagData.tag_group]}/>
                            <small>
                                This tag will automatically be applied when any tag in the selected group is applied.
                            </small>
                        </>
                    }
                    {
                        localConditionType === "tag" && <>
                            <TagSelector
                                selected={localConditionId}
                                onSelect={(tagGroupId) => {
                                    setLocalConditionId(tagGroupId)
                                }}
                                excludeIds={[tagData.id]} excludeTagGroupIds={[tagData.tag_group]}/>
                            <small>
                                This tag will automatically be applied when the selected tag is applied.
                            </small>
                        </>
                    }
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Row className="mt-3">
                <Col className="text-end">
                    <Button
                        disabled={!canSave}
                        color="primary"
                        onClick={() => {
                            setCondition(localCondition)
                            toggle()
                        }}>
                        Save
                    </Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>

}