import React from "react";
import * as PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import ProfilePicture from "../../../../../components/profilePicture";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

function RankingItem(props) {
    return <>

        <hr/>
        <Row>
            <Col className="col-auto d-flex align-items-center">
                {
                    props.useFavicon ?
                        <FontAwesomeIcon icon={faCheckCircle} className="fa-2x"/> :
                        <ProfilePicture url={props.image} className={props.smallImage ? "img-30" : "img-50"}/>
                }

            </Col>
            <Col className="lineheight-15">
                <strong>{props.name}</strong><br/>
                <small>{props.unitsSold} units sold</small>
            </Col>
        </Row>
    </>
}

RankingItem.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    unitsSold: PropTypes.number,
    useFavicon: PropTypes.bool
}

export default RankingItem