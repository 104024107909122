import React from "react";
import {Col, Container, Row} from "reactstrap";
import "../../../commonComponents/assets/css/loginPage.css"
import {LoginForm} from "./loginForm";
import logoBanner from "../../../commonComponents/assets/horizontal-banner.png";


export default function LoginPage(props) {
    return <Container className="public-page login-page py-5 px-md-5 my-md-5">
        <Row>
            <Col className="pe-md-5" md={6} sm={12}>

                <Row className="pb-3">
                    <Col className="px-5">
                        <img src={logoBanner} className="img-fluid"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="">
                        <hr className="mb-5"/>
                        <h3>
                            Welcome to Active Group Property Management Suite
                        </h3>

                        <p>
                            To access your dashboard, request your registration details from an administrator.
                        </p>
                    </Col>
                </Row>

            </Col>
            <Col className="" md={6} sm={12}>
                <LoginForm api={props.api}/>
            </Col>
        </Row>
    </Container>
}