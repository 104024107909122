import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import PhotoUpload from "../../../../../components/photoUpload";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import MediaGalleryItem from "./mediaGalleryItem";


function SimplePagination(props) {
    const hasNext = props.currentPage < props.pages;
    const hasPrevious = props.currentPage > 1;

    if (props.pages <= 1) {
        return null
    }

    return <>
        {
            hasPrevious && <Button
                color="text"
                className="mx-2"
                onClick={() => props.onPageChange(props.currentPage - 1)}
            >
                Previous
            </Button>
        }
        {
            [...Array(props.pages)].map((_, idx) => {
                return <Button
                    key={idx}
                    color="text"
                    className="mx-2"
                    disabled={props.currentPage === idx + 1}
                    onClick={() => props.onPageChange(idx + 1)}>
                    {idx + 1}
                </Button>
            })
        }
        {
            <Button
                disabled={!hasNext}
                color="text"
                className="mx-2"
                onClick={() => props.onPageChange(props.currentPage + 1)}>
                Next
            </Button>
        }
    </>
}

SimplePagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
}


function MediaGallery(props) {
    const [page, setPage] = useState(1);

    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const mediaQuery = useQuery(['group-media', props.groupId.toString()], () => {
        const resource = api.resource('properties/groups')
        return resource.getItem(props.groupId).then(response => {
            return response.data.media
        })
    }, {
        refetchOnWindowFocus: false
    })

    const deleteMutation = useMutation(data => {
        const resource = api.resource('properties/groups')
        const deletePromise = resource.deleteItem(props.groupId.toString() + "/delete_media", {
            params: {
                media_id: data
            }
        }).then(response => {
            return response.data.media
        })

        toast.promise(deletePromise, {
            error: "Cannot delete this media.",
            success: "Deleted media from property group gallery.",
            pending: "Deleting media from property group gallery."
        })

        return deletePromise
    }, {
        onSuccess: responseData => {
            queryClient.setQueryData(['group-media', props.groupId.toString()], [
                ...responseData
            ])
        }
    })

    const maxPage = mediaQuery.isSuccess ? Math.ceil(mediaQuery.data.length / 4.0) : 0;

    const resourceName = 'properties/groups'

    return <>
        <hr className="my-5"/>
        <Row className="my-3">
            <Col>
                <h3>Media Gallery</h3>
                <small>Photos uploaded to the media gallery will be visible on the carousel of the broker microsites
                    with access to this property group.</small>
            </Col>
        </Row>


        <Row>
            <Col>

                <PhotoUpload
                    modalTitle={"Add Property Group Media"}
                    resourceName={resourceName}
                    resourcePath={props.groupId.toString() + "/add_media"}
                    toastDetail={{
                        error: "Something went wrong. Cannot upload media.",
                        pending: "Uploading media to property group gallery.",
                        success: "Added new media to property group gallery."
                    }}
                    onSuccess={responseData => {
                        queryClient.setQueryData(['group-media', responseData.id.toString()], [
                            ...responseData.media
                        ])
                    }}
                >
                    <Button color={"info-outline"}>
                        <FontAwesomeIcon icon={faCirclePlus}/> ADD PHOTO
                    </Button>
                </PhotoUpload>
            </Col>
            <Col className="text-end align-items-end justify-content-end d-flex">
                <SimplePagination pages={maxPage} currentPage={page} onPageChange={newPage => setPage(newPage)}/>
            </Col>
        </Row>

        {
            mediaQuery.isLoading ? <Row>
                <Col className="text-center p-5 m-5">
                    <Spinner/>
                </Col>
            </Row> : <>
                <Row className="my-3">
                    {
                        mediaQuery.data.sort((a, b) => {
                            return b.id - a.id
                        }).filter((media, idx) => {
                            return idx >= (page - 1) * 4 && idx < page * 4
                        }).map((media, idx) => {
                            return <MediaGalleryItem media={media} onDelete={() => {
                                deleteMutation.mutate(media.id)
                            }} key={media.id}/>
                        })
                    }
                </Row>


            </>
        }
    </>
}

MediaGallery.propTypes = {
    groupId: PropTypes.number.isRequired
}

export default MediaGallery;