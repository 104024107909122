import React from "react";
import InfoCards from "../../../../components/charts/infoCards";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";


export default function BrokerInfoCards() {
    const api = useLoggedInApi()

    const result = useQuery(['brokers-summary'], () => {
        const salesPeopleResource = api.resource('broker')
        return salesPeopleResource.getItem('summary_counts').then(response => {
            return response.data
        })
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    })

    return <InfoCards data={[
        {
            label: "TOTAL BROKERS",
            value: result.data?.brokers,
            color: "blue2",
            isLoading: result.isLoading,
            navigateTo: 'brokers'
        },
        {
            label: "TOTAL SALESPEOPLE",
            value: result.data?.salespeople,
            color: "green2",
            isLoading: result.isLoading,
            navigateTo: 'salesperson'
        }, {
            label: "WINS THIS MONTH",
            value: result.data?.wins,
            color: "primary",
            isLoading: result.isLoading
        }
    ]}/>
}