import React from "react";
import {useLoggedInApi} from "../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {Alert, Button, Input, InputGroup} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faWarning} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useResetProfileData} from "../../commonComponents/hooks";


const CONFIRMATION_TEXT = 'disable profile'

export default function DeleteProfileForm({profileData, resourceName, listPagePath}) {
    const [isConfirmed, setIsConfirmed] = React.useState(false);

    const navigate = useNavigate()


    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const mutation = useMutation(() => {
        const resource = api.resource(resourceName)
        const deletePromise = resource.deleteItem(profileData.id).then(response => response.data)
        toast.promise(deletePromise, {
            pending: 'Disabling profile...',
            success: 'Profile disabled',
            error: 'Error disabling profile'
        })
        return deletePromise
    }, {
        onSuccess: () => {
            Promise.all([
                // queryClient.invalidateQueries(['profile', profileData.id]),
                queryClient.invalidateQueries(['table', resourceName]),
                queryClient.invalidateQueries(['broker-groups']),
                // queryClient.invalidateQueries([resourceName]),
            ]).then(() => {
                navigate(listPagePath)
            });
        }
    })

    return (
        <div className="mb-4">
            <hr className="mb-4"/>

            <h5>Disable Profile</h5>
            <Alert color="danger">
                <FontAwesomeIcon icon={faWarning} /> {" "}
                Danger zone! This action will disable the profile. The user will no longer be able to login.
                An email will be sent to the user informing them of the action.
                Are you sure you want to proceed?
            </Alert>

            <p>To disable this profile, type "{CONFIRMATION_TEXT}" in the textbox below.</p>
            <InputGroup>
                <Input type="text" placeholder={`Type '${CONFIRMATION_TEXT}' here`} onChange={e => {
                    setIsConfirmed(e.target.value === CONFIRMATION_TEXT)
                }}/>
                <Button
                    disabled={!isConfirmed || mutation.isLoading}
                    color={"danger"} className="text-white" onClick={mutation.mutate}>
                    <FontAwesomeIcon icon={faTrash} />{" "}
                    {mutation.isLoading ? "Disabling..." : "Disable Profile"}
                </Button>
            </InputGroup>

        </div>
    )
}