import React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Button, Col, Input, Label, Row, Spinner} from "reactstrap";
import Message from "./message";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";

export default function CommonInquiry({inquiryId, defaultResolved, canMarkResolved}) {
    const [response, setResponse] = React.useState("")
    const [markResolved, setMarkResolved] = React.useState(defaultResolved)

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const {isLoading, data: inquiry} = useQuery(
        ["inquiry", inquiryId],
        () => {
            const resource = api.resource('help-inquiry')
            return resource.getItem(inquiryId).then(res => res.data)
        }, {
            enabled: !!inquiryId,
            staleTime: 1000 * 60 * 60 * 24
        })

    const sendResponseMutation = useMutation((data) => {
        const resource = api.resource('help-inquiry')
        const responsePromise = resource.postItemAction(inquiryId, 'respond', data).then(res => res.data)
        if (data.resolved) {
            toast.promise(responsePromise, {
                loading: "Marking as resolved...",
                success: "Marked as resolved",
                error: "Failed to mark as resolved"
            })
        }
        return responsePromise
    }, {
        onSuccess: (data) => {
            queryClient.setQueryData(["inquiry", inquiryId], data)
        }
    })

    const canSendResponse = response.length > 0 && !sendResponseMutation.isLoading

    if (isLoading) {
        return <Row>
            <Col className="m-5 text-center">
                <Spinner/>
            </Col>
        </Row>
    }

    return <>
        <Message
            from={inquiry.user_name}
            message={inquiry.message}
            date={inquiry.datetime_added}
            isOwnMessage={inquiry.own_message}
        />
        {
            inquiry.responses.map((response, index) => {
                return <Message
                    key={response.id}
                    from={response.user_name}
                    message={response.response}
                    date={response.datetime_added}
                    isOwnMessage={response.own_message}
                />
            })
        }
        {
            !inquiry.is_resolved && <>
                <hr/>
                <Row>
                    <Col>
                        <Input type="textarea" value={response} onChange={e => setResponse(e.target.value)}/>
                        {
                            canMarkResolved && <>
                                <Input type="checkbox" checked={markResolved} onChange={e => {
                                    setMarkResolved(e.target.checked)
                                }}/>{" "}
                                <Label>Is Resolved</Label>
                            </>
                        }
                    </Col>

                    <Col className="ps-0 ms-0" sm={2}>
                        <Button
                            color={"primary"}
                            className="btn-sm w-100 h-100"
                            onClick={() => {
                                sendResponseMutation.mutate({
                                    response: response,
                                    resolved: markResolved
                                })
                                setResponse("")
                            }}
                            disabled={!canSendResponse}
                        >
                            <FontAwesomeIcon icon={faPaperPlane}/>
                        </Button>
                    </Col>
                </Row>
            </>
        }
    </>
}