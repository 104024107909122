import React from "react";
import {ButtonGroup, Col, Row} from "reactstrap";
import AddUpdate from "./addUpdate";
import ActivityList from "./activityList";
import Filter from "./filter";
import useDebounce from "../../../../util";
import {useParams} from "react-router-dom";
import {PRESET_LAST_30_DAYS} from "../../../../dateRangeFilter/presets";
import {useLead} from "../../../../hooks";
import ExportLead from "./exportLead";
import {LeadTagFilterContext} from "../../../../contexts";


export default function Activities() {
    const {leadId} = useParams()
    const {data, isLoading} = useLead(leadId, {params: {show_disabled: true}})
    const isLeadActive = data?.is_active
    const {tags: selectedTags, setTags: setSelectedTags} = React.useContext(LeadTagFilterContext)

    const [selectedUpdateBy, setSelectedUpdateBy] = React.useState(null)
    const [search, setSearch] = React.useState("")
    const [dateRange, setDateRange] = React.useState({
        ...PRESET_LAST_30_DAYS,
    })

    const debouncedSearch = useDebounce(search, 500)
    const debouncedDateRange = useDebounce(dateRange, 500)

    return <>
        <Row className="border-bottom">
            <Col>
                <h2>Recent Activities</h2>
            </Col>
            <Col className="text-end">
                <ButtonGroup>
                    <AddUpdate disabled={!isLeadActive}/>
                    <ExportLead/>
                </ButtonGroup>

            </Col>
        </Row>
        <Row>
            <Col>
                <Filter
                    setActiveTagIds={setSelectedTags}
                    activeTagIds={selectedTags}
                    selectedUpdateBy={selectedUpdateBy}
                    removeSelectedUpdateBy={() => setSelectedUpdateBy(null)}
                    search={search}
                    setSearch={setSearch}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    showPropertyGroups={true}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <ActivityList
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    setSelectedUpdateBy={setSelectedUpdateBy}
                    selectedUpdateBy={selectedUpdateBy}
                    search={debouncedSearch}
                    dateRange={debouncedDateRange}
                />
            </Col>
        </Row>
    </>
}