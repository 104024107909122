import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TagDisplay from "../../../../../../commonComponents/tagDisplay";
import {useLoggedInApi} from "../../../../../../api";
import {useMutation} from "react-query";
import {toast} from "react-toastify";

export default function ConfirmSequenceTagGroupModal({isOpen, toggle, tagAutoTag, confirm}) {
    const api = useLoggedInApi()
    const changeTagsMutation = useMutation(() => {
        const tagResource = api.resource('tag/tag')
        const tagIdsToChange = [
            ...tagAutoTag.map(row => row.id)
        ]
        const updatePromise = Promise.all(tagIdsToChange.map(tagId => {
            return tagResource.patchItem(tagId, {
                auto_tag_new: false,
                auto_tag_if_tag_added: null,
                auto_tag_if_taggroup_added: null,
            })
        }))
        toast.promise(updatePromise, {
            pending: `Removing auto-tag from ${tagIdsToChange.length} tags...`,
            success: `Removed auto-tag from ${tagIdsToChange.length} tags`,
            error: 'Error Updating Tags'
        })
        return updatePromise;
    }, {
        onSuccess: () => {
            confirm()
            toggle()
        }
    })
    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody>
            <h5>Confirm tag group change type to sequence?</h5>
            <p>
                This tag group has tags with {" "}
                <TagDisplay color={'burlywood'}>
                    AUTO TAG
                </TagDisplay>
                {" "} settings. To change this tag group to sequence, the following tags will be updated.
            </p>

            <p>
                {
                    tagAutoTag?.map(row => {
                        return <TagDisplay key={row.id} color={row.color}>
                            {row.name}
                        </TagDisplay>
                    })
                }
            </p>
        </ModalBody>
        <ModalFooter>
            <Row>
                <Col className="text-end">
                    <Button color="primary text-white" onClick={() => {
                        changeTagsMutation.mutate()
                    }}>
                        CONFIRM
                    </Button>
                    <Button color={'secondary'} onClick={toggle}>
                        CANCEL
                    </Button>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
}