import React, {useRef, useState} from "react";
import {Button, ButtonGroup, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import PropertyGroupSelector from "../../../../../commonComponents/propertyGroupSelector";
import AssetList from "./assetList";
import {toast} from "react-toastify";
import {valueIsEqual} from "../../../../../commonComponents/util";
import Compress from "compress.js";


export default function Detail({isOpen, toggle, mediaKitId}) {
    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const [localData, setLocalData] = useState({assets: [], property_groups: [], description: ''})
    const shouldQuery = !!mediaKitId;

    const saveToastId = useRef(null)
    const saveNotify = () => saveToastId.current = toast("Saving changes...", {autoClose: false});
    const saveUpdate = (newData) => toast.update(saveToastId.current, newData)

    const mediaKitQuery = useQuery(
        ["mediakit", mediaKitId],
        () => {
            const resource = api.resource('mediakit');
            return resource.getItem(mediaKitId).then(response => response.data)
        }, {
            enabled: shouldQuery && isOpen,
            onSuccess: data => {
                const localAssets = [
                    ...localData.assets
                ];
                const localAssetIds = localAssets.map(asset => asset.id).filter(assetId => !!assetId);
                data.assets.forEach(asset => {
                    if (!localAssetIds.includes(asset.id)) {
                        localAssets.push({...asset, uuid: asset.id})
                    }
                })
                setLocalData({
                    ...localData,
                    ...data,
                    assets: localAssets
                })
            },
            refetchOnWindowFocus: false
        }
    )

    const mediaKitMutation = useMutation({
        mutationFn: (data) => {
            const resource = api.resource('mediakit')
            if (!data.id) {
                return resource.create(data.data).then(response => response.data)
            } else {
                return resource.patchItem(data.id, data.data).then(response => response.data)
            }
        }
    })

    const getLocalAsset = function (assetUuid) {
        const filteredLocalAssets = localData.assets.filter(asset => asset.uuid === assetUuid)
        if (filteredLocalAssets.length >= 1) {
            return filteredLocalAssets[0]
        }
    }

    const updateLocalAsset = function (assetUuid, data) {
        setLocalData(prevState => ({
            ...prevState,
            assets: [...prevState.assets.map(asset => {
                if (asset.uuid === assetUuid) {
                    return {
                        ...asset,
                        ...data
                    }
                } else {
                    return asset
                }
            })]
        }))
    }

    const deleteLocalAsset = function (assetUuid) {
        setLocalData(prevState => ({
            ...prevState,
            assets: [...prevState.assets.filter(asset => asset.uuid !== assetUuid)]
        }))
    }

    const deleteAssetMutation = useMutation({
        mutationFn: (data) => {
            const resource = api.resource('mediakit')
            return resource.deleteItemAction(data.mediaKitId, 'delete_asset', {params: {media_id: data.assetId}})
        },
        onSuccess: (data, variables) => {
            deleteLocalAsset(variables.uuid)
        }
    })

    const uploadAssetMutation = useMutation({
        mutationFn: (data) => {
            const resource = api.resource('mediakit')

            const formData = new FormData()
            if (data.isCompressed) {
                formData.append("file", Compress.convertBase64ToFile(data.localFile.data, data.localFile.ext), data.localFile.alt);
            } else {
                formData.append("file", data.localFile);
            }


            return resource.postItemAction(data.mediaKitId, 'add_media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => response.data)
        },
        onMutate: (variables) => {
            updateLocalAsset(variables.uuid, {status: "Uploading"})
        },
        onSuccess: (data, variables) => {
            updateLocalAsset(variables.uuid, {...data, localFile: null, status: "Uploaded"})
        },
        onError: (error, variables) => {
            updateLocalAsset(variables.uuid, {status: "New (error uploading)"})
        }
    })

    const saveMediaKitChanges = function (data, id) {
        console.log("saveMediaKitChanges", data, id)
        if (localData.description !== mediaKitQuery.data?.description || !valueIsEqual(localData.property_groups, mediaKitQuery.data?.property_groups)) {
            const mediaKitData = {
                description: localData.description,
                property_groups: localData.property_groups
            }
            const savePromise = mediaKitMutation.mutateAsync({data: mediaKitData, id: id})
            return savePromise
        } else {
            return new Promise((resolve) => {
                return resolve(mediaKitQuery.data)
            })
        }
    }

    const deleteAsset = function (assetUuid) {
        const asset = getLocalAsset(assetUuid);
        if (asset?.id) {
            return deleteAssetMutation.mutateAsync({mediaKitId: mediaKitId, assetId: asset.id, uuid: asset.uuid})
        } else {
            return new Promise(resolve => {
                deleteLocalAsset(assetUuid)
                resolve()
            })
        }
    }

    const save = function () {
        saveNotify()

        saveMediaKitChanges(localData, mediaKitId).then(mediaKitData => {
            // upload each asset
            const localFiles = localData.assets
                .filter(asset => asset.hasOwnProperty('localFile'))  // upload local files
                .filter(asset => !asset.willDelete)  // exclude files that are marked for deletion
            const forDeletion = localData.assets.filter(asset => asset.willDelete)
            if (localFiles.length > 0 && forDeletion.length > 0) {
                saveUpdate({render: "Uploading new assets and deleting marked assets. Please wait..."})
            }

            return Promise.all([
                ...localFiles.map(localFile => {
                    return uploadAssetMutation.mutateAsync(({...localFile, mediaKitId: mediaKitData.id}))
                }),
                ...forDeletion.map(asset => {
                    return deleteAsset(asset.uuid)
                })
            ]).then(() => {
                return mediaKitData
            })
        }).then(mediaKitData => {
            saveUpdate({render: "Building media kit package..."})
            const resource = api.resource('mediakit')
            return resource.postItemAction(mediaKitData.id, 'build', {})
        }).then(() => {
            saveUpdate({render: "Mediakit changes had been saved!", autoClose: 5000, type: toast.TYPE.SUCCESS})
            queryClient.invalidateQueries(['table', 'mediakit'])
            toggle();
        }).catch(() => {
            saveUpdate({render: "Error saving changes", type: toast.TYPE.ERROR})
        })
        // build media kit
    }

    const errorMessages = React.useMemo(() => {
        const tmp = [];
        if (!localData.description) {
            tmp.push("Description is required.")
        }
        if (!localData.property_groups || !localData.property_groups.length) {
            tmp.push("Property Groups are required.")
        }

        if (tmp.length === 0) {
            return null;
        }
        return tmp.join(' ')
    }, [localData])

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row className="">
                <Col>
                    <h3>Media Kit</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <strong>Choose Property Groups *</strong>
                </Col>
            </Row>

            <Row>
                <Col>
                    <PropertyGroupSelector
                        propertyGroups={localData.property_groups}
                        isMulti={true}
                        setPropertyGroups={newPropertyGroups => {
                            setLocalData({
                                ...localData, property_groups: newPropertyGroups
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <strong>Label description *</strong>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <Input type="text" onChange={e => setLocalData({
                        ...localData, description: e.target.value
                    })} value={localData.description}/>
                </Col>
            </Row>


            <Row className="mt-5 mb-5">
                <Col>
                    <AssetList
                        assets={localData.assets}
                        addAssets={newAssets => {
                            console.log("addAssets", newAssets)
                            setLocalData(prevState => {
                                    return {
                                        ...prevState,
                                        assets: [...prevState.assets, ...newAssets]
                                    }
                                }
                            )
                        }}
                        updateAsset={updateLocalAsset}
                    />
                </Col>
            </Row>

        </ModalBody>
        <ModalFooter>

            {
                errorMessages && <Row className="text-start">
                    <Col>
                        <div className="text-danger">* {errorMessages}</div>
                    </Col>
                </Row>
            }

            <Row className="mt-3">
                <Col className="col-auto small">
                    Recommended image resolution: <strong>1920x1080px</strong>
                </Col>
                <Col className="col-auto small">
                    Maximum file size: <strong>2MB</strong>
                </Col>
                <Col className="text-end">
                    <ButtonGroup>
                        <Button color="info" onClick={() => {
                            toggle()
                        }}>
                            CANCEL
                        </Button>
                        <Button
                            disabled={mediaKitMutation.isLoading || uploadAssetMutation.isLoading || !localData.description || !localData.property_groups.length}
                            color="primary"
                            onClick={() => {
                                save()
                            }}>
                            SAVE MEDIA KIT
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
}