import React from "react";
import {Button, Col, Container, Row} from "reactstrap";
import PropertyGroupAsTag from "../../../commonComponents/propertyGroupAsTag";
import {useNavigate} from "react-router-dom";


export default function PropertyGroupTableCell({row}) {
    const navigate = useNavigate()
    const display = row.logo ?
        <img src={row.logo} className="img-fluid p-2" alt={row.name} title={row.name}
             style={{maxWidth: "120px", maxHeight: "120px"}}/> :
        <h5>{row.name}</h5>;

    const displayWithAction = row.permissions.includes('can_change') ?
        <Button color="text" onClick={() => {
            navigate("groups/edit/" + row.id.toString())
        }}>
            {display}
        </Button> : display

    return <Container fluid>
        <Row className="w-100">
            <Col className="text-center p-3">
                <PropertyGroupAsTag propertyGroup={row}/>
            </Col>
        </Row>
        <Row className="w-100 mb-3">
            <Col className="text-center">
                {displayWithAction}
            </Col>
        </Row>
    </Container>
}