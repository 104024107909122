import React, {useState} from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faHouseLaptop} from "@fortawesome/free-solid-svg-icons";
import PropertyGroupSelector from "../../../../../commonComponents/propertyGroupSelector";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import moment from "moment";
import ViewContent from "./viewContent";
import EditContent from "./editContent";
import {dictChangedValues} from "../../../../../commonComponents/util";
import {toast} from "react-toastify";
import SalesGroupSelector from "../../../component/salesGroupSelector";
import CreatorAndEditor from "./creatorAndEditor";


function DetailModal({isOpen, toggle, dataId, setDataId}) {
    const [isEdit, setIsEdit] = useState(!dataId)
    const [editedData, setEditedData] = useState({
        send_datetime: moment().add(1, 'd').format('YYYY-MM-DD 07:00:00+0800')
    });

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const broadcastDetailResult = useQuery(['broadcast', dataId], () => {
        const broadcastResource = api.resource('broadcast');
        return broadcastResource.getItem(dataId).then(response => response.data)
    }, {
        enabled: !!dataId,
        onSuccess: data => {
            setEditedData({...data})
        },
        refetchOnWindowFocus: false
    });
    const recipientCountParams = {
        target_brokers_groups: editedData.target_broker_groups?.length > 0 ? editedData.target_broker_groups.join(',') : undefined,
        send_to_my_brokers: editedData.send_to_my_brokers ? true : undefined,
        send_to_all_brokers: editedData.send_to_all_brokers ? true : undefined,
        id: dataId ? dataId : undefined
    }
    const recipientCountResult = useQuery(['broadcast', 'recipient-count', recipientCountParams], () => {
        const broadcastResource = api.resource('broadcast');
        return broadcastResource.getItem('recipient_count', {
            params: recipientCountParams
        }).then(response => response.data.count)
    }, {
        refetchOnWindowFocus: false,
        initialData: editedData.recipient_count,
        enabled: isEdit
    });

    const data = broadcastDetailResult.isSuccess ? broadcastDetailResult.data : {}

    const createMutation = useMutation(broadcastData => {
        const broadcastResource = api.resource('broadcast');
        return broadcastResource.create(broadcastData).then(response => response.data)
    }, {
        onSuccess: resultData => {
            queryClient.setQueryData(['broadcast', resultData.id], resultData)
            queryClient.invalidateQueries(['table', 'broadcast'])
            toast.success("Broadcast scheduled.");
            setDataId(resultData.id)
        }
    })
    const updateMutation = useMutation(broadcastData => {
        const broadcastResource = api.resource('broadcast');
        return broadcastResource.patchItem(dataId, dictChangedValues(data, broadcastData)).then(response => response.data)
    }, {
        onSuccess: resultData => {
            queryClient.setQueryData(['broadcast', resultData.id], resultData)
            queryClient.invalidateQueries(['table', 'broadcast'])
            toast.success("Broadcast updated!");
            setIsEdit(false)
        }
    })

    const canSave = editedData.title && editedData.content && editedData.broadcast_type && editedData.send_datetime;
    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row className="mb-5">
                <Col lg={7} className="border-end pe-4">
                    <Row>
                        <Col>
                            <h2>Broadcast to brokers</h2>
                        </Col>
                    </Row>
                    {
                        (dataId && data.is_editable) && <Row>
                            <Col>
                                <Button color="link" size="sm" onClick={() => {
                                    setEditedData({...broadcastDetailResult.data})
                                    setIsEdit(!isEdit)
                                }}>
                                    <small>{isEdit ? "Cancel editing" : "Edit details"}</small>
                                </Button>
                            </Col>
                        </Row>
                    }

                    {
                        isEdit ? <EditContent editedData={editedData} setEditedData={setEditedData}/> :
                            <ViewContent data={data}/>
                    }

                    <Row className="mt-3">
                        <Col className="small">
                            <strong>NOTE</strong>:{" "}
                            <i>
                                SMS broadcasts are limited to 160 characters.
                                You can divide your SMS broadcast into 2 parts if needed.
                                Please check with administrators if you have enough credits on
                                SMS Load Wallet before sending.
                            </i>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mt-5">
                        <Col>
                            <strong>CHOOSE BROKER GROUP</strong>
                        </Col>
                    </Row>


                    <div className="border-bottom"/>
                    <Row className="my-2">
                        <Col>
                            <Input
                                type="checkbox"
                                disabled={!isEdit}
                                checked={!editedData.send_to_my_brokers && !editedData.send_to_all_brokers}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setEditedData({
                                            ...editedData,
                                            send_to_all_brokers: false,
                                            send_to_my_brokers: false
                                        })
                                    }
                                }}
                            />{" "}
                            <small>Send Broadcast to selected Brokers/Salespeople</small>
                        </Col>
                    </Row>
                    {
                        (!editedData.send_to_my_brokers && !editedData.send_to_all_brokers) && <Row className="my-2">
                            <Col>
                                <SalesGroupSelector
                                    readOnly={!isEdit}
                                    salesGroups={editedData.target_broker_groups}
                                    setSalesGroups={(newBrokerGroups) => {
                                        setEditedData({...editedData, target_broker_groups: [...newBrokerGroups]})
                                    }}
                                />

                            </Col>
                        </Row>
                    }

                    <Row className="my-2">
                        <Col>
                            <Input
                                type="checkbox"
                                disabled={!isEdit}
                                checked={editedData.send_to_my_brokers}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setEditedData({
                                            ...editedData,
                                            send_to_all_brokers: false,
                                            send_to_my_brokers: true,
                                            target_broker_groups: []
                                        })
                                    }
                                }}
                            />{" "}
                            <small>Send Broadcast to my Brokers/Salespeople</small>
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col>
                            <Input
                                type="checkbox"
                                disabled={!isEdit}
                                checked={editedData.send_to_all_brokers}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setEditedData({
                                            ...editedData,
                                            send_to_all_brokers: true,
                                            send_to_my_brokers: false,
                                            target_broker_groups: []
                                        })
                                    }
                                }}
                            />{" "}
                            <small>Send Broadcast to <strong>ALL</strong> Brokers/Salespeople</small>
                        </Col>
                    </Row>

                    <div className="border-bottom"/>

                    {
                        (recipientCountResult.data !== undefined) && <>
                            <Row className="mt-5">
                                <Col>
                                    <strong>RECIPIENTS</strong>
                                </Col>
                            </Row>

                            <div className="border-bottom"/>
                            <Row className="my-2">
                                <Col>
                                    {
                                        recipientCountResult.isLoading ?
                                            <Spinner size="sm"/>
                                            : (recipientCountResult.data !== null ? recipientCountResult.data.toLocaleString(): 0)
                                    }
                                </Col>
                            </Row>
                        </>
                    }

                    <div className="border-bottom"/>

                    {
                        isEdit && <Row className="mt-3 mb-5">
                            <Col className="text-end">
                                <Button
                                    color="info"
                                    size="sm"
                                    onClick={() => {
                                        if (dataId) {
                                            updateMutation.mutate(editedData)
                                        } else {
                                            createMutation.mutate(editedData)
                                        }
                                    }}
                                    disabled={!canSave}
                                >
                                    <FontAwesomeIcon icon={faCheckSquare}/> {" "}
                                    {!dataId ? "SCHEDULE" : "UPDATE"} BROADCAST
                                </Button>
                            </Col>
                        </Row>
                    }

                    {
                        broadcastDetailResult.isSuccess && <CreatorAndEditor createdBy={data.created_by} updatedBy={data.last_updated_by}/>
                    }
                </Col>
            </Row>

        </ModalBody>
    </Modal>
}

export default DetailModal;