import React from "react";

export default function ExpandedLog({data}) {
    return <div className="mx-5 pb-2 small">
        <div className="">
            <strong>{data.title}</strong>
        </div>
        <p>
            {data.display_text}
            {
                (!!data.message && data.message !== data.title) && <>
                    <br/>
                    {data.message}
                </>
            }
        </p>
    </div>
}