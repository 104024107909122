import React from "react";
import {Col, Row} from "reactstrap";
import MyEmployeeProfileForm from "../../../../components/employeeProfileForm/myEmployeeProfileForm";
import EmployeeProfileForm from "../../../../components/employeeProfileForm";


export default function NewEmployee(props) {
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Add New Employee</h3>
            </Col>
        </Row>

        <EmployeeProfileForm
            isMe={false}/>
    </>
}