import React from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import ClassicEditor from "../../../components/classicEditor";
import {useLoggedInApi} from "../../../api";
import {useMutation, useQuery} from "react-query";
import {toast} from "react-toastify";

export default function PolicyEdit({site, label}) {
    const [content, setContent] = React.useState('')
    const api = useLoggedInApi()
    const query = useQuery(["system/policy", site], () => {
        const resource = api.resource('view-privacy-policy')
        return resource.getItem(site).then(response => response.data)
    }, {
        onSuccess: data => {
            setContent(data.content)
        },
        refetchOnWindowFocus: false
    });

    const mutation = useMutation((content) => {
        const resource = api.resource('view-privacy-policy')
        const patchPromise = resource.patchItem(site, {content})
        toast.promise(patchPromise, {
            loading: "Saving...",
            success: "Changes to privacy policy saved.",
            error: "Something went wrong. Cannot save."
        })
        return patchPromise
    }, {
        onSuccess: data => {
            query.refetch()
        }
    })

    const initialContent = query.isSuccess ? query.data.content : ''
    const hasChanges = content !== initialContent

    return <div className="m-4 pb-4">
        <Row>
            <Col>
                <h4>{label} Privacy Policy</h4>
            </Col>
            <Col className="col-auto">
                <Button color="primary" onClick={() => mutation.mutate(content)} disabled={!hasChanges || mutation.isLoading}>
                    {
                        mutation.isLoading ? "SAVING..." : "SAVE CHANGES"
                    }
                </Button>
            </Col>
        </Row>
        {
            query.isLoading ?
                <Row>
                    <Col>
                        <Spinner/>
                    </Col>
                </Row> :
                <Row>
                    <Col>
                        <ClassicEditor
                            initialValue={initialContent}
                            value={content}
                            onEditorChange={newContent => {
                                setContent(newContent)
                            }}
                        />
                    </Col>
                </Row>
        }
    </div>
}