import React, {useMemo} from "react";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Select from "react-select";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../../api";
import {toast} from "react-toastify";
import ConfirmUniqueTagGroupModal from "./confirmUniqueTagGroupModal";
import ConfirmSequenceTagGroupModal from "./confirmSequenceTagGroupModal";


export default function EditModal({isOpen, toggle, data}) {
    const [localData, setLocalData] = React.useState({
        ...data
    })
    const [confirmUniqueModalOpen, setConfirmUniqueModalOpen] = React.useState(false)
    const [confirmSequenceModalOpen, setConfirmSequenceModalOpen] = React.useState(false)

    const options = [
        {value: "unique", label: "Unique - only one tag per lead on this group", dataUpdate: {is_unique: true, is_sequence: false}},
        {value: "multiple", label: "Multiple - leads can have multiple tags in this group", dataUpdate: {is_unique: false, is_sequence: false}},
        {value: "sequence", label: "Sequence - tags are selected in a series", dataUpdate: {is_unique: false, is_sequence: true}},
    ]
    const selectedOption = options.find(option => option.dataUpdate.is_unique === localData.is_unique && option.dataUpdate.is_sequence === localData.is_sequence)

    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const saveMutation = useMutation((data) => {
        const tagGroupResource = api.resource('tag/group')
        const isCreate = !data.id

        if (!isCreate) {
            const updatePromise = tagGroupResource.updateItem(data.id, data).then(response => {
                return response.data
            })
            toast.promise(updatePromise, {
                pending: 'Updating Tag Group...',
                success: 'Tag Group Updated',
                error: 'Error Updating Tag Group'
            })
            return updatePromise;
        } else {
            const createPromise = tagGroupResource.create(data).then(response => {
                return response.data
            })
            toast.promise(createPromise, {
                pending: 'Creating Tag Group...',
                success: 'Tag Group Created',
                error: 'Error Creating Tag Group'
            })
            return createPromise;
        }

    }, {
        onSuccess: () => {
            toggle()
            queryClient.invalidateQueries(['tagGroup'])
        }
    })

    const fieldsChanged = useMemo(() => {
        if (!data) return [...Object.keys(localData)]
        return [...Object.keys(localData).filter(key => localData[key] !== data[key])]
    }, [localData, data])
    const fieldsComplete = useMemo(() => {
        return !Object.keys(localData).some(key => localData[key] === null || localData[key] === '')
    });

    const tagsWithDuplicate = data.tags?.filter(tag => !!tag.multiple_source)
    const tagsWithAutoTag = data.tags?.filter(tag => tag.auto_tag_new || !!tag.auto_tag_if_tag_added || !!tag.auto_tag_if_taggroup_added)


    const saveHandler = () => {
        if (tagsWithAutoTag?.length > 0 && localData.is_sequence) {
            setConfirmSequenceModalOpen(true)
        } else if ((tagsWithDuplicate?.length > 0 || tagsWithAutoTag?.length > 0) && localData.is_unique) {
            setConfirmUniqueModalOpen(true)
        } else {
            saveMutation.mutate(localData)
        }
    }

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody className="px-5 py-4" style={{minHeight: "33em"}}>
            <Row>
                <Col>
                    <h3>Tag Group Settings</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Label className="lineheight-1">Tag Group Label</Label>
                    <Input
                        value={localData.name}
                        onChange={(e) => {
                            setLocalData({...localData, name: e.target.value})
                        }}
                        type="text" placeholder="Enter Tag Group Label"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Label className="lineheight-1">Tag Group Description</Label>
                    <Input
                        value={localData.description}
                        onChange={(e) => {
                            setLocalData({...localData, description: e.target.value})
                        }}
                        type="textarea" placeholder="Enter Tag Group Label"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Select
                        value={selectedOption}
                        onChange={(option) => {
                            setLocalData({...localData, ...option.dataUpdate})
                        }}
                        options={options}
                        placeholder={<strong>Select Tag Group Type</strong>}
                        />
                </Col>
            </Row>

            <ConfirmUniqueTagGroupModal
                isOpen={confirmUniqueModalOpen}
                toggle={() => {
                    setConfirmUniqueModalOpen(!confirmUniqueModalOpen)
                }}
                confirm={() => {
                    saveMutation.mutate(localData)
                }}
                tagDuplicate={tagsWithDuplicate}
                tagAutoTag={tagsWithAutoTag}
                />

            <ConfirmSequenceTagGroupModal
                isOpen={confirmSequenceModalOpen}
                toggle={() => {
                    setConfirmSequenceModalOpen(!confirmSequenceModalOpen)
                }}
                confirm={() => {
                    saveMutation.mutate(localData)
                }}
                tagAutoTag={tagsWithAutoTag}
                />

        </ModalBody>
        <ModalFooter>
            <Row>
                <Col className="text-end">
                    <Button
                        onClick={saveHandler}
                        color="info" disabled={!fieldsComplete || fieldsChanged.length <= 0}>
                        SAVE TAG GROUP
                    </Button>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
}