import React from "react";
import {useLoggedInApi} from "../api";
import Select from "react-select";
import {useQuery} from "react-query";
import {useTagGroup} from "./fetchers/tags";
import {Badge} from "reactstrap";

export default function TagGroupSelector({selected, onSelect, excludeIds, ...props}) {

    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroup({})

    const options = tagGroupsData?.filter(row => {
        return !excludeIds?.includes(row.id)
    }).map(row => {
        return {
            value: row.id,
            label: row.name,
            disabled: !row.is_active
        }
    })

    const selectedOption = options?.find(row => row.value === selected)
    return <Select
        options={options}
        value={selectedOption ? selectedOption : null}
        onChange={(newValue) => {
            onSelect(newValue.value)
        }}
        placeholder={"Select Tag Group"}
        {...props}
        isOptionDisabled={option => option.disabled}
        formatOptionLabel={row => {
            return <span>
                {row.label}
                {
                    row.disabled && <sup>
                        <Badge color="danger" className="ms-2 rounded">INACTIVE</Badge>
                    </sup>
                }
            </span>
        }}
    />
}