import React, {useState} from "react";
import {Alert, Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import InputRow from "../../../../components/inputRow";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../../../api";
import PropertyGroupSelector from "../../../../commonComponents/propertyGroupSelector";


const defaultData = {
    title: '',
    description: '',
    template: null,
    propertyGroup: null
}

export default function NewTemplateModal({onCreate}) {

    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }


    const [localData, setLocalData] = useState(defaultData)

    const hasChanges = localData.title !== '' || localData.description !== '' || localData.template !== null || localData.propertyGroup !== null;
    const hasSelectedFile = !!localData.template;
    const fileExtensionIsXlsx = !!localData?.template?.name.endsWith('.xlsx');
    const canSave = localData.title !== '' && localData.description !== '' && localData.propertyGroup !== null && fileExtensionIsXlsx && hasSelectedFile;

    const saveMutation = useMutation((data) => {
        const resource = api.resource('properties/templates')

        const formData = new FormData()
        formData.append('template', data.template)
        formData.append('title', data.title)
        formData.append('description', data.description)
        formData.append('property_group', data.propertyGroup)
        return resource.create(formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => response.data)
    }, {
        disabled: !canSave,
        onSuccess: data => {
            queryClient.setQueryData(['properties/templates', data.id], data)
            queryClient.invalidateQueries(['table', 'properties/templates'])
            toast("New template uploaded.", {type: "success"})
            onCreate && onCreate(data)

            toggle()
            setLocalData(defaultData)
        },
        onError: error => {
            toast("Failed to upload new template.", {type: "error"})
        }
    })

    return <>
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            centered
            scrollable={true}>
            <ModalHeader toggle={toggle} className="activegroup-header">
                New Sample Computation Template</ModalHeader>
            <ModalBody className="m-3">
                <Row>
                    <Col>
                        <InputRow
                            label="Title" value={localData.title}
                            required={true}
                            onChange={newValue => {
                                setLocalData({...localData, title: newValue})
                            }}/>
                        <InputRow
                            required={true}
                            label="Description" value={localData.description} type={"textarea"}
                            onChange={newValue => {
                                setLocalData({...localData, description: newValue})
                            }}
                        />

                        <PropertyGroupSelector
                            propertyGroups={localData.propertyGroup}
                            setPropertyGroups={selectedPropertyGroup => {
                                setLocalData({...localData, propertyGroup: selectedPropertyGroup})
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <input
                            required={true}
                            className="mt-3"
                            type="file"
                            accept=".xlsx"
                            onChange={e => setLocalData({...localData, template: e.target.files[0]})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            (!fileExtensionIsXlsx && hasSelectedFile) &&
                            <Alert color="danger" className="small mt-2 p-2 ps-3">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger"/>{' '}
                                Only XLSX files are allowed.
                            </Alert>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col className={"text-end"}>
                        <Button
                            disabled={!hasChanges || !canSave} color={"primary"}
                            onClick={() => saveMutation.mutate(localData)}
                        >
                            UPLOAD TEMPLATE
                        </Button>
                    </Col>
                </Row>
                <Row className="small">
                    <Col>
                        <p>
                            File Guidelines
                            <ul>
                                <li>
                                    Only XLSX files are allowed.
                                </li>
                                <li>
                                    You can use the following placeholders in the template but are not required:
                                    <ul>
                                        <li><code>[PHASE]</code></li>
                                        <li><code>[BLOCK]</code></li>
                                        <li><code>[LOT]</code></li>
                                        <li><code>[AREA]</code></li>
                                        <li><code>[PRICE]</code></li>
                                        <li><code>[DESCRIPTION]</code></li>
                                    </ul>
                                    If no placeholders are used, the template will be used as is.
                                </li>
                            </ul>
                        </p>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>


        <Button color="info-outline" onClick={toggle}>
            <FontAwesomeIcon icon={faCirclePlus}/>{" "}
            ADD NEW TEMPLATE
        </Button>
    </>
}