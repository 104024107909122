import React from "react";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import ViewHelpExpandedColumn from "./viewHelpExpandedColumn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faPlusCircle, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import EditModal from "./editModal";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {toast} from "react-toastify";
import {MODULE_CHOICES} from "./constants";


export default function HelpList({site, listTitle, showModule}) {
    const [editId, setEditId] = React.useState(null)
    const api = useLoggedInApi()
    const queryClient = useQueryClient();
    const deleteHelpMutation = useMutation((id) => {
        const resource = api.resource('help')
        const deletePromise = resource.deleteItem(id)
        toast.promise(deletePromise, {
            loading: "Deleting...",
            success: "Deleted",
            error: "Error deleting"
        });
        return deletePromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table', 'help'])
        }
    });

    return <>

        <Row>
            <Col>
                <h3 className="page-header pb-2">{listTitle} Help Management</h3>
            </Col>
        </Row>

        <Row>
            <Col className="text-end">
                <Button color="primary" onClick={() => {
                    setEditId(0);
                }}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2"/>{" "}
                    ADD NEW HELP ENTRY
                </Button>
            </Col>
        </Row>

        <AdvancedTable
            filterMapOverride={{
                site: site
            }}
            apiResourceName={'help'}
            friendlyResourceName={"Help"}
            usePagination={true}
            columnDefinition={[
                {
                    name: "Title",
                    selector: row => row.title,
                    sortable: false,
                },
                ...(showModule ? [{
                    name: "Module",
                    selector: row => row.module,
                    sortable: false,
                    format: (row) => row.module ? MODULE_CHOICES[row.module] : null
                }] : []),
                {
                    name: "Actions",
                    selector: row => row.id,
                    sortable: false,
                    width: "10em",
                    format: (row) => <ButtonGroup>
                        <Button color="primary" size={"sm"} className={"px-2"} onClick={() => {
                            setEditId(row.id)
                        }}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </Button>

                        <ConfirmButton
                            color="danger"
                            size={"sm"}
                            className={"px-2"}
                            icon={faTrashCan}
                            message={"Sure?"}
                            onClick={() => {
                                deleteHelpMutation.mutate(row.id)
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </ConfirmButton>
                    </ButtonGroup>
                }
            ]}
            dataTableProps={{
                expandableRows: true,
                expandableRowsComponent: ViewHelpExpandedColumn
            }}
        />

        <EditModal key={editId} isOpen={editId !== null} toggle={() => {
            setEditId(null)
        }} helpId={editId} showModule={showModule} site={site}/>
    </>
}