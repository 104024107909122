import React from "react";
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import TagDisplay from "../../../tagDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faSortAlphaAsc,
    faSortAlphaDesc,
    faSortAmountAsc, faSortAmountDesc,
    faSortDown,
    faSortUp,
    faX
} from "@fortawesome/free-solid-svg-icons";
import {COLOR_PRIMARY, COLOR_DEFAULT, COLOR_SELECTED} from "./constants";
import moment from "moment";


export default function LeadSelector(
    {
        leads,
        setSelectedLeads,
        selectedLeads,
        primaryLead,
        setPrimaryLead,
        selectedSalesProfile
    }
) {

    const [sort, setSort] = React.useState("name")
    const [sortDirection, setSortDirection] = React.useState("asc")
    const [sortDropdownOpen, setSortDropdownOpen] = React.useState(false)

    const toggleSort = (sortName) => {
        if (sortName === sort) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc")
        } else {
            setSortDirection("asc")
            setSort(sortName)
        }
        setSortDropdownOpen(false)
    }

    const sortOptions = {
        name: {
            sortFunction: (a, b) => {
                if (a.name < b.name) {
                    return sortDirection === "asc" ? -1 : 1
                }
                if (a.name > b.name) {
                    return sortDirection === "asc" ? 1 : -1
                }
                return 0
            },
            display: "Name",
            sortIcon: sortDirection === "asc" ? faSortAlphaAsc : faSortAlphaDesc
        },
        datetime_updated: {
            sortFunction: (a, b) => {
                if (a.datetime_updated < b.datetime_updated) {
                    return sortDirection === "asc" ? -1 : 1
                }
                if (a.datetime_updated > b.datetime_updated) {
                    return sortDirection === "asc" ? 1 : -1
                }
                return 0
            },
            display: "Last Updated",
            sortIcon: sortDirection === "asc" ? faSortAmountAsc : faSortAmountDesc
        },
        datetime_created: {
            sortFunction: (a, b) => {
                if (a.datetime_created < b.datetime_created) {
                    return sortDirection === "asc" ? -1 : 1
                }
                if (a.datetime_created > b.datetime_created) {
                    return sortDirection === "asc" ? 1 : -1
                }
                return 0
            },
            display: "Date Created",
            sortIcon: sortDirection === "asc" ? faSortAmountAsc : faSortAmountDesc
        }
    }

    const sortedLeads = leads.sort(sortOptions[sort].sortFunction)

    return <>

        <p className="text-muted">
            Below are leads with duplicate information. Only users with merge capabilities can merge and re-assign a
            lead.
        </p>
        <p className="text-muted">
            <TagDisplay color={COLOR_PRIMARY}>
                PRIMARY LEAD
            </TagDisplay>
            will be the lead that will be retained after the merge.
        </p>

        <Row>
            <Col>
                Showing <strong>{leads.length}</strong> possible duplicate leads.
            </Col>
            <Col className={"text-end"}>
                <Dropdown isOpen={sortDropdownOpen}>
                    <DropdownToggle color="" variant="success" id="dropdown-basic" onClick={() => {
                        setSortDropdownOpen(!sortDropdownOpen)
                    }}>
                        Sort by:{" "}
                        {sortOptions[sort].display}{" "}
                        <FontAwesomeIcon icon={sortOptions[sort].sortIcon}/>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            Object.entries(sortOptions).map(([key, value]) => {
                                return <DropdownItem onClick={() => toggleSort(key)}>
                                    {value.display}
                                </DropdownItem>
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>

        {
            sortedLeads.map(lead => {
                const isPrimary = lead.id === primaryLead
                const isSelected = selectedLeads.includes(lead.id)

                const toggleSelected = () => {
                    if (selectedLeads.includes(lead.id)) {
                        if (!isPrimary) {
                            setSelectedLeads(selectedLeads.filter(row => row !== lead.id))
                        }
                    } else {
                        setSelectedLeads([...selectedLeads, lead.id])
                        if (!primaryLead) {
                            setPrimaryLead(lead.id)
                        }
                    }
                }

                return <Row
                    className="border-top mt-4 mx-1 py-2 text-muted"
                    style={{
                        backgroundColor: isSelected ? COLOR_SELECTED : COLOR_DEFAULT
                    }}
                    onClick={toggleSelected}
                >
                    <Col>
                        <Row>
                            <Col>{lead.name}</Col>
                            <Col className="text-end">
                                {
                                    <TagDisplay
                                        color={isSelected ? 'green' : 'gray'}
                                        onClick={(e) => {
                                            toggleSelected()
                                            e.stopPropagation()
                                        }}>
                                        <FontAwesomeIcon icon={isSelected ? faCheck : faX}/> MERGE
                                    </TagDisplay>
                                }
                                {
                                    <TagDisplay
                                        color={isPrimary ? COLOR_PRIMARY : 'gray'}
                                        onClick={(e) => {
                                            setPrimaryLead(lead.id)
                                            if (!selectedLeads.includes(lead.id)) {
                                                setSelectedLeads([...selectedLeads, lead.id])
                                            }
                                            e.stopPropagation()
                                        }}>
                                        PRIMARY LEAD
                                    </TagDisplay>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>{lead.contact_number} | {lead.email_address}</Col>
                        </Row>
                        <Row>
                            <Col>
                                Broker/Sales Contact: {lead.sales_profile_display.name}
                                {" "}
                                {
                                    lead.sales_profile === selectedSalesProfile &&
                                    <FontAwesomeIcon icon={faCheck} className="text-success"/>
                                }
                                {
                                    (lead.sales_profile !== selectedSalesProfile && isSelected) &&
                                    <FontAwesomeIcon icon={faX} className="text-danger"/>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col className="small">
                                Last Updated: {moment(lead.datetime_updated).format("MMM D, YYYY hh:mm A")}
                                {" "}|{" "}
                                Created: {moment(lead.datetime_added).format("MMM D, YYYY hh:mm A")}
                            </Col>
                        </Row>

                    </Col>
                </Row>
            })
        }
    </>
}