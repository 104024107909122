import React from "react";
import "../../../commonComponents/assets/css/dashboard.css"
import {
    faChartSimple,
    faCircleQuestion,
    faFileExcel,
    faHouseChimney,
    faHouseLaptop,
    faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";


export default function PropertyManagement() {
    const sidebarItems = [
        {icon: faHouseLaptop, text: "PROPERTY MANAGEMENT", to: '/property-management/'},
        {icon: faMapMarkedAlt, text: "MANAGE PROPERTY GROUPS", to: 'groups'},
        {icon: faHouseChimney, text: "MANAGE PROPERTY LISTINGS", to: 'listing'},
        {icon: faFileExcel, text: "MANAGE TEMPLATES", to: 'templates'},
        {icon: faChartSimple, text: "SUMMARY REPORTS", to: 'reports'},
        {icon: faCircleQuestion, text: "NEED HELP?", to: 'help'}
    ]

    return <ModuleFactory
        subItems={['properties/groups', 'properties']}
        sidebarItems={sidebarItems}
    />
}