import Reeact from 'react';
import {Input} from "reactstrap";
import {usePropertyGroups} from "../../../hooks";


export default function PropertyGroupsDropDown({selectedPropertyGroups, setSelectedPropertyGroups}) {
    const {data: propertyGroups, loading} = usePropertyGroups({})

    return <Input
        type="select" size="sm" className="border-0 font-bold"
        value={selectedPropertyGroups?.[0]}
        onChange={(e) => {
            setSelectedPropertyGroups(e.target.value ? [e.target.value] : [])
        }}>
        <option value={0}>Property</option>
        {
            loading && <option disabled>Loading...</option>
        }

        {
            propertyGroups?.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            }).map(propertyGroup => {
                return <option key={propertyGroup.id} value={propertyGroup.id}>
                    {/*{selectedPropertyGroups?.includes(propertyGroup.id) && "Property: "}*/}
                    {propertyGroup.name}
                </option>
            })
        }
    </Input>
}