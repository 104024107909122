import React, {useState} from "react";
import {Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import View from "./view";
import Edit from "./edit";


export default function Detail({propertyId, isOpen, toggle, onCreate, defaultData}) {
    const [isEdit, setIsEdit] = useState(!propertyId)

    const api = useLoggedInApi()
    const query = useQuery(['property', propertyId], () => {
        const resource = api.resource('properties')
        return resource.getItem(propertyId).then(response => response.data)
    }, {
        enabled: !!propertyId,
        refetchOnWindowFocus: false
    })

    if (query.isError) {
        return <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            scrollable={true}
        >
            <ModalHeader toggle={toggle} className="activegroup-header">

            </ModalHeader>
            <ModalBody className="px-5 py-4">
                <Row className="m-5">
                    <Col className="text-center">
                        Cannot fetch property information. Please try again. If problem persists, contact administrator.
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    }

    if (query.isLoading) {
        return <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            scrollable={true}
        >
            <ModalHeader toggle={toggle} className="activegroup-header">

            </ModalHeader>
            <ModalBody className="px-5 py-4">
                <Row className="m-5">
                    <Col className="text-center">
                        <Spinner/>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    }


    const data = (query.isSuccess && !!propertyId) ? query.data : defaultData
    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            {
                isEdit ?
                    <Edit
                        key={propertyId ? propertyId : 'new'}
                        defaultData={defaultData ? defaultData : data}
                        setIsEdit={setIsEdit}
                        propertyId={propertyId}
                        originalData={data}
                        onSave={newData => {
                            if (!propertyId) {
                                onCreate(newData)
                            } else {
                                setIsEdit(false)
                            }
                        }}
                    /> :
                    <View data={data} setIsEdit={setIsEdit}/>
            }
        </ModalBody>
    </Modal>
}