import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {
    faChartColumn,
    faGears, faHashtag,
    faHouseLaptop, faIdBadge, faIdCard,
    faPhotoVideo, faQuestionCircle,
    faUsersGear,
    faUserTag
} from "@fortawesome/free-solid-svg-icons";
import MenuItem from "./menuItem";
import {ProfileContext} from "../../../commonComponents/contexts";


export default function MainMenu() {

    const {profile} = useContext(ProfileContext)
    const {modules} = profile;

    return <>
        <Row>
            <Col className="text-center">
                <h3>Management Modules are available based on your access.</h3>
                <p>If you need access to additional modules, please contact the administrator.</p>
            </Col>
        </Row>
        <div className="border-bottom mb-5"/>

        <Row className="mt-5">
            {
                modules?.includes('broker') && <MenuItem
                    icon={faUserTag}
                    path={'broker-management/'}
                    name={'Broker Management'}
                    description={'Update broker information, create groups and assign properties.'}
                />
            }
            {
                modules?.includes('property') && <MenuItem
                    icon={faHouseLaptop}
                    path={'property-management/'}
                    name={'Property Inventory Management'}
                    description={'Update property information, group properties into categories.'}
                />
            }
            {
                modules?.includes('users') && <MenuItem
                    icon={faUsersGear}
                    path={'users/'}
                    name={'User Administration'}
                    description={'Manage user access to and download system backups.'}
                />
            }

            {
                modules?.includes('mediakit') && <MenuItem
                    icon={faPhotoVideo}
                    path={"media-kit/"}
                    name={"Media Kit"}
                    description={"Upload official photos, videos, brochures, site maps and logos into media kits that will be available for brokers to download."}
                />
            }

            {
                modules?.includes('tags') && <MenuItem
                    icon={faHashtag}
                    path={"tag-admin/"}
                    name={"Tags Management"}
                    description={"Manage tags for leads."}
                />
            }
            {
                modules?.includes('forms') && <MenuItem
                    icon={faIdCard}
                    path={"form-admin/"}
                    name={"Lead Profile Settings"}
                    description={"Manage leads profile form settings."}
                />
            }

            {
                modules?.includes('option') && <MenuItem
                    icon={faGears}
                    path={'system-options/'}
                    name={'System Options'}
                    description={'Manage options and other system settings.'}
                />
            }
            {
                modules?.includes('report') && <MenuItem
                    icon={faChartColumn}
                    path={'summary-reports/'}
                    name={'Summary Reports'}
                    description={'View and download summary reports'}
                />
            }


            {

                modules?.includes('help') && <MenuItem
                    icon={faQuestionCircle}
                    path={"help-management/"}
                    name={"Help Management"}
                    description={"Manage help entries and respond to help inquiries."}
                />
            }

        </Row>
    </>
}