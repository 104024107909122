import React from "react";

export const ProfileContext = React.createContext({
    profile: {
        'id': null,
        'name': null,
        'active_group_employee_id': null,
        'email_address': null,
        'contact_number': null,
        'profile_photo_url': null,
        'access': [],

        'licenses': null,
        'affiliation': null,
        'active_group_seller_id': null,
        'url_path': null,
        'is_broker': null,

        'is_superuser': false,
    },
    updateProfile: newProfileDict => {},
    refreshProfile: () => {}
})

export const IsLoggedInContext = React.createContext({
    isLoggedIn: false,
    logout: () => {}
})


export const TableSearchContext = React.createContext({
    searchText: {
        broker: '',
        salesperson: '',
        leads: ''
    },
    setSearchText: (key, value) => {}
})

export const TableFilterContext = React.createContext({
    filters: {
        broker: {},
        salesperson: {},
        leads: {},
    },
    setFilters: (key, filterName, filterValue) => {}
})


export const ToggleSidebarContext = React.createContext({
    isOpen: false,
    toggle: () => {},
    close: () => {}
})


export const FilterContext = React.createContext({
    filters: {},
    setFilters: (newFilters) => {},
    setFilter: (filterName, filterValue, filterGroup) => {}
})


export const PermissionsContext = React.createContext({
    permissions: []
})


export const LeadTagFilterContext = React.createContext({
    tags: [],
    setTags: (tags) => {}
})