import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {ProfileContext, ToggleSidebarContext} from "../../commonComponents/contexts";
import {useLogout} from "../../api";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Navbar,
    NavbarBrand,
    NavbarText,
    NavbarToggler
} from "reactstrap";
import bannerImage from "../../commonComponents/assets/horizontal-banner.png";
import ProfilePicture from "../../components/profilePicture";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import UpdatePasswordModal from "../../components/updatePasswordModal";


function DashboardHeader(props) {

    const {toggle: toggleSidebar} = useContext(ToggleSidebarContext)
    const navigate = useNavigate();
    const {profile} = useContext(ProfileContext)
    const {name, profile_photo_url} = profile
    const [isOpen, setIsOpen] = useState(false)
    const [showUpdatePassword, setShowUpdatePassword] = useState(false)

    const logout = useLogout()

    return <>
        <Navbar sticky={"top"} dark className="dashboard-header activegroup-header">

            <NavbarToggler onClick={toggleSidebar} className="d-lg-none d-xl-none"/>
            <NavbarBrand onClick={() => {
                navigate('/')
            }}>
                <img src={bannerImage} className="img-fluid" alt={"Active Group Banner"}/>
            </NavbarBrand>
            <NavbarText>
                <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                    <DropdownToggle className="profile-toggle toggle-clear">
                        <span className="pe-2 d-none d-lg-inline-block d-xl-inline-block">{name}</span>
                        <ProfilePicture url={profile_photo_url}/>
                    </DropdownToggle>
                    <DropdownMenu>
                        {/*<DropdownItem header>Header</DropdownItem>*/}
                        {/*<DropdownItem disabled>Action</DropdownItem>*/}
                        <DropdownItem onClick={() => {
                            navigate('/my-profile')
                        }}>
                            <FontAwesomeIcon icon={faUser}/> My Profile
                        </DropdownItem>
                        <DropdownItem onClick={() => setShowUpdatePassword(true)}>
                            <FontAwesomeIcon icon={faKey}/> Update Password
                        </DropdownItem>
                        <DropdownItem divider/>
                        <DropdownItem onClick={() => {
                            logout(() => {
                                navigate('/')
                            })
                        }}>
                            <FontAwesomeIcon icon={faLock}/> Logout
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavbarText>
        </Navbar>

        <UpdatePasswordModal isOpen={showUpdatePassword} toggle={() => setShowUpdatePassword(false)}/>
    </>;
}

export default DashboardHeader;