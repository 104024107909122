import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export default function Help({data}) {
    return <>
        <div dangerouslySetInnerHTML={{__html: data.content}}></div>

        <div className="pt-2">
            {
                data.attachments?.length > 0 ? <>

                    <span className="fw-bold text-black-50">Attachments</span>
                    <ul>
                        {
                            data.attachments.map((attachment, index) => {
                                return <li key={index}>
                                    {attachment.name}
                                    {" "}
                                    <a
                                        className={"small text-decoration-none"}
                                        href={attachment.file} target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faDownload}/>
                                        Download
                                    </a>

                                </li>
                            })
                        }
                    </ul>
                </> : <></>
            }
        </div>
    </>
}