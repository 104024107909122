import React from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faUndo, faWarning} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import DownloadTemplate from "./downloadTemplate";
import UploadReader from "./uploadReader";
import EditableLeadTable from "./editableLeadTable";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {toast} from "react-toastify";
import {chunkArray} from "../../../util";


export default function ImportLeads() {
    const navigate = useNavigate()

    const [data, setData] = React.useState([])
    const [filename, setFileName] = React.useState('')
    const [isProcessingFile, setIsProcessingFile] = React.useState(false)

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const saveMutation = useMutation((newData) => {
        const resource = api.resource('leads')

        const chunks = chunkArray(newData.rows, 50); // Create chunks of 50
        const savePromises = chunks.map(chunkData => {
            return resource.postAction('bulk_create', {
                ...newData,
                rows: chunkData,
            });
        });

        const saveAllPromise = Promise.all(savePromises);

        toast.promise(saveAllPromise, {
            pending: 'Saving...',
            success: 'Saved',
            error: 'Error saving'
        })
        return saveAllPromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table', 'leads'])
            navigate('../')
        }
    })

    return <>
        <Row className="mb-4">
            <Col>
                <Button color="primary" className="text-white" onClick={() => {
                    navigate('../')
                }}>
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
                    BACK TO MANAGE LEADS
                </Button>
            </Col>

            <Col className={"text-end"}>
                <DownloadTemplate/>
            </Col>
        </Row>
        <Row className="mt-4 border-bottom">
            <Col>
                <h3>Import Leads</h3>
            </Col>
        </Row>

        <Row>
            <Col>
                <p>
                    Download the template and fill in the details.
                </p>
                <p>
                    The template is personalized for your account and includes the latest fields. Make sure that the
                    template you are using is the latest one.
                </p>
                <p>
                    <FontAwesomeIcon icon={faWarning} className="me-2"/>
                    Do not modify the template.
                </p>
                <hr/>
            </Col>
        </Row>

        {
            !!filename ? <>
                <Row className="mb-3">
                    <Col>
                        Filename: {filename}
                        <Button
                            className="ms-3"
                            size={"sm"}
                            color={"link"} onClick={() => {
                            setData([])
                            setFileName('')
                        }}
                        >
                            Change File
                            <FontAwesomeIcon icon={faUndo} className={"ms-1"}/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EditableLeadTable
                            isLoadingData={isProcessingFile}
                            isSaving={saveMutation.isLoading}
                            data={data}
                            saveData={(newData) => {
                                saveMutation.mutate({
                                    rows: newData,
                                    filename: filename
                                })
                            }}
                        />
                    </Col>
                </Row>
            </> : <Row>
                <Col>
                    <UploadReader
                        setIsProcessing={setIsProcessingFile}
                        setFileName={setFileName}
                        setProcessedData={(fileData) => {
                            setData(fileData)
                        }}/>
                </Col>
            </Row>

        }

    </>
}