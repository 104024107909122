import React from "react";
import TagFilter from "./tagFilter";
import TagDisplay from "../../../../../tagDisplay";
import {Col, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import DateRangeFilter from "../../../../../dateRangeFilter";


export default function Filter(
    {
        activeTagIds, setActiveTagIds,
        selectedUpdateBy, removeSelectedUpdateBy,
        search, setSearch,
        dateRange, setDateRange,
        showPropertyGroups
    }
) {
    return <>
        <Row className="small row-cols-lg-auto g-3 align-items-top">

            <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange}/>

            <Col>
                <Label className="small">SEARCH</Label>
                <InputGroup>
                    <Input
                        size="sm"
                        className="border-0"
                        type="text" placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}/>
                    <InputGroupText style={{background: "none"}} className='border-0'>
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </InputGroupText>
                </InputGroup>

            </Col>
        </Row>
        <Row className="border-top mt-2 pt-2">
            <Col>

                <small style={{color: "#666"}} className="me-2">
                    <strong className="small">SHOWING:</strong>
                </small>
                <TagFilter
                    setActiveTagIds={setActiveTagIds}
                    activeTagIds={activeTagIds}
                    showSpecialTagGroup={true}
                    showPropertyGroups={showPropertyGroups}
                />

                {
                    selectedUpdateBy && <TagDisplay
                        color={"#b0c4de"}
                        onDelete={removeSelectedUpdateBy}
                    >
                        UPDATED BY: {selectedUpdateBy.name.toUpperCase()}
                    </TagDisplay>
                }
            </Col>
        </Row>
    </>
}