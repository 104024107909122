import React from "react";
import InputRow from "../inputRow";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../api";
import Select from "react-select";
import {REACT_SELECT_STYLES} from "../../constants";
import {useBrokerGroups} from "../../commonComponents/hooks";


export default function SalesPersonInfo({profileData, isProfileSaving, updateErrorStatus, setProfileData, canEditAffiliation}) {

    const brokersResult = useBrokerGroups({
        queryOptions: {
            enabled: canEditAffiliation
        }
    })

    const affiliationOptions = canEditAffiliation ? (
        brokersResult.isSuccess ? [
            ...brokersResult.data.map(row => ({label: row.name, value: row.id}))
        ] : []
    ) : [
        {label: profileData.group_name, value: profileData.group}
    ]
    const selectAffiliation = (profileData.group && affiliationOptions.length > 0) ? affiliationOptions.filter(o => o.value === profileData.group)[0] : null;

    return <>
        <InputRow
            required={!canEditAffiliation}
            readOnly={!canEditAffiliation}
            label={"Broker Affiliation"}
            value={selectAffiliation}
            setHasError={hasError => updateErrorStatus('group', hasError)}
            onChange={newOption => setProfileData({...profileData, group: newOption.value})}
            renderer={renderProps => <Select
                {...renderProps}
                isDisabled={!canEditAffiliation}
                placeholder={"Select broker affiliation..."}
                // styles={REACT_SELECT_STYLES}
                options={affiliationOptions}
                isLoading={brokersResult.isLoading}
            />}
        />
    </>
}