import React, {useState} from "react";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";
import ProfilePhotoModal from "./profilePhotoModal";
import * as PropTypes from 'prop-types'


function ProfilePhotoEdit(props) {

    const [profilePhotoUrl, setProfilePhotoUrl] = useState("")
    const [profileModalOpen, setProfileModalOpen] = useState(false)

    const effectiveProfilePhoto = profilePhotoUrl ? profilePhotoUrl : props.profilePhotoUrl

    return <>
        <Button color="info-outline" disabled={props.isNew} onClick={() => setProfileModalOpen(true)}>

            <FontAwesomeIcon icon={faCircleUser}/> {" "}
            {!effectiveProfilePhoto ? "SET" : "UPDATE"} {props.isMe && "MY"} PROFILE PHOTO
        </Button>
        <ProfilePhotoModal
            resourceName={props.resourceName}
            profileId={props.profileId}
            onChange={newUrl => {
                setProfilePhotoUrl(newUrl)
                props.onChange(newUrl)
            }}
            isOpen={profileModalOpen}
            url={effectiveProfilePhoto}
            toggle={() => setProfileModalOpen(false)}/>
    </>
}

ProfilePhotoEdit.propTypes = {
    isNew: PropTypes.bool,
    isMe: PropTypes.bool,
    profilePhotoUrl: PropTypes.string,
    profileId: PropTypes.number,
    onChange: PropTypes.func,
    resourceName: PropTypes.string.isRequired
}

export default ProfilePhotoEdit