import React, {useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import InputRow from "../../../../components/inputRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import ApplyModal from "./applyModal";
import EditTemplateFile from "./editTemplateFile";

export default function SampleComputationEdit({data}) {
    const templateId = data?.id
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const [localData, setLocalData] = useState({
        title: '',
        description: '',
        template: null
    })

    const query = useQuery(["properties/templates", templateId], () => {
        const resource = api.resource('properties/templates')
        return resource.getItem(templateId).then(response => response.data)
    }, {
        enabled: !!templateId,
        onSuccess: data => {
            setLocalData({
                title: data.title,
                description: data.description,
                template: data.template
            })
        },
        onError: () => {
            toast("Something went wrong. Cannot fetch template.", {type: "error"})
        },
        refetchOnWindowFocus: false
    })

    const hasChanges = query.isSuccess && !!query.data && (localData.title !== query.data?.title ||
        localData.description !== query.data?.description);

    const saveMutation = useMutation(() => {
        const resource = api.resource('properties/templates')
        return resource.patchItem(templateId, {
            title: localData.title,
            description: localData.description
        }).then(response => response.data)
    }, {
        onSuccess: data => {
            queryClient.setQueryData(['properties/templates', templateId], data)
            queryClient.invalidateQueries(['table', 'properties/templates'])
            toast("Template updated.", {type: "success"})
        }
    })

    return <div className="m-2 p-2 mb-4">

        <Row>
            <Col>
                <p>
                    Excel template was uploaded
                    by {query.data?.uploaded_by} on {new Date(query.data?.datetime_added).toLocaleString()}
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <EditTemplateFile data={query.data}/>
            </Col>
            <Col className="text-end">
                <ApplyModal data={data}/>

            </Col>
        </Row>

        <hr/>
        <h5>{data.id ? "Edit title and description" : "Upload new file"}</h5>
        <Row>
            <Col>
                <InputRow
                    label="Title" value={localData.title}
                    onChange={newValue => {
                        setLocalData({...localData, title: newValue})
                    }}/>
                <InputRow
                    label="Description" value={localData.description} type={"textarea"}
                    onChange={newValue => {
                        setLocalData({...localData, description: newValue})
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col className={"text-end"}>
                <Button
                    disabled={!hasChanges} color={"primary"}
                    onClick={() => saveMutation.mutate()}
                >
                    Save
                </Button>
            </Col>
        </Row>
    </div>
}