import React from "react";
import {Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLineChart} from "@fortawesome/free-solid-svg-icons";
import {useLoggedInApi} from "../../../../../api";
import RankingItem from "./rankingItem";
import {useQuery} from "react-query";
import moment from "moment/moment";


export default function TopTeams(props) {

    const api = useLoggedInApi()
    const leaderboardResource = api.resource('leaderboards')

    const results = useQuery(['top-teams'], () => {
        return leaderboardResource.getItem('top_groups').then(response => {
            return response.data
        })
    })


    return <>

        <Row>
            <Col className="col-auto">
                <FontAwesomeIcon icon={faLineChart} className="fa-3x"/>
            </Col>
            <Col>
                <h3 className="mb-0">
                    <strong>TOP TEAMS</strong>
                </h3>
                <small>Month of {moment().format("MMM, YYYY")}</small>
            </Col>
        </Row>
        <div className="small">
            {
                results.isLoading ? <div className="text-center py-5">
                    <Spinner/>
                </div> : (
                    (
                        !!results.data && results.data.length > 0) ?
                        results.data.map((row, idx) => <RankingItem
                            key={'ranking-' + idx.toString()}
                            image={row.profile_photo_url}
                            name={row.name}
                            unitsSold={row.sales_count}
                            canEdit={row.can_edit}
                        />) : <div className="text-center py-5">
                            <h4>No data yet</h4>
                            <h5>Get your team in the leaderboard!</h5>
                        </div>
                )
            }
        </div>
    </>
}