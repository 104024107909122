import React from "react";
import {Col, Row} from "reactstrap";
import SalesProfileForm from "../../../../components/salesProfileForm";


export default function NewSalesperson(props) {
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Add New Salesperson</h3>
            </Col>
        </Row>

        <SalesProfileForm
            isMe={false}/>
    </>
}