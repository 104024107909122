import React, {useMemo} from "react";
import {Col, Row} from "reactstrap";
import TagDisplay from "../../../../../commonComponents/tagDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faFloppyDisk, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import TagEditModal from "./tagEditModal";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {ReactSortable} from "react-sortablejs";
import PermissionRequiredComponent from "../../../../../commonComponents/permissionRequiredComponent";

import {usePermissionContextCheck} from "../../../../../commonComponents/hooks";

const TAG_DEFAULT_DATA = {
    "name": "",
    "description": "",
    "color": "#808080",
    "tag_group": null,
    "accept_datetime": false,
    "send_reminder": false,
    "accept_text": false,
    "multiple_source": null,
    "source_model_name": "",
    "source_field": "",
    "order": null,
    "tag_expiration": null,
    "auto_tag_new": false,
    "auto_tag_if_tag_added": null,
    "auto_tag_if_taggroup_added": null,
    "display_on_list": false,
    "is_active": true
}


export default function TagGroup({data, showEdit}) {
    const canEditTagGroup = usePermissionContextCheck('tagform.change_taggroup')
    const canEditTag = usePermissionContextCheck('tagform.change_tag')
    const canDeleteTagGroup = usePermissionContextCheck('tagform.delete_taggroup')
    const canDeleteTag = usePermissionContextCheck('tagform.delete_tag')

    const sortedTags = useMemo(() => {
        return [...data.tags.sort((a, b) => a.order - b.order)]
    }, [data.tags])
    const [editTagModalOpen, setEditTagModalOpen] = React.useState(false)
    const [editTagId, setEditTagId] = React.useState(null)
    const [localSortedTags, setLocalSortedTags] = React.useState(sortedTags)

    React.useEffect(() => {
        setLocalSortedTags(sortedTags)
    }, [sortedTags])

    const createNewTag = () => {
        setEditTagId(-(new Date()).getTime())
        setEditTagModalOpen(true)
    }
    const editTag = (id) => {
        setEditTagId(id)
        setEditTagModalOpen(true)
    }

    const selectedTagData = editTagId > 0 ? data?.tags?.find(row => row.id === editTagId) : {
        ...TAG_DEFAULT_DATA,
        tag_group: data.id,
        order: data.tags.length + 1
    }

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const mutateIsActive = useMutation((value) => {
        const tagsGroupResource = api.resource('tag/group')
        const updatePromise = tagsGroupResource.patchItem(data.id, {
            is_active: value
        }).then(response => {
            return response.data
        })

        toast.promise(updatePromise, {
            pending: value ? 'Showing Tag Group...' : 'Hiding Tag Group...',
            success: value ? 'Tag Group is now visible' : 'Tag Group is now hidden',
            error: 'Error Updating Tag Group'
        })

        return updatePromise;
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['tagGroup'])
        }
    })

    const mutateChangeOrder = useMutation((newTags) => {
        const tagsGroupResource = api.resource('tag/tag')
        const tagId2NewOrder = newTags.reduce((acc, row) => {
            const originalOrder = sortedTags.find(tag => tag.id === row.id).order
            if (originalOrder !== row.order) {
                acc[row.id] = row.order
            }
            return acc
        }, {})
        const updatePromise = Promise.all(Object.keys(tagId2NewOrder).map(tagId => {
            return tagsGroupResource.patchItem(tagId, {
                order: tagId2NewOrder[tagId]
            })
        }))

        toast.promise(updatePromise, {
            pending: 'Updating Tag Order...',
            success: 'Tag Order Updated',
            error: 'Error Updating Tag Order'
        })

        return updatePromise;
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['tagGroup'])
        }
    })

    const updateLocalSort = (newTags) => {
        const orderedTags = newTags.map((row, index) => {
            return {
                ...row,
                order: index + 1
            }
        });
        setLocalSortedTags(orderedTags)
    }

    const sortedTagsChanged = useMemo(() => {
        const sortedTagIds = sortedTags.map(row => row.id)
        const localSortedTagIds = localSortedTags.map(row => row.id)
        return JSON.stringify(sortedTagIds) !== JSON.stringify(localSortedTagIds)
    }, [sortedTags, localSortedTags]);


    const canSort = data.is_editable && canEditTagGroup && canEditTag

    return <Row className="border border-1 rounded p-3 my-4">
        <Col>
            <Row>
                <Col>
                    <PermissionRequiredComponent requiredPermissions={['tagform.change_taggroup']}>
                        <TagDisplay onClick={showEdit} color="red" disabled={!data.is_editable}>
                            <FontAwesomeIcon icon={faPencil}/> EDIT TAG GROUP SETTINGS
                        </TagDisplay>
                    </PermissionRequiredComponent>
                    <TagDisplay
                        disabled={!data.is_editable || !canEditTagGroup || !canDeleteTagGroup}
                        onClick={() => {
                            mutateIsActive.mutate(!data.is_active)
                        }}
                        color={data.is_active ? undefined : 'light-gray'}
                    >
                        <FontAwesomeIcon icon={data.is_active ? faEye : faEyeSlash}/> {" "}
                        {data.is_active ? "VISIBLE" : "NOT VISIBLE"}
                    </TagDisplay>
                    <TagDisplay disabled={!data.is_editable || !canEditTagGroup} onClick={showEdit}>
                        {data.is_unique ? 'UNIQUE' : (data.is_sequence ? 'SEQUENCE' : 'MULTIPLE')}
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h4>{data.name}</h4>
                    <p className="small">
                        {data.description}
                    </p>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        localSortedTags.length > 1 && canSort &&
                        <small className="text-black-50">
                            Note: You can re-order the tags by dragging and dropping.
                        </small>
                    }
                    {
                        !data.is_editable && <p><small className={'text-danger'}>
                            Note: You do not have permission to edit this tag group.
                        </small>
                        </p>
                    }
                    <ReactSortable
                        disabled={!canSort}
                        list={localSortedTags}
                        setList={updateLocalSort}
                    >
                        {
                            localSortedTags.map(row => {
                                const tagLocked = !row.is_editable || (!row.is_active && !canDeleteTag)
                                return <span>
                                    <TagDisplay
                                        disabled={!data.is_editable ? false : tagLocked}
                                        key={row.id}
                                        color={row.color}
                                        textColor={row.text_color}
                                        description={row.description}
                                        onClick={data.is_editable ? () => {
                                            editTag(row.id)
                                        } : undefined}
                                    >
                                        {
                                            !row.is_active ?
                                                <span className={'text-decoration-line-through'}>{row.name}</span> :
                                                row.name
                                        }
                                </TagDisplay>
                                </span>
                            })
                        }
                    </ReactSortable>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <PermissionRequiredComponent requiredPermissions={['tagform.add_tag']}>
                        <TagDisplay disabled={!data.is_editable} color="red" onClick={() => {
                            createNewTag()
                        }}>
                            <FontAwesomeIcon icon={faPlus}/> ADD TAG
                        </TagDisplay>
                    </PermissionRequiredComponent>
                    <PermissionRequiredComponent requiredPermissions={['tagform.change_tag']}>
                        <TagDisplay color={sortedTagsChanged ? "blue" : "light-gray"} onClick={() => {
                            sortedTagsChanged && mutateChangeOrder.mutate(localSortedTags)
                        }}>
                            <FontAwesomeIcon icon={faFloppyDisk}/> SAVE TAG ORDER
                        </TagDisplay>
                    </PermissionRequiredComponent>
                </Col>
            </Row>

            {
                editTagModalOpen && <TagEditModal
                    key={editTagId}
                    isOpen={editTagModalOpen}
                    toggle={() => {
                        setEditTagModalOpen(false)
                    }}
                    data={selectedTagData}
                    tagGroupData={data}
                />
            }

        </Col>
    </Row>
}