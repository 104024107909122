import React from "react";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useLeads} from "../../../../../commonComponents/hooks";
import Select from "react-select";
import {useLoggedInApi} from "../../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import useDebounce from "../../../../../commonComponents/util";


export default function CreateCloseSoldModal({propertyData}) {
    const [isOpen, setIsOpen] = React.useState(false)
    const [selectedLead, setSelectedLead] = React.useState(null)
    const [remarks, setRemarks] = React.useState('')
    const [searchText, setSearchText] = React.useState('')

    const debouncedSearchText = useDebounce(searchText, 500)


    const params = {
        page_size: 1000,
        sort: "-id",
        for_property: 1
    }
    if (debouncedSearchText) {
        params.search = debouncedSearchText
    }
    const {data: leads, isLoading: leadsLoading} = useLeads(params, {
        keepPreviousData: true
    })

    const leadOptions = React.useMemo(() => {
        return leads?.results?.map(lead => {
            return {
                value: lead.id,
                label: lead.name,
                data: lead
            }
        }).sort((a, b) => a.label.localeCompare(b.label))
    })

    const salesProfile = selectedLead && leads?.results?.find(row => row.id === selectedLead)?.sales_profile_display;

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const createRecordMutation = useMutation(() => {
        const resource = api.resource('properties')
        const postPromise = resource.postItemAction(propertyData.id, 'create_close_sold', {
            lead: selectedLead,
            remarks: remarks
        }).then(response => response.data)
        toast.promise(postPromise, {
            pending: 'Creating record...',
            success: 'Record created',
            error: 'Error creating record'
        })
        return postPromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['property', propertyData.id])
            queryClient.invalidateQueries(['table', 'properties'])
            queryClient.invalidateQueries(['closeSold', propertyData.id])
            queryClient.invalidateQueries(['table', 'leads'])
            setIsOpen(false)
        }
    })

    const isComplete = !!remarks && !!selectedLead

    const toggle = () => {
        if (!createRecordMutation.isLoading) {
            setIsOpen(!isOpen)
        }
    }
    const openModal = () => {
        setIsOpen(true)
        setRemarks('')
        setSelectedLead(null)
    }

    return <>
        <Button className="btn-sm" color="link" onClick={openModal}>
            <small>or create close-sold record for this property listing</small>
        </Button>
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered scrollable>
            <ModalHeader toggle={toggle} className="activegroup-header">
            </ModalHeader>
            <ModalBody>
                <div style={{minHeight: "36em"}}>
                    <h4>Create Closed-sold record for property</h4>

                    <p>
                        You can create a closed-sold record for this property listing. This will mark the property as
                        close-sold for the specified lead.
                    </p>

                    <Row>
                        <Col>
                            <Label>Select a lead</Label>

                            <Select
                                options={leadOptions}
                                isLoading={leadsLoading}
                                onChange={(selectedOption) => {
                                    setSelectedLead(selectedOption?.value)
                                    if (!selectedOption) {
                                        setSearchText("")
                                    }
                                }}
                                value={selectedLead && leadOptions?.find(option => option.value === selectedLead)}
                                formatOptionLabel={(option) => {
                                    return <div>
                                        <strong>{option.label}</strong>
                                        <div className="text-muted small">
                                            {[option.data.email_address, option.data.contact_number].filter(Boolean).join(' | ')}
                                        </div>
                                    </div>

                                }}
                                filterOption={(option, rawInput) => {
                                    const result = option.label.toLowerCase().includes(rawInput.toLowerCase())
                                        || option.data.data.email_address?.toLowerCase().includes(rawInput.toLowerCase())
                                        || option.data.data.contact_number?.toLowerCase().includes(rawInput.toLowerCase())
                                    return result
                                }}
                                isSearchable={true}
                                onInputChange={newValue => {
                                    if (newValue !== "") {
                                        setSearchText(newValue)
                                    }

                                }}
                                isClearable={true}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            Property: {propertyData.label}
                        </Col>
                    </Row>
                    {
                        salesProfile && <Row>
                            <Col>
                                <Row>
                                    <Col>Sales Person/Broker: {salesProfile.name}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {salesProfile.contact_number} | {salesProfile.email_address}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        Broker Group: {salesProfile.group_name}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }

                    <Row className="mt-3">
                        <Col>
                            <Label className={"text-small"}>Remarks</Label>
                            <Input type={"textarea"} value={remarks} onChange={(e) => {
                                setRemarks(e.target.value)
                            }}/>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                {
                    selectedLead && !remarks && <div className="text-danger small">
                        Remarks are required
                    </div>
                }
                {
                    !selectedLead && <div className="text-danger small">
                        A lead is required
                    </div>
                }
                <Button
                    disabled={createRecordMutation.isLoading}
                    color="secondary"
                    onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    disabled={!isComplete || createRecordMutation.isLoading}
                    color="primary"
                    onClick={() => {
                        createRecordMutation.mutate()
                    }}>
                    CREATE RECORD
                </Button>
            </ModalFooter>
        </Modal>
    </>
}