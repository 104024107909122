import React, {useState} from "react";
import {Button, ButtonGroup, Col, Container, Input, Row} from "reactstrap";
import {useCookies} from "react-cookie";
import {toast} from "react-toastify";
import {validateEmail} from "../../../commonComponents/util";

export default function ForgotPassword(props) {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [email, setEmail] = useState('')
    const [isSending, setIsSending] = useState(false)

    const isEmailValid = validateEmail(email)

    const send = () => {
        setIsSending(true)
        const forgotPasswordApi = props.api.resource('sales-register/forgot_password');
        forgotPasswordApi.create({
            "email": email,
            session: cookies.session,
            "site": "crm"
        }).then(() => {
            toast.success("Forgot password request had been received. Please check your email inbox for instructions.")
            props.close()
        }).catch(() => {
            toast.error("Something went wrong. If problem persists, please contact administrator.")
        }).finally(() => {
            setIsSending(false)
        })
    }

    return <Container className="forgot-password">
        <Row>
            <Col>
                <h3>Forgot your password?</h3>

            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                If your account exists, we will send you an email.
            </Col>
        </Row>
        <Row  className="mb-3">
            <Col>
                <Input
                    disabled={isSending}
                    value={email}
                    className={email && !isEmailValid ? "error shake" : ""}
                    onChange={e => setEmail(e.target.value)}
                    type="text" placeholder="Email Address"/>
            </Col>
        </Row>

        <Row>
            <Col className="text-end">
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            send()
                        }}
                        disabled={isSending || !isEmailValid} color="primary">
                        Send
                    </Button>
                    <Button disabled={isSending} onClick={() => {
                        if (props.close) props.close()
                    }}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    </Container>
}