import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import Detail from "./detail";
import {TablePropertyListGroupFilter} from "../../../../components/advancedTableFilters";
import AddPropertyListingButton from "./detail/addPropertyListingButton";
import PropertyGroupAsTag from "../../../../commonComponents/propertyGroupAsTag";
import {useGroupPermissions, usePropertyGroups} from "../../../../commonComponents/hooks";


export default function Properties() {
    const [selectedProperty, setSelectedProperty] = useState()
    const [isDetailOpen, setIsDetailOpen] = useState(false)
    const navigate = useNavigate()
    const {data: propertyGroups, isLoading: propertyGroupsLoading} = usePropertyGroups({
        params: {include_inactive: true}
    })
    const propertyGroupById = propertyGroups?.reduce((acc, group) => {
        acc[group.id] = group
        return acc
    }, {})

    const {permissions, isLoading: permissionsLoading} = useGroupPermissions('properties')
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Property Listings</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Add and manage Property Listings that are available for sale.{" "}
                Each listing should have a unique property code and can be assigned to multiple Property Groups.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                {
                    permissionsLoading ?
                        <Spinner size="sm"/> :
                        permissions.includes('can_add') && <AddPropertyListingButton
                            onCreate={newData => {
                                setSelectedProperty(newData.id)
                                setIsDetailOpen(true)
                            }}
                            defaultData={{}}
                            closeOnCreate={true}
                        />
                }
            </Col>

            <Col className="text-lg-end text-md-end">
                {
                    (!permissionsLoading && permissions.includes('can_add')) &&
                    <Button color="info-outline" onClick={() => {
                        navigate('bulk')
                    }}>
                        <FontAwesomeIcon icon={faFileUpload}/>{" "}
                        BULK UPLOAD
                    </Button>
                }
            </Col>
        </Row>

        <AdvancedTable
            filters={{
                group: (isCollapsed, value, setValue, renderProps) => <TablePropertyListGroupFilter
                    isCollapsed={isCollapsed}
                    value={value}
                    setValue={setValue}
                    renderProps={renderProps}
                />
            }}
            friendlyResourceName={"Property Listing"}
            apiResourceName={'properties'}
            dataTableProps={{
                defaultSortFieldId: 'uniquePropertyId'
            }}
            columnDefinition={[
                {
                    id: 'uniquePropertyId',
                    name: "ID",
                    selector: row => row.unique_property_id,
                    format: row => {
                        const content = <>
                            <strong className="small">Phase {row.phase} Block {row.block} Lot {row.lot}</strong><br/>
                            <small>{row.unique_property_id ? row.unique_property_id : <i>ID Not Set</i>}</small>
                        </>
                        if (row.status === 'group_cancelled') {
                            return <span className="text-muted">{content}</span>
                        }
                        return <Button
                            color="text"
                            onClick={() => {
                                setSelectedProperty(row.id)
                                setIsDetailOpen(true)
                            }}
                            className={"text-start" + (row.is_active ? "" : " strikethrough")}
                        >
                            {content}
                        </Button>
                    },
                    sortable: true,
                    sortField: 'unique_property_id'
                },
                {
                    name: "Property Group",
                    selector: row => row.group_name,
                    format: row => {
                        if (propertyGroupById) {
                            const group = propertyGroupById[row.group]
                            if (group) {
                                return <PropertyGroupAsTag propertyGroup={group}/>
                            }
                        }
                        return <strong>{row.group_name}</strong>
                    },
                    sortable: true,
                    sortField: 'group__name'
                },
                {
                    name: "Status",
                    selector: row => row.status,
                    format: row => {
                        if (row.status === 'available') {
                            return 'AVAILABLE'
                        } else if (row.status === 'cancelled') {
                            return 'CANCELLED'
                        } else if (row.status === 'group_cancelled') {
                            return 'GROUP CANCELLED'
                        } else {
                            return 'SOLD'
                        }
                    },
                    sortable: true,
                    sortField: 'is_available,is_active'
                }
            ]}
        />

        <Detail
            key={selectedProperty}
            isOpen={isDetailOpen}
            propertyId={selectedProperty}
            toggle={() => {
                setIsDetailOpen(false)
                setSelectedProperty(null)
            }}

            onCreate={newData => {
                setSelectedProperty(newData.id)
            }}
        />
    </>
}