import {Button, Col, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import logoBanner from "../../../commonComponents/assets/horizontal-banner.png";
import React, {useState} from "react";
import {toast} from "react-toastify";
import ForgotPassword from "../forgotPassword";
import PrivacyPolicy from "../../../commonComponents/privacyPolicy";

export function LoginForm(props) {
    const [auth, setAuth] = useState({
        username: "",
        password: ""
    })
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const isFormValid = !!auth.username && !!auth.password

    const doLogin = () => {
        props.api.login(auth).then(isLoggedIn => {
            setAuth({...auth, password: ""})
        }).catch(e => {
            if (e.response && e.response.data && e.response.data.detail) {
                toast.error(e.response.data.detail, {
                    autoClose: 10000
                })
            } else if (e.response?.status === 401) {
                toast.error("Login credentials are invalid.", {
                    autoClose: 10000
                })
            } else {
                toast.error("Something went wrong. Please refresh and try again. " +
                    "If problem persists, contact administrator.", {
                    autoClose: 10000
                })
            }
            setHasError(true)
            setAuth({...auth, password: ""})
        }).finally(() => {
            setIsLoggingIn(false)
        })
    }

    const onKeyDown = e => {
        if (e.key === 'Enter' && isFormValid) {
            doLogin(auth)
        }
    }

    return <div  className="login-col p-4">
        <Row className="pb-3 pt-3">
            <Label>EMAIL ADDRESS</Label>
            <Col>
                <Input
                    value={auth.username}
                    onKeyDown={onKeyDown}
                    onChange={e => setAuth({...auth, username: e.target.value})}
                    type="text" placeholder="Email Address"/>
            </Col>
        </Row>
        <Row className="pb-3">
            <Col>
                <Label>PASSWORD</Label>
                <Input
                    value={auth.password}
                    className={hasError ? "error shake" : ""}
                    onKeyDown={onKeyDown}
                    onChange={e => {
                        setHasError(false)
                        setAuth({...auth, password: e.target.value})
                    }}
                    type="password" placeholder="Password"/>
            </Col>
        </Row>

        <Row className="pb-3">
            <Col className="text-end">
                <small className="forgot-password-trigger" onClick={() => setShowForgotPasswordModal(true)}>
                    Forgot your password?
                </small>
            </Col>
        </Row>

        <Row>
            <Col>
                <PrivacyPolicy linkText={"By continuing, you agree to our Terms and Privacy Policy."}/>
            </Col>
        </Row>

        <Row className="pb-3">
            <Col>
                <Button color="primary" disabled={!isFormValid} onClick={() => {
                        setIsLoggingIn(true)
                        doLogin()
                    }}>
                    {isLoggingIn ? 'LOGGING IN' : 'LOGIN'}
                </Button>
            </Col>
        </Row>


        <Modal
            centered
            isOpen={showForgotPasswordModal}
            toggle={() => setShowForgotPasswordModal(!showForgotPasswordModal)}>
            <ModalBody>
                <ForgotPassword
                    api={props.api}
                    close={() => setShowForgotPasswordModal(false)}/>
            </ModalBody>
        </Modal>
    </div>;
}