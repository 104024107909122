import React, {useState} from "react";
import {Button, Col, Row, Table} from "reactstrap";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../../../../commonComponents/confirmButton";


export default function RegistrationDetail({data, cancel, onSave}) {
    const [changeBroker, setChangeBroker] = useState(false)
    const [newData, setNewData] = useState({
        ...data.data,
        group_id: data.detected_group_id,
        id: data.id,
    })
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const navigate = useNavigate()
    const brokerNamesQuery = useQuery(['registration', 'broker', 'names'], () => {
        const resource = api.resource('registration')
        return resource.getItem('names').then(response => response.data)
    }, {
        refetchOnWindowFocus: false
    })

    const editableGroupIds = brokerNamesQuery.isSuccess ? brokerNamesQuery.data?.map(row => row.group_id) : []
    const disapproveMutation = useMutation((saveData) => {
        const resource = api.resource('registration')
        const disapprovePromise = resource.deleteItemAction(saveData.id, 'disapprove', ).then(response => response.data)

        toast.promise(disapprovePromise, {
            pending: "Disapproving registration",
            success: "Registration disapproved.",
            error: "Something went wrong. Could not disapprove registration."
        })

        return disapprovePromise
    }, {
        onSuccess: responseData => {

            queryClient.invalidateQueries(['table'])
        }
    })
    const addMutation = useMutation((saveData) => {
        const resource = api.resource('registration')
        const addPromise = resource.postItemAction(saveData.id, 'approve', {
            group_id: saveData.group_id
        }).then(response => response.data)

        toast.promise(addPromise, {
            pending: "Approving registration",
            success: "Registration approved.",
            error: "Something went wrong. Could not approve registration."
        })

        return addPromise
    },  {
        onSuccess: responseData => {
            if (!newData.group_id || editableGroupIds.includes(responseData.group)) {

                if (responseData.is_broker) {
                    navigate('/broker-management/brokers/edit/' + responseData.id.toString())
                } else {
                    navigate('/broker-management/salesperson/edit/' + responseData.id.toString())
                }
            }
            queryClient.invalidateQueries(['table'])

        }
    })

    const brokerOptions = brokerNamesQuery.data?.map(row => {
        return {
            label: row.name,
            value: row.group_id
        }
    }).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    const selectedBroker = newData.group_id && brokerOptions && brokerOptions.filter(o => o.value === newData.group_id).length > 0
        ? brokerOptions.filter(o => o.value === newData.group_id)[0]
        : null;

    const canEditLater = !newData.group_id || editableGroupIds.includes(newData.group_id)

    return <>
        <Row className="mt-4 mx-4">
            <Col>
                <Table bordered hover striped>
                    <thead>
                    <tr>
                        <td>Registration Field</td>
                        <td>Registration Input</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>First Name</td>
                        <th>{data.data.first_name}</th>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <th>{data.data.last_name}</th>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <th>{data.data.email}</th>
                    </tr>
                    <tr>
                        <td>Contact Number</td>
                        <th>{data.data.contact_number}</th>
                    </tr>
                    <tr>
                        <td>Broker Affiliation</td>
                        <th onClick={() => setChangeBroker(true)}>
                            {
                                changeBroker ? <>
                                    <Select
                                        placeholder={data.data.broker_affiliation}
                                        isClearable={true}
                                        options={brokerOptions}
                                        value={selectedBroker}
                                        onChange={newOption => {
                                            if (newOption) {
                                                setNewData({...newData, group_id: newOption.value})
                                            } else {
                                                setNewData({...newData, group_id: data.detected_group_id})
                                                setChangeBroker(false)
                                            }
                                        }}
                                        isLoading={brokerNamesQuery.isLoading}
                                    />
                                </> : <>
                                    {data.data.broker_affiliation}{" "}
                                    <FontAwesomeIcon icon={faPencil}/>
                                </>
                            }

                        </th>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        {
            !newData.group_id && <Row  className="mx-4 mb-4">
                <Col>
                    <i>Approving this registration as is will create a new Broker Group under your management.</i>
                </Col>
            </Row>
        }
        {
            !canEditLater && <Row  className="mx-4 mb-4">
                <Col>
                    <i>You may not be able to edit this profile later. The registrant belongs to a sales group you do not manage.</i>
                </Col>
            </Row>
        }
        <Row className="mx-4 mb-4">
            <Col className="text-end">
                <ConfirmButton color={"danger"} onClick={() => {
                    disapproveMutation.mutate(newData)
                }}>
                    Reject
                </ConfirmButton>
                <Button color={"primary"} onClick={() => {
                    addMutation.mutate(newData)
                }}>
                    Approve
                </Button>
            </Col>
        </Row>
    </>
}