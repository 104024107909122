import {applyAuthTokenInterceptor, clearAuthTokens, isLoggedIn, setAuthTokens} from 'axios-jwt';
import axios from "axios";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {IsLoggedInContext} from "../commonComponents/contexts";
import {useQueryClient} from "react-query";
const { ConcurrencyManager } = require("axios-concurrency");

const __version__ = "1.0.1"

const BASE_URL = process.env.REACT_APP_API_URL

export const authResponseToAuthTokens = (res) => {
    return {
        accessToken: res.access,
        refreshToken: res.refresh
    }
};

export function Resource(objectName, authenticationErrorHandler) {
    const axiosSettings = {
        baseURL: BASE_URL.replace(/\/$/, "") + '/' + objectName,
        timeout: 25000  // 25 seconds
    }

    let apiClient = axios.create(axiosSettings);
    const manager = ConcurrencyManager(apiClient, 5);

    apiClient.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, (e) => {
        if (authenticationErrorHandler) {
            authenticationErrorHandler(e)
        } else {
            throw e
        }
    });

    const requestRefresh = (refresh) => {
        return new Promise((resolve, reject) => {
            // notice that this is the global axios instance.  <-- important
            axios.post('/token/refresh/', {
                refresh
            }).then(response => {
                if (response && response.data  && response.data.access) {
                    resolve(response.data.access);
                } else {
                    clearAuthTokens()
                }
            }).catch((e) => {
                clearAuthTokens()
                authenticationErrorHandler && authenticationErrorHandler(e);
                reject && reject(e)
            });
        });
    };

    applyAuthTokenInterceptor(apiClient, { requestRefresh });  // Notice that this uses the apiClient instance

    const get = (url, conf = {}) => {
        return apiClient.get(url, conf)
    }
    const head = (url, conf = {}) => {
        if (!url) {
            url = '/'
        }
        return apiClient.head(url, conf);
    }
    const options = (conf = {}) => {
        return apiClient.options('/', conf);
    }
    const post = (url, data = {}, conf = {}) => {
        return apiClient.post(url, data, conf);
    }
    const doDelete = (url, conf = {}) => {
        return apiClient.delete(url, conf);
    }
    const put = (url, data = {}, conf = {}) => {
        return apiClient.put(url, data, conf);
    }
    const patch = (url, data = {}, conf = {}) => {
        return apiClient.patch(url, data, conf);
    }

    return {
        get: get,
        head: head,
        options: options,
        post: post,
        'delete': doDelete,
        put: put,
        patch: patch,
        list:(conf= {}) => {
            return get('/', conf);
        },

        getItem: (id, conf = {}) => {
            return get('/' + id + '/', conf);
        },

        deleteItem: (id, conf = {}) => {
            return doDelete('/' + id + '/', conf)
        },

        create: (data = {}, conf = {}) => {
            return post('/', data, conf);
        },

        updateItem: (id, data = {}, conf = {}) => {
            return put('/' + id + '/', data, conf)
        },

        patchItem: (id, data = {}, conf = {}) => {
            return patch('/' + id + '/', data, conf);
        },

        getItemAction: (id, action, conf = {}) => {
            return get('/' + id + '/' + action + '/', conf);
        },
        postAction: (action, data, conf = {}) => {
            return post('/' + action + '/', data, conf);
        },
        postItemAction: (id, action, data, conf = {}) => {
            return post('/' + id + '/' + action + '/', data, conf);
        },
        patchItemAction: (id, action, data = {}, conf = {}) => {
            return patch('/' + id + '/' + action + '/', data, conf);
        },
        deleteItemAction: (id, action, conf = {}) => {
            return doDelete('/' + id + '/' + action + '/', conf);
        }
    }
}

export default function Api(onAuthChange, onAuthError) {
    axios.defaults.baseURL = BASE_URL

    if (isLoggedIn()) {
        onAuthChange(isLoggedIn())
    }

    return {
        login: (params) => {
            return axios.post("/token/", params).then(response => {
                setAuthTokens(authResponseToAuthTokens(response.data));
                onAuthChange(isLoggedIn())
                return isLoggedIn()
            })
        },
        logout: async () => {
            clearAuthTokens()
            onAuthChange(isLoggedIn())
        },
        // testToken: () => {
        //     const profileApi = new ApiClient('profile', authenticationErrorHandler.bind(this))
        //     return profileApi.getItem('check')
        // },
        resource: (objectName, authenticationErrorHandler) => {
            return new Resource(objectName, (e) => {
                authenticationErrorHandler && authenticationErrorHandler(e);
                onAuthError && onAuthError(e)
            })
        }
    };
}


export function logout(cb) {
    clearAuthTokens()
    cb && cb()
}

export function useLoggedInApi() {

    const {isLoggedIn, logout} = useContext(IsLoggedInContext)
    const navigate = useNavigate()

    const doLogout = () => {
        clearAuthTokens()
        logout()
        navigate('/')
    }

    const api = new Api(newLoginStatus => {
        if (isLoggedIn && !newLoginStatus) {
            doLogout()
        }
    }, error => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            doLogout()
            toast.error("You had been logged out automatically.", {toastId: "auto-logout"})
        }
        throw error
    })

    return api
}

export function useLogout() {
    const {isLoggedIn, logout} = useContext(IsLoggedInContext)
    const queryClient = useQueryClient()

    const navigate = useNavigate()
    return function doLogout(message) {
        logout()
        navigate('/')
        toast( "You had been logged out.", {toastId: "auto-logout"})
        queryClient.invalidateQueries()
    }
}
