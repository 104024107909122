import React from "react";
import {Label} from "reactstrap";
import PropertiesSelect from "../../../detailModal/downloadProposalModal/propertiesSelect";
import {useParams} from "react-router-dom";

import {useLead} from "../../../../../hooks";


export default function AdditionalPropertySelector({sourceObjectId, setSourceObjectId, usedPropertyIds, tag}) {
    const getLeadData = tag.system_identifier === 'closed_sold'

    const {leadId} = useParams()
    const {data, isLoading} = useLead(leadId,  {params: {show_disabled: true}}, {enabled: getLeadData})

    const suggestedPropertyIds = React.useMemo(() => {
        if (!data) {
            return []
        }
        if (tag.system_identifier === 'closed_sold') {
            // find tags with the system identifier `proposal` and get the property id from the tag data
            return data.form_tags
                .filter(row => row.tag_data.system_identifier === 'proposal')
                .map(row => row.source_object_id)
        }
        return []
    }, [data, tag.system_identifier])


    return <>
        <Label className="small">
            Select a property
        </Label>

        <PropertiesSelect
            showAllProperties={true}
            selectedProperty={sourceObjectId}
            setSelectedProperty={(propertyId) => {
                setSourceObjectId(propertyId)
            }}
            removeMarginTop={true}
            ignoredPropertyIds={usedPropertyIds}
            suggestedPropertyIds={suggestedPropertyIds}
        />

    </>
}