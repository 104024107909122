import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {useLoggedInApi} from "../../../../../api";
import {Badge, Col, Row, Spinner} from "reactstrap";

function LeadInfo(props) {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const api = useLoggedInApi()
    const leadsApi = api.resource('leads')

    useEffect(() => {
        if (!props.leadId) {
            setData({})
            return null
        }
        setIsLoading(true)
        leadsApi.getItem(props.leadId).then(response => {
            setData({...response.data})
        }).finally(() => {
            setIsLoading(false)
        })
    }, [props.leadId])

    if (isLoading) {
        return <Row>
            <Col className="m-5 p-5 text-center">
                <Spinner/>
            </Col>
        </Row>
    }

    return <>

        <Row className="mt-5">
            <Col>
                <h6>SUMMARY</h6>
            </Col>
        </Row>

        <div className="border-bottom"/>

        <Row className="mt-3">
            <Col>

                    {
                        data.property_group_names?.length > 0 ? data.property_group_names.map(p => {
                            return <Badge className="me-1"><strong className={""}>{p}</strong></Badge>
                        }) : <i>No associated properties</i>
                    }
            </Col>
        </Row>

        <div className="border-bottom"/>


        <Row className="mt-5">
            <Col>
                <h6>LEAD INFORMATION</h6>
            </Col>
        </Row>

        <div className="border-bottom"/>

        <Row className="mt-4">
            <Col>
                <strong className="text-black">{data.name}</strong>
            </Col>
        </Row>

        <Row>
            <Col>
                <small><strong>{data.contact_number}</strong></small>
            </Col>
        </Row>

        <Row>
            <Col>
                <small><strong>{data.email_address}</strong></small>
            </Col>
        </Row>
        <div className="border-bottom"/>
    </>
}

LeadInfo.propTypes = {
    leadId: PropTypes.number
}

export default LeadInfo