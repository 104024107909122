import React from "react";
import ProfileForm from "../profileForm";

import {DEFAULT_BROKER_PROFILE} from "../../commonComponents/hooks";

export default function BrokerProfileForm(props) {
    return <ProfileForm
        isMe={props.isMe}
        profileId={props.profileId}
        showMicroSite={true}
        showBrokerInfo={true}
        canEditPosition={true}
        isEmployee={false}
        hasActiveToggle={true}
        resourceName={'broker'}
        defaultProfileData={DEFAULT_BROKER_PROFILE}
        canEditID={true}
        showPropertyGroups={true}
    />
}
