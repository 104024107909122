import React, {useState} from "react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faHouseLaptop} from "@fortawesome/free-solid-svg-icons";
import PropertyGroupSelector from "../../../../../commonComponents/propertyGroupSelector";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import moment from "moment";
import ViewContent from "./viewContent";
import EditContent from "./editContent";
import {dictChangedValues} from "../../../../../commonComponents/util";
import {toast} from "react-toastify";


function DetailModal({isOpen, toggle, dataId, setDataId}) {
    const [isEdit, setIsEdit] = useState(!dataId)
    const [editedData, setEditedData] = useState({
        visible_since: moment().format('YYYY-MM-DD'),
        visible_until: moment().format('YYYY-MM-DD')
    });

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const announcementDetailResult = useQuery(['announcement', dataId], () => {
        const announcementResource = api.resource('announcement');
        return announcementResource.getItem(dataId).then(response => response.data)
    }, {
        enabled: !!dataId,
        onSuccess: data => {
            setEditedData({...data})
        },
        refetchOnWindowFocus: false
    });

    const data = announcementDetailResult.isSuccess ? announcementDetailResult.data : {}

    const createMutation = useMutation(announcementData => {
        const announcementResource = api.resource('announcement');
        return announcementResource.create(announcementData).then(response => response.data)
    }, {
        onSuccess: resultData => {
            queryClient.setQueryData(['announcement', resultData.id], resultData)
            queryClient.invalidateQueries(['table', 'announcement'])
            toast.success("Published new announcement!");
            setDataId(resultData.id)
        }
    })
    const updateMutation = useMutation(announcementData => {
        const announcementResource = api.resource('announcement');
        return announcementResource.patchItem(dataId, dictChangedValues(data, announcementData)).then(response => response.data)
    }, {
        onSuccess: resultData => {
            queryClient.setQueryData(['announcement', resultData.id], resultData)
            queryClient.invalidateQueries(['table', 'announcement'])
            toast.success("Updated announcement!");
            setIsEdit(false)
        }
    })



    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row className="mb-5">
                <Col lg={8} className="border-end pe-4">
                    <Row>
                        <Col>
                            <h2>Broker Sites Announcements</h2>
                        </Col>
                    </Row>
                    {
                        dataId && <Row>
                            <Col>
                                <Button color="link" size="sm" onClick={() => setIsEdit(!isEdit)}>
                                    <small>{isEdit ? "Cancel editing" : "Edit details"}</small>
                                </Button>
                            </Col>
                        </Row>
                    }

                    {
                        isEdit ? <EditContent editedData={editedData} setEditedData={setEditedData} /> : <ViewContent data={data}/>
                    }

                    <Row className="mt-3">
                        <Col className="small">
                            <strong>NOTE</strong>: Please make sure that there are no overlapping schedules.
                            In the event that there are overlapping announcements with the same property group targets,
                            the one scheduled first will be displayed.
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mt-5">
                        <Col>
                            <strong>PROPERTY GROUP</strong>
                        </Col>
                    </Row>
                    {
                        isEdit && <Row>
                            <Col className="small">
                                <small>Choose the Property Group(s) to show this announcement.</small>
                            </Col>
                        </Row>
                    }

                    <div className="border-bottom"/>

                    <Row className="my-2">
                        <Col>
                            <PropertyGroupSelector
                                isMulti={true}
                                readOnly={!isEdit}
                                propertyGroups={editedData.applicable_property_groups}
                                setPropertyGroups={(newPropertyGroups) => {
                                    setEditedData({...editedData, applicable_property_groups: [...newPropertyGroups]})
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="border-bottom"/>
                    <Row>
                        <Col className="small">
                            <small>
                                *Leaving this blank will show the announcement to all broker microsites.
                            </small>
                        </Col>
                    </Row>

                    {
                        (data?.hit_count !== undefined) && <>
                            <Row className="mt-5">
                                <Col>
                                    <strong>TOTAL VIEWS</strong>
                                </Col>
                            </Row>

                            <div className="border-bottom"/>
                            <Row className="my-2">
                                <Col>
                                    {data.hit_count?.toLocaleString()}
                                </Col>
                            </Row>
                        </>
                    }

                    <div className="border-bottom"/>

                    {
                        isEdit && <Row className="mt-3 mb-5">
                            <Col className="text-end">
                                <Button color="info" size="sm" onClick={() => {
                                    if (dataId) {
                                        updateMutation.mutate(editedData)
                                    } else {
                                        createMutation.mutate(editedData)
                                    }
                                }}>
                                    <FontAwesomeIcon icon={faCheckSquare}/> {" "}
                                    SAVE ANNOUNCEMENT
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>

        </ModalBody>
    </Modal>
}

export default DetailModal;