import {Col, Row} from "reactstrap";
import React from "react";

export default function Header() {

    return <>

        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Broker > Salespeople</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Salespeople are required to have a Broker Affiliation since they do not have proper real estate
                licenses. They can only be associated with (1) one broker.
            </Col>
        </Row>
    </>
}