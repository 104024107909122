import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink, useNavigate} from "react-router-dom";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import moment from "moment";
import DetailModal from "./detailModal";


export default function SiteAnnouncements(props) {
    const [editId, setEditId] = useState()
    const [detailModalOpen, setDetailModalOpen] = useState(false)

    const navigate = useNavigate()
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Broker Site Announcements</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                The announcements are pop-ups on all broker microsites.
                This feature is best used for promos or campaigns.
                Please note that you are seeing all scheduled microsite announcements
                scheduled by all users of the administration dashboards.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    setEditId(null)
                    setDetailModalOpen(true)
                }}>

                    <FontAwesomeIcon icon={faFileAlt}/>{" "}
                    SETUP BROKER SITE ANNOUNCEMENTS
                </Button>
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Announcement"}
            apiResourceName={'announcement'}
            columnDefinition={[
                {
                    name: "Announcement",
                    selector: row => <Button color='link' size='sm' onClick={() => {
                        setEditId(row.id);
                        setDetailModalOpen(true)
                    }}>
                        {row.title}
                    </Button>,
                    sortable: true,
                    sortField: 'title'
                },
                {
                    name: "Start Date",
                    selector: row => moment(row.visible_since).format('LL'),
                    sortable: true,
                    sortField: 'visible_since'
                },
                {
                    name: "End Date",
                    selector: row => moment(row.visible_until).format('LL'),
                    sortable: true,
                    sortField: 'visible_until'
                },
                {
                    name: 'Views',
                    selector: row => row.hit_count.toLocaleString(),
                    sortable: false,

                }
            ]}/>

        <DetailModal
            dataId={editId}
            key={editId}
            isOpen={detailModalOpen}
            toggle={() => setDetailModalOpen(false)}
            setDataId={setEditId}
            />
    </>
}