import React from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";


export default function ViewContent({data}) {
    return <>
        <Row className="mt-3">
            <Col className="border border-light" md={4}><strong>Title</strong></Col>
            <Col className="border border-light">
                <strong>{data.title}</strong>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="border border-light">
                <div dangerouslySetInnerHTML={{__html: data.content}}/>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>

                <Row className="">
                    <Col className="border border-light" md={4}><strong>Start Date</strong></Col>
                    <Col className="border border-light">
                        {moment(data.visible_since).format('LL')}
                    </Col>
                </Row>
            </Col>
            <Col>
                <Row className="">
                    <Col className="border border-light" md={4}><strong>End Date</strong></Col>
                    <Col className="border border-light">
                        {moment(data.visible_until).format('LL')}
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
}