import React from "react";
import {Badge, Col, Row} from "reactstrap";
import "../../../commonComponents/assets/css/dashboard.css"
import AdvancedTable from "../../../commonComponents/AdvancedTable";
import OptionEdit from "./optionEdit";
import ClassicEditor from "../../../components/classicEditor";
import PolicyEdit from "./policyEdit";


export default function SystemOption() {
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">System Options - Field Choices</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Update choices for fields in inputs. <br/>
                <code>POSITION</code> is for brokers and salespersons. For employee positions, please use the user type management.
            </Col>
        </Row>


        <Row>
            <Col>
                <AdvancedTable
                    friendlyResourceName={"System Options"}
                    apiResourceName={"option"}
                    hideSearch={true}
                    hideTotalCount={true}
                    usePagination={false}
                    columnDefinition={[
                        {
                            name: "Name",
                            selector: row => row.field_name,
                            format: row => {
                                return row.field_name.split('_').join(' ').toUpperCase()
                            },
                            sortable: true,
                            sortField: 'field_name'
                        },
                        {
                            name: "Choices",
                            selector: row => row.choices,
                            format: row => {
                                return <>
                                    {
                                        row.choices.map(choice => <Badge pill className="p-2 px-3 me-2">
                                            {choice}
                                        </Badge>)
                                    }
                                </>
                            },
                            sortable: false
                        }
                    ]}
                    dataTableProps={{
                        expandableRows: true,
                        expandableRowsComponent: OptionEdit
                    }}
                    />
            </Col>
        </Row>

        <hr className="my-5"/>

        <Row>
            <Col>
                <h3 className="page-header pb-2">System Options - Privacy Policies</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Edit privacy policy for MicroSite, Broker Portal and Admin Portal.
            </Col>
        </Row>
        <PolicyEdit site={"microsite"} label={"MicroSite"}/>
        <PolicyEdit site={"sales"} label={"Broker Dashboard"}/>
        <PolicyEdit site={"crm"} label={"Admin Dashboard"}/>
    </>
}