import React from "react";
import Select from "react-select";
import {Col, Label, Row, Spinner} from "reactstrap";
import {useSalesPersonOptions} from "../../../../hooks";


export default function SalesPersonSelector({selected, setSelected}) {

    const {
        groupedOptions: groupedSalesPersonOptions,
        ungroupedOptions: ungroupedSalesPersonOptions,
        isLoading: isSalesPersonOptionsLoading
    } = useSalesPersonOptions()


    if (isSalesPersonOptionsLoading) {
        return <Spinner size="sm"/>
    }

    if (groupedSalesPersonOptions.length === 0) {
        return null
    }


    const selectedSalesAgent = ungroupedSalesPersonOptions.length > 0 ? ungroupedSalesPersonOptions.find(row => row.value === selected) : null

    return <Row className="my-2 border-bottom pb-3">
        <Col>
            <Label className="text-muted small">Assigned Broker/Salesperson</Label>
            <Select
               options={groupedSalesPersonOptions}
               isLoading={isSalesPersonOptionsLoading}
               value={selectedSalesAgent}
               onChange={newOption => {
                     setSelected(newOption.value)
               }}
               />
        </Col>
    </Row>
}