import React, {useState} from "react";
import InputRow from "./inputRow";
import {Button, Col, Row} from "reactstrap";
import {Resource} from "../api";
import {toast} from "react-toastify";

export default function UpdatePasswordForm({isMe, hideTitle, resourceName}) {
    const useResourceName = resourceName ? resourceName : 'auth/change-password';

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")

    const [fieldErrors, setFieldErrors] = useState({})

    const [isSaving, setIsSaving] = useState(false)
    const [isSaved, setIsSaved] = useState(false)

    const _passwordMatches = newPassword && repeatPassword && newPassword === repeatPassword;
    const _oldPasswordIsOk = !isMe || (oldPassword && newPassword !== oldPassword);

    const canSave = _oldPasswordIsOk && _passwordMatches
        && !Object.values(fieldErrors).some(fieldError => !!fieldError);

    const save = () => {
        setIsSaving(true)
        const changePasswordResource = Resource(useResourceName)
        const changePasswordPromise = changePasswordResource.create({
            old_password: oldPassword,
            new_password: newPassword,
            repeat_password: repeatPassword
        }).then(response => {
            toast.success("Password had been updated.")
            setOldPassword("")
            setNewPassword("")
            setRepeatPassword("")
            setIsSaved(true)
        }).catch(e => {
            if (e && e.response && e.response.data && typeof e.response.data === "object") {
                setFieldErrors({...e.response.data})
            } else {
                toast.error("Something went wrong while saving change password. Please try again. If problem persists, contact administrator.")
            }
        }).finally(() => {
            setIsSaving(false)
        })
        toast.promise(changePasswordPromise, {
            pending: "Updating password"
        })
    }

    if (isSaved) {
        return <div className="mb-5 text-center">
            Password was successfully changed.
        </div>
    }

    return <div className="mb-5">
        {
            !hideTitle && <h5>Update Password</h5>
        }
        <Row>
            <Col>
                <ul className="small">
                    <li>
                        Your password can’t be too similar to your other personal information.
                    </li>
                    <li>
                        Your password must contain at least 8 characters.
                    </li>
                    <li>
                        Your password can’t be a commonly used password.
                    </li>
                    <li>
                        Your password can’t be entirely numeric.
                    </li>
                    <li>
                        Your password can't have appeared in a data breach.
                    </li>

                </ul>
            </Col>
        </Row>
        {
            isMe && <InputRow
                required
                label={"Old Password"}
                type={"password"}
                value={oldPassword}
                disabled={isSaving}
                staticError={true}
                errorMessage={fieldErrors.old_password && fieldErrors.old_password.join(' ')}
                onChange={newValue => {
                    setOldPassword(newValue)
                    setFieldErrors({...fieldErrors, old_password: null})
                }}
            />
        }

        <InputRow
            required
            label={"New Password"}
            type={"password"}
            value={newPassword}
            disabled={isSaving}
            staticError={true}
            errorMessage={fieldErrors.new_password && fieldErrors.new_password.join(' ')}
            onChange={newValue => {
                setNewPassword(newValue)
                setFieldErrors({...fieldErrors, new_password: null})
            }}
        />

        <InputRow
            required
            label={"Repeat Password"}
            type={"password"}
            value={repeatPassword}
            disabled={isSaving}
            staticError={true}
            onChange={newValue => {
                setRepeatPassword(newValue);
                setFieldErrors({...fieldErrors, repeat_password: null})
            }}
            errorMessage={
                (newPassword !== repeatPassword && repeatPassword && " New password does not match. ")
                || (fieldErrors.repeat_password && fieldErrors.repeat_password.join(' '))
            }
        />

        <Row className="mt-5">
            <Col className="justify-content-end d-flex">
                <Button color="secondary" disabled={!canSave || isSaving} onClick={save}>
                    {isSaving ? "UPDATING..." : "UPDATE " + (isMe ? "MY" : "") + " PASSWORD"}
                </Button>
            </Col>
        </Row>

    </div>
}