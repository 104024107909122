import React from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard, faPencil} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import EditModal from "../editModal";
import MergerModal from "../../mergerModal";
import TagFormDisplay from "../common/tagFormDisplay";
import DownloadProposal from "../../detailModal/downloadProposalModal";
import CheckDuplicateButton from "../checkDuplicateButton";
import DisableLead from "./disableLead";
import PropertyGroupSelectorAsTag from "../../../../propertyGroupSelectorAsTag";
import FormFieldDisplay from "./FormFieldDisplay";
import {useLead, useLeadForm} from "../../../../hooks";
import {LeadTagFilterContext} from "../../../../contexts";


export default function MainInfo() {
    const {tags: selectedTags, setTags: setSelectedTags} = React.useContext(LeadTagFilterContext)

    const [proposalSelectorOpen, setProposalSelectorOpen] = React.useState(false)
    const [editModalKey, setEditModalKey] = React.useState(null)
    const [mergerModalParams, setMergerModalParams] = React.useState(null)

    const {leadId} = useParams()
    const {data, isLoading} = useLead(leadId, {params: {show_disabled: true}})

    const isLeadActive = data?.is_active
    const {data: formData, isLoading: isFormLoading} = useLeadForm()

    if (isLoading || isFormLoading) {
        return <Spinner/>
    }

    if (!isLoading && !data) {
        return <div>Lead not found</div>
    }

    const visibleAnswers = formData?.form_fields?.filter(
        row => row.is_active && row.is_modifiable && row.field_type !== "tag"
    ).sort((a, b) => a.order - b.order).map(formField => {
        const answer = data?.form_answers?.find(row => row.form_field === formField.id)
        return {
            ...formField,
            answer
        }
    });

    const visibleTags = formData?.form_fields?.filter(
        row => row.is_active && row.field_type === "tag"
    ).sort((a, b) => a.order - b.order).map(formField => {
        const answer = data?.form_tags?.filter(row => row.tag_data.tag_group === formField.tag_group)
        return {
            ...formField,
            answer
        }
    });


    return <>
        <Row>
            <Col>
                <h3>
                    <FontAwesomeIcon icon={faIdCard} className="lead-main-info-color me-3" size={"lg"}/>
                    Contact information
                </h3>
            </Col>
        </Row>
        <div className="lead-main-info-container p-4 lineheight-1">
            <Row>
                <Col>
                    <h5>
                        <strong>{data.name}</strong>
                    </h5>
                </Col>
                {
                    isLeadActive && <Col className="col-auto text-sm-start text-end small" sm={12}>

                        <Button color="link" size="sm" className="text-blue" onClick={() => {
                            setEditModalKey(Date.now())
                        }}>
                            <FontAwesomeIcon icon={faPencil} className={"me-2"}/>
                            Edit Information
                        </Button>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <DisableLead/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="text-muted">
                    {data.contact_number}
                </Col>
            </Row>
            <Row>
                <Col className="small  lineheight-1">
                    <CheckDuplicateButton params={{
                        type: "contact_number",
                        value: data.contact_number,
                    }} setParameters={setMergerModalParams}>
                        <small>
                            View duplicate contact numbers
                        </small>
                    </CheckDuplicateButton>
                </Col>
            </Row>

            <Row className={"mt-3"}>
                <Col className="text-muted">
                    {data.email_address}
                </Col>
            </Row>
            <Row>
                <Col className="small lineheight-1">

                    <CheckDuplicateButton params={{
                        type: "email_address",
                        value: data.email_address,
                    }} setParameters={setMergerModalParams}>
                        <small>
                            View duplicate email addresses
                        </small>
                    </CheckDuplicateButton>
                </Col>
            </Row>

            {
                visibleAnswers.map(formField => <FormFieldDisplay
                    key={formField.id}
                    formField={formField}
                />)
            }
        </div>

        {
            isLeadActive && <Row className="mt-3">
                <Col>
                    <Button color="info-outline" className="w-100" onClick={() => {
                        setProposalSelectorOpen(true)
                    }}>
                        GENERATE PERSONALIZED PDF PROPOSAL
                    </Button>
                </Col>
            </Row>
        }
        <Row className="mt-3 lineheight-1">
            <Col>
                <Row className="mt-3 border-top py-3">
                    <Col className="text-muted" md={3}>
                        <small>
                            <strong>Property Groups</strong>
                        </small>
                    </Col>
                    <Col className="text-muted">
                        <PropertyGroupSelectorAsTag
                            isMulti={false}
                            propertyGroups={data?.property_groups}
                            readOnly={true}
                            showNotSelected={false}
                            onTagClick={(propertyGroupId) => {
                                setSelectedTags([...selectedTags, `property-${propertyGroupId}`])
                            }}
                        />
                        {
                            (!data?.property_groups || data?.property_groups.length === 0) && <span>
                            NONE
                            </span>
                        }
                    </Col>
                </Row>

                {
                    visibleTags.map(formField => {
                        const {answer, name} = formField
                        return <Row key={formField.id} className="mt-3 border-top py-3">
                            <Col className="text-muted" md={3}>
                                <small>
                                    <strong>{name}</strong>
                                </small>
                            </Col>
                            <Col className="text-muted">
                                {
                                    answer.length > 0 ?
                                        answer.map(tagForm => {
                                            return <TagFormDisplay
                                                onClick={() => {
                                                    if (!selectedTags.includes(tagForm.tag)) {
                                                        setSelectedTags([...selectedTags, tagForm.tag])
                                                    }
                                                }}
                                                key={tagForm.id}
                                                isColorActive={true}
                                                tagForm={tagForm}
                                                color={tagForm.tag_data.color}
                                            />
                                        }) :
                                        <span className="text-muted">NO TAGS ADDED</span>
                                }
                            </Col>
                        </Row>
                    })
                }
            </Col>
        </Row>
        <Row className="mt-3 lead-broker-info-container p-3 text-white text-opacity-75">
            <Col>
                <Row>
                    <Col>
                        <h5 className="text-white">
                            <strong>Broker/Sales Contact
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data.sales_profile_display.name}
                        {
                            data.sales_profile_display.group_name !== data.sales_profile_display.name &&
                            <> ({data.sales_profile_display.group_name})</>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="small lineheight-15">
                        {data.sales_profile_display.contact_number}
                    </Col>
                </Row>

                <Row>
                    <Col className="small lineheight-15">
                        {data.sales_profile_display.email_address}
                    </Col>
                </Row>
            </Col>
        </Row>


        {/* MODALS */}
        <EditModal key={editModalKey || -1} isOpen={!!editModalKey} toggle={() => {
            setEditModalKey(null)
        }}/>
        <MergerModal
            key={JSON.stringify(mergerModalParams) || -2}
            isOpen={!!mergerModalParams}
            toggle={() => {
                setMergerModalParams(null)
            }}
            params={mergerModalParams}
        />

        <DownloadProposal
            toggle={() => setProposalSelectorOpen(false)}
            isOpen={proposalSelectorOpen}
            leadId={parseInt(leadId)}
        />
    </>
}