import React from "react";
import {FilterContext} from "../../../contexts";
import PropertyGroupAsTag from "../../../propertyGroupAsTag";
import {usePropertyGroups} from "../../../hooks";


export default function PropertyGroupsDisplay({selectedPropertyGroups}) {
    const {filters, setFilter} = React.useContext(FilterContext)
    const {data: propertyGroups, loading} = usePropertyGroups({})

    if (loading) {
        return <span className="text-muted">Loading...</span>
    }

    if (!selectedPropertyGroups || selectedPropertyGroups.length === 0) {
        return '-'
    }

    return <>
        {
            propertyGroups?.filter(row => selectedPropertyGroups.includes(row.id)).map(row => {
                return <PropertyGroupAsTag
                    key={row.id}
                    propertyGroup={row}
                    onClick={() => {
                        const tagId = `property-${row.id}`
                        if (!filters.leads?.tag_ids?.includes(tagId)) {
                            setFilter('tag_ids', [
                                ...filters.leads?.tag_ids || [],
                                `property-${row.id}`
                            ], 'leads')
                        }

                    }}
                />
            })
        }
    </>
}