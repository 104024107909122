import React, {useContext, useState} from "react";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {Badge, Button, ButtonGroup, Col, Input, Row, Spinner} from "reactstrap";
import {dictEqualShallow, toTitleCase} from "../../../../commonComponents/util";
import InputRow from "../../../../components/inputRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {ProfileContext} from "../../../../commonComponents/contexts";
import PermissionCheckbox from "./permissionCheckbox";

const specialBrokerPermKeys = [
    "Manage Own Brokers Only",
    "Manage All Brokers",
    "Manage All Brokers (Can reassign brokers to other employees)",
    "Manage Brokers, Sales Persons and Leads of Own Employees",
]


export const GroupDetail = ({data, cancel, onSave}) => {
    const [localData, setLocalData] = useState({...data})
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const {profile} = useContext(ProfileContext)
    const isSuperUser = profile?.is_superuser || false

    const permissionMapQuery = useQuery(['permissions-map'], () => {
        const resource = api.resource('employee-group');
        return resource.getItem('permission_groups').then(response => response.data)
    }, {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000  // 5 minutes
    })

    const permissionMutation = useMutation(newData => {
        const resource = api.resource('employee-group');

        const promise = newData.id ?
            resource.updateItem(newData.id, {
                ...newData
            }).then(response => response.data) :
            resource.create({
                ...newData
            }).then(response => response.data)

        if (newData.id) {

            toast.promise(promise, {
                pending: "Updating employee type " + data.name,
                error: "Something went wrong. Please try again. If problem persists, please report to administrator.",
                success: "Updated Employee Type " + newData.name
            })
        } else {
            toast.promise(promise, {
                pending: "Creating employee type " + newData.name,
                error: "Something went wrong. Please try again. If problem persists, please report to administrator.",
                success: "Created Employee Type " + newData.name
            })
        }
        return promise
    }, {
        onSuccess: data1 => {
            queryClient.invalidateQueries(['table', 'employee-group']);
            queryClient.invalidateQueries(['employee-options'])
            !!onSave && onSave()
        }
    })

    if (permissionMapQuery.isLoading) {
        return <Row className="py-3">
            <Col className="text-center">
                <Spinner/>
            </Col>
        </Row>
    }
    const assignedPermissionGroups = Object.keys(permissionMapQuery.data)
        .filter(
            k => permissionMapQuery.data[k]["permission_ids"].every(
                permId => localData.permissions.includes(permId)
            )
        )

    const addPermissionByKey = permKey => {
        const newPermissions = permissionMapQuery.data[permKey]["permission_ids"];
        setLocalData(prevData => ({
            ...prevData,
            permissions: [
                ...prevData.permissions,
                ...newPermissions
            ]
        }))
    }
    const removePermissionByKey = permKey => {
        const removePermissions = permissionMapQuery.data[permKey]?.permission_ids || [];
        setLocalData(prevData => ({
            ...prevData,
            permissions: [
                ...prevData.permissions.filter(pId => !removePermissions.includes(pId))
            ]
        }))
    }

    const canSave = !dictEqualShallow(data, localData) && !!localData.name;

    const permissionMapByModule = Object.keys(permissionMapQuery.data).reduce((acc, permKey) => {
        const module = permissionMapQuery.data[permKey]["module"];
        if (!acc[module]) {
            acc[module] = []
        }
        acc[module].push(permKey)
        return acc
    }, {})

    const permKeyAdminOnly = Object.keys(permissionMapQuery.data).filter(permKey => permissionMapQuery.data[permKey]["superuser_only"])

    return <Row className="m-4">
        <Col>
            <InputRow
                label={"User Type Name"}
                value={localData.name}
                onChange={newValue => setLocalData({...localData, name: newValue})}
            />
            {
                Object.keys(permissionMapByModule).map(module => {
                    return <>
                        <Row>
                            <Col>
                                <h6>{toTitleCase(module)}</h6>
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            {
                                module !== 'broker' ? permissionMapByModule[module].map(permKey => {
                                    const isSuperUserOnly = permKeyAdminOnly.includes(permKey)
                                    const isEditable = !isSuperUserOnly || isSuperUser
                                    return <PermissionCheckbox
                                        isEditable={isEditable}
                                        isSuperUserOnly={isSuperUserOnly}
                                        permKey={permKey}
                                        addPermissionByKey={addPermissionByKey}
                                        isChecked={assignedPermissionGroups.includes(permKey)}
                                        removePermissionByKey={removePermissionByKey}
                                        />
                                }) : <>
                                    {
                                        permissionMapByModule[module].filter(permKey => {
                                            return !specialBrokerPermKeys.includes(permKey)
                                        }).map(permKey => {
                                            const isSuperUserOnly = permKeyAdminOnly.includes(permKey)
                                            const isEditable = !isSuperUserOnly || isSuperUser
                                            return <PermissionCheckbox
                                                isEditable={isEditable}
                                                isSuperUserOnly={isSuperUserOnly}
                                                permKey={permKey}
                                                addPermissionByKey={addPermissionByKey}
                                                isChecked={assignedPermissionGroups.includes(permKey)}
                                                removePermissionByKey={removePermissionByKey}
                                            />
                                        })
                                    }
                                    <Col sm={12} md={12} lg={12} className="p-3 mx-2 rounded border">
                                        <Row>
                                            <p className="text-muted">
                                                <strong>Visibility of Brokers</strong> - Only one or no option can be selected</p>
                                            {
                                                permissionMapByModule[module].filter(permKey => {
                                                    return specialBrokerPermKeys.includes(permKey)
                                                }).map(permKey => {
                                                    const isSuperUserOnly = permKeyAdminOnly.includes(permKey)
                                                    const isEditable = !isSuperUserOnly || isSuperUser
                                                    return <PermissionCheckbox
                                                        isEditable={isEditable}
                                                        isSuperUserOnly={isSuperUserOnly}
                                                        permKey={permKey}
                                                        addPermissionByKey={(newPermKey) => {
                                                            specialBrokerPermKeys.filter(k => k !== newPermKey).forEach(k => {
                                                                removePermissionByKey(k)
                                                            })
                                                            addPermissionByKey(newPermKey);
                                                        }}
                                                        isChecked={assignedPermissionGroups.includes(permKey)}
                                                        removePermissionByKey={removePermissionByKey}
                                                    />
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </>
                            }
                        </Row>
                    </>
                })
            }

            <Row>
            </Row>

            <Row>
                <Col className="text-end">
                    <ButtonGroup>
                        <Button color="primary" disabled={!canSave || permissionMutation.isLoading} onClick={() => {
                            if (canSave) {
                                permissionMutation.mutate(localData)
                            }
                        }}>
                            {
                                permissionMutation.isLoading ? <Spinner size="sm"/> : <FontAwesomeIcon icon={faSave}/>
                            } Save Changes
                        </Button>
                        {
                            !!cancel && <Button color={"info"} onClick={cancel}>
                                Cancel
                            </Button>
                        }
                    </ButtonGroup>
                </Col>
            </Row>
        </Col>
    </Row>
}