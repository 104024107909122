import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";
import ClassicEditor from "../../../../../components/classicEditor";
import InputRow from "../../../../../components/inputRow";
import DatePicker from "react-date-picker";
import Select from "react-select";
import {REACT_SELECT_STYLES} from "../../../../../constants";

export default function EditContent({editedData, setEditedData}) {
    const sendDateTime = moment(editedData.send_datetime).toDate();

    const broadcastTypeOptions = [
        {value: "sms", label: "SMS", disabled: true},
        {value: "email", label: "Email"},
        {value: "news", label: "News Section"},
    ]

    const selectedBroadcastType = editedData.broadcast_type ? broadcastTypeOptions.filter(o => o.value === editedData.broadcast_type)[0] : null;

    return <>
        <InputRow
            onChange={newValue => setEditedData({...editedData, title: newValue})}
            value={editedData.title}
            label={"Title"}
            required={true}
        />
        <Row className="mt-3">
            <Col>
                <ClassicEditor
                    value={editedData.content}
                    initialValue={editedData.content}
                    onEditorChange={(newContent) => {
                        setEditedData({...editedData, content: newContent})
                    }}
                />
            </Col>
        </Row>

        <InputRow
            onChange={
                newOption => {
                    setEditedData({...editedData, broadcast_type: newOption.value})
                }
            }
            value={sendDateTime}
            label={"Type"}
            required={true}
            renderer={(renderProps) =>
                <Select
                    {...renderProps}
                    isDisabled={editedData.can_edit_type === false}
                    placeholder="Choose a broadcast channel"
                    styles={REACT_SELECT_STYLES}
                    value={selectedBroadcastType}
                    options={broadcastTypeOptions}
                    isOptionDisabled={(option) => option.disabled}
                />
            }
        />
        <InputRow
            onChange={newValue => {
                if (newValue) {
                    setEditedData({...editedData, send_datetime: moment(newValue).format('YYYY-MM-DD 07:00:00+0800')})
                } else {
                    setEditedData({...editedData, send_datetime: moment(sendDateTime).format('YYYY-MM-DD 07:00:00+0800')})
                }
            }}
            value={sendDateTime}

            label={"Send Date"}
            required={true}
            renderer={(renderProps) =>
                <DatePicker
                    {...renderProps}
                    defaultValue={moment().add(1, 'd').toDate()}
                    minDate={moment().toDate()}
                    clearIcon={null}
                />
            }
        />
    </>
}