import React from "react";
import * as PropTypes from "prop-types";
import InfoCard from "./infoCard";
import {Col, Row} from "reactstrap";

function InfoCards(props) {
    const cardSize = props.data.length > 2 ? 4 : 6
    return <Row>
        {
            props.data.map((row, index) => {
                return <Col lg={cardSize} key={"infocard-" + index.toString()} className="mb-3">
                    <InfoCard {...row}/>
                </Col>
            })
        }
    </Row>
}

InfoCards.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string
        ]),
        value: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string,
            PropTypes.number
        ]),
        color: PropTypes.string,
        isLoading: PropTypes.bool,
        navigateTo: PropTypes.string
    }))
}


export default InfoCards