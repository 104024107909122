import React from "react";
import BrokerManagement from "./index";
import {Route} from "react-router-dom";
import LandingPage from "./landingPage";
import HelpPage from "../../../commonComponents/helpPage";
import Brokers from "./brokers";
import EditBroker from "./brokers/editBroker";
import NewBroker from "./brokers/newBroker";
import BulkUpload from "./brokers/bulkUpload";
import Salesperson from "./salesperson";
import EditSalesperson from "./salesperson/editSalesperson";
import NewSalesperson from "./salesperson/newSalesperon";
import Leads from "../../../commonComponents/modules/leads";
import SiteAnnouncements from "./siteAnnouncements";
import Broadcast from "./broadcast";
import Report from "./report";
import Registration from "./registration";
import {MODULE_CRM_BROKERS} from "../helpManagement/helpList/constants";
import Lead from "../../../commonComponents/modules/leads/lead";
import ImportLeads from "../../../commonComponents/modules/leads/importLeads";

const BrokerManagementRoutes = <Route exact path="broker-management" element={<BrokerManagement/>}>
    <Route index element={<LandingPage/>}/>
    <Route path="help" element={<HelpPage site={"crm"} module={MODULE_CRM_BROKERS}/>}/>
    <Route path="brokers">
        <Route index element={<Brokers/>}/>
        <Route path="edit/:brokerId" element={<EditBroker/>}/>
        <Route path="new" element={<NewBroker/>}/>
        <Route path="bulk" element={<BulkUpload isBroker={true}/>}/>
    </Route>
    <Route path="salesperson">
        <Route index element={<Salesperson/>}/>
        <Route path="edit/:brokerId" element={<EditSalesperson/>}/>
        <Route path="new" element={<NewSalesperson/>}/>
        <Route path="bulk" element={<BulkUpload isBroker={false}/>}/>
    </Route>
    <Route path="leads">
        <Route index element={<Leads/>}/>
        <Route path={"import"} element={<ImportLeads/>}/>
        <Route path={"edit/:leadId"} element={<Lead/>}/>
    </Route>
    <Route path="announcements">
        <Route index element={<SiteAnnouncements/>}/>
    </Route>
    <Route path="broadcast">
        <Route index element={<Broadcast/>}/>
    </Route>

    <Route path='registration'>
        <Route index element={<Registration/>}/>
    </Route>

    <Route path={"reports"}>
        <Route index element={<Report/>}/>
    </Route>
</Route>

export default BrokerManagementRoutes