import React from "react";
import {faCircleQuestion, faIdCard} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";

export default function FormAdmin() {

    const sidebarItems = [
        {icon: faIdCard, text: "LEAD PROFILE SETTINGS", to: ''},
        {icon: faCircleQuestion, text: "NEED HELP?", to: 'help'}
    ];

    return <ModuleFactory
        sidebarItems={sidebarItems}/>


}