import React from "react";
import {Col, Row, Spinner, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import TagFormDisplay from "../../lead/common/tagFormDisplay";
import {useDetectedAnswerChanges, useDetectedTagChanges} from "../hooks";

export default function Compare({primaryLead, secondaryLead, showPrimaryInfo, showUnchangedAnswers}) {
    const {changedTagsByTagGroup, isLoading: isLoadingTagChanges} = useDetectedTagChanges(primaryLead, secondaryLead)
    const {changedFormFields, isLoading: isLoadingAnswerChanges} = useDetectedAnswerChanges(primaryLead, secondaryLead)

    const mainFields = ['first_name', 'last_name', 'email_address', 'contact_number']
    const changedMainFields = mainFields.filter(field => primaryLead[field] !== secondaryLead[field])

    if (isLoadingAnswerChanges || isLoadingTagChanges) {
        return <Row>
            <Col>
                <div className="text-center">
                    <Spinner/>
                </div>
            </Col>
        </Row>
    }

    if (changedTagsByTagGroup.filter(row => row.isChanged).length === 0 && changedFormFields.length === 0) {
        return <Row>
            <Col>
                <div className="text-center">
                    No form field or tag changes will be applied to the primary lead.
                </div>
            </Col>
        </Row>

    }



    return <>
        <Table>
            <tbody>
            {
                mainFields.map(field => {
                    if (!showPrimaryInfo && !changedMainFields.includes(field)) {
                        return null
                    }
                    return <tr>
                        <th>
                            <strong className="text-muted">{field.toUpperCase().split('_').join(' ')}</strong>
                        </th>
                        <td colSpan={field in changedMainFields ? 1 : 3}>
                            {primaryLead[field]}
                        </td>
                        {
                            field in changedMainFields && <td>{secondaryLead[field]}</td>
                        }
                    </tr>
                })
            }

            {
                changedFormFields.map(formField => {
                    if (formField.field_type === 'tag') {
                        return null
                    }
                    const primaryAnswer = primaryLead.form_answers.find(row => row.form_field === formField.id)
                    const secondaryAnswer = secondaryLead.form_answers.find(row => row.form_field === formField.id)
                    const isChanged = primaryAnswer?.answer !== secondaryAnswer?.answer;

                    return <tr>
                        <th>
                            <strong className="text-muted">{formField.name.toUpperCase()}</strong>
                        </th>
                        <td colSpan={isChanged ? 1 : 3}>
                            {
                                primaryAnswer ?
                                    <span className={isChanged ? "strikethrough" : ""}>{primaryAnswer?.answer}</span> :
                                    <span className="text-muted">-</span>
                            }
                        </td>
                        {
                            isChanged && <>
                                <td>
                                    <FontAwesomeIcon icon={faArrowRight} className="text-muted me-2"/>
                                </td>
                                <td>
                                    {secondaryAnswer.answer}
                                </td>
                            </>
                        }
                    </tr>
                })
            }
            {
                changedTagsByTagGroup.map(({tagGroup, isChanged}) => {
                    if (!isChanged) {
                        return null
                    }
                    const primaryTags = primaryLead.form_tags.filter(row => row.tag_data.tag_group === tagGroup.id)
                    const secondaryTags = secondaryLead.form_tags.filter(row => row.tag_data.tag_group === tagGroup.id)
                    return <tr>
                        <th>
                            <strong className="text-muted">{tagGroup.name.toUpperCase()}</strong>
                        </th>
                        <td colSpan={isChanged ? 1 : 3}>
                            {
                                primaryTags.length > 0 ?
                                    <>
                                        {
                                            primaryTags.map(tagForm => <TagFormDisplay
                                                    tagForm={tagForm}
                                                    isColorActive={true}
                                                />
                                            )
                                        }
                                    </> :
                                    <span className="text-muted">-</span>
                            }
                        </td>
                        {
                            isChanged && <>
                                <td>
                                    <FontAwesomeIcon icon={faArrowRight} className="text-muted me-2"/>
                                </td>
                                <td>
                                    {
                                        secondaryTags.map(tagForm => <TagFormDisplay
                                                tagForm={tagForm}
                                                isColorActive={true}
                                            />
                                        )
                                    }
                                </td>
                            </>
                        }
                    </tr>
                })
            }
            </tbody>
        </Table>

    </>
}