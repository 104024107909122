import React, {useState} from "react";
import PrivacyPolicyModal from "./privacyPolicyModal";

export default function PrivacyPolicy({linkText}) {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
    const displayText = linkText ? linkText : "Privacy Policy"

    return <>
        <span
            onClick={() => setPrivacyPolicyOpen(true)}
            className="text-white pointer-cursor text-decoration-underline">
            <small>{displayText}</small>
        </span>
        <PrivacyPolicyModal isOpen={privacyPolicyOpen} toggle={() => setPrivacyPolicyOpen(false)}/>
    </>
}