import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {ProfileContext} from "../contexts";
import moment from "moment/moment";

export default function Welcome() {
    const {profile} = useContext(ProfileContext)
    const {
        name,
        last_login
    } = profile

    const last_login_moment = last_login && moment(last_login)
    return <Row className="mb-4">
        <Col className="greeting-content">
            <h3>Hello, {name}!</h3>

            <small>Last login: {last_login_moment ? last_login_moment.format('LLL') :
                <i>No previous login</i>}</small>
        </Col>
    </Row>
}