import React, {useState} from "react";
import {Button, Col, Input, Row} from "reactstrap";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import Select from "react-select";
import moment from "moment";
import {toast} from "react-toastify";
import {type} from "@testing-library/user-event/dist/type";
import CreateCloseSoldModal from "./createCloseSoldModal";


export default function Status({data}) {
    const [selectedClosedSoldStatus, setSelectedClosedSoldStatus] = useState(null)
    const [selectedSaleType, setSelectedSaleType] = useState(null)
    const [note, setNote] = useState('')

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const mutation = useMutation(
        postData => {
            console.log("Using mutation with data", postData)
            const propertyResource = api.resource('properties')

            const approvePromise = propertyResource.postItemAction(data.id, 'approve_closed_sold_sales_lead', {
                close_sold_id: selectedClosedSoldStatus.value,
                note: note,
                status: selectedSaleType.value,
            }).then(response => response.data)

            toast.promise(approvePromise, {
                pending: "Updating status...",
                error: "Something went wrong. Please contact administrator",
                success: "Property had been marked as sold."
            })

            return approvePromise;
        }, {
            onSuccess: responseData => {
                console.log(data.id, typeof data.id)
                queryClient.invalidateQueries(['property', data.id])
                queryClient.invalidateQueries(['table', 'properties'])
            }
        }
    );

    const undoSoldMutation = useMutation(
        postData => {
            console.log("Using mutation with data", postData)
            const propertyResource = api.resource('properties')

            const approvePromise = propertyResource.postItemAction(data.id, 'undo_closed_sold_sales_lead', {
                note: note,
                status: 'closed_sold',
            }).then(response => response.data)

            toast.promise(approvePromise, {
                pending: "Updating status...",
                error: "Something went wrong. Please contact administrator",
                success: "Removed mark as sold."
            })

            return approvePromise;
        }, {
            onSuccess: responseData => {
                console.log(data.id, typeof data.id)
                queryClient.invalidateQueries(['property', data.id])
                queryClient.invalidateQueries(['table', 'properties'])
            }
        }
    );

    const closedSoldQuery = useQuery(['closeSold', data.id], () => {
        const propertyResource = api.resource('properties')
        return propertyResource
            .getItemAction(data.id, 'closed_sold_sales_leads')
            .then(response => response.data)
    }, {
        enabled: !!data.is_available && !!data.can_update_status
    });

    const leadOptions = closedSoldQuery.data?.map(row => {
        return {
            label: <>
                Lead: <strong>
                {row.sales_lead.name} ({row.sales_lead.contact_number})
            </strong>
                <br/>

                <small>
                    Broker/Salesperson:{" "}
                    <strong>{row.sales_lead.sales_profile_display.name}</strong>
                </small>
                <br/>
                <small>
                    {moment(row.datetime_added).format('LLL')}
                </small>
            </>,
            value: row.id
        }
    })


    const canMutate = selectedClosedSoldStatus && selectedSaleType;
    const getUpdateStatusForm = () => {
        if (data.can_update_status) {
            if (data.is_available) {
                return <>

                    <Row className="mt-5">
                        <Col className="border-bottom p-0">
                            <strong>UPDATE STATUS</strong>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="p-0">
                            <Select
                                placeholder={<>
                                    <br/>
                                    Select lead...
                                    <br/>{" "}&nbsp;
                                </>}
                                options={leadOptions}
                                onChange={newOption => setSelectedClosedSoldStatus(newOption)}
                                value={selectedClosedSoldStatus}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0 small">
                            <CreateCloseSoldModal propertyData={data}/>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="p-0">
                            <Select
                                placeholder={"Select sale type..."}
                                options={[
                                    {label: "Paid-Up sale", value: 'paid_up_sale'},
                                    {label: "Option sale", value: 'option_sale'},
                                ]}
                                onChange={newOption => setSelectedSaleType(newOption)}
                                value={selectedSaleType}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="p-0">
                            <Input
                                placeholder={"Add a note"}
                                type={"textarea"}
                                value={note}
                                onChange={e => {
                                    setNote(e.target.value)
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="p-0 text-end">
                            <Button onClick={() => {
                                mutation.mutate({})
                            }} disabled={!canMutate}>
                                UPDATE
                            </Button>
                        </Col>
                    </Row>
                </>
            } else {
                return <>

                    <Row className="mt-5">
                        <Col className="border-bottom p-0">
                            <strong>UPDATE STATUS</strong>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="p-0">
                            <Input
                                placeholder={"Add a note"}
                                type={"textarea"}
                                value={note}
                                onChange={e => {
                                    setNote(e.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="p-0">
                            <Button onClick={() => {
                                undoSoldMutation.mutate({})
                            }}>
                                UNDO MARK AS SOLD
                            </Button>
                        </Col>
                    </Row>
                </>
            }
        }
        return null;
    }
    const updateStatusForm = getUpdateStatusForm();
    const listingStatus = () => {
        if (!data.is_active) {
            return <span className="text-red strikethrough">CANCELLED</span>
        } else if (data.is_available) {
            return <span className="text-green">AVAILABLE</span>
        } else {
            return <span className="text-red">NOT AVAILABLE</span>
        }
    }
    return <>
        <Row className="mt-2">
            <Col className="p-0">

                STATUS:{" "}
                <strong>
                    {
                        listingStatus()
                    }
                </strong>
            </Col>
        </Row>



        <Row>
            <Col className="border-bottom p-0">
                <small>
                    <strong>{data.sample_generated_count}</strong> Generated Sample Computations for this property
                    listing.
                </small>
            </Col>
        </Row>

        {
            data.sold_to && <>
                <Row className="mt-5">
                    <Col className="border-bottom p-0">
                        <strong>SOLD TO</strong>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col className="p-0">
                        Lead: <strong>
                        {data.sold_to.sales_lead.name} ({data.sold_to.sales_lead.contact_number})
                    </strong>
                        <br/>

                        <small>
                            Broker/Salesperson:{" "}
                            <strong>{data.sold_to.sales_lead?.sales_profile_display?.name}</strong>
                        </small>
                        <br/>
                        <small>
                            {moment(data.sold_to.datetime_added).format('LLL')}
                        </small>
                    </Col>
                </Row>
            </>
        }
        {updateStatusForm}
    </>
}