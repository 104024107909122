import React, {useEffect, useState} from "react";
import {Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCirclePlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import Checkbox from "react-three-state-checkbox";
import Select from "react-select";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQuery} from "react-query";
import useDebounce from "../../../../commonComponents/util";
import {toast} from "react-toastify";


function ModalFilters({filters, setFilters, propertyGroupId, isOpen}) {
    const api = useLoggedInApi()
    const [searchText, setSearchText] = useState(filters.search)
    const debouncedSearchText = useDebounce(searchText, 500)

    useEffect(() => {
        const newFilters = {...filters}
        newFilters.search = debouncedSearchText
        setFilters(newFilters)
    }, [debouncedSearchText])

    const propertyFilterValuesQuery = useQuery(['table', 'property', 'groupDistribution', propertyGroupId], () => {
        const resource = api.resource('properties/groups')
        return resource.getItemAction(
            propertyGroupId, 'property_distribution', {params: {include_inactive: true}})
            .then(response => response.data)
    }, {
        enabled: isOpen
    })

    if (propertyFilterValuesQuery.isLoading) {
        return <Row>
            <Col className={"text-center"}>
                <Spinner/>
            </Col>
        </Row>
    }

    const phaseOptions = !propertyFilterValuesQuery?.data?.phases ?
        [] :
        propertyFilterValuesQuery.data.phases.map(row => {
        return {
            value: row.phase,
            label: row.phase
        }
    }).sort((a, b) => {
        if (a.value > b.value) return 1
        if (a.value < b.value) return -1
        return 0
    });
    const selectedPhase = phaseOptions.find(option => option.value === filters.phase)

    const blockOptions = !propertyFilterValuesQuery?.data?.blocks ?
        [] :
        propertyFilterValuesQuery.data.blocks.map(row => {
        return {
            value: row.block,
            label: row.block
        }
    }).sort((a, b) => {
        if (a.value > b.value) return 1
        if (a.value < b.value) return -1
        return 0
    });
    const selectedBlock = blockOptions.find(option => option.value === filters.block)

    const toggleHasTemplate = () => {
        const newFilters = {...filters}
        if (newFilters.hasTemplate) {
            newFilters.hasTemplate = false
        } else if (newFilters.hasTemplate === null) {
            newFilters.hasTemplate = true
        } else {
            newFilters.hasTemplate = null
        }
        setFilters(newFilters)
    }
    return <>
        <Row>
            <Col>
                Search: <Input type={"text"} value={searchText} onChange={event => {
                setSearchText(event.target.value)
            }}/>
            </Col>
            <Col>
                <span>Current Template Status:</span><br/>
                <Checkbox
                    id={"hasTemplate"}
                    checked={filters.hasTemplate}
                    indeterminate={filters.hasTemplate === null}
                    onChange={toggleHasTemplate}/>
                {" "}
                <Label for={"hasTemplate"} className={"mb-0"} onClick={toggleHasTemplate}>
                    {
                        filters.hasTemplate === null ? "All Properties" : filters.hasTemplate ? "Properties with Template" : "Properties without Template"
                    }
                </Label>
            </Col>
        </Row>
        <Row>
            <Col>
                Phase:
                <Select
                    options={phaseOptions}
                    isClearable={true}
                    placeholder={"All"}
                    onChange={newOption => {
                        const newFilters = {...filters}
                        newFilters.phase = newOption ? newOption.value : null
                        setFilters(newFilters)
                    }}
                    value={selectedPhase}
                />
            </Col>
            <Col>
                Block:
                <Select
                    options={blockOptions}
                    isClearable={true}
                    placeholder={"All"}
                    onChange={newOption => {
                        const newFilters = {...filters}
                        newFilters.block = newOption ? newOption.value : null
                        setFilters(newFilters)
                    }}
                    value={selectedBlock}
                />
            </Col>
        </Row>
    </>
}

export default function ApplyModal({data}) {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggle = () => setIsOpen(!isOpen)
    const api = useLoggedInApi()


    const [filters, setFilters] = React.useState({
        hasTemplate: null
    })

    const [selectedProperties, setSelectedProperties] = React.useState([])


    const applyMutation = useMutation(() => {
        const resource = api.resource('properties/templates')
        const applyPromise = resource.postItemAction(data.id, 'apply_template', {
            properties: selectedProperties.join(',')
        }).then(response => response.data)
        toast.promise(applyPromise, {
            pending: "Applying Template...",
            success: "Template processing queued. Please check back in a few minutes.",
            error: "Error applying template"
        })
        return applyPromise
    })

    const effectiveFilters = {
        ...filters,
        group: data.property_group
    }

    return <>
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            scrollable={true}>
            <ModalHeader toggle={toggle} className="activegroup-header">
                <span className={"text-white"}>
                     Apply <strong>{data.title}</strong> Template to <strong>{data.property_group_name}</strong> Property Group
                </span>
            </ModalHeader>
            <ModalBody className="m-3">
                <ModalFilters filters={filters} setFilters={setFilters} propertyGroupId={data.property_group}
                              isOpen={isOpen}/>
                <AdvancedTable
                    selectAccessor={"id"}
                    selected={selectedProperties}
                    setSelected={setSelectedProperties}
                    customTableStyles={{
                        rows: {
                            style: {
                                minHeight: '2em'
                            }
                        }
                    }}
                    filterMapOverride={effectiveFilters}
                    hideSearch={true}
                    hideTotalCount={true}
                    friendlyResourceName={"Property Listing"}
                    apiResourceName={'properties'}
                    dataTableProps={{
                        defaultSortFieldId: 'unique_property_id',
                    }}
                    defaultSort={"unique_property_id"}
                    columnDefinition={[
                        {
                            id: "unique_property_id",
                            name: "ID",
                            selector: row => row.unique_property_id,
                            format: row => <>
                                {row.unique_property_id ? row.unique_property_id :
                                    <i>ID Not Set</i>}
                                {/*</small>*/}

                            </>,
                            sortable: true,
                            sortField: 'unique_property_id'
                        },
                        {
                            name: "Label",
                            selector: row => row.label,
                            sortable: true,
                            sortField: 'label',
                        },
                        {
                            name: "Phase",
                            selector: row => row.phase,
                            center: true,
                            sortable: true,
                            sortField: 'phase',
                            width: '7em'
                        },
                        {
                            name: "Block",
                            selector: row => row.block,
                            sortable: true,
                            center: true,
                            sortField: 'block',
                            width: '7em'
                        },
                        {
                            name: "Lot",
                            selector: row => row.lot,
                            sortable: true,
                            center: true,
                            sortField: 'lot',
                            width: '7em'
                        },
                        {
                            name: "Template",
                            selector: row => row.has_template,
                            center: true,
                            format: row => {
                                if (row.has_template) {
                                    return <FontAwesomeIcon icon={faCheckCircle} className="text-success"/>
                                } else {
                                    return <FontAwesomeIcon icon={faTimesCircle} className="text-danger"/>
                                }
                            },
                            width: '9em',
                            sortable: true,
                            sortField: 'sample_computation_template'
                        }
                    ]}
                />

                <Row>
                    <Col className="text-end">
                        <Button color={"primary"} onClick={() => {
                            applyMutation.mutate()
                        }} disabled={selectedProperties.length === 0}>
                            APPLY TEMPLATE TO {selectedProperties.length} PROPERTIES
                        </Button>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>


        <Button color="primary" onClick={toggle}>
            <FontAwesomeIcon icon={faCirclePlus}/>{" "}
            Apply to Properties
        </Button>
    </>
}