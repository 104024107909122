import React, {useContext} from "react";
import {useParams} from "react-router-dom";
import {PermissionsContext} from "../../../contexts";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {useLead} from "../../../hooks";


export default function DisabledAlert() {
    const {leadId} = useParams()
    const {data, isLoading} = useLead(leadId, {params: {show_disabled: true}})
    const isLeadActive = data?.is_active

    const {permissions} = useContext(PermissionsContext)

    const canEnable = permissions.includes('microsite.delete_saleslead')

    const [enableConfirm, setEnableConfirm] = React.useState(false)
    const [remarks, setRemarks] = React.useState("")

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const enableMutation = useMutation((remarks) => {
        const leadResource = api.resource('leads')
        const patchPromise = leadResource.patchItem(leadId, {is_active: true, remarks}, {
            params: {show_disabled: true}
        })
        toast.promise(patchPromise, {
            pending: 'Enabling Lead...',
            success: 'Lead Enabled',
            error: 'Error Enabling Lead'
        })
        return patchPromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['lead'])
            queryClient.invalidateQueries(['table', 'leads'])

            setEnableConfirm(false)
        }
    })

    if (isLoading) {
        return null
    }

    if (!data) {
        return <div className="alert alert-danger">
            <h4 className="alert-heading">Lead not found</h4>
            <p>
                This lead does not exist or has been deleted.
            </p>
        </div>
    }

    if (isLeadActive) {
        return null
    }

    return <div className="alert alert-danger">
        <h4 className="alert-heading">This lead is disabled</h4>
        <p>
            This lead has been disabled and is no longer available for use.
        </p>
        {
            canEnable &&
            <Row>
                <Col>
                    {
                        !enableConfirm ? <Button color="link" className="btn-sm" onClick={() => {
                            setEnableConfirm(true)
                        }}>
                            Enable this lead?
                        </Button> : <>
                            <Row>
                                <Col>
                                    <Label className="text-muted small">Remarks *</Label>
                                    <Input
                                        type="textarea"
                                        value={remarks}
                                        onChange={(e) => {
                                            setRemarks(e.target.value)
                                        }}
                                        placeholder="Add remarks"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="text-end">
                                    <Button color="secondary" onClick={() => {
                                        setEnableConfirm(false)
                                    }}>
                                        CANCEL
                                    </Button>
                                    <Button
                                        color="primary" disabled={!remarks || enableMutation.isLoading}
                                        onClick={() => {
                                        enableMutation.mutate(remarks)
                                    }}>
                                        {
                                            enableMutation.isLoading ? "ENABLING..." : "ENABLE LEAD"
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        }
    </div>
}