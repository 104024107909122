import React from 'react'
import InputRow from "../inputRow";
import {useQuery} from "react-query";
import {useLoggedInApi} from "../../api";
import Select from "react-select";
import {REACT_SELECT_STYLES} from "../../constants";
import ReactSwitch from "react-switch";
import {Col, Row} from "reactstrap";

export default function MicrositeInfo({
                                          profileData,
                                          originalProfileData,
                                          isProfileSaving,
                                          updateErrorStatus,
                                          setProfileData,
                                          showPropertyGroups
                                      }) {
    const api = useLoggedInApi()
    const propertyGroupsQuery = useQuery('property-groups', () => {
        const propertyGroupResource = api.resource('properties/groups')
        return propertyGroupResource.list().then(response => response.data)
    }, {
        enabled: showPropertyGroups,
        staleTime: 5 * 60 * 1000
    })

    const propertyGroupOptions = propertyGroupsQuery.data ? [
        ...propertyGroupsQuery.data.map(row => {
            return {label: row.name, value: row.id}
        })
    ].sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    }) : [];
    const selectedPropertyGroupOptions = profileData.assigned_properties ? propertyGroupOptions.filter(o => profileData.assigned_properties.includes(o.value)) : []

    const removedPropertyGroups = originalProfileData.assigned_properties ?
        originalProfileData.assigned_properties.filter(pk => !profileData.assigned_properties?.includes(pk)) : [];

    // show select all only if there are still available property groups
    const selectAllOptions = propertyGroupOptions.every(o => selectedPropertyGroupOptions.includes(o)) ?
        [] : [{label: "Select All", value: "all"}];

    const selectablePropertyGroupOptions = [
        ...selectAllOptions,
        ...propertyGroupOptions
    ];
    return <>
        <h5 className="mt-5">Microsite Information</h5>

        {
            showPropertyGroups && <>
                <InputRow
                    label={"Property Groups"}
                    value={selectedPropertyGroupOptions}
                    disabled={isProfileSaving}
                    setHasError={hasError => updateErrorStatus('assigned_properties', hasError)}
                    onChange={selected => {
                        const newValues = selected.length && selected.find(option => option.value === "all") ?
                            propertyGroupOptions : selected
                        setProfileData({
                            ...profileData,
                            assigned_properties: newValues.map(o => o.value)
                        })
                    }}
                    renderer={renderProps => <Select
                        {...renderProps}
                        placeholder={"Select property groups to assign..."}
                        styles={REACT_SELECT_STYLES}
                        options={selectablePropertyGroupOptions}
                        isMulti={true}
                        isLoading={propertyGroupsQuery.isLoading}
                    />}
                />

                {
                    removedPropertyGroups.length > 0
                        ? <Row className="align-content-center">
                            <Col className="col-auto">
                                <ReactSwitch
                                    checked={profileData.hasOwnProperty('remove_added_property_groups') ? profileData.remove_added_property_groups : false}
                                    onChange={newStatus => setProfileData({
                                        ...profileData,
                                        remove_added_property_groups: newStatus
                                    })}
                                    checkedIcon={null} uncheckedIcon={null}/>
                            </Col>
                            <Col>
                                Force removal of property groups from this broker and salespersons under them? <br/>
                            </Col>
                        </Row>
                        : null
                }
            </>
        }

        <InputRow
            required={profileData.is_broker}
            label={"Licenses/Accreditation"}
            value={profileData.licenses}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('licenses', hasError)}
            onChange={newValue => setProfileData({...profileData, licenses: newValue})}/>

        <InputRow
            label={"Public Profile Description"}
            type="textarea"
            rows={5}
            disabled={isProfileSaving}
            value={profileData.public_profile_description}

            setHasError={hasError => updateErrorStatus('public_profile_description', hasError)}
            onChange={newValue => setProfileData({...profileData, public_profile_description: newValue})}/>

    </>
}