import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import UpdateModal from "./updateModal";


export default function AddUpdate({disabled=false}) {
    const [addUpdateKey, setAddUpdateKey] = React.useState(null)

    return <>
        <Button color="info" className="float-end" onClick={() => {
            setAddUpdateKey(Date.now())
        }} disabled={disabled}>
            <FontAwesomeIcon icon={faCheckSquare}/>{" "}
            ADD AN UPDATE
        </Button>
        <UpdateModal key={addUpdateKey} isOpen={addUpdateKey} toggle={() => setAddUpdateKey(null)}/>
    </>
}