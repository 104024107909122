import React from "react";
import ProfileForm from "../profileForm";
import {DEFAULT_EMPLOYEE_PROFILE} from "../../commonComponents/hooks";

export default function MyEmployeeProfileForm(props) {
    return <ProfileForm
        isMe={props.isMe}
        profileId={props.profileId}
        showMicroSite={false}
        showBrokerInfo={false}
        canEditPosition={false}
        isEmployee={true}
        hasActiveToggle={false}
        resourceName={'my-employee-profile'}
        defaultProfileData={DEFAULT_EMPLOYEE_PROFILE}
        canEditID={false}
    />
}