import React, {useContext} from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {NavLink, useNavigate} from "react-router-dom";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {TableFilterContext} from "../../../../commonComponents/contexts";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";
import OptionEdit from "../../systemOption/optionEdit";
import EditManagerExpandableRow from "./EditManagerExpandableRow";


export default function Brokers() {
    const {setFilters: setAllFilters} = useContext(TableFilterContext)
    const navigate = useNavigate()

    const apiClient = useLoggedInApi()
    const {data: brokerManagers, isLoading: isLoadingBrokerManagers} = useQuery(['broker-managers'], () => {
        const brokerResource = apiClient.resource('broker')
        return brokerResource.getItem('broker_managers').then(response => {
            return response.data.reduce((acc, row) => {
                if (!acc[row.id]) {
                    acc[row.id] = row
                }
                return acc
            }, {})
        })
    }, {
        staleTime: 1000 * 60 * 5,  // 5 minutes
        cacheTime: 1000 * 60 * 5,  // 5 minutes
    })

    const canViewManagers = brokerManagers && Object.keys(brokerManagers).length > 0

    const viewSalesPeople = (groupId) => {
        setAllFilters('salesperson', 'group', groupId)
        navigate('/broker-management/salesperson')
    }
    const viewGroupLeads = (groupId) => {
        setAllFilters('leads', 'sales_profile__group', groupId)
        navigate('/broker-management/leads')
    }
    const viewLeads = (salesProfileId) => {
        setAllFilters('leads', 'sales_profile', salesProfileId)
        navigate('/broker-management/leads')
    }
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Brokers</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Brokers can be individuals or group/entity with a valid real estate license. Salespeople can only be affiliated with (1) one broker.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    navigate('new')
                }}>

                    <FontAwesomeIcon icon={faCirclePlus}/>{" "}
                    ADD A BROKER
                </Button>
            </Col>
            <Col className="text-lg-end text-md-end">
                <Button color="info-outline" onClick={() => {
                    navigate('bulk')
                }}>
                    <FontAwesomeIcon icon={faFileUpload}/>{" "}
                    BULK UPLOAD
                </Button>
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Broker"}
            apiResourceName={'broker'}
            columnDefinition={[
                {
                    name: "Broker ID",
                    selector: row => <NavLink to={"edit/" + row.id}>
                        {row.active_group_seller_id ? row.active_group_seller_id : <i>ID Not Set</i>}
                    </NavLink>,
                    sortable: true,
                    sortField: 'active_group_seller_id'
                },
                {
                    name: "Broker Name", selector: row => row.name, sortable: true,
                    sortField: 'group__name,user__first_name',
                    grow: 2
                },
                {
                    name: "Assigned Properties",
                    selector: row => {return Object.values(row.property_group_names).join(', ')},
                    // TODO: convert the property group names to tags
                    sortable: true, sortField: 'is_active',
                    maxWidth: "150px",
                    grow: 2
                },
                {
                    name: "Salespeople",
                    selector: row => row.salespeople_count,
                    format: row => {
                        if (row.salespeople_count === 0) return <span className="text-muted">0</span>
                        return <>
                            <Button color="link" onClick={() => viewSalesPeople(row.group)} size="sm">
                                {row.salespeople_count}
                            </Button>
                        </>
                    },
                    compact: true,
                    width: 'auto',
                    center: true
                },
                {
                    name: "Leads",
                    selector: row => row.leads_count,
                    format: row => {
                        if (row.leads_count === 0) return <span className="text-muted">0</span>
                        return <Button color="link" onClick={() => viewLeads(row.id)} size="sm">
                            {row.leads_count}
                        </Button>
                    },
                    compact: true,
                    width: 'auto',
                    center: true
                },
                {
                    name: "Group Leads",
                    selector: row => row.group_leads_count,
                    format: row => {
                        if (row.group_leads_count === 0) return <span className="text-muted">0</span>
                        return <Button color="link" onClick={() => viewGroupLeads(row.group)} size="sm">
                            {row.group_leads_count}
                        </Button>
                    },
                    width: 'auto',
                    compact: true,
                    center: true
                },
                {
                    name: canViewManagers ? "Managed By" : "",
                    selector: row => {
                        if (canViewManagers) {
                            const managers = row.managers.map(managerId => {
                                const manager = brokerManagers[managerId] ? brokerManagers[managerId] : null
                                if (manager) {
                                    return manager.email
                                }
                            }).filter(managerEmail => !!managerEmail).sort()

                            if (managers.length === 0) {
                                return <span className="text-muted">None</span>
                            } else if (managers.length === 1) {
                                return managers.join(', ')
                            } else {
                                return <strong>
                                    {managers.length} Users
                                </strong>
                            }

                        }
                    },
                    sortable: true,
                    sortField: 'group__managed_by2__email',
                    omit: !canViewManagers,
                    maxWidth: "110px",
                    grow: 2
                }
            ]}
            dataTableProps={{
                expandableRows: canViewManagers,
                expandableRowsComponent: EditManagerExpandableRow
            }}
        />
    </>
}