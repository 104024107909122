import React from "react";
import TagDisplay from "./tagDisplay";


export default function PropertyGroupAsTag({propertyGroup, isSelected=true, isStrikeThrough=false, ...props}) {
    if (!propertyGroup) return null

    return <TagDisplay
        color={isSelected ? propertyGroup.color : 'light-gray'}
        textColor={isSelected ? propertyGroup.text_color : null}
        description={propertyGroup.unique_property_group_id ? `ID: ${propertyGroup.unique_property_group_id}` : null}
        {...props}
    >
        {
            isStrikeThrough ? <s>
                {propertyGroup.name}
            </s> : propertyGroup.name
        }

    </TagDisplay>
}