import React, {useEffect, useState} from "react";
import {useLoggedInApi} from "../../../api";
import {REACT_SELECT_STYLES} from "../../../constants";
import Select from "react-select";
import {useQuery} from "react-query";
import * as PropTypes from 'prop-types';


function SalesGroupSelector(props) {
    const [isSaving, setIsSaving] = useState(false)
    const api = useLoggedInApi()
    const salesGroupsQuery = useQuery('sales-groups', () => {
        const salesGroupResource = api.resource('salesperson/broker_groups')
        return salesGroupResource.list().then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000
    })
    const options = salesGroupsQuery.isSuccess ? [
        ...salesGroupsQuery.data.map(row => {
            return {
                label: row.name,
                value: row.id,
                data: row
            }
        })
    ] : []
    const selectedOptions = props.salesGroups?.length > 0 ? [
        ...options.filter(o => props.salesGroups.indexOf(o.value) > -1)
    ] : []
    console.log(selectedOptions, options)
    if (props.readOnly) {
        if (selectedOptions.length === 0) {
            return <small><i>No sales group selected</i></small>
        }
        return <strong>
            <small>
                {selectedOptions.map(o => o.label).join(", ")}
            </small>
        </strong>
    }

    return <Select
        placeholder="Choose a sales group"
        isMulti={true}
        styles={REACT_SELECT_STYLES}
        value={selectedOptions}
        options={options}
        isDisabled={isSaving}
        isLoading={salesGroupsQuery.isLoading}
        onChange={
            newOptions => {
                if (props.setSalesGroups) {
                    if (props.isSetSaving) {
                        setIsSaving(true)
                        props.setSalesGroups([...newOptions.map(o => o.value)]).finally(() => {
                            setIsSaving(false)
                        })
                    } else {
                        props.setSalesGroups([...newOptions.map(o => o.value)])
                    }
                }
            }
        }
        filterOption={(option, rawInput) => {
            const filterInput = rawInput.toLowerCase()
            const searchableInput = (option.data.data.name + " " + option.data.data.address).toLowerCase()

            return searchableInput.includes(filterInput)
        }}
    />

}

SalesGroupSelector.propTypes = {
    salesGroups: PropTypes.arrayOf(PropTypes.number),
    setSalesGroups: PropTypes.func,
    isSetSaving: PropTypes.bool,
    readOnly: PropTypes.bool
}

export default SalesGroupSelector