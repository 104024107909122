import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {UncontrolledTooltip} from "reactstrap";
import {v4 as uuidv4} from 'uuid'


export default function InfoTooltip({text}) {
    const ref = React.useRef()
    const randomElementId = React.useMemo(() => {
        return 't' + uuidv4().replaceAll('-', '')
    })
    return (
        <>
            <span
                className="ms-1"
                href={"#"}
                ref={ref}
                id={randomElementId}>
                    <FontAwesomeIcon icon={faCircleInfo}/>
            </span>
            <UncontrolledTooltip
                target={ref}
            >
                {text}
            </UncontrolledTooltip>
        </>
    )
}