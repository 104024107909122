import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {Alert, Button, Col, Label, Progress, Row, Spinner} from "reactstrap";
import {useLoggedInApi} from "../../../../../api";
import {chunkArray, chunkDict} from "../../../../../commonComponents/util";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useResetProfileData} from "../../../../../commonComponents/hooks";

function Commit({processedData, onComplete, onStart, previousStep, isBroker}) {
    const resourceName = isBroker ? 'broker' : 'salesperson';
    const [isProcessing, setIsProcessing] = useState(false)
    const [updatedCount, setUpdatedCount] = useState(0)
    const [createdCount, setCreatedCount] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const editableProfiles = processedData.filter(row => row.status === 'changed' || row.status === 'new');

    const navigate = useNavigate()
    const api = useLoggedInApi()
    const {resetProfile} = useResetProfileData(resourceName)

    const update = data => {
        const dataToUpdate = data.filter(row => row.status === 'changed').reduce((acc, row) => {
            acc[row.apiId.toString()] = row.changedData
            return acc
        }, {})
        const dataToInsert = data.filter(row => row.status === 'new').map(row => row.processedData)
        const dataToUpdateChunks = chunkDict(dataToUpdate, 10).map(chunk => ({update: chunk, new: []}))
        const dataToInsertChunks = chunkArray(dataToInsert, 10).map(chunk => ({update: {}, new: chunk}))
        runPost([...dataToUpdateChunks, ...dataToInsertChunks])
    }

    const runPost = (data) => {
        const postDataChunks = JSON.parse(JSON.stringify(data))
        let _updatedCount = 0;
        let _createdCount = 0
        let isOngoing = false;
        const intervalId = window.setInterval(() => {
            const summaryTextParts = [];
            if (_updatedCount > 0) {
                summaryTextParts.push("updated " + _updatedCount.toString())
            }
            if (_createdCount > 0) {
                summaryTextParts.push("created " + _createdCount.toString())
            }
            const summaryText = <>{summaryTextParts.length === 0 ? "No updated/created" : "Successfully " + summaryTextParts.join(" and ")} profiles.</>

            if (!isOngoing) {
                if (postDataChunks.length === 0) {
                    clearInterval(intervalId)
                    setIsProcessing(false)
                    setIsComplete(true)
                    toast.success(<>Data upload done! {summaryText}</>)
                    return false
                }

                isOngoing = true
                const postData = postDataChunks.pop()
                const profileApi = api.resource(resourceName)
                profileApi.post('bulk_update/', postData)
                    .then(response => response.data)
                    .then(data => {
                        data.updated.forEach(row => {
                            resetProfile(row)
                        })
                        data.new.forEach(row => {
                            resetProfile(row)
                        })
                        _updatedCount += data.updated.length
                        _createdCount += data.new.length

                        setUpdatedCount(_updatedCount)
                        setCreatedCount(_createdCount)
                        isOngoing = false
                        return data
                    }).catch(() => {
                        toast.error(<>
                            Something went wrong during upload! {summaryText}
                            <br/>
                            If problem persists, contact administrator

                        </>)

                        clearInterval(intervalId)
                        setIsProcessing(false)
                        return false;
                    })
            }
        }, 1000)
    }

    const changeableCount = editableProfiles.filter(row => row.status === 'changed').length;
    const creatableCount = editableProfiles.filter(row => row.status === 'new').length

    return <>
        <Row className={"mt-4"}>
            <Col><h4>Step 3 - Commit Changes</h4></Col>
        </Row>
        <Row>
            <Col>
                <Button color="link" onClick={previousStep}>
                    <small>Go Back To Step 2 - Review</small>
                </Button>
            </Col>
        </Row>
        <Row className={"mt-4"}>
            <Col>
                <Label>
                    <strong>Update Profiles</strong>{" "}
                    {updatedCount} of {changeableCount}
                </Label>
                <Progress value={changeableCount ? (updatedCount / changeableCount * 100) : 100}>
                    {updatedCount} of {changeableCount}
                </Progress>
            </Col>
        </Row>
        <Row className={"mt-4"}>
            <Col>
                <Label>
                    <strong>Create Profiles</strong>{" "}
                    {createdCount} of {creatableCount}
                </Label>
                <Progress value={creatableCount ? (createdCount / creatableCount * 100) : 100}>
                    {createdCount} of {creatableCount}
                </Progress>
            </Col>
        </Row>
        {
            (!isProcessing && !isComplete) && <Row className={"mt-4"}>
                <Col>
                    <Button color={'primary'} onClick={() => {
                        setIsProcessing(true)
                        update(editableProfiles)
                    }}>
                        Commit {editableProfiles.length} Profiles
                    </Button>
                </Col>
            </Row>
        }
        {
            (isProcessing) && <Row className={"mt-4"}>
                <Col>
                    <Spinner size={"sm"}/> Processing
                </Col>
            </Row>
        }

        {
            isComplete && <>
                <Row className={"mt-4"}>
                    <Col>
                        <Alert color={"success"}>
                            Done! Updated {updatedCount} and created {createdCount} new profiles.
                        </Alert>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col>
                        <Button color="link" onClick={() => {
                            navigate(isBroker ? '/broker-management/brokers/' : '/broker-management/salesperson/')
                        }}>
                            <small>Go To {isBroker ? "Brokers" : "SalesPersons"} List</small>
                        </Button>
                    </Col>
                </Row>
            </>
        }
    </>
}

Commit.propTypes = {
    processedData: PropTypes.array.isRequired,
    onStart: PropTypes.func,
    onComplete: PropTypes.func
}

export default Commit;

