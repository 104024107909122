import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../api";
import {toast} from "react-toastify";


export default function OptionEdit({data}) {
    const [choices, setChoices] = useState([...data.choices])
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const fieldName = data.field_name.toUpperCase();
    const choicesAsOptions = choices.map(c => ({value: c, label: c}))

    const saveMutation = useMutation(newChoices => {
        const resource = api.resource('option')
        const updatePromise = resource.patchItem(data.id, {choices: newChoices})
        toast.promise(updatePromise, {
            pending: "Updating choices for " + fieldName,
            success: "Updated choices for " + fieldName,
            error: "Something went wrong. Please contact administrator."
        })
        return updatePromise
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries(['table', 'option'])
            queryClient.invalidateQueries(['profile-options'])
        }
    })

    return <div className="m-4 pb-4">
        <Row className="mb-4">
            <Col>
                <Label>Choices</Label>
                <CreatableSelect
                    options={choicesAsOptions}
                    value={choicesAsOptions}
                    isMulti={true}
                    placeholder={"Add choices for " + fieldName}
                    noOptionsMessage={() => "Start typing to add new choices"}
                    onChange={options => setChoices([...options.map(o => o.value)])}
                    />
            </Col>
        </Row>
        <Row>
            <Col className="text-end">
                <Button color="primary" onClick={() => {
                    saveMutation.mutate(choices)
                }}>
                    SAVE CHANGES
                </Button>
            </Col>
        </Row>
    </div>
}