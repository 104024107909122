import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import {PermissionsContext} from "../../../../contexts";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useLoggedInApi} from "../../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {useLead} from "../../../../hooks";

export default function DisableLead() {
    const [disableConfirm, setDisableConfirm] = useState(false)
    const [remarks, setRemarks] = useState('')

    const {leadId} = useParams()
    const {data, isLoading} = useLead(leadId, {params: {show_disabled: true}})
    const isLeadActive = data?.is_active

    const {permissions} = useContext(PermissionsContext)

    const canEnable = permissions.includes('microsite.delete_saleslead')

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const disableMutation = useMutation((remarks) => {
        const leadResource = api.resource('leads')
        const patchPromise = leadResource.patchItem(leadId, {is_active: false, remarks}, {
            params: {show_disabled: true}
        })
        toast.promise(patchPromise, {
            pending: 'Disabling Lead...',
            success: 'Lead Disabled',
            error: 'Error Disabling Lead'
        })
        return patchPromise
    }, {
        onSuccess: () => {
            setDisableConfirm(false)
            queryClient.invalidateQueries(['lead'])
            queryClient.invalidateQueries(['table', 'leads'])
        }
    })

    if (isLoading || !isLeadActive || !canEnable) {
        return null
    }

    return <>
        <Button color="link" className="btn-sm" onClick={() => {
            setDisableConfirm(true)
        }}>
            <FontAwesomeIcon icon={faTrash} className="me-2"/>
            Disable this lead?
        </Button>


        <Modal
            isOpen={disableConfirm}
            toggle={() => setDisableConfirm(false)}
            centered
            scrollable={true}
        >
            <ModalHeader toggle={() => {
                setDisableConfirm(false)
            }} className="activegroup-header">

            </ModalHeader>
            <ModalBody className="px-5 py-4">
                <h3>Disable this lead?</h3>
                <hr/>
                <p>
                    Disabled leads are no longer available for use. They will also be excluded from stats.
                </p>
                <p>
                    You can restore this lead later if needed.
                </p>

                <Row>
                    <Col>
                        <Label className={"text-muted small"}>Remarks</Label>
                        <Input
                            type="textarea"
                            value={remarks}
                            onChange={(e) => {
                                setRemarks(e.target.value)
                            }}
                            placeholder="Add remarks"
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    setDisableConfirm(false)
                }}>
                    CANCEL
                </Button>
                <Button
                    color="primary" disabled={!remarks || disableMutation.isLoading}
                    onClick={() => {
                        disableMutation.mutate(remarks)
                    }}>
                    {
                        disableMutation.isLoading ? "DISABLING..." : "DISABLE LEAD"
                    }
                </Button>
            </ModalFooter>
        </Modal>
    </>
}