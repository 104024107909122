import React from "react";
import HelpManagement from "./index";
import {Route} from "react-router-dom";
import HelpList from "./helpList";
import InquiryList from "./inquiryList";

const Routes = <Route exact path="help-management" element={<HelpManagement/>}>
    <Route index element={<HelpList listTitle={"Admin Dashboard"} site={"crm"} showModule={true}/>}/>
    <Route path={"sales_dashboard"} index element={<HelpList listTitle={"Sales Dashboard"} site={"sales_dashboard"}/>}/>

    <Route path={"inquiry"} element={<InquiryList/>}/>
</Route>

export default Routes;
