import React, {useEffect, useState} from "react";
import {useLoggedInApi} from "../../../api";
import {toast} from "react-toastify";
import InfoCards from "../../../components/charts/infoCards";
import {useQuery} from "react-query";

export default function TeamLeads(props) {
    const api = useLoggedInApi()

    const query = useQuery(['salesperson', 'team_lead_per_period', props.filters], () => {
        const resource = api.resource('salesperson')
        return resource.getItem('team_lead_per_period', {
            params: props.filters
        }).then(response => response.data)
    })

    const data = query.isSuccess ? query.data : {}

    return <InfoCards data={[
        {
            label: "TODAY'S LEADS",
            value: data.todays_leads,
            color: "blue2",
            isLoading: query.isLoading
        },
        {
            label: "LEADS THIS WEEK",
            value: data.leads_this_week,
            color: "green2",
            isLoading: query.isLoading
        },{
            label: "LEADS THIS MONTH",
            value: data.leads_this_month,
            color: "primary",
            isLoading: query.isLoading
        }
    ]}/>
}