import React from "react";
import moment from "moment/moment";
import TagDisplay from "../../../../tagDisplay";
import {Spinner} from "reactstrap";
import {useQuery} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import {usePropertyGroups} from "../../../../hooks";
import {PROPERTY_GROUP_SOURCE_MODEL_NAME, PROPERTY_SOURCE_MODEL_NAME} from "../../../../constants";
import InfoTooltip from "./infoTooltip";


export default function TagFormDisplay({tagForm, isColorActive, isStrikeThrough, showText = true, ...tagDisplayProps}) {
    const tag = tagForm.tag_data;

    const {data: propertyGroups, isLoading: isLoadingPropertyGroups} = usePropertyGroups({
        enabled: tag.source_model_name === PROPERTY_GROUP_SOURCE_MODEL_NAME && !!tagForm.source_object_id
    })

    const api = useLoggedInApi()
    const {data: property, isLoading: isLoadingProperty} = useQuery(['property', tagForm.source_object_id], () => {
        const resource = api.resource('properties')
        return resource.getItem(tagForm.source_object_id).then(response => response.data)
    }, {
        enabled: tag.source_model_name === PROPERTY_SOURCE_MODEL_NAME && !!tagForm.source_object_id
    })

    const moreInfoTags = React.useMemo(() => {
        const moreInfoArray = []
        const tooltips = []
        if (tagForm.tag_datetime) {
            moreInfoArray.push(moment(tagForm.tag_datetime).format('MMMM D, YYYY | h:mm A'))
        }

        if (showText) {
            if (tagForm.tag_text && showText) {
                tooltips.push(<InfoTooltip text={tagForm.tag_text}/>)
                // moreInfoArray.push(tagForm.tag_text)
            }
            // if (tagForm.extra_display && false) {
            //
            //     moreInfoArray.push(tagForm.extra_display)
            // } else {
            if (tagForm.source_text) {
                tooltips.push(<InfoTooltip text={tagForm.source_text}/>)
                // moreInfoArray.push(tagForm.source_text)
            }
            if (tagForm.source_object_id) {
                if (tag.source_model_name === PROPERTY_GROUP_SOURCE_MODEL_NAME) {
                    if (isLoadingPropertyGroups) {
                        moreInfoArray.push(<Spinner size="sm"/>)
                    } else {
                        const propertyGroup = propertyGroups?.find(row => row.id === tagForm.source_object_id)
                        if (propertyGroup) {
                            moreInfoArray.push(propertyGroup[tag.source_field])
                        }
                    }
                } else {
                    if (isLoadingProperty) {
                        moreInfoArray.push(<Spinner size="sm"/>)
                    } else if (property) {
                        moreInfoArray.push(property[tag.source_field])
                    }
                }
            }
            // }
        }

        // return array with empty tags in between
        const infoTexts = moreInfoArray.reduce((acc, row, index) => {
            if (index > 0) {
                acc.push(<span className="mx-1">|</span>)
            }
            acc.push(row)
            return acc
        }, [])

        return [
            ...infoTexts,
            ...tooltips
        ]
    }, [tagForm.tag_datetime, tagForm.tag_text, propertyGroups, isLoadingPropertyGroups, property, isLoadingProperty, showText])

    // TODO: double check isColorActive how it should affect textColor
    return <TagDisplay
        color={isColorActive ? tag.color : 'light-gray'}
        textColor={tag.text_color}
        description={tag.description}
        {...tagDisplayProps}
    >
        <span className={`${isStrikeThrough ? "strikethrough" : ""}`}>
        {tag.name}
            {
                (!!moreInfoTags && moreInfoTags.length > 0) && <>: {moreInfoTags}</>
            }
        </span>

    </TagDisplay>

}