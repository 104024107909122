import React from "react";
import {Col, Container, Row, Spinner} from "reactstrap";
import banner from "./assets/horizontal-banner.png";
import {Resource} from "../api";
import {useQuery} from "react-query";


export default function ServerAliveWrapper(props) {
    console.log("Server alive wrapper")
    const resource = new Resource('status')
    const checkStatus = () => {
        console.log("Checking status")
        return resource.list({timeout: 10000}).then(response => {
            return true
        })
    }

    const fiveMinutes = 5 * 60 * 1000  // 5 minutes
    const {isLoading, isRefetching, isError, failureCount} = useQuery('server-alive', checkStatus, {
        retry: 5,
        refetchInterval: fiveMinutes,
        refetchIntervalInBackground: false,
    })

    const messages = [
        "",
        "Waking up server...",
        "Booting up the database...",
        "Still booting up...",
        "Any time now...",
        "Checking for server issues...",
    ]

    const message = messages[failureCount]

    if (isLoading && !isRefetching) {
        return <Container
            fluid
            className="align-items-center vh-100 d-flex justify-content-center text-center"
            style={{backgroundColor: "#002463", color: "white"}}>
            <div>
                <Row className="m-5 p-3">
                    <Col>
                        <img src={banner} style={{width: "200px"}} alt="banner"/>
                    </Col>
                </Row>
                <Row className="m-5 p-3">
                    <Col>
                        <h2>
                            <Spinner/>
                        </h2>

                        { message ? <><br/>{message}</>: ""}
                    </Col>
                </Row>
            </div>
        </Container>
    }

    if (isError) {
        return <Container
            fluid
            className="align-items-center vh-100 d-flex justify-content-center text-center"
            style={{backgroundColor: "#002463", color: "white"}}>
            <div>
                <Row className="m-5 p-3">
                    <Col>
                        <img src={banner} style={{width: "200px"}} alt="banner"/>
                    </Col>
                </Row>
                <Row className="m-5 p-3">
                    <Col>
                        <h2 className="text-danger">
                            Server is currently unavailable. Please refresh.
                        </h2>
                        If problem persists, please contact administrator.
                    </Col>

                </Row>
            </div>
        </Container>
    }

    return <>
        {props.children}
    </>
}