import React, {useEffect, useState} from "react";
import {AccordionBody, AccordionHeader, AccordionItem, Col, Row, Spinner, UncontrolledAccordion} from "reactstrap";
import {useLoggedInApi} from "../../api";
import Inquiry from "./inquiry";
import Help from "../help";
import HelpList from "./helpList";

export default function HelpPage({site, module}) {

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Need Help?</h3>
            </Col>
        </Row>

        <HelpList site={site} module={module}/>

        <Inquiry site={site} module={module}/>
    </>
}