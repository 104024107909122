import React from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";
import TagDisplay from "../../../../../tagDisplay";
import TagFormDisplay from "../../common/tagFormDisplay";
import AnswerChangeDisplay from "./answerChangeDisplay";
import PropertyGroupAsTag from "../../../../../propertyGroupAsTag";
import {useLeadForm, usePropertyGroups} from "../../../../../hooks";
import Remarks from "./remarks";


export default function Activity({activity, selectedTags, setSelectedTags, setSelectedUpdateBy}) {
    const {data: leadForm} = useLeadForm()
    const formFieldsById = leadForm?.form_fields?.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    }, {})

    const allChanges = activity.context.changes || []

    const answerChanges = allChanges.filter(row => row.field_type !== "tag" && row.change_type !== 'property_group')
    const propertyGroupChanges = allChanges.filter(row => row.change_type === 'property_group')

    const {data: propertyGroups, isLoading: isLoadingPropertyGroups} = usePropertyGroups({
        useQueryOptions: {
            enabled: propertyGroupChanges.length > 0,
        }
    })
    const propertyGroupById = propertyGroups?.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    }, {}) || {}

    const isMerge = (activity.context.merged_from || activity.context.merged_to)
    const updateProfile = answerChanges.length > 0

    return <Row className="border rounded my-3 mx-2">
        <Col className="py-3">
            <Row>
                <Col style={{fontSize: "0.7rem"}}>
                    <strong>
                        {moment(activity.datetime_added).format('MMMM D, YYYY | h:mm a').toUpperCase()}
                    </strong> - {}
                    {
                        activity.updated_by_data && <span
                            className="text-decoration-underline pointer-cursor"
                            onClick={() => {
                                setSelectedUpdateBy(activity.updated_by_data)
                            }}
                        >
                        {activity.updated_by_data.name.toUpperCase()}
                            {activity.updated_by_data.sales_group && ` (${activity.updated_by_data.sales_group.toUpperCase()})`}
                    </span>
                    }
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        activity.context.type === "delete" && <TagDisplay color={'#b0c4de'}>
                            DISABLED
                        </TagDisplay>
                    }
                    {
                        activity.context.type === "restore" && <TagDisplay color={"#b0c4de"}>
                            RESTORE
                        </TagDisplay>
                    }
                    {
                        isMerge && <TagDisplay color={"#b0c4de"} onClick={() => {
                            if (!selectedTags.includes('merge')) {
                                setSelectedTags([...selectedTags, 'merge'])
                            }
                        }}>
                            MERGE
                        </TagDisplay>
                    }
                    {
                        updateProfile && (
                            activity.context.type !== 'create' ?
                                <TagDisplay color={"#b0c4de"} onClick={() => {
                                    if (!selectedTags.includes('update_profile')) {
                                        setSelectedTags([...selectedTags, 'update_profile'])
                                    }
                                }}>
                                    UPDATED PROFILE
                                </TagDisplay> :
                                <TagDisplay color={"#b0c4de"}>
                                    CREATE
                                </TagDisplay>
                        )
                    }
                    {
                        activity.new_tag_forms?.map(tagForm => {
                            return <TagFormDisplay
                                key={tagForm.id}
                                tagForm={tagForm}
                                isColorActive={true}
                                onClick={() => {
                                    if (!selectedTags.includes(tagForm.tag)) {
                                        setSelectedTags([...selectedTags, tagForm.tag])
                                    }
                                }}
                            />
                        })
                    }

                    {
                        activity.old_tag_forms?.map(tagForm => {
                            return <TagFormDisplay
                                key={tagForm.id}
                                tagForm={tagForm}
                                isColorActive={true}
                                isStrikeThrough={true}
                                onClick={() => {
                                    if (!selectedTags.includes(tagForm.tag)) {
                                        setSelectedTags([...selectedTags, tagForm.tag])
                                    }
                                }}
                            />
                        })
                    }

                    {
                        propertyGroupChanges.map(row => {
                            const clickPropertyId = (propertyGroupId) => {
                                if (!selectedTags.includes(`property-${propertyGroupId}`)) {
                                    setSelectedTags([...selectedTags, `property-${propertyGroupId}`])
                                }
                            }
                            const newPropertyGroups = row.new_property_groups.map(propertyGroupId => {
                                const propertyGroup = propertyGroupById[propertyGroupId]
                                return <PropertyGroupAsTag
                                    key={propertyGroupId}
                                    propertyGroup={propertyGroup}
                                    isSelected={true}
                                    onClick={() => clickPropertyId(propertyGroupId)}
                                    />
                            })
                            const removedPropertyGroups = row.removed_property_groups.map(propertyGroupId => {
                                const propertyGroup = propertyGroupById[propertyGroupId]
                                return <PropertyGroupAsTag
                                    key={propertyGroupId}
                                    propertyGroup={propertyGroup}
                                    isStrikeThrough={true}
                                    onClick={() => clickPropertyId(propertyGroupId)}
                                />
                            })
                            return [
                                ...newPropertyGroups,
                                ...removedPropertyGroups
                            ]
                        })
                    }
                </Col>
            </Row>

            <Row className="border-top mt-3 mx-1 py-2" style={{backgroundColor: "#f4f4f4"}}>
                <Col>
                    {
                        answerChanges.map(row => <AnswerChangeDisplay
                            key={row.field_id}
                            answerHistory={row}
                            formField={formFieldsById?.[row.field_id]}
                        />)
                    }
                    <Remarks remarks={activity.remarks}/>
                </Col>
            </Row>
        </Col>
    </Row>
}