import React from "react";
import {Col, Row} from "reactstrap";
import {COLOR_DEFAULT, COLOR_PRIMARY, COLOR_SELECTED} from "./constants";
import TagDisplay from "../../../tagDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function SalesProfileSelector({salesProfiles, selectedSalesProfile, setSelectedProfile}) {
    return <>

        <p className="text-muted">
            Selected leads have different sales profiles. Please select the sales profile that will be retained after the merge.
        </p>


        {
            salesProfiles.map(salesProfile => {
                const isSelected = salesProfile.id === selectedSalesProfile

                const toggleSelected = () => {
                    if (salesProfile.id === selectedSalesProfile) {
                        setSelectedProfile(null)
                    } else {
                        setSelectedProfile(salesProfile.id)
                    }
                }

                return <Row
                    className="border-top mt-4 mx-1 py-2 text-muted"
                    style={{
                        backgroundColor: isSelected ? COLOR_SELECTED : COLOR_DEFAULT
                    }}
                    onClick={toggleSelected}
                >
                    <Col>
                        <Row>
                            <Col>{salesProfile.name}</Col>
                            <Col className="text-end">
                                {
                                    isSelected && <TagDisplay
                                        color={'green'}
                                        onClick={(e) => {
                                            toggleSelected()
                                            e.stopPropagation()
                                        }}>
                                        PRIMARY BROKER/SALES CONTACT
                                    </TagDisplay>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {salesProfile.contact_number} | {salesProfile.email_address}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                Broker Group: {salesProfile.group_name}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            })
        }
    </>
}