import React from "react";
import {faCircleQuestion, faHashtag} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";

export default function TagAdmin() {
    const sidebarItems = [
        {icon: faHashtag, text: "TAGS MANAGEMENT", to: '/tag-admin/'},
        {icon: faCircleQuestion, text: "NEED HELP?", to: 'help'}
    ];

    return <ModuleFactory
        sidebarItems={sidebarItems}/>

}