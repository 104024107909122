import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import TopAgents from "./topAgents";
import TopTeams from "./topTeams";

export default function Leaderboard(props) {



    return <Card className="text-white px-2 py-3 mt-3" style={{backgroundColor: "#002463"}}>
        <CardBody>
            <Row className="flex-fill">
                <Col lg={6} md={12} className="mb-4">
                    <TopTeams/>
                </Col>
                <Col className="d-flex">
                    <Card className="text-white px-2 py-3 flex-fill" style={{
                        borderColor: "",
                        backgroundColor: "#0169a6"
                    }}>
                        <CardBody>
                            <TopAgents/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </CardBody>
    </Card>
}