import React from "react";
import {isUrlImage} from "../../../../../commonComponents/util";
import Compress from "compress.js";


export default function AssetDisplay({url, localFile}) {
    if (url) {
        if (isUrlImage(url)) {
            return <img className="img-fluid img-thumbnail" src={url} alt={"Media Asset"}/>
        } else {
            return <i className="text-black"> No thumbnail available.</i>
        }

    } else if (localFile) {
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

        if (!validImageTypes.includes(localFile.ext)) {
            return <i className="text-black"> No thumbnail available.</i>
        } else {
            const src = URL.createObjectURL(Compress.convertBase64ToFile(localFile.data))
            return <img className="img-fluid img-thumbnail" src={src} alt={"Media Asset"}/>
        }

    }
    return null

}