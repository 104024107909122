import React from "react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import TagGroupTagSelector from "../../common/tagGroupTagSelector";
import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../../api";
import {isArrayEqual} from "../../../../../util";
import {toast} from "react-toastify";
import {detectTagFormChanges} from "../../../util";
import {useTagGroup} from "../../../../../fetchers/tags";
import PropertyGroupSelectorAsTag from "../../../../../propertyGroupSelectorAsTag";
import {useLead} from "../../../../../hooks";


export default function UpdateModal({isOpen, toggle}) {
    const [unlockPermanentTags, setUnlockPermanentTags] = React.useState(false)

    const [selectedTags, setSelectedTags] = React.useState([])
    const [selectedProperties, setSelectedProperties] = React.useState([])

    const [remarks, setRemarks] = React.useState("")
    const {leadId} = useParams()

    const {data: leadData, isLoading: leadIsLoading} = useLead(leadId, {params: {show_disabled: true}}, {
        onSuccess: (data) => {
            setSelectedTags(data?.form_tags)
            setSelectedProperties((data?.property_groups || []))
        }
    })

    React.useEffect(() => {
        setSelectedTags(leadData?.form_tags || [])
        setSelectedProperties(leadData?.property_groups || [])
    }, [leadData?.form_tags, leadData?.property_groups])

    const {data: tagGroupData, isLoading: tagGroupIsLoading} = useTagGroup({
        params: {
            active_only: true
        }
    });

    const isLoading = leadIsLoading || tagGroupIsLoading

    const updates = React.useMemo(() => {
        return detectTagFormChanges(selectedTags, leadData?.form_tags)
    }, [selectedTags, leadData?.form_tags])

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const mutation = useMutation((patchData) => {
        const resource = api.resource('leads')
        const patchPromise = resource.patchItem(leadId, patchData).then(response => {
            return response.data
        })

        toast.promise(patchPromise, {
            pending: 'Updating Lead...',
            success: 'Lead Updated',
            error: 'Error Updating Lead'
        })

        return patchPromise
    }, {
        onSuccess: (data) => {
            queryClient.setQueryData(['lead', leadId], data)
            queryClient.invalidateQueries(['lead', leadId, 'activities']).then(
                () => {
                    toggle()
                }
            )
        }
    })


    const hasChanges = updates.length > 0 || !isArrayEqual(selectedProperties, leadData?.property_groups || [])
    const canSave = hasChanges && remarks.length > 0 && !mutation.isLoading && !isLoading

    const hasUnlockableTags = selectedTags.some(tagFormData => {
        return tagFormData.can_unlock && tagFormData.tag_data.is_permanent
    })

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row className="border-bottom">
                <Col>
                    <h3>Choose an Update</h3>
                </Col>
                {
                    hasUnlockableTags && <Col className="col-auto">
                        <FormGroup switch>
                            <Input
                                checked={unlockPermanentTags}
                                onClick={() => {
                                    setUnlockPermanentTags(!unlockPermanentTags)
                                }}
                                type="switch" role="switch"
                            />
                            <Label check>Unlock Permanent Tags</Label>
                        </FormGroup>
                    </Col>
                }

            </Row>

            {
                isLoading ?
                    <>
                        <Spinner/>
                    </> :
                    <>
                        <Row>
                            <Col className="mx-2">
                                <h4>Property Groups</h4>
                                <PropertyGroupSelectorAsTag
                                    isMulti={true}
                                    propertyGroups={selectedProperties}
                                    setPropertyGroups={setSelectedProperties}
                                    showNotSelected={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-2">
                                {
                                    tagGroupData?.map(row => {
                                        return <TagGroupTagSelector
                                            key={row.id}
                                            tagGroup={row}
                                            selectedTags={selectedTags}
                                            setSelectedTags={setSelectedTags}
                                            showPermanentIcon={true}
                                            unlockPermanentTags={unlockPermanentTags}
                                        />
                                    })
                                }
                            </Col>
                        </Row>
                    </>
            }

        </ModalBody>
        <ModalFooter>
            <Row className="border-1 w-100">
                <Col>
                    <Label className={"text-muted small lineheight-1"}>Remarks *</Label>
                    <Input
                        type="textarea"
                        placeholder="Enter reason for this update"
                        value={remarks}
                        onChange={e => {
                            setRemarks(e.target.value)
                        }}/>
                </Col>
            </Row>
            {
                (!hasChanges && remarks.length > 0) && <span className="text-danger">No changes detected.</span>
            }
            {
                (hasChanges && remarks.length === 0) && <span className="text-danger">Remarks is required.</span>
            }
            <Button color="primary" className="ms-4" onClick={() => {
                mutation.mutate({
                    property_groups: selectedProperties,
                    form_tags: updates,
                    remarks: remarks
                })
            }} disabled={!canSave}>
                SAVE UPDATE
            </Button>
        </ModalFooter>
    </Modal>
}