import React from "react";
import InfoCards from "../../../components/charts/infoCards";
import {Col, Row} from "reactstrap";

export default function Summary({filters}) {
    return <>
        <InfoCards data={[
            {label: 'TOTAL BROKERS', color: '#0169a6', value: 125},
            {label: 'TOTAL PROPERTY GROUPS', color: '#967126', value: 32}
        ]}/>

        <Row className="text-center">
            <Col>
                ACTIVE SALESPEOPLE: 698
            </Col>
            <Col>
                PROPERTY LISTINGS: 1,095
            </Col>
        </Row>
    </>
}