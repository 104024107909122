import React from "react";
import {PermissionsContext} from "../contexts";

import {useMyPermissions} from "../hooks";


export default function PermissionProvider({children}) {
    const {data} = useMyPermissions()

    return <PermissionsContext.Provider value={{
        permissions: data?.permissions || []
    }}>
        {children}
    </PermissionsContext.Provider>
}