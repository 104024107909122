import {Resource, useLoggedInApi} from "../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useContext, useState} from "react";
import {PermissionsContext} from "./contexts";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {dictChangedValues, dictEqualShallow} from "./util";
import {clearAuthTokens, getRefreshToken} from "axios-jwt";
import {useCookies} from "react-cookie";
import {v4 as uuidv4} from "uuid";
import {SESSION_OPTIONS} from "../constants";

export const usePropertyGroups = ({useQueryOptions, params}) => {
    const api = useLoggedInApi()

    return useQuery(['property-groups', params], () => {
        const propertyGroupResource = api.resource('properties/groups')
        return propertyGroupResource.list({
            params: params || {}
        }).then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        ...useQueryOptions
    })
}
export const useLeadForm = () => {
    const api = useLoggedInApi()

    return useQuery(['form', 'lead'], () => {
        const formResource = api.resource('form')
        return formResource.getItem('lead').then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
    })
}
export const useTagGroups = ({useQueryOptions}) => {
    const api = useLoggedInApi()

    return useQuery(['tagGroup'], () => {
        const tagsGroupResource = api.resource('tag/group')
        return tagsGroupResource.list().then(response => {
            return response.data
        })
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        ...useQueryOptions
    })
}
export const useLead = (leadId, fetchOptions = {}, useQueryOptions = {}) => {
    const api = useLoggedInApi()
    const enabledFromOptions = useQueryOptions?.hasOwnProperty('enabled') ? useQueryOptions.enabled : true
    return useQuery(['lead', leadId], () => {
        const leadResource = api.resource('leads')
        return leadResource.getItem(leadId, {
            ...fetchOptions
        }).then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        ...useQueryOptions,
        enabled: !!leadId && enabledFromOptions
    })
}
export const useLeads = (params, useQueryOptions) => {
    const api = useLoggedInApi()
    // delete params with undefined values
    const newParams = Object.keys(params).reduce((acc, key) => {
        if (params[key] !== undefined) {
            acc[key] = params[key]
        }
        return acc
    }, {})
    return useQuery(['table', 'leads', newParams], () => {
        const leadResource = api.resource('leads')
        return leadResource.list({
            params: newParams
        }).then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        ...useQueryOptions
    })
}
export const useFindDuplicateLeads = (params) => {
    // params = {type: 'email_address', value: 'test'}
    const queryParams = {page_size: 20}
    if (params) {
        queryParams[params.type] = params.value
    }

    return useLeads(queryParams, {enabled: Object.keys(queryParams).length > 0})
}
export const useMyPermissions = () => {
    const api = useLoggedInApi()

    return useQuery(['my-permissions'], () => {
        const meResource = api.resource('me')
        return meResource.getItem('permissions').then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        keepPreviousData: true
    })
}
export const usePermissionContextCheck = (requiredPermissions, hasAll = true) => {
    const _requiredPermissions = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions]

    const {permissions} = useContext(PermissionsContext)
    if (hasAll) {
        return _requiredPermissions.every(permission => permissions.includes(permission))
    } else {
        return _requiredPermissions.some(permission => permissions.includes(permission))
    }
}
export const DEFAULT_EMPLOYEE_PROFILE = {
    "is_active": true,
    "active_group_employee_id": "",
    "profile": {
        "first_name": "",
        "last_name": "",
        "middle_name": "",
        "ext_name": "",
        "dob": null,
        "place_of_birth": "",
        "gender": "",
        "civil_status": "",
        "citizenship": "",
        "tin_number": "",
        "address": "",
        "phone_number": "",
        "mobile_number": "",
        "email_address": "",
        "last_login": null,
        "profile_photo_url": "http://localhost:8000/media/profile/abstract-user-flat-4.png"
    },
    "user": {
        "email": "",
        "first_name": "",
        "last_name": ""
    }
}
export const DEFAULT_BROKER_PROFILE = {
    "is_active": true,
    "licenses": "",
    "url_path": "",
    "public_profile_description": "",
    "active_group_seller_id": "",
    "is_group": true,
    "is_broker": true,
    "group_name": "",
    "profile": {
        "middle_name": "",
        "ext_name": "",
        "dob": null,
        "place_of_birth": "",
        "gender": "",
        "civil_status": "",
        "citizenship": "",
        "tin_number": "",
        "address": "",
        "phone_number": "",
        "mobile_number": "",
        "position": ""
    },
    "user": {
        "email": "",
        "first_name": "",
        "last_name": ""
    },
    remove_added_property_groups: false
}
export const DEFAULT_SALESPERSON_PROFILE = {
    "is_active": true,
    "licenses": "",
    "url_path": "",
    "public_profile_description": "",
    "active_group_seller_id": "",
    "is_group": false,
    "is_broker": false,
    "affiliation": '',
    "profile": {
        "middle_name": "",
        "ext_name": "",
        "dob": null,
        "place_of_birth": "",
        "gender": "",
        "civil_status": "",
        "citizenship": "",
        "tin_number": "",
        "address": "",
        "phone_number": "",
        "mobile_number": "",
        "position": ""
    },
    "user": {
        "email": "",
        "first_name": "",
        "last_name": ""
    }
}

export function useProfileEdit(
    {
        profileId,
        isMe,
        resourceName,
        defaultData,
        navigateToOnCreate,
        navigateToOnFailedLoad
    }
) {
    const navigate = useNavigate()
    const {resetProfile} = useResetProfileData(resourceName)

    const [data, setData] = useState({...defaultData})
    const [profilePhoto, setProfilePhoto] = useState('')

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const isNew = !profileId

    const processResponseData = responseData => {
        const profilePhotoUrl = responseData.profile.profile_photo_url
        delete responseData['profile_photo_url']
        setData({...data, ...responseData})
        setProfilePhoto(profilePhotoUrl)
    }

    const result = useQuery([resourceName, profileId?.toString()], () => {
        const profileApi = api.resource(resourceName)
        if (resourceName === 'my-employee-profile') {
            return profileApi.list().then(response => response.data)
        } else {
            return profileApi.getItem(profileId).then(response => response.data)
        }
    }, {
        onSuccess: processResponseData,
        onError: () => {
            toast.error("Cannot load profile information with id " + profileId.toString() + ". Please contact administrator.")
            navigateToOnFailedLoad && navigate(navigateToOnFailedLoad)
        },
        enabled: !!profileId,
        refetchOnWindowFocus: false  // we do not want user's changes to be reset when coming back to the window
    })
    const isLoading = result.isLoading
    const originalData = result.data ? {...result.data} : {...defaultData};

    // reset the flag, this is causing to show you have unsaved changes if not reset
    if (data.hasOwnProperty('remove_added_property_groups')) {
        originalData.remove_added_property_groups = data.remove_added_property_groups
    }

    const hasChanges = !dictEqualShallow(data, originalData)

    const mutation = useMutation(newProfile => {
        const salesProfileApi = api.resource(resourceName)
        if (isNew) {
            const createPromise = salesProfileApi
                .create(newProfile)
                .then(response => {
                    return response.data
                })

            toast.promise(createPromise, {
                success: "Created new profile.",
                pending: "Creating profile...",
                error: "Something went wrong while creating profile. " +
                    "Please try again. " +
                    "If problem persits, please contact administrator."
            });
            return createPromise
        } else {
            const updatePromise = salesProfileApi
                .patchItem(profileId, dictChangedValues(originalData, newProfile))
                .then(response => {
                    return response.data
                })

            toast.promise(updatePromise, {
                success: "Updated profile.",
                pending: "Updating profile...",
                error: "Something went wrong while updating profile. " +
                    "Please try again. " +
                    "If problem persits, please contact administrator."
            });
            return updatePromise
        }
    }, {
        onSuccess: (data) => {
            resetProfile(data)
            processResponseData(data)
            queryClient.setQueryData([resourceName, data.id], data)
            if (isMe) {
                const currentRefreshToken = getRefreshToken();
                queryClient.setQueryData(['my-employee-profile', currentRefreshToken], data)
            }
            if (isNew) {
                navigate(navigateToOnCreate + data.id.toString());
            }
        }
    })
    const isSaving = mutation.isLoading

    const save = () => {
        return mutation.mutate(data);
    }

    const saveAsync = () => {
        return mutation.mutateAsync(data)
    }

    // TODO: handle field errors

    return [isLoading, isSaving, isNew, data, originalData, profilePhoto, hasChanges, save, setData, mutation, saveAsync]
}

export function useResetProfileData(resourceName) {
    const queryClient = useQueryClient()
    const resetProfile = (data) => {
        console.log("resetting resourceName", resourceName)
        queryClient.invalidateQueries(['table', resourceName])
        queryClient.invalidateQueries(['broker-groups'])
        queryClient.invalidateQueries([resourceName, 'bulk-profile-check'])
        if (data) {
            queryClient.setQueryData([resourceName, data.id.toString()], data)
        }
    }

    return {resetProfile}
}

export function useProfileOptions({getEmployeePosition}) {

    const defaultOptions = {
        "position": [],
        "citizenship": [],
        "ewt_rate": [],
        "vat_rate": [],
        "employee_position": []
    }

    const api = useLoggedInApi()
    const result = useQuery('profile-options', () => {
        const optionApi = api.resource('option')
        return optionApi.list().then(response => {
            return response.data.reduce((acc, row) => {
                acc[row['field_name']] = row['choices']
                return acc
            }, {})
        })
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
    })
    const employeeResult = useQuery('employee-options', () => {
        const employeeApi = api.resource('employee')
        return employeeApi.getItem('options').then(response => {
            return response.data.reduce((acc, row) => {
                acc[row['field_name']] = row['choices']
                return acc
            }, {})
        })
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: getEmployeePosition
    })

    const options = {
        ...defaultOptions,
        ...(result.data ? result.data : {}),
        ...(employeeResult.data ? employeeResult.data : {})
    }

    return {isLoading: result.isLoading || employeeResult.isLoading, options: options}
}

export function useProfileFetch({isLoggedIn, setIsLoggedIn}) {
    const defaultData = {
        "is_superuser": false,
        "is_active": true,
        "active_group_employee_id": "",
        "profile": {
            "id": null,
            "first_name": "",
            "last_name": "",
            "middle_name": "",
            "ext_name": "",
            "dob": null,
            "place_of_birth": "",
            "gender": "",
            "civil_status": "",
            "citizenship": "",
            "tin_number": "",
            "address": "",
            "phone_number": "",
            "mobile_number": "",
            "email_address": "",
            "last_login": "",
            "position": "",
            "name": "",
            "profile_photo_url": ""
        },
        "user": {
            "email": "",
            "first_name": "",
            "last_name": ""
        },
        "access_display": []
    }
    const currentRefreshToken = getRefreshToken();
    const profileResult = useQuery(['my-employee-profile', currentRefreshToken], () => {
        const myProfileApi = new Resource('my-employee-profile')
        return myProfileApi.list().then(response => response.data)
    }, {
        staleTime: 15 * 60 * 1000,
        onError: err => {
            if (err.response?.status === 404) {
                toast.error("You do not have enough permission to view this dashboard.")
                setIsLoggedIn(false);
                clearAuthTokens()
            }
        },
        enabled: isLoggedIn,
        retry: (failureCount, error) => {
            if (error.response?.status === 404) {
                return false
            } else if (failureCount < 3) {
                return true
            }
            return false
        }
    })

    return {
        isLoading: profileResult.isLoading,
        profile: profileResult.isSuccess ? profileResult.data : defaultData,
        fetchProfile: () => profileResult.refetch()
    }
}

export function useFieldErrorChecking() {
    const [fieldsWithErrors, setFieldsWithErrors] = useState([])

    const updateErrorStatus = (fieldName, hasError) => {
        if (hasError && fieldsWithErrors.indexOf(fieldName) === -1)
            setFieldsWithErrors([...fieldsWithErrors, fieldName])
        if (!hasError && fieldsWithErrors.indexOf(fieldName) > -1)
            setFieldsWithErrors([...fieldsWithErrors.filter(f => f !== fieldName)])
    }

    const hasError = fieldsWithErrors.length > 0;
    const clearErrors = () => setFieldsWithErrors([])
    return [updateErrorStatus, hasError, fieldsWithErrors, clearErrors]
}

const sortByLabel = (a, b) => {
    if (a.label > b.label) return 1
    if (a.label < b.label) return -1
    return 0
}

export function useBrokers({params, queryOptions}) {
    const api = useLoggedInApi()
    return useQuery(['table', 'broker', params], () => {
        const brokerResource = api.resource('broker')
        return brokerResource.list({
            params: params
        }).then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        ...queryOptions
    })
}

export function useSalesPerson({salesPersonId, queryOptions = {}}) {
    const api = useLoggedInApi()
    return useQuery(['table', 'salesperson', salesPersonId], () => {
        const salesPersonResource = api.resource('salesperson')
        return salesPersonResource.getItem(salesPersonId).then(response => response.data)
    }, {
        ...queryOptions,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: !!salesPersonId && (queryOptions.hasOwnProperty('enabled') ? queryOptions.enabled : true)
    })
}

export function useBrokerPerson({brokerId, queryOptions = {}}) {
    const api = useLoggedInApi()
    return useQuery(['table', 'broker', brokerId], () => {
        const brokerResource = api.resource('broker')
        return brokerResource.getItem(brokerId).then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: !!brokerId && (queryOptions.hasOwnProperty('enabled') ? queryOptions.enabled : true)
    })
}

export function useBrokerGroup({brokerGroupId, queryOptions = {}}) {
    const api = useLoggedInApi()
    return useQuery(['table', 'broker', 'group', brokerGroupId], () => {
        const brokerGroupResource = api.resource('salesperson/broker_groups')
        return brokerGroupResource.list({
            params: {
                id: brokerGroupId
            }
        }).then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        enabled: !!brokerGroupId && (queryOptions.hasOwnProperty('enabled') ? queryOptions.enabled : true)
    })
}

export function useSalesPersonOptions() {

    const api = useLoggedInApi()

    const params = {
        page_size: 1000
    }


    const brokersListResource = useQuery(['table', 'broker'], () => {
        const brokersResource = api.resource('broker')
        return brokersResource.list({
            params: params
        }).then(response => response.data.results)
    }, {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000
    })
    const brokerOptions = brokersListResource.isSuccess ? brokersListResource.data.map(row => ({
        label: row.name, value: row.id, groupName: row.group_name
    })).sort((a, b) => {
        if (a.groupName > b.groupName) return 1
        if (a.groupName < b.groupName) return -1
        return 0
    }) : []
    const salesPersonListResource = useQuery(['table', 'salesperson'], () => {
        const salesPersonResource = api.resource('salesperson')
        return salesPersonResource.list({
            params: params
        }).then(response => response.data.results)
    }, {
        staleTime: 5 * 60 * 1000
    })
    const salesPersonOptions = salesPersonListResource.isSuccess ? salesPersonListResource.data.map(row => ({
        label: row.name, value: row.id, parentId: row.affiliation_owner_id
    })).sort(sortByLabel) : []

    const groupedOptions = brokerOptions.map(row => ({
        label: row.groupName,
        options: [
            {
                ...row,
                label: row.label + " (Broker)"
            },
            ...salesPersonOptions.filter(o => o.parentId === row.value)
        ]
    }))

    const ungroupedOptions = [
        ...brokerOptions,
        ...salesPersonOptions,
    ].sort(sortByLabel);

    return {
        ungroupedOptions,
        groupedOptions,
        isLoading: brokersListResource.isLoading || salesPersonListResource.isLoading
    }
}

export const useSessionCookie = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    if (!cookies.session) {
        console.log("setting session")
        setCookie(
            'session',
            uuidv4(),
            SESSION_OPTIONS
        )
    }
    return cookies.session
}
export const useGroupPermissions = resourceName => {
    const api = useLoggedInApi()
    const result = useQuery([resourceName, 'permissions'], () => {
        const salesProfileApi = api.resource(resourceName)
        return salesProfileApi.getItem('permissions').then(response => {
            return response.data
        })
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
    })

    return {
        isLoading: result.isLoading,
        permissions: result.isSuccess ? result.data : []
    }
}


export const useLeadCountPerPeriod = ({queryOptions = {}}) => {
    const api = useLoggedInApi()
    return useQuery(['table', 'leads', 'lead-per-period'], () => {
        const salesPeopleResource = api.resource('leads')
        return salesPeopleResource.getItem('lead_per_period').then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        ...queryOptions
    })
}


export const useEmployeeManagers = ({params= {}, queryOptions = {}}) => {
    const api = useLoggedInApi()
    return useQuery(['employee-managers'], () => {
        const employeeResource = api.resource('employee')
        return employeeResource.getItem('managers', {
            params: params
        }).then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        ...queryOptions
    })
}


export const useBrokerGroups = ({params={}, queryOptions = {}}) => {
    const api = useLoggedInApi()
    return useQuery(['broker-groups'], () => {
        const salesPeopleResource = api.resource('salesperson')
        return salesPeopleResource.get('broker_groups/').then(response => {
            return response.data
        })
    }, {
        enabled: queryOptions.enabled !== undefined ? queryOptions.enabled : true,
        onError: () => {
            toast.error("Something went wrong. Please refresh. If problem persists, please contact administrator.")
        },
        staleTime: 60 * 1000  // one minute
    })
}

