import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TagDisplay from "../../../../../../commonComponents/tagDisplay";

import {
    PROPERTY_GROUP_SOURCE_MODEL_NAME,
    PROPERTY_SOURCE_MODEL_NAME,
    TAG_SOURCE_DISPLAY
} from "../../../../../../commonComponents/constants";


export default function SelectExtraInfoSourceModal({isOpen, toggle, sourceData, setSourceData}) {
    const [localSourceData, setLocalSourceData] = React.useState(sourceData)
    React.useEffect(() => {
        setLocalSourceData(sourceData)
    }, [sourceData])



    const canSave = !!localSourceData.multiple_source;

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size=""
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody

            style={{minHeight: "20em"}}>
            <Row>
                <Col>
                    <h5>Set Source for Extra Info</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>
                        To allow duplicate tags, you must set where to get extra information for the tag.
                        This extra information will be displayed along with the tag to make it unique.
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TagDisplay
                        onClick={() => {
                            setLocalSourceData({...localSourceData, multiple_source: 'text'})
                        }}
                        color={localSourceData.multiple_source === 'text' ? 'green' : 'light-gray'}
                    >
                        TEXT INPUT
                    </TagDisplay>
                    <TagDisplay
                        onClick={() => {
                            setLocalSourceData({
                                ...localSourceData,
                                multiple_source: 'object',
                                source_model_name: PROPERTY_GROUP_SOURCE_MODEL_NAME,
                                source_field: 'unique_property_group_id'
                            })
                        }}
                        color={(localSourceData.multiple_source === 'object' && localSourceData.source_model_name === PROPERTY_GROUP_SOURCE_MODEL_NAME) ? 'green' : 'light-gray'}
                    >
                        {TAG_SOURCE_DISPLAY[PROPERTY_GROUP_SOURCE_MODEL_NAME].toUpperCase()}
                    </TagDisplay>

                    <TagDisplay
                        onClick={() => {
                            setLocalSourceData({
                                ...localSourceData,
                                multiple_source: 'object',
                                source_model_name: PROPERTY_SOURCE_MODEL_NAME,
                                source_field: 'unique_property_id',
                            })
                        }}
                        color={(localSourceData.multiple_source === 'object' && localSourceData.source_model_name === PROPERTY_SOURCE_MODEL_NAME) ? 'green' : 'light-gray'}
                    >
                        {TAG_SOURCE_DISPLAY[PROPERTY_SOURCE_MODEL_NAME].toUpperCase()}
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {
                        localSourceData.multiple_source === "text" && <>
                            <small>
                                Text will be entered manually each time the tag is added.
                            </small>
                        </>
                    }
                    {
                        (localSourceData.multiple_source === 'object' && localSourceData.source_model_name === 'PropertyGroup') && <>
                            <small>
                                The tag will be unique based on the property group selected.
                            </small>
                        </>
                    }
                    {
                        (localSourceData.multiple_source === 'object' && localSourceData.source_model_name === 'Property') && <>
                            <small>
                                The tag will be unique based on the property listing selected.
                            </small>
                        </>
                    }
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Row className="mt-3">
                <Col className="text-end">
                    <Button
                        disabled={!canSave}
                        color="primary"
                        onClick={() => {
                            setSourceData(localSourceData)
                            toggle()
                        }}>
                        Save
                    </Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
}