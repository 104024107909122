import React, {useState} from "react";
import {useLoggedInApi} from "../api";
import {REACT_SELECT_STYLES} from "../constants";
import Select from "react-select";
import {useQuery} from "react-query";
import * as PropTypes from 'prop-types';
import {Badge} from "reactstrap";


function PropertySelector(props) {
    const [isSaving, setIsSaving] = useState(false)
    const api = useLoggedInApi()

    const params = {
        property_group_id: props.propertyGroupId,
        is_available: true
    }

    if (props.showAllProperties) {
        delete params.is_available
    }

    const propertiesQuery = useQuery(['properties', props.propertyGroupId], () => {
        const propertyGroupResource = api.resource('properties')
        return propertyGroupResource.list({
            params: params
        }).then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        enabled: !!props.propertyGroupId
    })

    const suggestedPropertyIds = props.suggestedPropertyIds || []
    const options = propertiesQuery.isSuccess ? [
        ...propertiesQuery.data.filter(row => {
            return !props.ignoredPropertyIds || props.ignoredPropertyIds.indexOf(row.id) === -1
        }).map(row => {
            return {
                label: "PH" + row.phase + " BLK" + row.block + " Lot" + row.lot,
                value: row.id,
                data: row,
                suggested: suggestedPropertyIds.indexOf(row.id) > -1,
                disabled: props.templateRequired ? !row.has_template : false
            }
        }).sort((a,b) => {
            // sort by in suggestedPropertyIds, is_available, has_template, label
            if (a.suggested && !b.suggested) {
                return -1
            } else if (!a.suggested && b.suggested) {
                return 1
            }
            if (a.data.is_available && !b.data.is_available) {
                return -1
            } else if (!a.data.is_available && b.data.is_available) {
                return 1
            }
            if (a.data.has_template && !b.data.has_template) {
                return -1
            } else if (!a.data.has_template && b.data.has_template) {
                return 1
            }
            if (a.label < b.label) {
                return -1
            } else if (a.label > b.label) {
                return 1
            }
            return 0
        })
    ] : []

    const selectedOptions = props.isMulti ?
        (
            props.properties?.length > 0 ? [
                ...options.filter(o => props.properties.indexOf(o.value) > -1)
            ] : []
        ) :
        (
            !!props.properties ? [
                ...options.filter(o => props.properties === o.value)
            ][0] : null
        )

    if (props.readOnly) {
        if (selectedOptions.length === 0) {
            return <small><i>No property group selected</i></small>
        }
        return <strong>
            <small>
                {selectedOptions.map(o => o.label).join(", ")}
            </small>
        </strong>
    }

    let placeholderText = "Choose available property listing"
    if (!props.propertyGroupId) {
        placeholderText = "Select property group first"
    } else if (!propertiesQuery.isLoading && options.length === 0) {
        placeholderText = "No available property listing"
    }

    return <Select
        {...props}
        placeholder={placeholderText}
        isMulti={props.isMulti}
        styles={REACT_SELECT_STYLES}
        value={selectedOptions}
        options={options}
        isOptionDisabled={option => option.disabled}
        isDisabled={isSaving || options.length === 0 || !props.propertyGroupId}
        isLoading={propertiesQuery.isLoading}
        onChange={
            newOptions => {
                if (props.setProperties) {
                    if (props.isSetSaving) {
                        setIsSaving(true)
                        const setValuePromise = props.setProperties(
                            props.isMulti ? [...newOptions.map(o => o.value)] : newOptions.value
                        )
                        setValuePromise.finally(() => {
                            setIsSaving(false)
                        })
                    } else {
                        props.setProperties(
                            props.isMulti ? [...newOptions.map(o => o.value)] : newOptions.value
                        )
                    }
                }
            }
        }
        filterOption={(option, rawInput) => {
            const filterInput = rawInput.toLowerCase()
            const searchableInput = (option.data.data.name + " " + option.data.data.address).toLowerCase()

            return searchableInput.includes(filterInput)
        }}
        formatOptionLabel={option => {
            const badges = []
            if (option.data.is_available) {
                badges.push(<Badge color="success" className="rounded me-1">Available</Badge>)
            } else {
                badges.push(<Badge color="danger" className="rounded me-1">Not Available</Badge>)
            }
            if (!option.data.has_template) {
                badges.push(<Badge color="danger" className="rounded me-1">No Template</Badge>)
            }
            if (option.suggested) {
                badges.push(<Badge color="info" className="rounded me-1">Suggested</Badge>)
            }

            return <>
                {option.label} {badges.length > 0 && <sup>{badges}</sup>}
            </>

        }}
    />

}

PropertySelector.propTypes = {
    propertyGroupId: PropTypes.number,
    properties: PropTypes.arrayOf(PropTypes.number),
    setProperties: PropTypes.func,
    isSetSaving: PropTypes.bool,
    readOnly: PropTypes.bool,
    isMulti: PropTypes.bool,
    showAllProperties: PropTypes.bool
}

export default PropertySelector