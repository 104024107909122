import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import FileSelector from "./fileSelector";
import Review from "./review";
import Commit from "./commit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";


export default function BulkUpload({isBroker}) {
    const [fileName, setFileName] = useState('')
    const [fileData, setFileData] = useState([])
    const [isFileRead, setIsFileRead] = useState(false)

    const [processedData, setProcessedData] = useState([])
    const [isDataProcessed, setIsDataProcessed] = useState(false)


    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Brokers {isBroker ? "" : "> Salesperson"} - Bulk Upload</h3>
            </Col>
        </Row>

        {
            !isFileRead && <FileSelector
                setFileData={(data, fileName) => {
                    setFileData(data)
                    setIsFileRead(true)
                    setFileName(fileName)
                }}
                header={
                    <Row className={"mt-4"}>
                        <Col>
                            <a href={"/docs/ActiveGroup Template - Bulk " + (isBroker ? "Brokers" : "Salespeople") + ".xlsx"} target={"_blank"}>
                                <FontAwesomeIcon icon={faDownload}/> Download the excel template for bulk listing creation
                            </a>
                        </Col>
                    </Row>
                }
            />
        }

        {
            (isFileRead && !isDataProcessed) && <Review
                fileData={fileData}
                fileName={fileName}
                isFileRead={isFileRead}
                onDataProcessed={data => {
                    setProcessedData(data)
                    setIsDataProcessed(true)
                }}
                previousStep={() => {
                    setIsFileRead(false)
                    setFileName('')
                    setFileData([])
                }}
                isBroker={isBroker}
            />
        }

        {
            (isDataProcessed) &&
            <Commit
                processedData={processedData.filter(row => row.status === 'changed' || row.status === 'new')}
                previousStep={() => {
                    setIsDataProcessed(false)
                }}
                isBroker={isBroker}
            />
        }

        <div className="clearfix mb-5"/>
    </>
}