import React from "react";
import {Col, Row} from "reactstrap";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import RegistrationDetail from "./registrationDetail";


export default function Registration({}) {
    return <>

        <Row>
            <Col>
                <h3 className="page-header pb-2">New Registrations</h3>
            </Col>
        </Row>

        <Row>
            <Col>
                <AdvancedTable
                    friendlyResourceName={'New Registrations'}
                    apiResourceName={'registration'}
                    columnDefinition={[
                        {
                            name: 'Name',
                            selector: row => {
                                return row.data.first_name + " " + row.data.last_name
                            }
                        },
                        {
                            name: 'Group Name',
                            selector: row => {
                                return row.data.broker_affiliation
                            },
                            format: row => {
                                return <>
                                    {row.data.broker_affiliation}{" "}
                                    <FontAwesomeIcon
                                        icon={row.detected_group_id ? faCheckCircle : faPlusCircle}
                                        className={row.detected_group_id ? "text-success" : "text-info"}
                                    />
                                </>
                            }
                        },
                        {
                            name: 'Registration Date',
                            selector: row => {
                                return row.datetime_added
                            },
                            format: row => {
                                return moment(row.datetime_added).format('LLL')
                            },
                            sortable: true,
                            sortField: 'datetime_added'
                        }
                    ]}
                    dataTableProps={{
                        expandableRows: true,
                        expandableRowsComponent: RegistrationDetail

                    }}
                />
            </Col>
        </Row>
    </>
}