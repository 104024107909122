import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {Button, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


function MediaGalleryItem(props) {
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    return <Col
        md={6} sm={12}
        className="p-3 align-items-center justify-content-center d-flex media-gallery-item"
        key={props.media.id}
        onBlur={() => setDeleteConfirm(false)}
    >
        <img src={props.media.photo} className="img-fluid img" alt={"Media Gallery Item"}/>

        <div style={{
            position: "absolute"
        }} className="actions">

            <Button color="text" size="lg" onClick={() => {
                if(deleteConfirm) {
                    props.onDelete()
                } else {
                    setDeleteConfirm(true)
                }
            }}>
                <FontAwesomeIcon icon={faTrash}/>
                {deleteConfirm && <>{" "}Confirm Delete?</>}
            </Button>
        </div>
    </Col>
}

MediaGalleryItem.propTypes = {
    media: PropTypes.shape({
        id: PropTypes.number.isRequired,
        photo: PropTypes.string.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired
}

export default MediaGalleryItem;