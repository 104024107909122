import React from "react";
import Select from "react-select";
import {Col, Row} from "reactstrap";
import moment from "moment";
import DatePicker from "react-date-picker";

export default function InputRangeSelector({fromDate, toDate, setFromDate, setToDate}) {
    const options = [
        {
            group: 'Period', value: 'today', label: 'Today',
            dateValue: {f: moment().startOf('day').toDate(), t: moment().endOf('day').toDate()}
        },
        {
            group: 'Period', value: 'this-week', label: 'This Week',
            dateValue: {f: moment().startOf('week').toDate(), t: moment().endOf('week').toDate()}
        },
        {
            group: 'Period', value: 'this-month', label: 'This Month',
            dateValue: {f: moment().startOf('month').toDate(), t: moment().endOf('month').toDate()}
        },
        {
            group: 'Period', value: 'this-year', label: 'This Year',
            dateValue: {f: moment().startOf('year').toDate(), t: moment().endOf('year').toDate()}
        },
        {
            group: 'Period', value: 'all-time', label: 'All Time',
            dateValue: {f: null, t: null}
        },

        {
            group: 'Relative', value: 'last-7-days', label: 'Last 7 Days',
            dateValue: {f: moment().subtract(7, "days").startOf('day').toDate(), t: moment().endOf('day').toDate()}
        },
        {
            group: 'Relative', value: 'last-30-days', label: 'Last 30 Days',
            dateValue: {f: moment().subtract(30, "days").startOf('day').toDate(), t: moment().endOf('day').toDate()}
        },
        {
            group: 'Relative', value: 'last-month', label: 'Last Month',
            dateValue: {
                f: moment().subtract(1, "month").startOf('month').toDate(),
                t: moment().subtract(1, "month").endOf('month').toDate()
            }
        },
        {
            group: 'Relative', value: 'last-year', label: 'Last Year',
            dateValue: {
                f: moment().subtract(1, "year").startOf('year').toDate(),
                t: moment().subtract(1, "year").endOf('year').toDate()
            }
        }
    ]

    const selectedOption = options.find((option) => {
        return option.dateValue.f === fromDate && option.dateValue.t === toDate
    })

    const groupedOptions = Object.values(options.reduce((acc, option) => {
        if (!acc.hasOwnProperty(option.group)) {
            acc[option.group] = {label: option.group, options: []}
        }
        acc[option.group].options.push(option)
        return acc
    }, {}));

    return <Row>
        <Col md={4} sm={12}>
            Period
            <Select
                value={selectedOption || null}
                options={groupedOptions}
                onChange={option => {
                    if (option) {
                        setFromDate(option.dateValue.f)
                        setToDate(option.dateValue.t)
                    } else {
                        setFromDate(null)
                        setToDate(null)
                    }
                }}
                isClearable={true}
                placeholder={(fromDate || toDate) ? "Custom period" : "Select period..."}
            />
        </Col>
        <Col md={4} sm={6}>
            Start Date<br/>
            <DatePicker
                className="react-date-picker flex-fill py-1"
                style={{height: '5em'}}
                maxDate={new Date()}
                // disabled={isSaving}
                value={fromDate}
                onChange={newDate => {
                    // const newDateString = newDate && moment(newDate).format("YYYY-MM-DD");
                    setFromDate(newDate)
                }}/>
        </Col>
        <Col md={4} sm={6}>
            End Date<br/>
            <DatePicker
                className="react-date-picker flex-fill py-1"
                maxDate={new Date()}
                // disabled={isSaving}
                value={toDate}
                onChange={newDate => {
                    // const newDateString = newDate && moment(newDate).format("YYYY-MM-DD");
                    if (newDate) {
                        const d = moment(newDate).endOf('day').toDate();
                        setToDate(d)
                    } else {
                        setToDate(newDate)
                    }
                }}/>
        </Col>
    </Row>
}