import React, {useContext} from "react";
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import LoginPage from "./public/loginPage";
import BrokerManagement from "./private/brokerManagement";
import ResetPassword from "./common/resetPassword";
import MyProfile from "./private/brokerManagement/myProfile";
import {IsLoggedInContext} from "../commonComponents/contexts";
import Dashboard from "./private/dashboard";
import MainMenu from "./private/mainMenu";
import BrokerManagementRoutes from "./private/brokerManagement/brokerManagementRoutes";
import {default as PropertyManagementRoutes} from "./private/propertyManagement/routes";
import UserAdministrationRoutes from "./private/userAdministration/userAdministrationRoutes";
import {default as MediaKitRoutes} from "./private/mediaKit/routes";
import {default as HelpManagementRoutes} from "./private/helpManagement/routes";
import {default as TagAdminRoutes} from "./private/tagAdmin/routes";
import {default as FormAdminRoutes} from "./private/formAdmin/routes";
import SystemOption from "./private/systemOption";
import SummaryReports from "./private/summaryReports";


export default function AppRoutes(props) {

    const {isLoggedIn} = useContext(IsLoggedInContext)
    
    const defaultContent = isLoggedIn ? <BrokerManagement/> : <LoginPage api={props.api}/>;

    const loggedInRoutes = <>
        <Route path='/' element={<Dashboard/>}>
            <Route index element={<MainMenu/>}/>
            {/* route path='broker-management' */}
            {BrokerManagementRoutes}
            {PropertyManagementRoutes}
            {UserAdministrationRoutes}
            {MediaKitRoutes}
            {HelpManagementRoutes}
            {TagAdminRoutes}
            {FormAdminRoutes}

            <Route path='system-options' element={<SystemOption/>}/>
            <Route path='summary-reports' element={<SummaryReports/>}/>

            <Route path="my-profile" element={<MyProfile/>}/>
            <Route path="*" element={<Navigate to="/" />} />
        </Route>
    </>
    const loggedOutRoutes = <>
        <Route path="*" element={<LoginPage api={props.api}/>}/>
    </>

    return <Router>
        <Routes>
            {isLoggedIn ? loggedInRoutes : loggedOutRoutes}
            <Route path="forgot-password" element={<ResetPassword/>}/>

        </Routes>
    </Router>
}
