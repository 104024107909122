import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import InputRangeSelector from "../../../commonComponents/inputRangeSelector";
import InfoCards from "../../../components/charts/infoCards";
import LeadStatusBreakdown from "../../../commonComponents/report/leadStatusBreakdown";
import {default as BrokerReport} from "../brokerManagement/report";
import {default as PropertyReport} from "../propertyManagement/reports"
import Summary from "./summary";

export default function SummaryReports() {
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()

    const filters = {
        'from': fromDate,
        'to': toDate
    }

    return <>
        <Row>
            <Col>
                <h1 className="page-header pb-2">Summary Reports</h1>
            </Col>
        </Row>

        <Row>
            <Col
                className="align-items-end offset-lg-3 offset-md-0 offset-sm-0 small mb-2"
                lg={9} md={12} sm={12}>
                <InputRangeSelector
                    fromDate={fromDate} setFromDate={setFromDate}
                    toDate={toDate} setToDate={setToDate}
                />
            </Col>
        </Row>

        <Summary filters={filters}/>

        <LeadStatusBreakdown filters={filters}/>

        <br/>
        <hr className="my-5"/>
        <br/>

        <BrokerReport filters={filters}/>

        <hr className="my-5"/>
        <br/>

        <PropertyReport filters={filters}/>
    </>
}