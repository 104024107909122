import {dictEqualShallow} from "../../util";
import FileSaver from "file-saver";


export function detectTagFormChanges(selectedTags, originalFormTags) {
    if (!selectedTags || !originalFormTags) {
        return []
    }

    const originalTagById = originalFormTags.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    }, {})

    const newTags = selectedTags.filter(row => row.__isNew && !row._delete).map(row => {
        return {
            ...row,
            id: undefined,
            tag_data: undefined
        }
    })
    const deletedTags = selectedTags.filter(row => row.id && row._delete && !row.__isNew).map(row => {
        return {
            _delete: true,
            id: row.id,
            tag: row.tag
        }
    })

    const updatedTags = selectedTags.filter(row => row.id && !row._delete && !row.__isNew).filter(row => {
        const original = {...originalTagById[row.id]}
        const newTag = {...row}
        delete original.tag_data
        delete newTag.tag_data
        return !dictEqualShallow(original, newTag)
    }).map(row => {
        return {
            ...row,
            tag_data: undefined
        }
    })

    return [
        ...newTags,
        ...updatedTags,
        ...deletedTags
    ]
}


export const processResponseAsDownload = (response) => {
    const contentDisposition = response.headers['content-disposition']
    const startFileNameIndex = contentDisposition.indexOf('"') + 1
    const endFileNameIndex = contentDisposition.lastIndexOf('"');
    const filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);
    const file = new File([response.data], filename, {type: response.headers['content-type']});
    FileSaver.saveAs(file);
}