import React from "react";
import {Col, Row} from "reactstrap";


export default function DisplayInfoRow({label, value}) {
    return <Row>
        <Col className="border border-light px-4 py-2" md={4}>{label}</Col>
        <Col className="border border-light px-4 py-2">
            {value}
        </Col>
    </Row>
}