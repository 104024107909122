import React from "react";
import {Col, Row} from "reactstrap";
import {toTitleCase} from "../../../../../util";


const processRemarksContent = (remarks) => {
    if (remarks.trim().indexOf('[JSON: ') === 0) {
        const indexClosingBracket = remarks.indexOf(']')
        if (indexClosingBracket > 0) {
            const json = remarks.substring(7, indexClosingBracket).trim()
            try {
                console.log('JSON:', json)
                const content = JSON.parse(json)

                const ret = []
                if (content['message']) {
                    ret.push(<span key="message">{content.message}</span>)
                    delete content['message']
                }
                Object.keys(content).forEach(key => {
                    ret.push(<Row key={key}>
                        <Col className="small">
                            <strong>{toTitleCase(key.split('_').join(' '))}:</strong> {content[key]}
                        </Col>
                    </Row>)
                })
                return ret
            } catch (e) {
                console.error('Error parsing JSON', e)
            }
        }
    }
    return remarks
}

export default function Remarks({remarks}) {
    const content = React.useMemo(() => {
        if (!remarks) {
            return remarks
        }
        if (remarks.indexOf('[') === 0) {
            const indexClosingBracket = remarks.indexOf(']')
            if (indexClosingBracket > 0) {
                return {
                    sender: remarks.substring(1, indexClosingBracket),
                    remarks: processRemarksContent(remarks.substring(indexClosingBracket + 1))
                }
            }
        }
        return {
            sender: 'REMARKS',
            remarks: remarks
        }
    }, [remarks])


    if (!remarks) {
        return <Row><Col className="text-center small text-muted">
            <small>
                <i>NO REMARKS</i>
            </small>
        </Col></Row>
    }



    return <Row className="mt-3">
        <Col>
            <strong>{content.sender}:</strong>{" "}
            {content.remarks}
        </Col>
    </Row>
}