import React from "react";
import {Col, Row} from "reactstrap";
import {useParams} from "react-router-dom";
import SalesProfileForm from "../../../../components/salesProfileForm";


export default function EditSalesperson() {
    const params = useParams();
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Salesperson Information</h3>
            </Col>
        </Row>

        <SalesProfileForm
            profileId={params.brokerId}
            isMe={false}/>
    </>
}
