import React, {useEffect, useState} from "react";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import {Col, Row, Spinner} from "reactstrap";
import ChartProgressBar from "../../components/charts/chartProgressBar";
import {
    COLOR_REF,
    REF_DIRECT,
    REF_EMAIL,
    REF_FACEBOOK,
    REF_INSTAGRAM,
    REF_SMS,
    REF_TIKTOK,
    REF_TWITTER,
    REF_YOUTUBE
} from "../../constants";
import {stringToColour} from "../util";
import {useQuery} from "react-query";

const DEFAULT_DATA = {
    [REF_DIRECT]: 0,
    [REF_SMS]: 0,
    [REF_FACEBOOK]: 0,
    [REF_INSTAGRAM]: 0,
    [REF_EMAIL]: 0,
    [REF_TWITTER]: 0,
    [REF_YOUTUBE]: 0,
    [REF_TIKTOK]: 0
}

export default function TrackingTrafficSources(props) {
    const api = useLoggedInApi()

    const query = useQuery(['salesperson', 'tracking_traffic_source_breakdown', props.filters], () => {
        const resource = api.resource('salesperson')
        return resource.getItem('tracking_traffic_source_breakdown', {
            params: props.filters
        }).then(response => response.data)
    })

    const data = query.isSuccess ? query.data : {}


    const customLabels = {
        [REF_SMS]: "SMS/CHAT"
    }

    return <>
        <Row className="mt-5">
            <Col className="pb-2">
                <h5>TRAFFIC SOURCES</h5>
            </Col>
            <Col className="col-auto">
                { query.isLoading && <Spinner/>}
            </Col>
        </Row>

        <div className="border-bottom"/>

        <ChartProgressBar
            data={[
                ...Object.keys(data).map(k => {
                    const label = customLabels.hasOwnProperty(k) ? customLabels[k] : k
                    return {
                        label: !!label ? label.toUpperCase() : <i>UNKNOWN</i>,
                        value: data[k],
                        color: COLOR_REF.hasOwnProperty(k) ? COLOR_REF[k]: stringToColour(k)
                    }
                }).filter(row => !!row.value)
            ]}
        />
    </>
}