import React from "react";
import BrokerManagement from "./index";
import {Route} from "react-router-dom";
import LandingPage from "./landingPage";
import Groups from "./groups";
import Logs from "./logs"
import EditEmployee from "./landingPage/editEmployee";
import NewEmployee from "./landingPage/newEmployee";
import HelpPage from "../../../commonComponents/helpPage";
import {MODULE_CRM_USERS} from "../helpManagement/helpList/constants";
import DisabledUsers from "./disabledUsers";

const UserAdministrationRoutes = <Route exact path="users" element={<BrokerManagement/>}>
    <Route index element={<LandingPage/>}/>
    <Route path="edit/:profileId" element={<EditEmployee/>}/>
    <Route path="new" element={<NewEmployee/>}/>
    <Route path="disabled-users" element={<DisabledUsers/>}/>

    <Route path="help" element={<HelpPage module={MODULE_CRM_USERS} site={"crm"}/>}/>
    <Route path="groups">
        <Route index element={<Groups/>}/>
        {/*<Route path="bulk" element={<BulkUpload isBroker={true}/>}/>*/}
    </Route>
    <Route path="logs">
        <Route index element={<Logs/>}/>
        {/*<Route path="edit/:brokerId" element={<EditSalesperson/>}/>*/}
        {/*<Route path="new" element={<NewSalesperson/>}/>*/}
        {/*<Route path="bulk" element={<BulkUpload isBroker={false}/>}/>*/}
    </Route>
</Route>

export default UserAdministrationRoutes