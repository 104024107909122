import React from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import * as PropTypes from "prop-types"
import EditBasicInformation from "./editBasicInformation";
import EditAdditionalInformation from "./editAdditionalInformation";
import MediaGallery from "./mediaGallery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faCirclePlus, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {useLoggedInApi} from "../../../../../api";
import {dictChangedValues} from "../../../../../commonComponents/util";
import PhotoUpload from "../../../../../components/photoUpload";
import {toast} from "react-toastify";
import Statistics from "./statistics";
import AddPropertyListingButton from "../../properties/detail/addPropertyListingButton";


function Detail(props) {
    const params = useParams();
    const navigate = useNavigate()
    const api = useLoggedInApi();

    const resourceName = 'properties/groups'

    const queryClient = useQueryClient()

    const query = useQuery(['property-group', params.propertyGroupId?.toString()], () => {
        const resource = api.resource(resourceName)
        return resource.getItem(params.propertyGroupId).then(response => {
            return response.data
        })
    }, {
        enabled: !props.isNew && !!params.propertyGroupId,
        refetchOnWindowFocus: false
    })

    const createMutation = useMutation(newData => {
        const resource = api.resource('properties/groups')
        const promise = resource.create(newData).then(response => response.data)
        toast.promise(promise, {
            pending: "Creating new Property Group...",
            error: "Something went wrong. Please contact administrator.",
            success: "Created new Property Group."
        })
        return promise
    }, {
        onSuccess: responseData => {
            navigate('/property-management/groups/edit/' + responseData.id)
            queryClient.invalidateQueries(['table', 'properties/groups'])
            queryClient.setQueryData(['property-group', responseData.id.toString()], responseData)
        }
    })
    const updateMutation = useMutation(newData => {
        const resource = api.resource('properties/groups')
        const promise = resource.patchItem(params.propertyGroupId, dictChangedValues(query.data, newData, true))
            .then(response => response.data)

        toast.promise(promise, {
            pending: "Updating Property Group...",
            error: "Something went wrong. Please contact administrator.",
            success: "Updated property group."
        })
        return promise
    }, {
        onSuccess: responseData => {
            queryClient.invalidateQueries(['table', 'properties/groups'])
            queryClient.setQueryData(['property-group', responseData.id.toString()], responseData)
        }
    })

    const isSaving = createMutation.isLoading || updateMutation.isLoading

    if (!props.isNew && query.isLoading) {
        return <>
            <Row>
                <Col>
                    <h3 className="page-header pb-2">
                        {props.isNew ? "Create Property Group" : "Property Group Details"}
                    </h3>
                </Col>
            </Row>
            <Row className="m-5">
                <Col className="text-center">
                    <Spinner/>
                </Col>
            </Row>
        </>
    }
    if (!props.isNew && query.isError) {
        return <>
            <Row>
                <Col>
                    <h3 className="page-header pb-2">
                        {props.isNew ? "Create Property Group" : "Property Group Details"}
                    </h3>
                </Col>
            </Row>
            <Row className="m-5">
                <Col className="text-center">
                    <i>Cannot load property group. Please try again later.</i>
                </Col>
            </Row>
        </>
    }

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">
                    {props.isNew ? "Create Property Group" : "Property Group Details"}
                </h3>
            </Col>
        </Row>


        <Row className="my-5">
            <Col>
                <Button color="info-outline" onClick={() => navigate('/property-management/groups/')}>
                    <FontAwesomeIcon icon={faChevronLeft}/>{" "}
                    BACK TO PROPERTY GROUPS
                </Button>
            </Col>
        </Row>

        {
            !props.isNew && <>
                <Row>
                    <Col className="text-center">

                        {
                            query.data.logo && <Row>
                                <Col>
                                    <img src={query.data.logo} className="img-fluid p-2" alt={"Logo"}/>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col>
                                <PhotoUpload
                                    recommendedResolution={"256x256px"}
                                    // explanation={"Preferred size: 256x256 pixels"}
                                    modalTitle={"Property Group Logo"}
                                    resourceName={resourceName}
                                    resourcePath={params.propertyGroupId.toString() + "/logo"}
                                    toastDetail={{
                                        error: "Something went wrong. Cannot upload property group logo.",
                                        pending: "Uploading property group logo.",
                                        success: "Updated property group logo."
                                    }}
                                    onSuccess={responseData => {
                                        queryClient.invalidateQueries(['table', 'properties/groups'])
                                        queryClient.setQueryData(['property-group', responseData.id.toString()], {
                                            ...query.data,
                                            ...responseData
                                        })
                                    }}
                                >
                                    <Button color="info-outline">
                                        <FontAwesomeIcon icon={faUpload}/>{" "}
                                        UPLOAD LOGO
                                    </Button>
                                </PhotoUpload>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="small">
                        <Statistics groupId={params.propertyGroupId}/>
                    </Col>
                </Row>
                <hr className="my-5"/>
            </>
        }

        <EditBasicInformation
            isSaving={isSaving}
            isNew={props.isNew}

            save={(data) => {
                if (props.isNew) {
                    createMutation.mutate(data)
                } else {
                    updateMutation.mutate(data)
                }
            }}
            query={query}
        />

        {
            !props.isNew && <>
                <EditAdditionalInformation
                    isSaving={isSaving}
                    query={query}
                    save={(data) => {
                        updateMutation.mutate(data)
                    }}
                />

                <MediaGallery
                    groupId={params.propertyGroupId}/>

                <hr className="my-5"/>

                <Row className="my-5">
                    <Col>
                        <AddPropertyListingButton
                            closeOnCreate={true}
                            defaultData={{group: query.data?.id}}
                            label={"ADD PROPERTY LISTINGS TO THIS GROUP"}
                            />
                    </Col>
                </Row>
            </>
        }
    </>
}

Detail.propTypes = {
    isNew: PropTypes.bool
}

export default Detail;