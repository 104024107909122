import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {ProfileContext} from "../../../commonComponents/contexts";
import MyEmployeeProfileForm from "../../../components/employeeProfileForm/myEmployeeProfileForm";


export default function MyProfile(props) {
    const {profile} = useContext(ProfileContext)
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage My Profile</h3>
            </Col>
        </Row>

        <MyEmployeeProfileForm
            profileId={profile.id}
            isMe={true}/>
    </>
}