import React, {useContext} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LeadSelector from "./leadSelector";
import SalesProfileSelector from "./salesProfileSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Preview from "./preview";
import moment from "moment/moment";
import {useDetectedAnswerChanges, useDetectedTagChanges} from "./hooks";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {PermissionsContext} from "../../../contexts";
import {useFindDuplicateLeads, useTagGroups} from "../../../hooks";


const mergeLeads = (selectedLeadsData, tagGroups) => {
    const latestAnswersForEachFormField = {}
    selectedLeadsData.forEach(lead => {
        lead.form_answers.forEach(formAnswer => {
            const existingAnswer = latestAnswersForEachFormField[formAnswer.form_field]
            if (!existingAnswer || moment(existingAnswer.datetime_updated).isBefore(formAnswer.datetime_updated)) {
                latestAnswersForEachFormField[formAnswer.form_field] = formAnswer
            }
        })
    })

    const allTags = selectedLeadsData.flatMap(lead => lead.form_tags)
    const latestTagsForTagGroups = tagGroups.map(tagGroup => {
        const tagsInTagGroup = allTags.filter(tag => tag.tag_data.tag_group === tagGroup.id)
        if (tagsInTagGroup.length === 0) {
            return []
        }
        if (tagGroup.is_unique) {
            return [tagsInTagGroup.sort((a, b) => moment(b.updated_at).diff(a.updated_at))[0]]
        }
        if (tagGroup.is_sequence) {
            const maxSequenceNumber = Math.max(...tagsInTagGroup.map(tag => tag.tag_data.order))
            return Array.from({length: maxSequenceNumber}, (_, i) => tagsInTagGroup.find(tag => tag.tag_data.order === i + 1)).filter(Boolean)
        }
        return tagGroup.tags.flatMap(tag => {
            const tagsInTagGroupTag = tagsInTagGroup.filter(tagInTagGroup => tagInTagGroup.tag === tag.id).sort((a, b) => moment(b.updated_at).diff(a.updated_at))
            if (tag.multiple_source) {
                return tagsInTagGroupTag.reduce((acc, tagInTagGroup) => {
                    if (acc.some(row => row.source_object_id === tagInTagGroup.source_object_id && row.source_text === tagInTagGroup.source_text)) {
                        return acc
                    }
                    return [...acc, tagInTagGroup]
                }, [])
            } else {
                return [tagsInTagGroupTag[0]]
            }
        })
    }).flat().filter(Boolean)

    return {
        form_answers: Object.values(latestAnswersForEachFormField),
        form_tags: latestTagsForTagGroups
    }
}

export default function MergerModal({isOpen, toggle, params}) {
    const {permissions} = useContext(PermissionsContext)
    const canMergeLeads = permissions.indexOf('microsite.merge_leads') >= 0

    const [primaryLead, setPrimaryLead] = React.useState(null)
    const [primarySalesProfile, setPrimarySalesProfile] = React.useState(null)
    const [selectedLeads, setSelectedLeads] = React.useState([])

    const [showSalesProfileSelector, setShowSalesProfileSelector] = React.useState(false)
    const [showPreview, setShowPreview] = React.useState(false)

    const {data: tagGroups, isLoading: isLoadingTagGroups} = useTagGroups({})
    const {data: leadsResponse, isLoading: isLoadingLeads} = useFindDuplicateLeads(params)

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const mergeMutation = useMutation(data => {
        const leadResource = api.resource('leads')
        const mergePromise = leadResource.patchItem(data.id, data)
        toast.promise(mergePromise, {
            pending: 'Merging leads...',
            success: 'Successfully merged leads.',
            error: 'Failed to merge leads.'
        })
        return mergePromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table', 'leads'])
            queryClient.invalidateQueries(['lead'])
            toggle()
        }
    })

    const leads = leadsResponse?.results ? leadsResponse.results : []

    const salesProfileMap = leads.reduce((acc, lead) => {
        acc[lead.sales_profile] = lead.sales_profile_display
        return acc
    }, {})
    const salesProfileIds = new Set(leads
        .filter(lead => selectedLeads.includes(lead.id))
        .map(lead => lead.sales_profile)
    )

    const selectedSalesProfile = salesProfileIds.size === 1 ? salesProfileIds.values().next().value : primarySalesProfile;

    const mergedData = React.useMemo(() => {
        const selectedLeadsData = leads.filter(lead => selectedLeads.includes(lead.id))
        if (!tagGroups || !selectedLeadsData) {
            return {}
        }
        return mergeLeads(selectedLeadsData, tagGroups)
    }, [leads, selectedLeads, tagGroups])

    const primaryLeadData = leads.find(lead => lead.id === primaryLead)
    const {
        tagChanges,
        isLoading: isLoadingTagChanges
    } = useDetectedTagChanges(primaryLeadData, {...primaryLead, ...mergedData})
    const {
        answerChanges,
        isLoading: isLoadingAnswerChanges
    } = useDetectedAnswerChanges(primaryLeadData, {...primaryLead, ...mergedData})

    const dataToSave = {
        id: primaryLead,
        form_tags: tagChanges,
        form_answers: answerChanges,
        sales_profile: primarySalesProfile || undefined,
        merge_from: selectedLeads.filter(lead => lead !== primaryLead),
        remarks: `Merged ${selectedLeads.length} duplicate leads.`
    }
    if (primaryLeadData?.sales_profile === primarySalesProfile) {
        delete dataToSave.sales_profile
    }

    const showLeadsSelector = !showSalesProfileSelector && !showPreview

    const backToLeadsSelector = () => {
        setShowSalesProfileSelector(false)
        setShowPreview(false)
    }


    return <Modal isOpen={isOpen} toggle={toggle} size="lg" centered scrollable>
        <ModalHeader toggle={toggle} className="activegroup-header">
        </ModalHeader>
        <ModalBody className="p-5">
            <h3>Duplicate Leads</h3>

            {
                showLeadsSelector && <LeadSelector
                    leads={leads}
                    setSelectedLeads={setSelectedLeads}
                    selectedLeads={selectedLeads}
                    primaryLead={primaryLead}
                    setPrimaryLead={setPrimaryLead}
                    selectedSalesProfile={selectedSalesProfile}
                />
            }
            {
                showSalesProfileSelector && <SalesProfileSelector
                    salesProfiles={Object.values(salesProfileMap).filter(salesProfile => {
                        return salesProfileIds.has(salesProfile.id)
                    })}
                    selectedSalesProfile={primarySalesProfile}
                    setSelectedProfile={setPrimarySalesProfile}
                />
            }
            {
                showPreview && <Preview
                    leadData={primaryLeadData}
                    latestData={mergedData}
                    isLoadingPreview={isLoadingTagGroups}
                    oldSalesProfile={primaryLeadData?.sales_profile_display}
                    newSalesProfile={salesProfileMap[selectedSalesProfile]}
                />
            }
        </ModalBody>

        <ModalFooter>
            {
                (showLeadsSelector && salesProfileIds.size > 1) && <>
                    {
                        canMergeLeads ? <>
                            <div className="text-danger">
                                <strong>WARNING:</strong> Leads selected for merge have different broker/sales contact.
                            </div>

                            <Button color="secondary" onClick={() => {
                                setShowSalesProfileSelector(true)
                            }}>
                                SELECT BROKER/SALES CONTACT
                            </Button>
                        </> : <div className="text-danger">
                            <strong>PERMISSION DENIED:</strong> Insufficient permission to merge leads with different broker/sales
                            contacts.
                        </div>
                    }
                </>
            }
            {
                !showLeadsSelector && <Button color="secondary" onClick={backToLeadsSelector}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>{" "}
            BACK TO LEAD SELECTION
        </Button>
        }
        {
        showPreview ?
        <Button
            disabled={!selectedSalesProfile || selectedLeads < 2}
            color="primary"
            onClick={() => {
                mergeMutation.mutate(dataToSave)
                // saveMutation.mutate(changedData)
            }}>
            MERGE {selectedLeads.length} LEAD{selectedLeads.length > 1 && 'S'}
        </Button> :
        <Button
            disabled={!selectedSalesProfile || selectedLeads.length < 2}
            color="secondary"
            onClick={() => {
                setShowPreview(true)
                setShowSalesProfileSelector(false)
            }}>
            PREVIEW MERGE
        </Button>
        }
    </ModalFooter>
</Modal>
}