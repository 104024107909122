import React from "react";
import {Button, Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import TagDisplay from "./tagDisplay";
import {daysToNumberAndType, numberAndTypeToDays} from "./util";


export default function ExpirationPickerModal({isOpen, toggle, expiration, setExpiration}) {
    const {number, type} = daysToNumberAndType(expiration)
    const [localNumber, setLocalNumber] = React.useState(number)
    const [localType, setLocalType] = React.useState(type)

    React.useEffect(() => {
        setLocalNumber(number)
        setLocalType(type)
    }, [number, type])


    const localExpiration = numberAndTypeToDays(localNumber, localType)

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <h5>Set Expiration</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        type="number"
                        value={localNumber}
                        onChange={(e) => {
                            setLocalNumber(e.target.value)
                        }}
                        min={1}
                    />
                    <TagDisplay
                        color={localType === 'days' ? 'green' : 'light-gray'}
                        onClick={() => {
                            setLocalType('days')
                        }}
                    >
                        Days
                    </TagDisplay>
                    <TagDisplay
                        color={localType === 'weeks' ? 'green' : 'light-gray'}
                        onClick={() => {
                            setLocalType('weeks')
                        }}
                    >
                        Weeks
                    </TagDisplay>
                    <TagDisplay
                        color={localType === 'months' ? 'green' : 'light-gray'}
                        onClick={() => {
                            setLocalType('months')
                        }}
                    >
                        Months
                    </TagDisplay>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="text-end">
                    <Button color="primary" onClick={() => {
                        setExpiration(localExpiration)
                        toggle()
                    }}>Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}