import React, {useState} from "react";
import {Col, Row, Spinner} from "reactstrap";


export default function TableChart({title, rows, maxVisible, isLoading, headers}) {
    const [showMore, setShowMore] = useState(false)

    const visibleRows = rows ? (
        rows.length > maxVisible && !showMore ? rows.slice(0, maxVisible) : rows
    ) : [];

    const total = rows?.map(row => row.value).reduce((acc, obj) => {
        return acc + obj
    }, 0)

    const headerRow = <Row>
        <Col><h5>{title}</h5></Col>
    </Row>

    if (isLoading) {
        return <>
            {headerRow}
            <Row>
                <Col className="text-center p-5">
                    <Spinner/>
                </Col>
            </Row>
        </>
    }

    if (!rows || rows.length === 0) {
        return <>
            {headerRow}
            <Row>
                <Col className="text-center text-gray italic p-5">
                    No available data.
                </Col>
            </Row>
        </>
    }

    return <>
        {headerRow}

        <Row>
            <Col>
                <small>
                    <strong>Total: </strong> {total}
                </small>
            </Col>
        </Row>

        <Row>
            <Col>
                <table className="table table-bordered border-light">
                    <thead>
                    <tr>
                        {
                            headers.map(header => <th>{header}</th>)
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        visibleRows?.map(row => {
                            return <tr>
                                <td>{row.label}</td>
                                <td>{row.value}</td>
                            </tr>
                        })
                    }</tbody>
                </table>
            </Col>
        </Row>

        {
            maxVisible < rows?.length && !showMore && <Row onClick={() => setShowMore(true)}>
                <Col>
                    <small>
                        <strong>Load more</strong>
                    </small>
                </Col>
            </Row>
        }


    </>
}