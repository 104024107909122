import React, {useState} from "react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import * as PropTypes from "prop-types";
import {useLoggedInApi} from "../../../../../api";
import PropertiesSelect from "./propertiesSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faHouseChimney} from "@fortawesome/free-solid-svg-icons";
import BrokerShortInfo from "../../../../../appRoutes/private/component/brokerShortInfo";
import LeadInfo from "./leadInfo";
import {toast} from "react-toastify";
import {processResponseAsDownload} from "../../util";
import {useQueryClient} from "react-query";

function DownloadProposal(props) {
    const [selectedProperty, setSelectedProperty] = useState(null)
    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const download = () => {
        const leadResource = api.resource('leads')
        const downloadPromise = leadResource.getItemAction(props.leadId, 'sample_computation', {
            params: {
                property: selectedProperty
            },
            responseType: "blob"
        }).then(processResponseAsDownload)
        toast.promise(downloadPromise, {
            error: {
                render({data}){
                    const reasonDetail = data?.response?.data?.detail
                    if (reasonDetail) {
                        return reasonDetail
                    }

                    return "Something went wrong. Please contact administrator"
                }
            },
            pending: "Generating proposal",
            success: "Proposal successfully downloaded!"
        }).then(() => {
            queryClient.invalidateQueries(['lead-activities'])
            queryClient.invalidateQueries(['lead'])
            queryClient.invalidateQueries(['table', 'leads'])
            console.log("invalidateQueries")
        })
    }

    return <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size="xl"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={props.toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row>
                <Col lg={8} className="pe-4 border-end">
                    <Row>
                        <Col>
                            <h3>Choose Property</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-auto d-flex align-items-center">

                            <FontAwesomeIcon icon={faHouseChimney} className="text-blue2 fa-2x"/>
                        </Col>
                        <Col>
                            <PropertiesSelect
                                selectedProperty={selectedProperty}
                                setSelectedProperty={setSelectedProperty}
                                templateRequired
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <small>
                                Generate a downloadable PDF file that you can send to your leads with your contact information.
                            </small>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-end">

                            <Button
                                color="info"
                                size="sm"
                                className="flex-fill"
                                disabled={!selectedProperty}
                                onClick={download}
                            >
                                <FontAwesomeIcon icon={faFileDownload}/> {" "}
                                GENERATE DOWNLOADABLE PDF
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col className="pb-5">

                    <LeadInfo
                        leadId={props.leadId}
                        />

                    <BrokerShortInfo/>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}

DownloadProposal.propTypes = {
    leadId: PropTypes.number,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func
}


export default DownloadProposal