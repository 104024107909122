import React from "react";
import TagDisplay from "../../../../tagDisplay";
import {Alert, Spinner} from "reactstrap";
import TagAdditionalData from "./tagAdditionalData";
import TagFormDisplay from "./tagFormDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {useTagGroups} from "../../../../hooks";


export default function TagGroupTagSelector(
    {
        tagGroup,
        tagGroupId,
        selectedTags,
        setSelectedTags,
        tagGroupNameWrapper,
        doNotFollowRules,
        setTagDataOnAdd,
        unlockPermanentTags=false
    }
) {
    const followTagGroupRules = !doNotFollowRules
    const [addTagWithData, setAddTagWithData] = React.useState(null)
    const {data: tagGroupsData, isLoading: isLoadingTagGroups} = useTagGroups({
        enabled: !!tagGroupId && !tagGroup,
    })
    const tagGroupData = tagGroup ? tagGroup : tagGroupsData?.find(row => row.id === tagGroupId)

    if (isLoadingTagGroups) {
        return <Spinner/>
    }
    if (!tagGroupData) {
        console.error("Error displaying tag group")
        return null
    }

    const tagGroupTags = tagGroupData.tags
    const tagGroupTagIds = tagGroupTags.map(row => row.id)
    const tagGroupTagById = tagGroupTags.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    }, {})

    const tagGroupName = tagGroupData.name

    const tagFormsInTagGroup = selectedTags.filter(row => tagGroupTagIds.includes(row.tag))

    const deleteTagForm = (tag, tagFormId) => {
        setSelectedTags([
            ...selectedTags.map(row => {
                const rowTagData = tagGroupTagById[row.tag]
                const isNextInSequence = tagGroupData.is_sequence && tagGroupTagIds.includes(row.tag) && rowTagData.order > tag.order
                if ((!!tagFormId && row.id === tagFormId) || (isNextInSequence && followTagGroupRules)) {
                    if (row.id && !row.__isNew) {  // only mark it as deleted
                        return {
                            ...row,
                            _delete: true
                        }
                    } else {  // if not yet in database, delete it
                        return null
                    }
                }
                return row
            }).filter(row => !!row)
        ])
    }

    const addTag = (tag, additionalData) => {
        const keysToMatch = [
            'source_object_id',
            'source_text'
        ]
        const matchedTagFormId = selectedTags.find(row => {
            const matchesAdditionalData = !additionalData || !tag.multiple_source || keysToMatch.every(key => {
                if (additionalData[key] === undefined) {
                    return true
                }
                return row[key] === additionalData[key]
            })
            return row.tag === tag.id && matchesAdditionalData
        })?.id

        if (!!matchedTagFormId) {
            // if already in database, remove the delete mark
            setSelectedTags([
                ...selectedTags.map(row => {
                    if (!tagGroupTagIds.includes(row.tag)) {
                        return row
                    }

                    if (row.id === matchedTagFormId) {
                        return {
                            ...row,
                            _delete: undefined,
                            ...additionalData  // update additional data
                        }
                    }
                    if (tagGroupData.is_unique && followTagGroupRules) {
                        return null
                    }
                    return row

                }).filter(row => !!row)
            ])
        } else {
            // if not yet in database, add a new tag
            const newTag = {
                id: Date.now(),
                tag: tag.id,
                __isNew: true,
                ...additionalData
            }
            if (setTagDataOnAdd) {
                newTag.tag_data = tag
            }
            if (tagGroupData.is_unique && followTagGroupRules) {
                // mark delete all other tags in the tag group or remove them if id is null, and add the new tag
                const clearedTags = [
                    ...selectedTags.map(row => {
                        if (!tagGroupTagIds.includes(row.tag)) {
                            return row
                        }

                        if (!!row.id) {  // this row is already in database, mark it for deletion
                            return {
                                ...row,
                                _delete: true
                            }
                        }
                        return null
                    }).filter(row => !!row)
                ]
                setSelectedTags([
                    ...clearedTags,
                    newTag
                ])
            } else {
                setSelectedTags([...selectedTags, newTag])
            }
        }
    }

    return <div className="my-2 py-3 border-bottom">
        {
            typeof (tagGroupNameWrapper) === "function" ?
                tagGroupNameWrapper(tagGroupName) :
                <h4>{tagGroupName}</h4>
        }


        {
            !tagGroupData.is_active && <Alert color="danger" className="py-1">
                <FontAwesomeIcon icon={faExclamationTriangle} className={"me-2"}/>
                This tag group is inactive
            </Alert>
        }

        <p className="text-muted small">
            {tagGroupData.description}
        </p>
        <div className="tags">
            {
                tagGroupData.tags.filter(tag => tag.is_active).map(tag => {
                    const tagFormsForTag = selectedTags.filter(row => row.tag === tag.id).filter(tagForm => !tagForm._delete)
                    const isSelected = tagFormsForTag.length > 0

                    const isPreviousTagInGroupSelected = tag.order === 1 || !!selectedTags.some(selectedTag => {
                        const selectedTagIsSavedAndNotDeleted = selectedTag.id && !selectedTag._delete && !selectedTag.__isNew;
                        return selectedTagIsSavedAndNotDeleted
                            && selectedTag.tag_data.order === tag.order - 1
                            && selectedTag.tag_data.tag_group === tag.tag_group  //sameTagGroup
                    })
                    const selectableIfSequence = (tagGroupData.is_sequence && followTagGroupRules) ? isPreviousTagInGroupSelected : true
                    const selectable = selectableIfSequence && (tag.is_usable || !followTagGroupRules)

                    const onTagClickAdd = () => {
                        const needsAdditionalData = tag.accept_datetime || tag.accept_text || tag.multiple_source;
                        if (needsAdditionalData && followTagGroupRules) {
                            setAddTagWithData(tag)
                        } else {
                            addTag(tag)
                        }
                    }


                    const newTag = <TagDisplay
                        key={`${tag.id}-new`}
                        color={'light-gray'}
                        description={tag.description}
                        disabled={!selectable}
                        onClick={onTagClickAdd}
                    >
                        {tag.name}
                    </TagDisplay>

                    if (tagFormsForTag.length > 0) {
                        const retVal = [
                            ...tagFormsForTag.map(tagForm => {

                                const onTagClick = () => {
                                    if (isSelected) {
                                        // delete the tag
                                        deleteTagForm(tag, tagForm.id)
                                    } else {
                                        // add the new tag
                                        onTagClickAdd()
                                    }
                                }

                                const isUnlocked = !tag.is_permanent || (unlockPermanentTags && tagForm.can_unlock) || tagForm.__isNew

                                return <TagFormDisplay
                                    key={`${tag.id}-${tagForm.id}`}
                                    tagForm={{...tagForm, tag_data: tag}}
                                    isColorActive={isSelected}
                                    disabled={!selectable || !isUnlocked}
                                    onClick={onTagClick}

                                    onDelete={(isSelected && tag.is_usable && isUnlocked) ? () => {
                                        deleteTagForm(tag, tagForm.id)
                                    } : null}
                                />
                            })
                        ]
                        if (!!tag.multiple_source && followTagGroupRules) {
                            retVal.push(newTag)
                        }
                        return retVal
                    }

                    return newTag
                })
            }

            <TagAdditionalData
                key={addTagWithData?.id}
                tag={addTagWithData}
                isOpen={!!addTagWithData}
                toggle={() => {
                    setAddTagWithData(null)
                }}
                onSuccess={(additionalData) => {
                    addTag(addTagWithData, additionalData)
                    setAddTagWithData(null)
                }}
                otherTagForms={tagFormsInTagGroup.filter(tagForm => tagForm.tag === addTagWithData?.id)}
            />

        </div>
    </div>
}