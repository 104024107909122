import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";
import ClassicEditor from "../../../../../components/classicEditor";
import InputRow from "../../../../../components/inputRow";
import DatePicker from "react-date-picker";

export default function EditContent({editedData, setEditedData}) {
    const startDate = moment(editedData.visible_since).toDate();
    const endDate = moment(editedData.visible_until).toDate();

    return <>
        <InputRow
            onChange={newValue => setEditedData({...editedData, title: newValue})}
            value={editedData.title}
            label={"Title"}
            required={true}
        />
        <Row className="mt-3">
            <Col>
                <ClassicEditor
                    value={editedData.content}
                    initialValue={editedData.content}
                    onEditorChange={(newContent) => {
                        setEditedData({...editedData, content: newContent})
                    }}
                />
            </Col>
        </Row>

        <InputRow
            onChange={newValue => {
                if (newValue) {
                    setEditedData({...editedData, visible_since: moment(newValue).format('YYYY-MM-DD')})
                } else {
                    setEditedData({...editedData, visible_since: moment(startDate).format('YYYY-MM-DD')})
                }
            }}
            value={startDate}

            label={"Start Date"}
            required={true}
            renderer={(renderProps) =>
                <DatePicker
                    {...renderProps}
                    defaultValue={new Date()}
                    maxDate={endDate}
                    clearIcon={null}
                />
            }
        />
        <InputRow
            onChange={newValue => {
                if (newValue) {
                    setEditedData({...editedData, visible_until: moment(newValue).format('YYYY-MM-DD')})
                } else {
                    setEditedData({...editedData, visible_until: moment(endDate).format('YYYY-MM-DD')})
                }

            }}
            value={endDate}

            label={"End Date"}
            required={true}
            renderer={(renderProps) =>
                <DatePicker
                    {...renderProps}
                    defaultValue={new Date()}
                    minDate={startDate}
                    clearIcon={null}
                />
            }
        />
    </>
}