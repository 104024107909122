import React, {useMemo} from "react";
import {Badge} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faLock, faX} from "@fortawesome/free-solid-svg-icons";

export default function TagDisplay({className, onClick, onDelete, color, textColor, description, disabled, ...props}) {
    const effectiveClassName = useMemo(() => {
        const classes = [
            'mx-1 py-1',
            'no-wrap',
            'shadow-sm',
            className,
        ]
        if (onClick && !disabled) {
            classes.push('pointer-cursor')
        }
        return classes.join(' ')
    }, [onClick, className, disabled])

    const internalProps = {...props}
    internalProps.style = {
        ...(internalProps.style || {}),
        marginBottom: "5px",
    }
    if (color && color.indexOf('#') === 0) {
        internalProps.style = {
            ...internalProps.style,
            backgroundColor: color,
        }
        internalProps.color = 'style'
    } else if (!!color) {
        internalProps.color = color
    } else {
        internalProps.color = 'style'
        internalProps.style = {
            ...internalProps.style,
            backgroundColor: '#666666',
        }
    }

    internalProps.style = {
        ...internalProps.style,
        color: (textColor && textColor.indexOf('#') === 0) ? textColor : undefined,
    }

    if (disabled) {
        internalProps.style = {
            ...internalProps.style,
            opacity: 0.5,
        }
    }

    return <span className={effectiveClassName} title={description}>
        <Badge
            className={`rounded-start ${!onDelete ? "rounded-end": ""}`}
            onClick={disabled ? undefined : onClick}
            {...internalProps}
        >
            {disabled && <FontAwesomeIcon icon={faLock} className="me-1"/>}
            <small>
                {props.children}
            </small>
        </Badge>

        {
            !!onDelete && <Badge
            className={`rounded-end border-start pointer-cursor`}
            onClick={onDelete}
            {...internalProps}>
            <small>
                <FontAwesomeIcon icon={faX}/>
            </small>
        </Badge>
        }
    </span>
}