import React from "react";
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTag} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";


export default function MenuItem({icon, name, description, path}) {

    const navigate = useNavigate()

    return <Col lg={6} md={12} className="p-5 pointer-cursor" onClick={() => {
        navigate(path)
    }}>
        <Row>
            <Col className="col-auto">
                <FontAwesomeIcon icon={icon} size="4x" className="text-blue2"/>
            </Col>
            <Col>
                <h4>{name}</h4>
                <p>
                    {description}
                </p>
            </Col>
        </Row>
    </Col>
}