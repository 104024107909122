import React, {useMemo} from "react";
import {useLoggedInApi} from "../../../../../api";
import {Alert, Badge, Button, Col, Row, Spinner} from "reactstrap";
import {useQuery} from "react-query";
import DataTable from "react-data-table-component";
import {dictChangedValues, dictEqualShallow, toTitleCase, valueIsEqual} from "../../../../../commonComponents/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCirclePlus, faCircleXmark, faClone, faFloppyDisk, faPencil} from "@fortawesome/free-solid-svg-icons";
import InfoCards from "../../../../../components/charts/infoCards";

const ROW_STATUS_STYLES = [
    {
        when: row => row.status === 'new',
        style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status === 'error-conflict',
        style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status.startsWith('error-') && row.status !== 'error-conflict',
        style: {
            backgroundColor: 'rgba(255,102,88,0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status === 'changed',
        style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
        }
    },

]

const customTableStyles = {
    rows: {
        style: {
            minHeight: '5em'
        }
    }
}

const statusSortRows = (rowA, rowB) => {
    const a = rowA.status;
    const b = rowB.status;
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

const valueToDisplay = (value) => {
    if (value === false) {
        return 'FALSE'
    } else if (value === true) {
        return 'TRUE'
    } else if (!value) {
        return <i>EMPTY</i>
    }
    return value
}

const getValueFromApiDataByLabel = (key, apiData) => {
    const apiKey = LABEL_TRANSLATION.hasOwnProperty(key) ? LABEL_TRANSLATION[key] : key
    return apiData[apiKey]
}


const EXPECTED_LABELS = [
    'GROUP ID',
    'UNIQUE PROPERTY ID',
    'LABEL',
    'LOT',
    'PHASE',
    'BLOCK',
    'AREA',
    'PRICE',
    'LOT DESCRIPTION',
]

const LABEL_TRANSLATION = {
    'GROUP ID': 'group',
    'UNIQUE PROPERTY ID': 'unique_property_id',
    'LABEL': 'label',
    'LOT': 'lot',
    'PHASE': 'phase',
    'BLOCK': 'block',
    'AREA': 'area',
    'PRICE': 'price',
    'LOT DESCRIPTION': 'description'
}

const REQUIRED_FIELDS = [
    'group',
    'unique_property_id',
    'label',
    'lot',
    'phase',
    'block',
]

const FIELD_TYPES = {
    'group': 'string',
    'unique_property_id': 'string',
    'label': 'string',
    'lot': 'string',
    'phase': 'string',
    'block': 'string',
    'area': 'number',
    'price': 'number',
    'description': 'string',
}

const matchType = (value, key) => {
    const valueType = FIELD_TYPES[key]
    if (valueType === 'number') {
        return parseFloat(value)
    } else if (valueType === 'string') {
        return value ? value.toString() : ''
    }
    return value
}

const remapData = (row) => {
    return EXPECTED_LABELS.reduce((acc, key) => {
        // where to put it in the new object
        const apiKey = LABEL_TRANSLATION.hasOwnProperty(key) ? LABEL_TRANSLATION[key] : key
        // what to put in as value
        const value = row[key];

        acc[apiKey] = matchType(value, apiKey)

        return acc
    }, {});
}

const setRowStatus = row => {
    if (row.conflict) {
        return 'error-conflict'
    }
    if (row.hasInvalid) {
        return 'error-invalid-value'
    }
    if (!row.isComplete) {
        return 'error-incomplete'
    }
    if (row.hasDuplicate) {
        return 'error-duplicate'
    }

    if (row.hasChanges) {
        // check if it is actually changed or no changes
        return 'changed'
    }
    if (row.isNew) {
        return 'new'
    }
    return 'ok'
}

export default function Review({fileData, onDataProcessed, previousStep}) {
    const api = useLoggedInApi()

    const remappedFileData = useMemo(() => {
        return fileData.map((row) => {
            return remapData(row)
        })
    }, [fileData])

    const propertyGroupsQuery = useQuery(['properties', 'all-property-groups'], () => {
        const resource = api.resource('properties/groups')
        return resource.list().then(response => response.data)
    })

    const propertyGroupIdsByUniqueId = propertyGroupsQuery.data?.reduce((acc, row) => {
        acc[row.unique_property_group_id.toString().toUpperCase()] = row.id
        return acc
    }, {
        refetchOnWindowFocus: false
    })
    const propertyGroupsById = propertyGroupsQuery.data?.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    })
    const propertyGroupIdToDisplay = propertyGroupId => {
        if (propertyGroupsById?.hasOwnProperty(propertyGroupId)) {
            return propertyGroupsById[propertyGroupId].name
        }
        return null
    }

    const propertyIds = remappedFileData.map(row => row.unique_property_id);


    const propertyIdsQuery = useQuery(['properties', propertyIds], () => {
        const resource = api.resource('properties')
        return resource.post('get_by_ids/', {
            ids: propertyIds
        }).then(response => response.data)
    }, {
        refetchOnWindowFocus: false
    })
    const duplicates = useMemo(() => {
        const ids = {}

        remappedFileData.forEach((item, index) => {
            ids[item.unique_property_id] = ids[item.unique_property_id] || [];
            ids[item.unique_property_id].push(index)
        })

        return {
            'UNIQUE PROPERTY ID': [].concat(...Object.values(ids).filter(values => values.length > 1)),
        }
    }, [remappedFileData])
    const rowsWithDuplicates = [].concat(...Object.values(duplicates))

    const processedData = useMemo(() => {
        if (!propertyGroupIdsByUniqueId) return [];

        const apiDataById = propertyIdsQuery.data ? propertyIdsQuery.data.editable : {};
        const apiDataConflict = propertyIdsQuery.data ? propertyIdsQuery.data.unauthorized : [];
        const apiDataNew = propertyIdsQuery.data ? propertyIdsQuery.data.new : [];

        return fileData.map((row, idx) => {
            const remappedRow = remappedFileData[idx];
            const uniqueId = remappedRow.unique_property_id;
            const apiData = apiDataById?.hasOwnProperty(uniqueId) ? apiDataById[uniqueId] : null;

            if (apiData) {
                delete apiData['group_name'];
                delete apiData['status'];
            }
            const newApiData = {
                id: apiData?.id,
                ...remappedRow,
                group: propertyGroupIdsByUniqueId[remappedRow.group.toString().toUpperCase()]
            }
            console.log(apiData, newApiData)
            const returnValue = {
                id: apiData?.id,
                originalIndex: idx,
                key: idx,

                rawData: row,
                mappedData: remappedRow,
                apiData: apiData,
                newApiData: newApiData,
                changedData: apiData ? dictChangedValues(apiData, newApiData) : null,

                isNew: apiDataNew.includes(uniqueId.toUpperCase()),
                isConflict: apiDataConflict.includes(uniqueId.toUpperCase()),
                hasChanges: apiData ? !dictEqualShallow(apiData, newApiData) : false,
                isComplete: REQUIRED_FIELDS.every(apiKey => !!newApiData[apiKey]),
                hasInvalid: !newApiData.group && !!remappedRow.group,
                hasDuplicate: rowsWithDuplicates.includes(idx)
            }

            returnValue.status = setRowStatus(returnValue)
            return returnValue
        })
    }, [fileData, remappedFileData, propertyGroupIdsByUniqueId, propertyIdsQuery.data, rowsWithDuplicates])

    const isCheckingApi = !propertyIdsQuery.isSuccess || !propertyGroupsQuery.isSuccess;

    const usedValues = [];  // todo: implement this if needed
    const validRows = processedData.filter(row => ['changed', 'new'].includes(row.status))
    const invalidRows = processedData.filter(row => row.status.startsWith('error-'));
    const unchangedRows = processedData.filter(row => row.status === 'ok')

    const valueToDisplayWithContext = (value, label, fallbackValue) => {
        if (label === 'GROUP ID') {
            const returnValue = propertyGroupIdToDisplay(value)
            if (returnValue || !fallbackValue) {
                return returnValue
            } else {
                return fallbackValue
            }
        }
        return valueToDisplay(value)
    }

    console.table(processedData)

    return <>
        <Row className={"mt-4"}>
            <Col><h4>Step 2 - Review</h4></Col>
        </Row>
        <Row>
            <Col>
                <Button color="link" onClick={previousStep}>
                    <small>Go Back To Step 1 - Select File</small>
                </Button>
            </Col>
        </Row>

        <Row className={"mt-3"}>
            <Col><h5>Total Rows: {fileData.length}</h5></Col>
        </Row>
        <Row>
            <Col>
                Checking Property Groups{" "}
                {
                    (propertyGroupsQuery.isLoading || propertyGroupsQuery.isIdle) ? <Spinner size="sm"/> : (
                        propertyGroupsQuery.isSuccess ? "... done!" : "... error!"
                    )
                }
            </Col>
        </Row>


        <Row className={"mb-3"}>
            <Col>
                Checking Property IDs{" "}
                {
                    (propertyIdsQuery.isLoading || propertyIdsQuery.isIdle) ? <Spinner size="sm"/> : (
                        propertyIdsQuery.isSuccess ? "... done!" : "... error!"
                    )
                }
            </Col>
        </Row>

        <Row className="my-3">
            <Col>
                <Button
                    color={'primary'}
                    onClick={() => {
                        onDataProcessed(validRows)
                    }}
                    disabled={processedData.filter(row => row.status === 'new' || row.status === 'changed').length === 0 || isCheckingApi}>
                    <FontAwesomeIcon icon={faFloppyDisk}/>{" "}
                    COMMIT VALID ROWS
                </Button>

                {processedData.filter(row => row.status === 'new' || row.status === 'changed').length === 0 &&
                    <Alert color="danger" className="mt-4 small">No rows to commit</Alert>}
            </Col>
        </Row>

        <InfoCards
            data={[
                {label: "Valid Rows", value: validRows.length, color: "green", isLoading: isCheckingApi},
                {label: "Invalid Rows", value: invalidRows.length, color: "red", isLoading: isCheckingApi},
                {label: "Unchanged Rows", value: unchangedRows.length, color: "gray", isLoading: isCheckingApi},
            ]}
            />
        <Row className={"mt-5"}>
            <Col>
                <DataTable
                    noDataComponent={<i>No data. Select a file and click process.</i>}
                    striped
                    responsive
                    persistTableHead
                    pagination
                    progressPending={isCheckingApi}
                    data={processedData}
                    conditionalRowStyles={ROW_STATUS_STYLES}
                    customStyles={customTableStyles}
                    keyField={'originalIndex'}
                    columns={[
                        {
                            name: "Status",
                            sortable: true,
                            sortFunction: statusSortRows,
                            selector: row => {
                                if (row.status === 'error-conflict') {
                                    return <span title={"Conflict"}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>{" "}
                                        Existing. No permission to edit.
                                    </span>
                                } else if (row.status === 'error-invalid-value') {
                                    return <span title={"Invalid Value found"}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>{" "}
                                        Has invalid values
                                    </span>
                                } else if (row.status === 'error-incomplete') {
                                    return <span title={"Incomplete"}>
                                        <FontAwesomeIcon icon={faCircleXmark}/>{" "}
                                        Missing required fields
                                    </span>
                                } else if (row.status === 'new') {
                                    return <span title={"New"}>
                                        <FontAwesomeIcon icon={faCirclePlus}/> New
                                    </span>
                                } else if (row.status === 'changed') {
                                    return <span title={"Changed"}>
                                        <FontAwesomeIcon icon={faPencil}/> Changed
                                    </span>
                                } else if (row.status === 'error-duplicate') {

                                    return <span title={"Duplicate"}>
                                        <FontAwesomeIcon icon={faClone}/>{" "}
                                        Duplicate Unique value
                                    </span>
                                } else if (row.status === 'ok') {
                                    return <span title={"No Changes"}>
                                        <FontAwesomeIcon icon={faCheck}/>{" "}
                                        No Changes
                                    </span>
                                }

                                return <span title={row.statusDisplayString}>{row.statusDisplay}</span>
                            }
                        },
                        ...EXPECTED_LABELS.map(label => ({
                            name: toTitleCase(label.split('_').join(' ')),
                            sortable: true,

                            sortFunction: (rowA, rowB) => {
                                let a = getValueFromApiDataByLabel(label, rowA.newApiData)
                                let b = getValueFromApiDataByLabel(label, rowB.newApiData)

                                if (label === 'GROUP ID') {
                                    a = propertyGroupIdToDisplay(a)
                                    b = propertyGroupIdToDisplay(b)
                                }
                                if (a > b) return 1;
                                if (a < b) return -1;
                                return 0;
                            },
                            selector: row => {
                                const rawValue = row.rawData[label];
                                const fileValue = getValueFromApiDataByLabel(label, row.newApiData, row.fileData)
                                const apiKey = LABEL_TRANSLATION.hasOwnProperty(label) ? LABEL_TRANSLATION[label] : label

                                const requiredBadge = (REQUIRED_FIELDS.includes(apiKey) && [null, undefined, ''].includes(fileValue)) && !rawValue ?
                                    <Badge color='danger'>REQUIRED</Badge> : null
                                const invalidBadge = !fileValue && !!rawValue ?
                                    <Badge color='danger'>INVALID</Badge> : null

                                const duplicateBadge = (fileValue && duplicates.hasOwnProperty(label) && duplicates[label].includes(row.originalIndex)) ?
                                    <Badge color='danger'>DUPLICATE IN FILE</Badge> : null;

                                const fileValueDisplay = valueToDisplayWithContext(fileValue, label, rawValue);

                                let valueHasChanged = false
                                if (row.hasChanges) {
                                    const apiValue = getValueFromApiDataByLabel(label, row.apiData)
                                    valueHasChanged = !valueIsEqual(apiValue, fileValue)
                                    const usedBadge = (valueHasChanged && fileValue && usedValues.hasOwnProperty(label) && usedValues[label][fileValue]) ?
                                        <Badge color='danger'>ALREADY IN USE</Badge> : null;

                                    if (valueHasChanged) {
                                        const apiValueDisplay = valueToDisplayWithContext(apiValue, label)
                                        return <>
                                            <strike>{apiValueDisplay}</strike><br/>{fileValueDisplay} {requiredBadge}
                                            {(!!duplicateBadge || !!usedBadge || !!invalidBadge) ? <br/> : null}
                                            {invalidBadge} {duplicateBadge} {usedBadge}
                                        </>
                                    }
                                } else if (row.isNew) {
                                    valueHasChanged = true
                                }

                                const usedBadge = (valueHasChanged && fileValue && usedValues.hasOwnProperty(label) && usedValues[label][fileValue]) ?
                                    <Badge color='danger'>ALREADY IN USE</Badge> : null;


                                return <>
                                    <span title={fileValueDisplay}>{fileValueDisplay}</span>{requiredBadge}
                                    {(!!duplicateBadge || !!usedBadge || !!invalidBadge) ? <br/> : null}
                                    {invalidBadge} {duplicateBadge} {usedBadge}
                                </>
                            },
                        }))
                    ]}/>
            </Col>
        </Row>

    </>
}