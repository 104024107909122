import React from "react";
import {useLoggedInApi} from "../../api";
import {useQuery} from "react-query";


export function useTagGroup({id, params, useQueryOptions}) {
    const defaultParams = {
        // active_only: true
        page_size: 100,
    }
    const appliedParams = {
        ...params,
        ...defaultParams
    }

    const api = useLoggedInApi()
    return useQuery(['tagGroup', 'active', id, appliedParams], () => {
        const tagsGroupResource = api.resource('tag/group')
        if (id) {
            return tagsGroupResource.getItem(id, {
                params: appliedParams
            }).then(response => {
                return response.data
            })
        } else {
            return tagsGroupResource.list({
                params: appliedParams
            }).then(response => {
                return response.data
            })
        }
    }, {
        ...useQueryOptions,
    })
}

export function useActiveTags({params}) {
    const defaultParams = {
        active_only: true
    }
    const api = useLoggedInApi()
    return useQuery(['tagGroup', 'tags', 'active'], () => {
        const tagsGroupResource = api.resource('tag/tag')
        return tagsGroupResource.list({
            params: {
                ...params,
                ...defaultParams
            }
        }).then(response => {
            return response.data
        })
    })
}