/**
 * @fileoverview This file defines the Sidebar component which renders the sidebar of the application.
 */

// Importing necessary libraries and components
import react from "react";
import SidebarItem from "./sidebarItem";
import SideBarWrapper from "./sideBarWrapper";

/**
 * Sidebar component that renders the sidebar of the application.
 * @param {Object[]} items - The items to be displayed in the sidebar. Each item is an object with `icon`, `text`, and `to` properties.
 * @returns {JSX.Element|null} A SideBarWrapper component containing the sidebar items, or null if no items are provided.
 */
export default function Sidebar({items}) {
    // If no items are provided, return null
    if (!items || !items.length) return null

    // Render the SideBarWrapper component with the SidebarItem components as children
    return (
        <SideBarWrapper>
            {items.map(({icon, text, to}) => <SidebarItem key={to} icon={icon} text={text} to={to}/>)}
        </SideBarWrapper>
    );
}