import React, {useState} from "react";
import {Alert, Button, Col, Container, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Resource} from "../../api";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")

    const [fieldErrors, setFieldErrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const canSave = newPassword && repeatPassword && newPassword === repeatPassword
        && !Object.values(fieldErrors).some(fieldError => !!fieldError);

    const save = () => {
        setIsSaving(true)
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        const changePasswordResource = Resource('sales-register/reset_password')
        const changePasswordPromise = changePasswordResource.create({
            token: searchParams.get('token'),
            new_password: newPassword,
            repeat_password: repeatPassword
        }).then((response) => {
            setNewPassword("")
            setRepeatPassword("")
            navigate('/')
        }).catch(e => {
            if (e && e.response && e.response.data && typeof e.response.data === "object") {
                setFieldErrors({...e.response.data})
            } else {
                toast.error("Something went wrong while saving updating password. Please try again.")
            }
            throw e;
        }).finally(() => {
            setIsSaving(false)
        })
        toast.promise(changePasswordPromise, {
            pending: "Resetting password",
            success: "Password had been updated. Please login again."
        })
    }

    return <>

        <Container className="public-page py-5 px-md-5 my-md-5">
            <Row>
                <Col>
                    <h2>Reset your password</h2>
                </Col>
            </Row>
            {
                fieldErrors.token && <Row>
                    <Col>
                        <Alert color="danger">
                            Reset link is invalid or expired.
                        </Alert>
                    </Col>
                </Row>
            }
            <Row className="pt-3">
                <Col>
                    <FormGroup floating>
                        <Input
                            name="newPassword"
                            value={newPassword}
                            onChange={e => {
                                setNewPassword(e.target.value)
                                setFieldErrors({...fieldErrors, new_password: null})
                            }}
                            type="password"
                            placeholder={"New Password"}
                            invalid={!!fieldErrors.new_password}
                        />

                        <Label for="newPassword">New Password</Label>
                        <FormFeedback>
                            {fieldErrors.new_password}
                        </FormFeedback>
                    </FormGroup>

                </Col>
            </Row>
            <Row className="pb-3 pt-3">
                <Col>
                    <FormGroup floating>
                        <Input
                            name="repeatPassword"
                            value={repeatPassword}
                            onChange={e => {
                                setRepeatPassword(e.target.value)
                                setFieldErrors({...fieldErrors, repeat_password: null})
                            }}
                            type="password"
                            placeholder={"Repeat Password"}
                            invalid={!!fieldErrors.repeat_password || (!!repeatPassword && repeatPassword !== newPassword)}
                        />

                        <Label for="repeatPassword">Repeat Password</Label>

                        <FormFeedback>
                            {fieldErrors.repeat_password}
                            {(repeatPassword && repeatPassword !== newPassword) && "New password does not match"}
                        </FormFeedback>
                    </FormGroup>
                </Col>
            </Row>

            <Row className="pb-3">
                <Col>
                    <Button color="primary" disabled={!canSave || isSaving} onClick={() => {
                        // setIsLoggingIn(true)
                        // doLogin()
                        save()
                    }}>
                        RESET PASSWORD
                    </Button>
                </Col>
            </Row>
        </Container>
    </>
}