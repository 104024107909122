import React, {useRef, useState} from "react";
import Asset from "./asset";
import {Button, Col, Row} from "reactstrap";
import uuid from "react-uuid";
import Compress from 'compress.js'
import {toast} from "react-toastify";


const IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
const MAX_FILE_SIZE = 2 * 1024 * 1024  // 2MB


export default function AssetList({assets, maxDisplay, addAssets, updateAsset}) {
    if (!maxDisplay) {
        maxDisplay = 4
    }
    const compress = new Compress()

    const [page, setPage] = useState(0)
    const visibleAssetStart = page * maxDisplay
    const visibleAssets = assets.slice(visibleAssetStart, visibleAssetStart + maxDisplay)
    const inputFile = useRef()


    const pageCount = Math.ceil(assets.length / maxDisplay)
    const pages = [...Array(pageCount).keys()]
    const hasNext = page < (pageCount - 1)
    const hasPrev = page >= 1;
    const displayPagination = pageCount > 1

    return <>
        <Row>
            <Col>
                <Button color="info-outline" onClick={() => {
                    inputFile.current.click()
                }}>Add Asset</Button>
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    style={{display: "none"}}
                    accept="image/*,video/*"
                    multiple={false}
                    onChange={e => {
                        const imageFiles = [...e.target.files].filter(f => IMAGE_TYPES.includes(f["type"]))
                        if (imageFiles && imageFiles.length > 0) {
                            const compressPromise = compress.compress(imageFiles, {
                                size: 2, // the max size in MB, defaults to 2MB
                                quality: 1, // the quality of the image, max is 1,
                                maxWidth: 3840, // the max width of the output image, defaults to 1920px
                                maxHeight: 3840, // the max height of the output image, defaults to 1920px
                                resize: true // defaults to true, set false if you do not want to resize the image width and height
                            }).then(resizedImages => {
                                addAssets([
                                    ...Object.values(resizedImages).map(f => {
                                        return {
                                            localFile: f,
                                            isCompressed: true,
                                            id: 0,
                                            uuid: uuid(),
                                            status: "New"
                                        }
                                    })
                                ])
                            })

                            toast.promise(compressPromise, {
                                pending: "Preparing images",
                                success: "Images prepared for upload.",
                                error: "Cannot prepare images for upload"
                            })
                        }

                        // allow videos
                        const nonImageFiles = [...e.target.files].filter(f => !IMAGE_TYPES.includes(f["type"]));
                        const acceptableSizeFiles = nonImageFiles.filter(f => f.size <= MAX_FILE_SIZE)
                        const largeFiles = nonImageFiles.filter(f => f.size > MAX_FILE_SIZE)
                        if (largeFiles.length > 0) {
                            toast.error("The following files are too large: " + largeFiles.map(f => f.name).join(", "))
                        }
                        addAssets(acceptableSizeFiles.map(f => {
                            return {
                                localFile: f,
                                id: 0,
                                uuid: uuid(),
                                status: "New"
                            }
                        }))

                    }}
                />
            </Col>
        </Row>
        <Row className="align-items-center mt-2">
            {
                visibleAssets.map(asset => <Col lg={3} md={6} className="text-center">
                        <Asset
                            data={asset}
                            id={asset.uuid}
                            markDelete={(willDelete) => {
                                console.log("DELETE", asset, willDelete)
                                updateAsset(asset.uuid, {willDelete: willDelete})
                            }}
                        />
                    </Col>
                )
            }
        </Row>

        <Row>
            <Col className="text-end">
                {
                    displayPagination && <>
                        <Button
                            disabled={!hasPrev}
                            color="text"
                            className="mx-1"
                            onClick={() => setPage(page - 1)}
                        >
                            Previous
                        </Button>
                        {
                            pages.map(
                                pageNumber => <Button
                                    className="mx-1"
                                    onClick={() => {
                                        setPage(pageNumber)
                                    }}
                                    color="text"
                                    disabled={pageNumber === page}
                                >
                                    {pageNumber + 1}
                                </Button>
                            )
                        }
                        <Button
                            disabled={!hasNext}
                            className="mx-1"
                            color="text"
                            onClick={() => {
                                setPage(page + 1)
                            }}
                        >
                            Next
                        </Button>

                    </>
                }
            </Col>
        </Row>
    </>
}