import React from "react";
import {FormFeedback, Input} from "reactstrap";


export default function EmailField({value, onChange, invalid, ...props}) {
    const [localValue, setLocalValue] = React.useState(value)
    React.useEffect(() => {
        setLocalValue(value)
    }, [value])

    const isEmailValid = !localValue || localValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

    const handleChange = (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue)
        // if email is valid, use onChange
        if (newValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            onChange(e)
        }
    }

    return <>
        <Input
            className=""
            type={"email"}
            value={localValue}
            onChange={handleChange}
            invalid={!isEmailValid || invalid}
            {...props}
        />
        {
            !isEmailValid && <FormFeedback>
                Please enter a valid email address.
            </FormFeedback>
        }
    </>
}