import React, {useMemo} from "react";
import {useLoggedInApi} from "../../../../../api";
import {useQuery} from "react-query";
import {dictChangedValues, toTitleCase, valueIsEqual} from "../../../../../commonComponents/util";
import {Alert, Badge, Button, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation,
    faCirclePlus,
    faCircleXmark,
    faPencil
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {DEFAULT_BROKER_PROFILE} from "../../../../../commonComponents/hooks";

const customTableStyles = {
    rows: {
        style: {
            minHeight: '5em'
        }
    }
}

const COMMON_EXPECTED_LABELS = [

    "is_broker",
    "url_path",
    "is_active",
    "position",

    "last_name",
    "first_name",
    "middle_name",
    "ext_name",
    "dob",
    "place_of_birth",
    "gender",
    "civil_status",
    "citizenship",
    "tin_number",
    "address",
    "phone_number",
    "mobile_number",
    "email_address",

    "licenses",
    "public_profile_description"
]

const BROKER_EXPECTED_LABELS = [
    "active_group_seller_id",
    "group_name",
    "is_group",
    ...COMMON_EXPECTED_LABELS,
    "properties",
]

const SALES_PERSON_EXPECTED_LABELS = [
    "active_group_seller_id",
    "affiliation",
    ...COMMON_EXPECTED_LABELS,
]

const LABEL_TO_LOCATION = {
    "first_name": "user",
    "last_name": "user",
    "middle_name": "profile",
    "ext_name": "profile",
    "dob": "profile",
    "place_of_birth": "profile",
    "gender": "profile",
    "civil_status": "profile",
    "citizenship": "profile",
    "tin_number": "profile",
    "address": "profile",
    "phone_number": "profile",
    "mobile_number": "profile",
    "last_login": "profile",
    "position": "profile",
    "email_address": "user",
}
const LABEL_TRANSLATION = {
    'properties': 'assigned_properties',
    'address': 'address_details',
    'email_address': 'email',
    'affiliation': 'group',
}


const COMMON_LABEL_REQUIRED_FIELDS = [
    'active_group_seller_id',

    'is_broker',
    'position',
    'last_name',
    'first_name',
    'gender',
    'mobile_number',
    'email_address',
    'url_path',
]

const BROKER_REQUIRED_FIELDS = [
    ...COMMON_LABEL_REQUIRED_FIELDS,
    'is_group',
    'tin_number',
    "licenses",
]

const SALES_PERSON_REQUIRED_FIELDS = [
    ...COMMON_LABEL_REQUIRED_FIELDS,
    'affiliation',
]

const ROW_STATUS_STYLES = [
    {
        when: row => row.status === 'new',
        style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status === 'error-conflict',
        style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status.startsWith('error-') && row.status !== 'error-conflict',
        style: {
            backgroundColor: 'rgba(255,102,88,0.9)',
            color: 'white',
        }
    },
    {
        when: row => row.status === 'changed',
        style: {
            backgroundColor: 'rgba(248, 148, 6, 0.9)',
            color: 'white',
        }
    },

]

const FORMATTERS_BY_LABEL = {
    mobile_number: (value) => value ? '0' + value.toString().slice(-10) : value,
    phone_number: (value) => value ? '0' + value.toString().slice(-10) : value,
    dob: (value) => {
        if (value) {
            try {
                return moment(value).format('YYYY-MM-DD')
            } catch (e) {
                return null
            }

        }
        return null
    },
    properties: (value) => {
        if (!value) {
            return []
        }
        if (typeof value === 'string') {
            return value.split(',')
        }
        return value
    }
}

const stringToBoolean = (string) => {
    if (string === null || string === undefined) return false;

    switch (string.toString().toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
            return false;

        default:
            return Boolean(string);
    }
}

const statusSortRows = (rowA, rowB) => {
    const a = rowA.status;
    const b = rowB.status;
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

const matchOriginalType = (originalValue, newValue, key) => {
    const formattedNewValue = FORMATTERS_BY_LABEL.hasOwnProperty(key) ? FORMATTERS_BY_LABEL[key](newValue) : newValue

    if (!formattedNewValue) {
        if (typeof originalValue === 'string') {
            return ''
        } else if (originalValue === null) {
            return null
        }
    }
    if (typeof originalValue !== typeof formattedNewValue) {
        if (typeof originalValue === 'string') {
            return formattedNewValue.toString()
        } else if (typeof originalValue === 'boolean') {
            return stringToBoolean(formattedNewValue)
        }
    }
    return formattedNewValue
}


const remapData = (row, expectedFields) => {
    return expectedFields.reduce((acc, key) => {
        const apiKey = LABEL_TRANSLATION.hasOwnProperty(key) ? LABEL_TRANSLATION[key] : key
        const value = row[key];

        if (LABEL_TO_LOCATION.hasOwnProperty(key)) {
            const parentKey = LABEL_TRANSLATION.hasOwnProperty(LABEL_TO_LOCATION[key]) ? LABEL_TRANSLATION[LABEL_TO_LOCATION[key]] : LABEL_TO_LOCATION[key]
            if (!acc.hasOwnProperty(parentKey)) {
                acc[parentKey] = {}
            }
            acc[parentKey][apiKey] = matchOriginalType(DEFAULT_BROKER_PROFILE[parentKey][apiKey], value, key)
        } else {
            acc[apiKey] = matchOriginalType(DEFAULT_BROKER_PROFILE[apiKey], value, key)
        }
        return acc
    }, {});
}

const getValueFromApiDataByLabel = (key, apiData, rawData) => {
    const apiKey = LABEL_TRANSLATION.hasOwnProperty(key) ? LABEL_TRANSLATION[key] : key
    if (LABEL_TO_LOCATION.hasOwnProperty(key)) {
        const parentKey = LABEL_TRANSLATION.hasOwnProperty(LABEL_TO_LOCATION[key]) ? LABEL_TRANSLATION[LABEL_TO_LOCATION[key]] : LABEL_TO_LOCATION[key]
        return apiData[parentKey][apiKey]
    }
    if (key === 'affiliation' && !apiData[apiKey]) {
        return rawData[key]
    }
    return apiData[apiKey]
}

const statusSpinner = <><Spinner size={"sm"}/> Checking</>
const statusCheck = <><FontAwesomeIcon icon={faCircleCheck}/> No Changes</>

const resolveStatusDisplay = (row, requiredFields, expectedBroker) => {
    const hasChanges = row.hasChanges;
    const hasDuplicate = row.hasDuplicate
    const hasMissingRequiredField = requiredFields.some(label => !row.fileData[label])

    let status;
    let statusDisplay;
    let statusDisplayString;

    if (row.isLoading) {
        status = 'loading'
        statusDisplay = statusSpinner
        statusDisplayString = 'Loading'
    } else if ((expectedBroker && !row.isBroker) || (!expectedBroker && row.isBroker)) {
        status = 'error-not-match-profile-type'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleExclamation}/>{" "}
            Not a {expectedBroker ? "broker" : "salesperson"}
        </>;
        statusDisplayString = 'Row is not a ' + (expectedBroker ? "broker" : "salesperson") + ' profile.'
    } else if (row.isUnauthorized) {
        status = 'error-conflict'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleXmark}/>{" "}
            Existing. No permission to edit.
        </>;
        statusDisplayString = 'Existing. You have no permission to edit.'
    } else if (hasMissingRequiredField) {
        status = 'error-incomplete'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleXmark}/>{" "}
            Missing required fields
        </>;
        statusDisplayString = 'Has missing fields that are required'
    } else if (hasDuplicate) {
        status = 'error-file-duplicate'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleXmark}/>{" "}
            Has duplicate value in file
        </>;
        statusDisplayString = 'Some values are duplicates of other rows in the file.'
    } else if (row.hasUsedValues) {
        status = 'error-unique'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleXmark}/>{" "}
            Has values that are already used.
        </>;
        statusDisplayString = 'Some values are already in use by other profiles.'
    } else if (!expectedBroker && !row.processedData.group) {

        status = 'error-affiliation'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCircleExclamation}/>{" "}
            Salesperson's affiliation cannot be found.
        </>;
        statusDisplayString = "Salesperson's affiliation cannot be found."

    } else if (row.isNew) {
        status = 'new'
        statusDisplay = <>
            <FontAwesomeIcon icon={faCirclePlus}/> New
        </>
        statusDisplayString = 'New profile.'
    } else if (hasChanges) {
        status = 'changed'
        statusDisplay = <>
            <FontAwesomeIcon icon={faPencil}/> Changed
        </>
        statusDisplayString = 'Has changes.'
    } else {
        status = 'ok'
        statusDisplay = statusCheck
        statusDisplayString = 'No changes detected.'
    }

    return {status, statusDisplay, statusDisplayString}
}

const valueToDisplay = (value) => {
    if (value === false) {
        return 'FALSE'
    } else if (value === true) {
        return 'TRUE'
    } else if (!value) {
        return <i>EMPTY</i>
    }
    return value
}

export default function Review({fileData, fileName, isFileRead, onDataProcessed, previousStep, isBroker}) {
    const api = useLoggedInApi()
    const resourceName = isBroker ? 'broker' : 'salesperson';
    const profileApi = api.resource(resourceName);

    const requiredFields = isBroker ? BROKER_REQUIRED_FIELDS : SALES_PERSON_REQUIRED_FIELDS;
    const expectedFields = isBroker ? BROKER_EXPECTED_LABELS : SALES_PERSON_EXPECTED_LABELS;

    const remappedFileData = useMemo(() => {
        return fileData.map((row) => {
            return remapData(row, expectedFields)
        })
    }, [fileData])

    const retrievedProfilesResult = useQuery([resourceName, 'bulk-profile-check', fileName], () => {
        return profileApi
            .post('get_by_active_group_id/', {profiles: remappedFileData})
            .then(response => response.data)
    }, {
        enabled: isFileRead
    })

    const changedOrNewEmails = remappedFileData.filter(remappedData => {
        const activeGroupSellerId = remappedData.active_group_seller_id;
        const apiData = retrievedProfilesResult.data?.editable[activeGroupSellerId]
        return (apiData && remappedData.user.email !== apiData.user.email) || (retrievedProfilesResult.data?.new.includes(activeGroupSellerId))
    }).map(row => row.user.email)

    const retrievedEmailsResult = useQuery([resourceName, 'bulk-email-check', fileName], () => {
        return profileApi
            .post('email_exists/', {emails: changedOrNewEmails})
            .then(response => response.data)
    }, {
        enabled: isFileRead && retrievedProfilesResult.isSuccess
    })


    const changedOrNewGroups = remappedFileData.filter(remappedData => {
        const activeGroupSellerId = remappedData.active_group_seller_id;
        const apiData = retrievedProfilesResult.data?.editable[activeGroupSellerId]
        return (apiData && remappedData.group_name !== apiData.group_name) || (retrievedProfilesResult.data?.new.includes(activeGroupSellerId))
    }).map(row => row.group_name)

    const retrievedGroupsResult = useQuery(['broker', 'bulk-group-check', fileName], () => {
        return profileApi
            .post('group_exists/', {groups: changedOrNewGroups})
            .then(response => response.data)
    }, {
        enabled: isBroker && isFileRead && retrievedProfilesResult.isSuccess
    })

    const changedOrNewUrls = remappedFileData.filter(remappedData => {
        const activeGroupSellerId = remappedData.active_group_seller_id;
        const apiData = retrievedProfilesResult.data?.editable[activeGroupSellerId]
        return (apiData && remappedData.url_path !== apiData.url_path) || (retrievedProfilesResult.data?.new.includes(activeGroupSellerId))
    }).map(row => row.url_path)

    const retrievedUrlsResult = useQuery([resourceName, 'bulk-url-check', fileName], () => {
        return profileApi
            .post('url_exists/', {urls: changedOrNewUrls})
            .then(response => response.data)
    }, {
        enabled: isFileRead && retrievedProfilesResult.isSuccess
    })

    // select property groups
    const propertyGroupsQuery = useQuery('property-groups', () => {
        const propertyGroupResource = api.resource('properties/groups')
        return propertyGroupResource.list().then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        enabled: isBroker
    })

    // validate affiliation selected

    const existingBrokerGroups = useQuery(['broker-groups'], () => {
        return profileApi.get('broker_groups/').then(response => response.data)
    }, {
        staleTime: 5 * 60 * 1000,
        enabled: !isBroker
    })
    const groupNameToId = existingBrokerGroups.data?.reduce((acc, row) => {
        acc[row.name.toString().toLowerCase()] = row.id
        return acc;
    }, {})
    const groupActiveGroupIdToId = existingBrokerGroups.data?.reduce((acc, row) => {
        acc[row.broker_active_group_seller_id.toString().toLowerCase()] = row.id
        return acc;
    }, {})
    const groupIdToDisplay = existingBrokerGroups.data?.reduce((acc, row) => {
        acc[row.id] = <>
            <strong>{row.name}</strong><br/>
            <small>{row.broker_active_group_seller_id}</small>
        </>
        return acc;
    }, {})

    const affiliationToId = affiliation => {
        console.log("groupActiveGroupIdToId", affiliation, groupActiveGroupIdToId)
        if (!groupActiveGroupIdToId || !groupNameToId) {
            return null
        }
        if (groupActiveGroupIdToId.hasOwnProperty(affiliation)) {
            return groupActiveGroupIdToId[affiliation]
        } else if (groupActiveGroupIdToId.hasOwnProperty(affiliation.toString())) {
            return groupActiveGroupIdToId[affiliation.toString()]
        } else if (groupActiveGroupIdToId.hasOwnProperty(affiliation.toString().toLowerCase())) {
            return groupActiveGroupIdToId[affiliation.toString().toLowerCase()]
        } else if (groupActiveGroupIdToId.hasOwnProperty(affiliation.toString().toUpperCase())) {
            return groupActiveGroupIdToId[affiliation.toString().toUpperCase()]
        } else if (groupActiveGroupIdToId.hasOwnProperty(affiliation.toString().padStart(5, '0'))) {
            return groupActiveGroupIdToId[affiliation.toString().padStart(5, '0')]
        }
        if (groupNameToId.hasOwnProperty(affiliation)) {
            return groupNameToId[affiliation]
        }
        return null
    }

    const propertyGroupsByActiveGroupId = propertyGroupsQuery.data ?
        propertyGroupsQuery.data.reduce((acc, row) => {
            acc[row.unique_property_group_id.toLowerCase()] = row
            return acc
        }, {}) : {};
    const propertyGroupsById = propertyGroupsQuery.data ?
        propertyGroupsQuery.data.reduce((acc, row) => {
            acc[row.id] = row
            return acc
        }, {}) : {};

    const propertyIdsToDisplay = propertyIds => {
        if (propertyGroupsQuery.isSuccess && propertyIds && Array.isArray(propertyIds)) {
            return propertyIds.map(propertyId => propertyGroupsById[propertyId].name).sort().join(', ')
        }
        return null
    }

    const duplicates = useMemo(() => {
        if (!isFileRead) {
            return {
                email_address: [],
                group_name: [],
                active_group_seller_id: [],
                url_path: [],
            }
        }

        const emails = {}
        const groups = {}
        const ids = {}
        const urls = {}

        fileData.forEach((item, index) => {
            emails[item.email_address] = emails[item.email_address] || [];
            groups[item.group_name] = groups[item.group_name] || [];
            ids[item.active_group_seller_id] = ids[item.active_group_seller_id] || [];
            urls[item.url_path] = urls[item.url_path] || [];

            emails[item.email_address].push(index)
            groups[item.group_name].push(index)
            ids[item.active_group_seller_id].push(index)
            urls[item.url_path].push(index)
        })

        return {
            email_address: [].concat(...Object.values(emails).filter(values => values.length > 1)),
            group_name: isBroker ? [].concat(...Object.values(groups).filter(values => values.length > 1)) : [],
            active_group_seller_id: [].concat(...Object.values(ids).filter(values => values.length > 1)),
            url_path: [].concat(...Object.values(urls).filter(values => values.length > 1)),
        }
    }, [fileData])
    const rowsWithDuplicates = [].concat(...Object.values(duplicates))


    const isCheckingApi = isBroker ?
        !(retrievedGroupsResult.isSuccess && retrievedProfilesResult.isSuccess && retrievedEmailsResult.isSuccess && retrievedUrlsResult.isSuccess) :
        !(retrievedProfilesResult.isSuccess && retrievedEmailsResult.isSuccess && retrievedUrlsResult.isSuccess && existingBrokerGroups.isSuccess)

    const processedData = fileData.map((row, idx) => {
        const remappedData = remappedFileData[idx];
        if (isBroker) {
            remappedData.assigned_properties = remappedData.assigned_properties
                .map(uniqueId => uniqueId.toString().trim().toLowerCase())
                .filter(uniqueId => propertyGroupsByActiveGroupId.hasOwnProperty(uniqueId))
                .map(uniqueId => propertyGroupsByActiveGroupId[uniqueId].id)
        } else {
            remappedData.group = affiliationToId(row.affiliation?.toString().toLowerCase())
        }

        const activeGroupSellerId = remappedData.active_group_seller_id;

        const result = {
            fileData: {...row},
            processedData: remappedData,
            hasChanges: false,
            hasDuplicate: rowsWithDuplicates.includes(idx),
            originalIndex: idx,
            isNew: retrievedProfilesResult.data?.new.includes(activeGroupSellerId),
            isUnauthorized: retrievedProfilesResult.data?.unauthorized.includes(activeGroupSellerId),
            apiData: retrievedProfilesResult.data?.editable[activeGroupSellerId],
            isBroker: !!remappedData.is_broker,
            isLoading: isCheckingApi,
        }

        const valueUsedCheckerResults = []

        if (result.apiData) {
            result.apiId = result.apiData.id
            result.changedData = dictChangedValues(result.apiData, remappedData, true)
            result.hasChanges = Object.keys(result.changedData).length > 0

            // if email has changed
            if (remappedData.user.email !== result.apiData.user.email) {
                if (!retrievedEmailsResult.isSuccess) {
                    valueUsedCheckerResults.push(null)
                } else {
                    valueUsedCheckerResults.push(!retrievedEmailsResult.data[remappedData.user.email])
                }
            }
            // if group name has changed
            if (isBroker && remappedData.group_name !== result.apiData.group_name) {
                if (!retrievedGroupsResult.isSuccess) {
                    valueUsedCheckerResults.push(null)
                } else {
                    valueUsedCheckerResults.push(!retrievedGroupsResult.data[remappedData.group_name])
                }
            }
            // if url has changed
            if (remappedData.url_path !== result.apiData.url_path) {
                if (!retrievedUrlsResult.isSuccess) {
                    valueUsedCheckerResults.push(null)
                } else {
                    valueUsedCheckerResults.push(!retrievedUrlsResult.data[remappedData.url_path])
                }
            }
        }

        // this row is new
        if (result.isNew) {
            if (!retrievedEmailsResult.isSuccess) {
                valueUsedCheckerResults.push(null)
            } else {
                valueUsedCheckerResults.push(!retrievedEmailsResult.data[remappedData.user.email])
            }
            if (!retrievedGroupsResult.isSuccess) {
                valueUsedCheckerResults.push(null)
            } else {
                valueUsedCheckerResults.push(!retrievedGroupsResult.data[remappedData.group_name])
            }
            if (!retrievedUrlsResult.isSuccess) {
                valueUsedCheckerResults.push(null)
            } else {
                valueUsedCheckerResults.push(!retrievedUrlsResult.data[remappedData.url_path])
            }
        }
        result.finishedChecks = valueUsedCheckerResults.every(val => val !== null)
        result.hasUsedValues = valueUsedCheckerResults.some(val => val === false)

        const {status, statusDisplay, statusDisplayString} = resolveStatusDisplay(
            result,
            requiredFields,
            isBroker
        )

        result.status = status
        result.statusDisplay = statusDisplay
        result.statusDisplayString = statusDisplayString
        return result
    })

    const usedValues = {
        'email_address': retrievedEmailsResult.isSuccess ? retrievedEmailsResult.data : {},
        'url_path': retrievedUrlsResult.isSuccess ? retrievedUrlsResult.data : {},
        'group_name': retrievedGroupsResult.isSuccess ? retrievedGroupsResult.data : {}
    }

    const valueToDisplayWithContext = (value, label) => {
        if (label === 'properties') {
            return propertyIdsToDisplay(value)
        } else if (label === 'affiliation') {
            if (!value) {
                return null
            }
            if (!groupIdToDisplay) {
                return <>
                    <Spinner size="sm"/> <i>{value}</i>
                </>
            } else if (groupIdToDisplay.hasOwnProperty(value)) {
                return groupIdToDisplay[value]
            } else {
                return <>
                    <i>{value}</i> <br/>
                    <Badge color={"warning"} size={"sm"}>GROUP NOT FOUND</Badge>
                </>
            }
        }

        return valueToDisplay(value)
    }

    return <>
        <Row className={"mt-4"}>
            <Col><h4>Step 2 - Review</h4></Col>
        </Row>
        <Row>
            <Col>
                <Button color="link" onClick={previousStep}>
                    <small>Go Back To Step 1 - Select File</small>
                </Button>
            </Col>
        </Row>

        <Row className={"mt-3"}>
            <Col><h5>Total Rows: {processedData.length}</h5></Col>
        </Row>


        <Row className={"mt-3"}>
            <Col>
                Checking Active Group Seller IDs{" "}
                {
                    (retrievedProfilesResult.isLoading || retrievedProfilesResult.isIdle) ? <Spinner size="sm"/> : (
                        retrievedProfilesResult.isSuccess ? "... done!" : "... error!"
                    )
                }
            </Col>
        </Row>
        <Row>
            <Col>
                Checking email addresses{" "}
                {
                    (retrievedEmailsResult.isLoading || retrievedEmailsResult.isIdle) ? <Spinner size="sm"/> : (
                        retrievedEmailsResult.isSuccess ? "... done!" : "... error!"
                    )
                }
            </Col>
        </Row>
        {
            isBroker && <>
                <Row>
                    <Col>
                        Checking groups{" "}
                        {
                            (retrievedGroupsResult.isLoading || retrievedGroupsResult.isIdle) ? <Spinner size="sm"/> : (
                                retrievedGroupsResult.isSuccess ? "... done!" : "... error!"
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Checking URL paths for microsite{" "}
                        {
                            (retrievedUrlsResult.isLoading || retrievedUrlsResult.isIdle) ? <Spinner size="sm"/> : (
                                retrievedUrlsResult.isSuccess ? "... done!" : "... error!"
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Checking assigned properties{" "}
                        {
                            (propertyGroupsQuery.isLoading || propertyGroupsQuery.isIdle) ? <Spinner size="sm"/> : (
                                propertyGroupsQuery.isSuccess ? "... done!" : "... error!"
                            )
                        }
                    </Col>
                </Row>
            </>
        }
        {
            !isBroker && <Row>
                <Col>
                    Checking affiliations{" "}
                    {
                        (existingBrokerGroups.isLoading || existingBrokerGroups.isIdle) ? <Spinner size="sm"/> : (
                            existingBrokerGroups.isSuccess ? "... done!" : "... error!"
                        )
                    }
                </Col>
            </Row>
        }


        <Row className={"mt-3"}>
            <Col>Will not process the following profiles</Col>
        </Row>
        {
            processedData.filter(row => row.status === 'ok').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleCheck}/> No changes:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'ok').length}
                    </strong>
                </Col>
            </Row>
        }
        {
            processedData.filter(row => row.status === 'error-not-match-profile-type').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleExclamation}/> Not {isBroker ? 'broker' : 'salesperson'} profiles:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-not-match-profile-type').length}
                    </strong>
                </Col>
            </Row>
        }

        {
            processedData.filter(row => row.status === 'error-affiliation').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleExclamation}/> Invalid affiliation: {" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-affiliation').length}
                    </strong>
                </Col>
            </Row>
        }

        {
            processedData.filter(row => row.status === 'error-conflict').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleXmark}/> Existing but you no permission to edit:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-conflict').length}
                    </strong>
                </Col>
            </Row>
        }
        {
            processedData.filter(row => row.status === 'error-incomplete').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleXmark}/> Incomplete profile:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-incomplete').length}
                    </strong>
                </Col>
            </Row>
        }
        {
            processedData.filter(row => row.status === 'error-file-duplicate').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleXmark}/> Duplicate values found in file:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-file-duplicate').length}
                    </strong>
                </Col>
            </Row>
        }
        {
            processedData.filter(row => row.status === 'error-unique').length > 0 && <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircleXmark}/> Values already used by other profiles:{" "}
                    <strong>
                        {processedData.filter(row => row.status === 'error-unique').length}
                    </strong>
                </Col>
            </Row>
        }


        <Row className={"mt-3"}>
            <Col>Profiles for update/create</Col>
        </Row>
        <Row>
            <Col>
                <FontAwesomeIcon icon={faPencil}/> Changed Profiles:{" "}
                <strong>
                    {processedData.filter(row => row.status === 'changed').length}
                </strong>
            </Col>
        </Row>
        <Row>
            <Col>
                <FontAwesomeIcon icon={faCirclePlus}/> New Profiles:{" "}
                <strong>
                    {processedData.filter(row => row.status === 'new').length}
                </strong>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <Button
                    color={'primary'}
                    onClick={() => {
                        onDataProcessed(processedData)
                    }}
                    disabled={processedData.filter(row => row.status === 'new' || row.status === 'changed').length === 0 || isCheckingApi}>
                    Continue
                </Button>

                {processedData.filter(row => row.status === 'new' || row.status === 'changed').length === 0 &&
                    <Alert color="danger" className="mt-4 small">No profiles to commit</Alert>}
            </Col>
        </Row>

        <hr className="mt-5"/>

        <Row className={"mt-5"}>
            <Col>
                <DataTable
                    noDataComponent={<i>No data. Select a file and click process.</i>}
                    striped
                    responsive
                    persistTableHead
                    pagination
                    progressPending={!isFileRead && (isCheckingApi)}
                    data={processedData}
                    conditionalRowStyles={ROW_STATUS_STYLES}
                    customStyles={customTableStyles}
                    columns={isFileRead ? [
                        {
                            name: "Status",
                            sortable: true,
                            sortFunction: statusSortRows,
                            selector: row => <span title={row.statusDisplayString}>{row.statusDisplay}</span>
                        },
                        ...expectedFields.map(label => ({
                            name: toTitleCase(label.split('_').join(' ')),
                            sortable: true,

                            sortFunction: (rowA, rowB) => {
                                let a = getValueFromApiDataByLabel(label, rowA.processedData)
                                let b = getValueFromApiDataByLabel(label, rowB.processedData)

                                if (label === 'properties') {
                                    a = propertyIdsToDisplay(a)
                                    b = propertyIdsToDisplay(b)
                                }
                                if (a > b) return 1;
                                if (a < b) return -1;
                                return 0;
                            },
                            selector: row => {
                                const fileValue = getValueFromApiDataByLabel(label, row.processedData, row.fileData)
                                const requiredBadge = (requiredFields.includes(label) && [null, undefined, ''].includes(fileValue)) ?
                                    <Badge color='danger'>REQUIRED</Badge> : null
                                const duplicateBadge = (fileValue && duplicates.hasOwnProperty(label) && duplicates[label].includes(row.originalIndex)) ?
                                    <Badge color='danger'>DUPLICATE IN FILE</Badge> : null;

                                const fileValueDisplay = valueToDisplayWithContext(fileValue, label);

                                let valueHasChanged = false
                                if (row.hasChanges) {
                                    const apiValue = getValueFromApiDataByLabel(label, row.apiData)
                                    valueHasChanged = !valueIsEqual(apiValue, fileValue)
                                    const usedBadge = (valueHasChanged && fileValue && usedValues.hasOwnProperty(label) && usedValues[label][fileValue]) ?
                                        <Badge color='danger'>ALREADY IN USE</Badge> : null;

                                    if (valueHasChanged) {
                                        const apiValueDisplay = valueToDisplayWithContext(apiValue, label)
                                        return <>
                                            <strike>{apiValueDisplay}</strike><br/>{fileValueDisplay} {requiredBadge}
                                            {(!!duplicateBadge || !!usedBadge) ? <br/> : null}
                                            {duplicateBadge} {usedBadge}
                                        </>
                                    }
                                } else if (row.isNew) {
                                    valueHasChanged = true
                                }

                                const usedBadge = (valueHasChanged && fileValue && usedValues.hasOwnProperty(label) && usedValues[label][fileValue]) ?
                                    <Badge color='danger'>ALREADY IN USE</Badge> : null;


                                return <>
                                    <span title={fileValueDisplay}>{fileValueDisplay}</span>{requiredBadge}
                                    {(!!duplicateBadge || !!usedBadge) ? <br/> : null}
                                    {duplicateBadge} {usedBadge}
                                </>
                            },
                        }))
                    ] : []}/>
            </Col>
        </Row>

        <div className="clearfix mb-3"/>
    </>
}