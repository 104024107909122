import React from "react";
import {dictChangedValues, dictEqualShallow} from "../../../util";
import {useLeadForm, useTagGroups} from "../../../hooks";



const prepTagFormForComparison = (tag) => {
    const _tag = {...tag}
    delete _tag.tag_data
    delete _tag.updated_at
    delete _tag.created_at
    delete _tag.sales_lead
    delete _tag.id
    return _tag
}

const isTagFormEqual = (tag1, tag2) => {
    if (tag1.tag !== tag2.tag) {
        return false
    }
    const _tag1 = prepTagFormForComparison(tag1)
    const _tag2 = prepTagFormForComparison(tag2)

    return dictEqualShallow(_tag1, _tag2)
}

export const useDetectedTagChanges = (primaryLead, secondaryLead) => {
    const {data: tagGroups, isLoading} = useTagGroups({})

    const changedTagsByTagGroup = tagGroups?.map(tagGroup => {
        if (!primaryLead || !secondaryLead) {
            return {
                tagGroup,
                isChanged: false
            }
        }
        const primaryTags = primaryLead.form_tags.filter(row => row.tag_data.tag_group === tagGroup.id)
        const secondaryTags = secondaryLead.form_tags.filter(row => row.tag_data.tag_group === tagGroup.id)

        // the following should cover all cases
        const isChanged = primaryTags?.length !== secondaryTags?.length || primaryTags.some(primaryTag => {
            const secondaryTag = primaryTag.tag_data.multiple_source ?
                secondaryTags.find(row => row.tag === primaryTag.tag && row.source_object_id === primaryTag.source_object_id && row.source_text === primaryTag.source_text) :
                secondaryTags.find(row => row.tag === primaryTag.tag)

            if (!secondaryTag) {
                return true
            }
            return !isTagFormEqual(primaryTag, secondaryTag)
        })

        return {
            tagGroup,
            isChanged
        }
    }) || []

    const tagChanges = React.useMemo(() => {
        const tagsToAdd = [], tagsToDelete = [], tagsToUpdate = [];
        changedTagsByTagGroup.filter(row => row.isChanged).forEach(row => {
            const primaryTags = primaryLead.form_tags.filter(tag => tag.tag_data.tag_group === row.tagGroup.id);
            const secondaryTags = secondaryLead.form_tags.filter(tag => tag.tag_data.tag_group === row.tagGroup.id);
            if (row.isChanged) {
                secondaryTags.forEach(secondaryTag => {
                    const matchingPrimaryTags = primaryTags.filter(row => row.tag === secondaryTag.tag);
                    if (!matchingPrimaryTags.length || (secondaryTag.tag_data.multiple_source && !matchingPrimaryTags.some(matchingPrimaryTag => matchingPrimaryTag.source_object_id === secondaryTag.source_object_id && matchingPrimaryTag.source_text === secondaryTag.source_text))) {
                        tagsToAdd.push({...prepTagFormForComparison(secondaryTag), sales_lead: primaryLead.id, __isNew: true});
                    } else if (!secondaryTag.tag_data.multiple_source && !isTagFormEqual(matchingPrimaryTags[0], secondaryTag)) {
                        const changedTagData = dictChangedValues(prepTagFormForComparison(matchingPrimaryTags[0]), prepTagFormForComparison(secondaryTag));
                        tagsToUpdate.push({
                            ...changedTagData,
                            id: matchingPrimaryTags[0].id,
                            tag: matchingPrimaryTags[0].tag,
                        });
                    }
                });
                primaryTags.forEach(primaryTag => {
                    if (!secondaryTags.some(secondaryTag => secondaryTag.tag === primaryTag.tag)) {
                        tagsToDelete.push({id: primaryTag.id, _delete: true, tag: primaryTag.tag});
                    }
                });
            }
        });
        return [...tagsToDelete, ...tagsToAdd, ...tagsToUpdate];
    }, [changedTagsByTagGroup, primaryLead, secondaryLead]);

    return {
        isLoading,
        changedTagsByTagGroup,
        tagChanges
    }
}

export const useDetectedAnswerChanges = (primaryLead, secondaryLead) => {
    const {data: leadForm, isLoading: isLoadingLeadForm} = useLeadForm()
    if (!primaryLead || !secondaryLead || isLoadingLeadForm) {
        return {
            changedFormFields: [],
            answerChanges: [],
            isLoading: isLoadingLeadForm
        }
    }
    const leadFormFields = leadForm?.form_fields || []

    const changedFormFields = leadFormFields.filter(formField => {
        if (formField.field_type === 'tag') {
            return false
        }
        const primaryAnswer = primaryLead.form_answers.find(row => row.form_field === formField.id)
        const secondaryAnswer = secondaryLead.form_answers.find(row => row.form_field === formField.id)
        return primaryAnswer?.answer !== secondaryAnswer?.answer
    })
    const answerChanges = changedFormFields.map(formField => {
        const primaryAnswer = primaryLead.form_answers.find(row => row.form_field === formField.id)
        const secondaryAnswer = secondaryLead.form_answers.find(row => row.form_field === formField.id)
        return {
            form_field: formField.id,
            answer: secondaryAnswer?.answer,
            id: primaryAnswer?.id
        }
    })

    return {
        changedFormFields,
        answerChanges,
        isLoading: isLoadingLeadForm
    }
}