import React from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";


export default function Message({from, message, date, isOwnMessage}) {
    console.log(isOwnMessage)
    const messageDisplay = message.includes("\n") ?
        message.split("\n").map(line => <p>{line}</p>) :
        message
    const inquiryTime = moment(date)

    return <div className={isOwnMessage ? "text-end" : "text-start"}>

        <Row>
            <Col className="small">
                <small>
                    <strong title={from}>
                        {isOwnMessage ? "You" : from}
                    </strong> - {" "}
                    <span title={inquiryTime.toLocaleString()}>
                        {inquiryTime.fromNow()}
                    </span>
                </small>
            </Col>
        </Row>
        {
            messageDisplay
        }
    </div>
}