import React from "react";
import {Col, Container, Row} from "reactstrap";
import PrivacyPolicy from "./privacyPolicy";

export default function DashboardFooter(props) {

    return <footer className="text-center text-lg-start activegroup-footer">
        <Container>
            <Row className="small align-items-center">
                <Col className="my-2">
                    v{process.env.REACT_APP_VERSION} © {new Date().getFullYear()} Copyright
                </Col>
                <Col className="text-end my-2">
                    <PrivacyPolicy/>
                </Col>
            </Row>
        </Container>
    </footer>

}