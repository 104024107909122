import {useEffect, useState} from "react";

const __version__ = '1.0.0'

function isDict(v) {
    return typeof v==='object' && v!==null && !(v instanceof Array) && !(v instanceof Date);
}

export function validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
}

export function isArrayEqual(array1, array2) {
    const array2Sorted = array2.slice().sort();
    return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
        return value === array2Sorted[index];
    });
}

export function dictEqualShallow(dictA, dictB) {
    const dictAKeys = Object.keys(dictA);
    const dictBKeys = Object.keys(dictB);

    if (dictAKeys.length !== dictBKeys.length) return false
    // check if all keys in dictA exists in dictB
    if (dictAKeys.some(aKey => dictBKeys.indexOf(aKey) === -1)) return false
    // check if contents of dictA and dictB has changes in values
    if (dictAKeys.some(aKey => {
        if (isDict(dictA[aKey])) {
            return !dictEqualShallow(dictA[aKey], dictB[aKey])
        } else if (Array.isArray(dictA[aKey])) {
            return !isArrayEqual(dictA[aKey], dictB[aKey])
        }
        return dictA[aKey] !== dictB[aKey]
    })) return false
    return true
}

export function dictChangedValues(dictA, dictB, ignoreMissingInDictB) {
    const _ignoreMissing = ignoreMissingInDictB === undefined ? false : !!ignoreMissingInDictB;
    return Object.keys(dictA).reduce((acc, k) => {
        if (isDict(dictA[k]) && isDict(dictB[k])) {
            if (!dictEqualShallow(dictA[k], dictB[k])) {
                const changedSubDict = dictChangedValues(dictA[k], dictB[k], _ignoreMissing)
                if (Object.keys(changedSubDict).length > 0 ) {
                    acc[k] = changedSubDict
                }
            }
        } else {
            if (_ignoreMissing && !dictB.hasOwnProperty(k)) {
                // do nothing
            } else if (!valueIsEqual(dictA[k], dictB[k])) {
                acc[k] = dictB[k]
            }
        }
        return acc
    }, {})
}

export function trimDict(sourceDict, allowedKeys) {
    return Object.keys(sourceDict).reduce((acc, k) => {
        if (allowedKeys.indexOf(k) > -1) {
            acc[k] = sourceDict[k]
        }
        return acc
    }, {})
}

export function profileCookieDict(sourceDict) {

    const outDict = trimDict({...sourceDict, ...sourceDict.profile, id: sourceDict.id}, [
        'id',
        'name',
        'active_group_employee_id',
        'email_address',
        'contact_number',
        'profile_photo_url',
        'last_login',
        'is_active',
        'access_display',
        'modules',
        'is_superuser',
    ])
    if (!outDict.contact_number) {
        if (sourceDict.profile.mobile_number) {
            outDict.contact_number = sourceDict.profile.mobile_number
        } else if (sourceDict.profile.phone_number) {
            outDict.contact_number = sourceDict.profile.phone_number
        }
    }
    outDict.user_id = sourceDict.user?.id
    return outDict
}


export function toTitleCase(str) {
    if (!str) {
        return str
    }
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


export const stringToColour = function(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const valueIsEqual = (a, b) => {
    if (Array.isArray(a) || Array.isArray(b)) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }
    return a === b
}

export const chunkArray = (inputArray, perChunk) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])
}

export const chunkDict = (inputDict, perChunk) => {
    const chunkedKeys = chunkArray(Object.keys(inputDict), perChunk);
    return chunkedKeys.map(chunk => {
        return chunk.reduce((acc, key) => {
            acc[key] = inputDict[key]
            return acc;
        }, {})
    })
}

export const isUrlImage = url => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url.split('?')[0]);
}


// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export default function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}
export const daysToNumberAndType = (days) => {
    // returns months if days is divisible by 30
    // returns weeks if days is divisible by 7
    // returns days otherwise
    if (days % 30 === 0) {
        return {number: days / 30, type: 'months'}
    } else if (days % 7 === 0) {
        return {number: days / 7, type: 'weeks'}
    } else {
        return {number: days, type: 'days'}
    }
}
export const numberAndTypeToDays = (number, type) => {
    if (type === 'months') {
        return number * 30
    } else if (type === 'weeks') {
        return number * 7
    } else {
        return number
    }
}