import React, {useState} from "react";
import {
    Alert,
    Button,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import TagGroupSelector from "../../../../../commonComponents/tagGroupSelector";
import FormField from "../../../../../commonComponents/modules/leads/lead/editModal/formField";
import DefaultTagSelector from "./defaultTagSelector";
import FormFieldFactory from "../../../../../commonComponents/modules/leads/lead/editModal/formFieldFactory";
import {useTagGroup} from "../../../../../commonComponents/fetchers/tags";


const RESERVED_FIELD_NAMES = [
    'id',
    'name',
    'first name',
    'last name',
    'contact number',
    'email address',
    'message',
    'datetime updated',
    'datetime added',
    'property name',
    'property groups',
    'inquiry type',
    'referrer',
    'property group names',
    'sales profile',
    'sales profile display',
    'form answers',
    'form tags',
]

export default function EditLeadFieldModal({isOpen, toggle, field, setField, usedTagGroups}) {
    const [localField, setLocalField] = useState({...field})
    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroup({})
    React.useEffect(() => {
        setLocalField({
            ...field
        })
    }, [field])


    const dataTypeOptions = [
        {value: "text", label: "Text Field"},
        {value: "text_long", label: "Long Text Format"},
        {value: "number", label: "Numeric"}
    ]
    const selectedDataTypeOption = dataTypeOptions.find(opt => opt.value === localField.field_type)

    const isNameValid = !RESERVED_FIELD_NAMES.includes(localField.name?.toLowerCase().trim())
    const fieldsComplete = !!localField.name && isNameValid && !!localField.field_type && (localField.is_required ? !!localField.default_value : true);

    const selectedTagGroup = tagGroupsData?.find(row => row.id === localField.tag_group)

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody className="px-5 py-4">
            <div style={{minHeight: "35em"}}>
                <Row>
                    <Col>
                        <h3>Lead Information Field</h3>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>

                        <FormGroup>
                            <Label className="lineheight-1">Label</Label>
                            <Input
                                placeholder="Enter Label"
                                type="text"
                                value={localField.name}
                                invalid={!isNameValid}
                                onChange={e => {
                                    setLocalField({
                                        ...localField,
                                        name: e.target.value
                                    })
                                }}
                            />
                            <FormFeedback>
                                This field name is reserved. Please choose another.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <FormGroup>
                            <Label className="lineheight-1">Field description</Label>
                            <Input
                                placeholder="Enter Label"
                                type="textarea"
                                value={localField.description}
                                onChange={e => {
                                    setLocalField({
                                        ...localField,
                                        description: e.target.value
                                    })
                                }}
                            />
                        </FormGroup>

                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Label className="small text-muted">Data Type</Label>
                        <Select
                            options={dataTypeOptions}
                            value={selectedDataTypeOption ? selectedDataTypeOption : null}
                            placeholder={<strong>Choose data type</strong>}
                            onChange={newOption => {
                                setLocalField({
                                    ...localField,
                                    field_type: newOption.value, tag_group: null
                                })
                            }}
                        />
                    </Col>
                    <Col className="col-auto text-center text-muted small justify-content-center d-flex flex-column">
                        - <strong>OR</strong> -
                    </Col>
                    <Col>
                        <Label className="small text-muted">Tag Group</Label>
                        <TagGroupSelector
                            selected={localField.tag_group}
                            onSelect={newTagGroupId => {
                                setLocalField({
                                    ...localField,
                                    field_type: "tag", tag_group: newTagGroupId
                                })
                            }}
                            excludeIds={usedTagGroups.filter(tagId => tagId !== field.tag_group)}
                            placeholder={<strong>Choose Tag Group</strong>}
                        />
                    </Col>
                </Row>

                {
                    !!selectedTagGroup && !selectedTagGroup.is_active && <Row>
                        <Col>
                            <Alert color={"warning"} className="mt-3">
                                <strong>WARNING:</strong> This tag group is currently inactive. You can still use it, but
                                this field will not show up in the list of leads.
                            </Alert>
                        </Col>
                    </Row>
                }


                <Row className="mt-3">
                    <Col>

                        <FormGroup switch>
                            <Input type="switch" checked={localField.is_active} onChange={e => {
                                setLocalField({
                                    ...localField,
                                    is_active: e.target.checked
                                })
                            }}/>
                            <Label check>VISIBLE</Label>
                        </FormGroup>

                        <FormGroup switch>
                            <Input type="switch" checked={localField.is_required} onChange={e => {
                                setLocalField({
                                    ...localField,
                                    is_required: e.target.checked
                                })
                            }}/>
                            <Label check>REQUIRED</Label>
                        </FormGroup>
                    </Col>
                </Row>

                {
                    localField.is_required && <Row>
                        <Col>
                            {
                                localField.field_type === 'tag' ?
                                    <>
                                        <Label className="text-muted small lineheight-1">Select Default Tag</Label>
                                        <DefaultTagSelector
                                            selectedTag={localField.default_value}
                                            setSelectedTag={newTagId => {
                                                setLocalField({
                                                    ...localField,
                                                    default_value: newTagId
                                                })
                                            }}
                                            tagGroupId={localField.tag_group}/>

                                    </> :
                                    <FormFieldFactory
                                        fieldType={localField.field_type}
                                        value={localField.default_value}
                                        label={"Set Default Value"}
                                        setValue={newValue => {
                                            setLocalField({
                                                ...localField,
                                                default_value: newValue
                                            })
                                        }}
                                    />
                            }

                            {
                                localField.id && <p>
                                    <small className="text-muted">
                                        <strong>NOTE:</strong> Changing this value will not affect existing leads.
                                    </small>
                                </p>
                            }
                        </Col>
                    </Row>
                }
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color={"primary"} disabled={!fieldsComplete} onClick={() => {
                setField({...localField})
                toggle()
            }}>
                SAVE LEAD INFORMATION FIELD
            </Button>
        </ModalFooter>
    </Modal>
}