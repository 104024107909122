import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload, faUserPlus, faWarning} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {TableSalesGroupFilter} from "../../../../components/advancedTableFilters";
import {NavLink, useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {TableFilterContext} from "../../../../commonComponents/contexts";

export default function SalespersonList() {

    const navigate = useNavigate()
    const {setFilters: setAllFilters} = useContext(TableFilterContext)
    const viewLeads = (salesProfileId) => {
        setAllFilters('leads', 'sales_profile', salesProfileId)
        navigate('/broker-management/leads')
    }

    return <>
        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    navigate('new')
                }}>

                    <FontAwesomeIcon icon={faUserPlus}/>{" "}
                    ADD A SALESPERSON
                </Button>
            </Col>
            <Col className="text-lg-end text-md-end">
                <Button color="info-outline" onClick={() => {
                    navigate('bulk')
                }}>
                    <FontAwesomeIcon icon={faFileUpload}/>{" "}
                    BULK UPLOAD
                </Button>
            </Col>
        </Row>

        <AdvancedTable
            filters={{
                group: (isCollapsed, value, setValue, renderProps) => <TableSalesGroupFilter
                    isCollapsed={isCollapsed}
                    value={value}
                    setValue={setValue}
                    renderProps={renderProps}
                />
            }}
            friendlyResourceName={"Sales Person"}
            apiResourceName={'salesperson'}
            columnDefinition={[
                {
                    name: "Salesperson",
                    selector: row => <NavLink to={"edit/" + row.id}>
                        {row.name}
                    </NavLink>,
                    sortable: true,
                    sortField: 'user__first_name,user__last_name'
                },
                {
                    name: "Broker Affiliation",
                    selector: row => {
                        if (!row.is_affiliation_active) {
                            return <>
                                <FontAwesomeIcon icon={faWarning} title={"Broker Group's owner is disabled"}/>{" "}
                                {row.affiliation}
                            </>
                        }
                        return <NavLink to={"/broker-management/brokers/edit/" + row.affiliation_owner_id}>
                            {row.affiliation}
                        </NavLink>
                    },
                    sortable: true,
                    sortField: 'group__name'
                },
                {
                    name: "Leads",
                    selector: row => <Button color="link" onClick={() => viewLeads(row.id)} size="sm">
                        {row.leads_count}
                    </Button>
                }
            ]}/>
    </>
}