import React from "react";
import {Col, Row} from "reactstrap";
import PropertyInfoCards from "./PropertyInfoCards";
import LatestActivities from "./latestActivities";


export default function LandingPage() {
    return <>

        <Row>
            <Col>
                <h3 className="page-header pb-2">Property Inventory Management</h3>
            </Col>
        </Row>


        <Row className="my-5">
            <Col>
                <PropertyInfoCards/>
            </Col>
        </Row>


        <LatestActivities/>
    </>
}