import React, {useState} from "react";
import './App.css';
import AppRoutes from "./appRoutes";
import Api from "./api";
import {ToastContainer} from "react-toastify";
import {profileCookieDict} from "./commonComponents/util";
import {clearAuthTokens, isLoggedIn as jwtLoggedIn} from "axios-jwt";
import {Col, Container, Row, Spinner} from "reactstrap";
import {IsLoggedInContext, ProfileContext} from "./commonComponents/contexts";
import {useProfileFetch, useSessionCookie} from "./commonComponents/hooks";

function AdminApp() {
    const [isLoggedIn, setIsLoggedIn] = useState(jwtLoggedIn())
    const {isLoading, profile, fetchProfile} = useProfileFetch({isLoggedIn, setIsLoggedIn})
    const session = useSessionCookie()
    const api = new Api(newLoginStatus => {
        if (newLoginStatus !== isLoggedIn) {
            setIsLoggedIn(newLoginStatus)
        }
    })

    const isLoggedInContextValue = {
        isLoggedIn: isLoggedIn,
        logout: () => {
            console.log("logging out")
            setIsLoggedIn(false);
            clearAuthTokens()
        }
    }

    const profileContextValue = {
        profile: profileCookieDict(profile),
        updateProfile: () => {
            fetchProfile()
        },
        refreshProfile: fetchProfile
    }

    if (isLoading) {
        return <Container className="vh-100">
            <Row className="vh-100">
                <Col className="text-center d-flex align-items-center justify-content-center">
                    <Spinner/>
                </Col>
            </Row>
        </Container>
    }

    return <>
        <IsLoggedInContext.Provider value={isLoggedInContextValue}>
            <ProfileContext.Provider value={profileContextValue}>
                <ToastContainer/>
                <AppRoutes api={api} isLoggedIn={isLoggedIn}/>
            </ProfileContext.Provider>
        </IsLoggedInContext.Provider>

    </>
}

export default AdminApp;
