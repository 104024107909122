import React, {useContext} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {toast} from "react-toastify";
import {useGroupPermissions} from "../../../../commonComponents/hooks";
import PropertyGroupTableCell from "../propertyGroupTableCell";
import {TableFilterContext} from "../../../../commonComponents/contexts";


export default function PropertyGroups() {
    const navigate = useNavigate()
    const {filters, setFilters} = useContext(TableFilterContext)

    const {permissions, isLoading: permissionsLoading} = useGroupPermissions('properties/groups')
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const deleteMutation = useMutation((id) => {
        const resource = api.resource('properties/groups')
        const deletePromise = resource.deleteItem(id)
        toast.promise(deletePromise, {
            pending: "Disabling property group",
            success: "Property group disabled. Property listings will no longer be visible.",
            error: "Something went wrong. Could not disable property group."
        });
        return deletePromise
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table'])
        }
    });
    const enableMutation = useMutation((id) => {
        const resource = api.resource('properties/groups')
        const enablePromise = resource.postItemAction(id, 'enable', {});
        toast.promise(enablePromise, {
            pending: "Enabling property group",
            success: "Property group enabled. Property listings will now be visible.",
            error: "Something went wrong. Could not enable property group."
        });
        return enablePromise;
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table'])

        }
    });

    const columnDefinitions = [
        {
            name: "ID",
            selector: row => row.unique_property_group_id,
            format: row => {
                const display = <>
                    {row.unique_property_group_id || <i>ID Not Set</i>}
                </>
                if (row.permissions.includes('can_change') && row.is_active) {
                    return <NavLink to={"edit/" + row.id}>
                        {display}
                    </NavLink>
                } else {
                    return display
                }
            },
            sortable: true,
            sortField: 'unique_property_group_id'
        },
        {
            name: "Property Group",
            selector: row => row.name,
            cell: row => {
                return <PropertyGroupTableCell row={row}/>
            },
            center: true,
            sortable: true,
            sortField: 'name'
        },
        {
            name: "Total Listings",
            center: true,
            selector: row => row.listing_count,
            format: row => <Button color="text" onClick={() => {
                setFilters('properties', 'group', row.id);
                navigate('listing')
            }}>
                <strong>
                    {row.listing_count}
                </strong>
            </Button>,
            sortable: false
        },
        {
            name: "",
            selector: row => row.id,
            width: "10em",
            format: row => {
                if (row.permissions.includes("can_delete")) {
                    if (row.is_active) {
                        return <ConfirmButton
                            color="danger"
                            className={"btn-sm text-white"}
                            confirmText="Are you sure?"
                            onClick={() => {
                                // deleteGroup(row.id)
                                deleteMutation.mutate(row.id)
                            }}
                        >
                            DISABLE
                        </ConfirmButton>
                    } else {
                        return <ConfirmButton
                            color="success"
                            className={"btn-sm text-white"}
                            confirmText="Are you sure?"
                            onClick={() => {
                                // deleteGroup(row.id)
                                enableMutation.mutate(row.id)
                            }}
                        >
                            ENABLE
                        </ConfirmButton>
                    }
                } else {
                    if (!row.is_active) {
                        return <span className="text-danger">DISABLED</span>
                    }
                }
            }
        },
    ]

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Property Groups</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Property Groups are setup to organize available Property Listings under a group.{" "}
                Groups can be based on development, classification or location.{" "}
                Property listings can be part of multiple property groups.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                {
                    permissionsLoading ?
                        <Spinner size="md"/> :
                        permissions.includes('can_add') && <Button color="info-outline" onClick={() => {
                            navigate('new')
                        }}>
                            <FontAwesomeIcon icon={faCirclePlus}/>{" "}
                            ADD PROPERTY GROUP
                        </Button>
                }
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Property Group"}
            apiResourceName={'properties/groups'}
            columnDefinition={columnDefinitions}
            filterMapOverride={{
                include_inactive: true
            }}
        />
    </>
}