import React from "react";
import {Col, Row} from "reactstrap";
import {useParams} from "react-router-dom";
import EmployeeProfileForm from "../../../../components/employeeProfileForm";


export default function EditEmployee() {
    const params = useParams();
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Employee Information</h3>
            </Col>
        </Row>

        <EmployeeProfileForm
            profileId={params.profileId}
            isMe={false}/>
    </>
}