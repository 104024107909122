import React from "react";
import {useParams} from "react-router-dom";
import {useLoggedInApi} from "../../../../../../api";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import {Button, Spinner} from "reactstrap";
import Activity from "./activity";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";


export default function ActivityList(
    {
        selectedTags,
        setSelectedTags,
        selectedUpdateBy,
        setSelectedUpdateBy,
        search,
        dateRange
    }
) {

    const {leadId} = useParams()
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const queryKey = ['lead', leadId, 'activities', selectedTags.sort(), selectedUpdateBy?.id, search, dateRange]

    const fetchHistoryPage = ({pageParam}) => {
        const leadResource = api.resource('leads')
        const params = {
            page: pageParam,
            page_size: 10,
            sort: '-datetime_added',
            show_disabled: true
        }
        if (selectedTags.length > 0) {
            params.tags = selectedTags.filter(tag => tag !== 'search').join(",")
        }
        if (selectedUpdateBy) {
            params.update_by = selectedUpdateBy.id
        }
        if (search) {
            params.search_remarks = search
        }
        if (dateRange) {
            params.history_from = dateRange.from
            params.history_to = moment(dateRange.to).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        }
        return leadResource.getItemAction(leadId, 'history', {params}).then(response => response.data)
    }

    const {
        data: pages,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(
        queryKey,
        fetchHistoryPage, {
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (lastPage.next) {
                    const url = new URL(lastPage.next)
                    const page = url.searchParams.get('page')
                    return page
                }
                return undefined
            }, getPreviousPageParam: (firstPage) => {
                if (firstPage.previous) {
                    const url = new URL(firstPage.previous)
                    const page = url.searchParams.get('page')
                    return page
                }
                return undefined
            }
        })

    const data = pages?.pages?.reduce((acc, row) => {
        acc.push(...row.results)
        return acc
    }, [])

    if (isLoading) {
        return <Spinner/>
    }

    if (!isLoading && !data?.length) {
        return <p className="text-muted text-center small">No activities found.</p>
    }

    const itemsLength = data?.length || 0
    return <InfiniteScroll
        dataLength={itemsLength}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<Spinner/>}
        endMessage={
            <p style={{textAlign: 'center'}}>
                <b>No more history to load.</b>
            </p>
        }
        refreshFunction={() => {
            queryClient.invalidateQueries(queryKey)
        }}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
            <div style={{textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.5)'}}>
                <strong>&#8595; Pull down to refresh</strong>
            </div>
        }
        releaseToRefreshContent={
            <div style={{textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.5)'}}>
                <strong>&#8595; Release to refresh</strong>
            </div>
        }
    >
        {
            data.map(activity => <Activity
                setSelectedTags={setSelectedTags}
                selectedTags={selectedTags}
                setSelectedUpdateBy={setSelectedUpdateBy}
                key={activity.id}
                activity={activity}
            />)
        }

        {
            (hasNextPage && !(isLoading || isFetchingNextPage)) && <Button color={"link"} onClick={fetchNextPage}>
                Load More...
            </Button>
        }
    </InfiniteScroll>
}