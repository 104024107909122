import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {ProfileContext} from "../../../commonComponents/contexts";

export default function BrokerShortInfo({profile}) {
    const {profile: profileFromContext} = useContext(ProfileContext)
    const displayProfile = profile ? profile : profileFromContext;  // prioritize broker info from props if available
    const {
        name,
        email_address,
        contact_number
    } = displayProfile

    return <>
        <Row className="mt-5">
            <Col>
                <h6>BROKER/SALESPERSON INFORMATION</h6>
            </Col>
        </Row>

        <div className="border-bottom"/>

        <Row className="mt-4">
            <Col>
                <strong className="text-black">{name}</strong>{" "}
                {(displayProfile.group_name && displayProfile.group_name !== name) ? "(" + displayProfile.group_name + ")" : ""}
            </Col>
        </Row>

        <Row>
            <Col>
                <small><strong>{contact_number}</strong></small>
            </Col>
        </Row>

        <Row>
            <Col>
                <small><strong>{email_address}</strong></small>
            </Col>
        </Row>
        <div className="border-bottom"/>
    </>
}