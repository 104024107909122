import React, {Component} from "react";
import {Editor} from "@tinymce/tinymce-react";
import 'tinymce/skins/ui/oxide/skin.min.css';


import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textpattern';

const TINY_MCE_DEMO_PLUGINS = [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
]
const TINY_MCE_PLUGINS = [
    "advlist autolink link image lists charmap print preview hr anchor pagebreak",
    "searchreplace visualblocks visualchars code media nonbreaking",
    "table contextmenu template textcolor paste textcolor colorpicker textpattern"
]
const TINY_MCE_DEMO_TOOLBAR = 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help'
const TINY_MCE_TOOLBAR = "searchreplace | styleselect fontselect fontsizeselect | " +
    "bold italic underline strikethrough";
const TINY_MCE_TOOLBAR2 = "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
    "link image | table | code"

class ClassicText extends Component {

  constructor(props) {
      super(props);
      this.state = {initialValue: props.value};
  }
  render() {
    return (
        <Editor
            initialValue={this.state.initialValue}
            // tinymceScriptSrc={'tinymce/tinymce.min.js'}
            value={this.props.value}
            onEditorChange={(value, editor) => {
                this.props.onEditorChange(value)
            }}
            onSaveContent={() => {
                console.log("RESETTING?!? WHY?!?")
            }}
            init={{
                // theme: false,
                height: this.props.height ? this.props.height : 500,
                menubar: true,
                plugins: TINY_MCE_PLUGINS,
                toolbar1: TINY_MCE_TOOLBAR,
                toolbar2: TINY_MCE_TOOLBAR2,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />
    );
  }
}

export default ClassicText;
