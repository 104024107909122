import React, {useMemo} from "react";
import {Input, Label} from "reactstrap";
import TagGroupTagSelector from "../common/tagGroupTagSelector";
import FormFieldFactory from "./formFieldFactory";


export default function FormField({field, data, setData, unlockPermanentTags}) {
    const {id, name, field_type, required, options, tag_group} = field
    const nameAsLabel = name.toLowerCase().replace(" ", "_")

    const [value, value_source] = useMemo(() => {
        if (field_type === "tag") {
            const tags = data?.form_tags
            if (tags) {
                return [
                    tags,
                    "form_tags"
                ]
            } else {
                return [[], "form_tags"]
            }
        }
        // try to get it from data using field label
        if (data && nameAsLabel in data) {
            return [data[nameAsLabel], "data"]
        }
        // try to get it from form_answers using id
        const formAnswer = data?.form_answers?.find(answer => answer.form_field === id)

        if (formAnswer !== undefined) {
            return [formAnswer.answer, "form_answers"]
        }
        return [null, "form_answers"]
    }, [data, id, nameAsLabel, field_type])


    const updateValue = (newValue) => {
        if (value_source === "form_tags") {
            setData({
                ...data,
                form_tags: newValue
            })
        } else if (value_source === "data") {
            setData({
                ...data,
                [nameAsLabel]: newValue
            })
        } else if (value_source === "form_answers") {
            const originalAnswer = data?.form_answers?.find(answer => answer.form_field === id)
            const otherAnswers = data?.form_answers?.filter(answer => answer.form_field !== id) || []
            const newAnswer = originalAnswer ? {
                ...originalAnswer,
                answer: newValue
            } : {
                form_field: id,
                sales_lead: data.id,
                answer: newValue
            }
            setData({
                ...data,
                form_answers: [
                    ...otherAnswers,
                    newAnswer
                ]
            })
        }
    }

    const isRequired = field.is_required


    if (field_type === "tag") {
        return <TagGroupTagSelector
            tagGroupId={tag_group}
            selectedTags={value}
            setSelectedTags={updateValue}
            setTagDataOnAdd={true}
            unlockPermanentTags={unlockPermanentTags}
            tagGroupNameWrapper={(name) => <Label className="text-muted lineheight-1">
                {field.name}{" "}
                {field.name.toLowerCase() !== name.toLowerCase() && <small>({name})</small>}
                {isRequired && <span className="text-danger">*</span>}
            </Label>
            }
        />
    } else {
        return <FormFieldFactory
            fieldType={field_type}
            label={name}
            value={value}
            setValue={updateValue}
            required={isRequired}
        />
    }
}