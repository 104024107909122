import React from "react";
import {Col, Row} from "reactstrap";
import Select from "react-select";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";
import InputRangeSelector from "../../../../commonComponents/inputRangeSelector";
import DatePicker from "react-date-picker";
import moment from "moment";

export default function Filter({filters, setFilters}) {
    const fromDate = filters["fromDate"]
    const toDate = filters["toDate"]
    const setFromDate = newDate => {
        setFilters(prevData => ({
            ...prevData,
            fromDate: newDate
        }))
    }
    const setToDate = newDate => {
        setFilters(prevDate=> ({
            ...prevDate,
            toDate: newDate
        }))
    }

    const api = useLoggedInApi()
    const brokerNamesQuery = useQuery(['broker', 'names'], () => {
        const resource = api.resource('broker')
        return resource.getItem('names').then(response => response.data)
    })

    const queryParams = {group_id: filters.group_id}
    const salesPersonQuery = useQuery(['salesperson', 'names', queryParams], () => {
        const resource = api.resource('salesperson')
        return resource.getItem('names', {
            params: queryParams
        }).then(response => response.data)
    }, {
        enabled: !!queryParams.group_id
    })

    const brokerOptions = brokerNamesQuery.data?.map(row => {
        return {
            label: row.name,
            value: row.group_id
        }
    }).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    const salespersonOptions = salesPersonQuery.data?.map(row => {
        return {
            label: row.name,
            value: row.id
        }
    }).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    return <div  className="border-light border-1 border-primary border">
        <Row className="p-4">
            <Col md={6} sm={12}>
                Broker
                <Select
                    placeholder={"All"}
                    isClearable={true}
                    options={brokerOptions}
                    onChange={newOption => {
                        if (newOption) {
                            setFilters({...filters, group_id: newOption.value})
                        } else {
                            setFilters({...filters, group_id: undefined})
                        }
                    }}
                    isLoading={brokerNamesQuery.isLoading}
                />
            </Col>
            <Col md={6} sm={12}>
                Salesperson
                <Select
                    placeholder={"All"}
                    isClearable={true}
                    options={salespersonOptions}
                    isLoading={salesPersonQuery.isLoading}
                    onChange={newOption => {
                        if (newOption) {
                            setFilters({...filters, pk: newOption.value})
                        } else {
                            setFilters({...filters, pk: undefined})
                        }
                    }}
                    isDisabled={!filters.group_id}
                />
            </Col>
            <Col md={12} sm={12}>
                <InputRangeSelector
                    fromDate={fromDate} setFromDate={setFromDate}
                    toDate={toDate} setToDate={setToDate}
                />
            </Col>
        </Row>
    </div>
}