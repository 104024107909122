import TableChart from "../../../../components/charts/tableChart";
import React from "react";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";

export default function TableProposal({filters}) {
    const api = useLoggedInApi()
    const query = useQuery(['properties', 'report', 'proposal_by_group', filters], () => {
        const resource = api.resource('properties/groups')
        return resource.getItem('proposal_by_group', {
            params: filters
        }).then(response => response.data)
    })

    return <TableChart
        isLoading={query.isLoading}
        rows={query.data?.map(row => ({
            label: row.property__group__name, value: row.total
        }))}
        maxVisible={5}
        title={"PROPOSAL"}
        headers={["PROPERTY GROUP", "COUNT"]}
        total={1000}/>
}