import React from "react";
import {processResponseAsDownload} from "../../util";
import {toast} from "react-toastify";
import {ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useLoggedInApi} from "../../../../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";


export default function ExportLead() {
    const {leadId} = useParams()
    const api = useLoggedInApi()
    const download = () => {
        const leadResource = api.resource('leads')
        const downloadPromise = leadResource.getItemAction(leadId, 'export_xlsx', {
            responseType: "blob"
        }).then(processResponseAsDownload);

        toast.promise(downloadPromise, {
            error: {
                render({data}) {
                    const reasonDetail = data?.response?.data?.detail
                    if (reasonDetail) {
                        return reasonDetail
                    }

                    return "Something went wrong. Please contact administrator"
                }
            },
            pending: "Generating export file",
            success: "Lead export downloaded!"
        })
    }

    if (!leadId) {
        return null
    }

    return <ButtonGroup>
        <UncontrolledDropdown>
            <DropdownToggle caret color="info"
                            className="ps-1 pe-2 border-start-1 border-light border border-bottom-0 border-top-0"/>
            <DropdownMenu>
                <DropdownItem onClick={download}>
                    <FontAwesomeIcon icon={faFileExcel} className="me-2"/>
                    Download Profile as Excel
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </ButtonGroup>
}