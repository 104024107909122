import React from "react";
import "../../../commonComponents/assets/css/dashboard.css"
import {faCircleQuestion, faPhotoVideo} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";


export default function PropertyManagement() {
    const sideBarItems = [
        {icon: faPhotoVideo, text: "MEDIA KITS", to: '/property-management/'},
        {icon: faCircleQuestion, text: "NEED HELP?", to: 'help'}
    ];

    return <ModuleFactory
        subItems={['kit']}
        sidebarItems={sideBarItems}/>
}