import {Col, Row} from "reactstrap";
import LeadsByPeriod from "../../report/leadsByPeriod";
import React from "react";

export default function Header() {
    return <>

        <Row className="mb-3">
            <Col>
                <h3 className="page-header pb-2">Manage Leads</h3>
            </Col>
        </Row>

        <LeadsByPeriod/>
    </>
}