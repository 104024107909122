import React from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";


export default function ViewContent({data}) {
    return <>
        <Row className="mt-3">
            <Col className="border border-light" md={4}><strong>Title</strong></Col>
            <Col className="border border-light">
                <strong>{data.title}</strong>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="border border-light p-3">
                <div dangerouslySetInnerHTML={{__html: data.content}}/>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col className="border border-light" md={4}><strong>Type</strong></Col>
            <Col className="border border-light">
                {data.get_broadcast_type_display}
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="border border-light" md={4}><strong>Send Date</strong></Col>
            <Col className="border border-light">
                {moment(data.send_datetime).format('LL')}
            </Col>
        </Row>
    </>
}