import React from "react";
import {Col, Row, Spinner, UncontrolledAccordion} from "reactstrap";
import {useLoggedInApi} from "../../api";
import HelpTicket from "./helpTicket";
import NewInquiry from "./newInquiry";
import {useQuery, useQueryClient} from "react-query";


export default function Inquiry({site}) {


    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const {isLoading, data} = useQuery(["my-inquiries"], () => {
        const helpInquiryApi = api.resource('help-inquiry')
        return helpInquiryApi.list({params: {site: site, own: true}}).then(response => {
            return response.data
        })
    }, {
        onSuccess: data => {
            data.forEach(row => {
                queryClient.setQueryData(["inquiry", row.id], row)
            })
        }
    });


    if (isLoading) {
        return  <Row>
            <Col className="text-center my-5">
                <Spinner/>
            </Col>
        </Row>
    }

    const sortedData = data.sort((a,b) => {
        if (a.datetime_updated > b.datetime_updated) return -1
        if (a.datetime_udpated < b.datetime_updated) return 1
        return 0
    })

    const renderHelpTickets = () => {

        if (data.length > 0) {
            return <>
                <Row className="">
                    <Col>
                        <h4>Help Tickets</h4>
                    </Col>
                </Row>
                <UncontrolledAccordion className="mb-5">
                    {
                        sortedData.map(row => <HelpTicket key={row.id} row={row} />)
                    }
                </UncontrolledAccordion>
            </>
        }
    }


    return <>
        <NewInquiry site={site}/>

        { renderHelpTickets() }
    </>
}