import React, {useContext, useEffect, useRef, useState} from "react";
import {useLoggedInApi} from "../api";
import {toast} from "react-toastify";

import * as PropTypes from "prop-types";
import {
    Button,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import DataTable from "react-data-table-component";
import {toTitleCase} from "./util";
import {faCaretDown, faCaretRight, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useQuery} from "react-query";
import {TableFilterContext, TableSearchContext} from "./contexts";


const customTableStyles = {
    rows: {
        style: {
            minHeight: '5em'
        }
    }
}


function AdvancedTable(props) {
    // use pagination is turned on by default
    const usePagination = props.usePagination || props.usePagination === undefined;

    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const defaultSort = props.defaultSort || '-id';

    const [sort, setSort] = useState(defaultSort)

    // SEARCH FUNCTIONALITY
    const [tmpSearchText, setTmpSearchText] = useState('')
    const {searchText: searchTextSet, setSearchText} = useContext(TableSearchContext)
    const searchText = props.ignoreContexts ? '' : searchTextSet[props.apiResourceName]

    const searchTextRef = useRef(searchText)
    searchTextRef.current = searchText
    const api = useLoggedInApi()

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (tmpSearchText !== searchTextRef.current) {
                setSearchText(props.apiResourceName, tmpSearchText)
                setPage(1)
            }
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [tmpSearchText])

    useEffect(() => {
        if (tmpSearchText !== searchText) {
            setTmpSearchText(searchText)
        }
    }, [searchText])

    // TODO: reset pagination if filtered or changed search text

    // filter functionality
    const {filters: allFilters, setFilters: setAllFilters} = useContext(TableFilterContext)
    const filters = (props.ignoreContexts || props.dontUseFilters)
        ? {}
        : (props.filterMapOverride ? props.filterMapOverride : allFilters[props.apiResourceName])
    const setFilters = (filterName, filterValue) => {
        setPage(1)
        setAllFilters(props.apiResourceName, filterName, filterValue)
    }

    const [isFiltersShown, setIsFiltersShown] = useState(false)

    // queries
    const queryParams = {
        page_size: pageSize,
        page: page,
        sort: sort,
        search: searchText,
        ...filters
    }
    const {
        isLoading,
        data,
    } = useQuery(['table', props.apiResourceName, queryParams], () => {
        const salesPeopleResource = api.resource(props.apiResourceName)
        return salesPeopleResource.list({
            params: queryParams
        }).then(response => {
            return response.data
        })
    }, {
        onError: () => {
            toast.error("Something went wrong. Please refresh. If problem persists, please contact administrator.")
        },
        onSuccess: data => {
            if (totalCount === 0) {
                setTotalCount(data.count)
            }
        },
        staleTime: 60 * 1000  // one minute
    })

    const tableData = usePagination ? data?.results : data;
    const filteredCount = data ? data.count : 0;

    const filtersDisplayed = (!props.dontUseFilters && props.filters) && Object.keys(props.filters).map(filterName => {
        return <div key={filterName}>{
            props.filters[filterName](
                !isFiltersShown,
                filters[filterName],
                value => setFilters(filterName, value),
                {key: filterName}
            )
        }</div>
    })

    const hasNoFilters = !filters || Object.values(filters).filter(v => !!v).length === 0;

    const filtersTextDisplay = (props.dontUseFilters || hasNoFilters || !props.filters) ? "All" : Object.keys(filters)
        .filter(k => !!filters[k])
        .map((filterName, idx, arr) => {
            return <span key={filterName + '-display'}>
                {
                    props.filters[filterName](
                        true,
                        filters[filterName],
                        value => setFilters(filterName, value),
                        {}
                    )
                } {(idx + 1) < arr.length ? <i> and </i> : null}
            </span>
        });

    const columns = [
        ...(
            props.selectAccessor ? [
                {
                    name: "Select",
                    selector: row => row[props.selectAccessor],
                    format: row => <input
                        type={"checkbox"}
                        checked={props.selected?.includes(row[props.selectAccessor])}
                        onChange={e => {
                            if (e.target.checked) {
                                props.setSelected([...props.selected, row[props.selectAccessor]])
                            } else {
                                props.setSelected(props.selected.filter(r => r !== row[props.selectAccessor]))
                            }
                        }}
                    />,
                    sortable: false,
                    width: '5em'
                },
            ] : []
        ),
        ...props.columnDefinition
    ]

    return <>
        {
            !props.hideSearch && <Row className="mt-5">
                <Col>
                    <InputGroup>
                        <div className="form-floating flex-grow-1">
                            <Input
                                name={'search'}
                                type={"text"}
                                placeholder="Search"
                                value={tmpSearchText}
                                onChange={e => setTmpSearchText(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter' && tmpSearchText !== searchText) {
                                        setSearchText(props.apiResourceName, tmpSearchText)
                                        setPage(1)
                                    }
                                }}

                            />
                            <Label for="search">Search</Label>

                        </div>
                        {
                            tmpSearchText !== searchText && <InputGroupText>
                                <FontAwesomeIcon icon={faSpinner} className="form-control-feedback" color="#0169a6" spin/>
                            </InputGroupText>
                        }

                    </InputGroup>
                </Col>
            </Row>
        }
        {

            !props.hideTotalCount && <Row className="mt-3">
                <Col className="text-end">
                    {totalCount} Total {toTitleCase(props.friendlyResourceName)}
                </Col>
            </Row>
        }

        {
            (!props.dontUseFilters && props.filters && Object.keys(props.filters).length > 0) && <>

                <Row>
                    <Col>
                        <Dropdown isOpen={isFiltersShown} toggle={() => setIsFiltersShown(!isFiltersShown)}>
                            <DropdownToggle className="toggle-clear text-black ps-0">
                                Displaying: {filtersTextDisplay} <FontAwesomeIcon
                                icon={isFiltersShown ? faCaretRight : faCaretDown}/>
                            </DropdownToggle>
                            <DropdownMenu className="px-3 pt-2 min-vw-50">
                                {
                                    filtersDisplayed
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>

            </>
        }


        {
            props.selectAccessor && <Row className="mt-4">
                <Col>
                    <input
                        type={"checkbox"}
                        checked={tableData?.every(row => props.selected?.includes(row[props.selectAccessor]))}
                        onChange={e => {
                            if (e.target.checked) {
                                props.setSelected([...props.selected, ...tableData.map(row => row[props.selectAccessor])])
                            } else {
                                props.setSelected(props.selected.filter(r => !tableData.map(row => row[props.selectAccessor]).includes(r)))
                            }
                        }}
                        id={"select-all"}
                    />
                    {" "}
                    <Label for={"select-all"}>Select All</Label>

                    {
                        // !!tableData && <small className="text-black-50">
                        //     {" "}|{" "}
                        //     {tableData.filter(row => props.selected?.includes(row[props.selectAccessor])).length} of {tableData.length} selected
                        // </small>
                    }
                </Col>
                <Col className="text-end">
                    {
                        !!tableData && <small className="text-black-50">
                            {props.selected.length} total selected
                        </small>
                    }
                    {
                        !!props.selected.length && <>
                            {" "}|{" "}
                            <Button
                                color={"link"}
                                className="text-danger btn-sm"
                                onClick={() => {
                                    props.setSelected([])
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} className="me-1"/>
                                Clear Selection
                            </Button>
                        </>
                    }
                </Col>
            </Row>
        }

        <Row className="mb-5">
            <Col>
                <DataTable
                    striped
                    responsive
                    persistTableHead
                    customStyles={props.customTableStyles ? props.customTableStyles : customTableStyles}
                    pagination={usePagination}
                    onChangeRowsPerPage={rows => setPageSize(rows)}
                    onChangePage={newPage => {
                        setPage(newPage)
                    }}
                    paginationTotalRows={filteredCount}
                    paginationServer
                    sortServer
                    onSort={(selectedColumn, sortDirection) => {
                        setSort((sortDirection === 'desc' ? '-' : '') + selectedColumn.sortField)
                    }}

                    progressPending={isLoading}
                    data={tableData}
                    columns={columns}

                    {...props.dataTableProps}
                />
            </Col>
        </Row>
    </>
}

AdvancedTable.propTypes = {
    apiResourceName: PropTypes.string,
    friendlyResourceName: PropTypes.string,
    columnDefinition: PropTypes.array,
    hideTotalCount: PropTypes.bool,
    hideSearch: PropTypes.bool,
    filters: PropTypes.objectOf(PropTypes.func),
    dataTableProps: PropTypes.object,
    ignoreContexts: PropTypes.bool,
    usePagination: PropTypes.bool,
    filterMapOverride: PropTypes.object,
    customTableStyles: PropTypes.object,
    selectAccessor: PropTypes.string,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
}

export default AdvancedTable