import React, {useContext} from "react";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {useNavigate} from "react-router-dom";
import {TableFilterContext} from "../../../../commonComponents/contexts";
import {Button, Col, Container, Row} from "reactstrap";
import moment from "moment";
import {LEAD_STATUS_BADGE} from "../../../../constants";
import PropertyGroupAsTag from "../../../../commonComponents/propertyGroupAsTag";
import {usePropertyGroups} from "../../../../commonComponents/hooks";
import PropertyGroupTableCell from "../propertyGroupTableCell";


export default function LatestActivities() {
    const navigate = useNavigate()
    const {filters, setFilters} = useContext(TableFilterContext)


    return <AdvancedTable
        ignoreContexts={true}
        hideSearch={true}
        hideTotalCount={true}
        apiResourceName={'properties/groups'}

        columnDefinition={[
            {
                name: "Last Activity",
                selector: row => row.last_activity && <>
                    {LEAD_STATUS_BADGE[row.last_activity.status]}<br/>
                    {moment(row.last_activity.datetime).format('LL')}<br/>
                    <small>
                        {moment(row.last_activity.datetime).format('LT')}<br/>
                    </small>
                </>,
                sortable: false
            },
            {
                name: "Property Group",
                selector: row => row.name,
                cell: row => {
                    return <PropertyGroupTableCell row={row} />
                },
                center: true,
                sortable: true,
                sortField: 'name',
                grow: 2,
            },
            {
                name: "Total Listings",
                center: true,
                selector: row => row.listing_count,
                format: row => <Button color="text" onClick={() => {
                    setFilters('properties', 'group', row.id);
                    navigate('listing')
                }}>
                    <strong>
                        {row.listing_count}
                    </strong>
                </Button>,
                sortable: false
            }
        ]}
    />
}