import React from "react";
import {Button} from "reactstrap";


export default function ConfirmButton({wrapInline=true, onClick, children, confirmText="Sure?", ...props}) {

    const [showConfirm, setShowConfirm] = React.useState(false)

    const handleConfirm = () => {
        if (showConfirm) {
            onClick()
        }
        setShowConfirm(!showConfirm)
    }

    const btn = <Button {...props} onClick={handleConfirm} onBlur={()=> {
        setShowConfirm(false)
    }}>
        {
            showConfirm ? confirmText : children
        }
    </Button>

    if (!wrapInline) {
        return btn
    }

    return <div className="d-inline-block">
        {btn}
    </div>
}
