import React, {useContext, useState} from "react";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import Select from "react-select";
import {Button, Col, Row, Spinner} from "reactstrap";
import {toast} from "react-toastify";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import {ProfileContext} from "../../../../commonComponents/contexts";


export default function EditManagerExpandableRow({data}) {
    const [managers, setManagers] = useState([
        ...data.managers
    ])

    const {profile} = useContext(ProfileContext)
    const {user_id} = profile

    const apiClient = useLoggedInApi()
    const queryClient = useQueryClient()

    const {data: brokerManagers, isLoading: isLoadingBrokerManagers} = useQuery(['broker-managers'], () => {
        const brokerResource = apiClient.resource('broker')
        return brokerResource.getItem('broker_managers').then(response => {
            return response.data.reduce((acc, row) => {
                if (!acc[row.id]) {
                    acc[row.id] = row
                }
                return acc
            }, {})
        })
    }, {
        staleTime: 1000 * 60 * 5,  // 5 minutes
        cacheTime: 1000 * 60 * 5,  // 5 minutes
    })

    const changeManagersMutation = useMutation((managers) => {
        const brokerResource = apiClient.resource('broker')
        const patchPromise = brokerResource.patchItem(data.id, {
            managers_update: managers
        }).then(response => {
            return response.data
        })
        toast.promise(patchPromise, {
            pending: "Updating managers for Broker Group " + data.name,
            success: "Updated managers for Broker Group " + data.name,
            error: "Something went wrong. Please contact administrator."
        })
        return patchPromise
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['table', 'broker'])
        }
    })

    const options = React.useMemo(() => {
        const managerChoices = [
            ...Object.values(brokerManagers),
            ...data.manager_details
        ].reduce((acc, row) => {
            if (!acc[row.id]) {
                acc[row.id] = row
            }
            return acc
        }, {})

        return Object.values(managerChoices)
            .sort((a, b) => a.email.localeCompare(b.email))
            .map(row => {
                return {
                    value: row.id,
                    name: row.first_name + " " + row.last_name,
                    email: row.email,
                    label: <div style={{lineHeight: "1.2em"}}>
                        <strong>{row.email}</strong>
                        <br/>
                        <small>{row.first_name} {row.last_name}</small>
                    </div>
                }
            })
    }, [brokerManagers])

    if (isLoadingBrokerManagers) {
        return <div className="text-center py-5">
            <Spinner/>
        </div>
    }

    const selectedOptions = managers.map(managerId => {
        return options.find(option => option.value === managerId)
    })

    const hasChanges = managers.length !== data.managers.length || managers.some(managerId => !data.managers.includes(managerId))
    const hasNoManagers = managers.length === 0

    const isManagedByLoggedInUser = data.managers.includes(user_id)
    const isRemovingLoggedInUser = !managers.includes(user_id) && isManagedByLoggedInUser;

    return <div className="px-3 py-2">
        <div className="row" style={{maxWidth: "50vw"}}>
            <div className="col-12">
                This broker group is managed by the following users:
                <Select
                    filterOption={(option, rawInput) => {
                        if (!rawInput) return true
                        console.log("option", option)
                        return option.data.name.toLowerCase().includes(rawInput.toLowerCase()) ||
                            option.data.email.toLowerCase().includes(rawInput.toLowerCase())
                    }}
                    options={options}
                    value={selectedOptions}
                    isMulti={true}
                    onChange={(selectedOptions) => {
                        if (selectedOptions) {
                            setManagers(selectedOptions.map(option => option.value))
                        } else {
                            setManagers([])
                        }
                    }}
                />

                {
                    hasNoManagers && <div className="text-danger mt-2">
                        <small>
                            Cannot remove all managers. At least one manager is required.
                        </small>
                    </div>
                }

                {
                    isRemovingLoggedInUser && <div className="text-danger mt-2">
                        <small>
                            <strong>Warning:</strong> You are removing yourself from managing this group. You will lose access to this group.
                        </small>
                    </div>
                }

                <ConfirmButton
                    color="primary" className="mt-3"
                    onClick={() => {
                        changeManagersMutation.mutate(managers)
                    }}
                    disabled={!hasChanges || hasNoManagers}
                >
                    SAVE CHANGES
                </ConfirmButton>

            </div>
        </div>
    </div>
}