import React from "react";
import InputRow from "../inputRow";
import DatePicker from "react-date-picker";
import moment from "moment";


export default function PersonalInfo({profileData, isProfileSaving, updateErrorStatus, setProfileData, profileOptions, requireTin}) {
    return <>
        <h5 className="mt-5">Personal Information</h5>

        <InputRow
            required
            label={"Last Name"}
            value={profileData.user.last_name}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('last_name', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                user: {
                    ...profileData.user,
                    last_name: newValue
                }
            })}/>
        <InputRow
            required
            label={"First Name"}
            value={profileData.user.first_name}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('first_name', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                user: {
                    ...profileData.user,
                    first_name: newValue
                }
            })}/>
        <InputRow
            label={"Middle Name"}
            value={profileData.profile.middle_name}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('middle_name', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    middle_name: newValue
                }
            })}/>
        <InputRow
            label={"EXT Name"}
            value={profileData.profile.ext_name}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('ext_name', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    ext_name: newValue
                }
            })}/>
        <InputRow
            label={"Date of Birth"}
            renderer={(props) => <DatePicker
                {...props}
                clearIcon={null}
                maxDate={new Date()}
                disabled={isProfileSaving}
                value={profileData.profile.dob && moment(profileData.profile.dob, "YYYY-MM-DD").toDate()}
                onChange={newDate => {
                    const newDateString = moment(newDate).format("YYYY-MM-DD");
                    setProfileData({
                        ...profileData,
                        profile: {
                            ...profileData.profile,
                            dob: newDateString
                        }
                    })
                }}/>
            }/>
        <InputRow
            label={"Place of Birth"}
            value={profileData.profile.place_of_birth}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('place_of_birth', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    place_of_birth: newValue
                }
            })}/>

        <InputRow
            required
            label={"Gender"}
            value={profileData.profile.gender}
            type={"select"}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('gender', hasError)}
            children={[
                <option key="no-selected"></option>,
                <option key="male">Male</option>,
                <option key="female">Female</option>,
                <option key="others">Others</option>
            ]}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    gender: newValue
                }
            })}/>
        <InputRow
            label={"Civil Status"}
            value={profileData.profile.civil_status}
            type={"select"}
            disabled={isProfileSaving}
            children={[
                <option key="no-selected"></option>,
                <option key="single">Single</option>,
                <option key="married">Married</option>,
                <option key="widowed">Widowed</option>,
                <option key="seperated">Separated</option>,
                <option key="divorced">Divorced</option>
            ]}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    civil_status: newValue
                }
            })}/>


        <InputRow
            label={"Citizenship"}
            value={profileData.profile.citizenship}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('citizenship', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    citizenship: newValue
                }
            })}

            type={"select"}
            children={[
                <option key="no-selected"></option>,
                ...profileOptions['citizenship'].map(o => <option key={o} value={o}>{o}</option>)
            ]}
        />

        <InputRow
            required={profileData.is_broker}
            label={"TIN"}
            value={profileData.profile.tin_number}
            disabled={isProfileSaving}
            setHasError={hasError => updateErrorStatus('tin_number', hasError)}
            pattern={'^[0-9]{12}$'}
            invalidPatternError={"TIN must be 12 digits long."}
            placeholder="123456789000"
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    tin_number: newValue.replace(/\D/gi, '')
                }
            })}/>

    </>
}

