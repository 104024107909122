/**
 * @fileoverview This file defines the ModuleFactory component which is responsible for rendering the main content of the application.
 */

// Importing necessary libraries and components
import React, {useState} from "react";
import Sidebar from "./sidebar";
import MainContentWrapper from "./mainContentWrapper";
import {TableFilterContext, TableSearchContext} from "./contexts";
import {Outlet, useLocation} from "react-router-dom";
import {Row} from "reactstrap";

/**
 * ModuleFactory component that renders the main content of the application.
 * @param {Object[]} sidebarItems - The items to be displayed in the sidebar.
 * @param {string[]} subItems - The sub items to be used for table search and filter context.
 * @param {string[]} pathsWithNoSidebar - The paths where the sidebar should not be displayed.
 * @returns {JSX.Element} A Row component containing the Sidebar and MainContentWrapper components.
 */
export default function ModuleFactory({sidebarItems, subItems=[], pathsWithNoSidebar = []}) {
    // Using the current location
    const {pathname} = useLocation()

    // Creating state for table search and filter context
    const [tableSearchContextValue, setTableSearchContextValue] = useState(subItems.reduce((acc, item) => {
        acc[item] = ''
        return acc
    }, {}))
    const [tableFilterContextValue, setTableFilterContextValue] = useState(subItems.reduce((acc, item) => {
        acc[item] = {}
        return acc
    }, {}))

    // Determining whether to hide the sidebar
    const hideSidebar = pathsWithNoSidebar.some(path => pathname.includes(path))

    // Rendering the main content
    return <Row>
        {
            !hideSidebar && <Sidebar items={sidebarItems}/>
        }
        <MainContentWrapper noSidebar={hideSidebar}>
            <TableFilterContext.Provider value={{
                filters: tableFilterContextValue,
                setFilters: (key, filterName, filterValue) => {
                    setTableFilterContextValue({
                        ...tableFilterContextValue,
                        [key]: {
                            ...tableFilterContextValue[key],
                            [filterName]: filterValue
                        }
                    })
                }
            }}>
                <TableSearchContext.Provider value={{
                    searchText: tableSearchContextValue,
                    setSearchText: (key, value) => {
                        setTableSearchContextValue({...tableSearchContextValue, [key]: value})
                    }
                }}>
                    <Outlet/>
                </TableSearchContext.Provider>
            </TableFilterContext.Provider>
        </MainContentWrapper>
    </Row>
}