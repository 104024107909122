import React from "react";
import InfoCards from "../../../../components/charts/infoCards";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";


export default function PropertyInfoCards() {
    const api = useLoggedInApi()

    const result = useQuery(['property-summary'], () => {
        const propertyGroupsApi = api.resource('properties/groups')
        return propertyGroupsApi.getItem('summary_counts').then(response => {
            return response.data
        })
    }, {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    })

    return <InfoCards data={[
        {
            label: "PROPERTY GROUPS",
            value: result.data?.group_count,
            color: "blue2",
            isLoading: result.isLoading,
            navigateTo: 'groups'
        },
        {
            label: "PROPERTY LISTINGS",
            value: result.data?.listing_count,
            color: "green2",
            isLoading: result.isLoading,
            navigateTo: 'listing'
        }, {
            label: "WINS THIS MONTH",
            value: result.data?.wins,
            color: "primary",
            isLoading: result.isLoading
        }
    ]}/>
}