import React, {useEffect, useState} from "react";
import {useLoggedInApi} from "../../api";
import {AccordionBody, AccordionHeader, AccordionItem, Col, Row, Spinner, UncontrolledAccordion} from "reactstrap";
import Help from "../help";

export default function HelpList({site, module}) {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const api = useLoggedInApi()

    const loadData = () => {
        const helpApi = api.resource('help-read')
        setIsLoading(true)
        helpApi.list({params: {site: site, module: module}}).then(response => {
            setData(response.data)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    if (isLoading) {
        return <Row>
            <Col className="text-center my-5">
                <Spinner/>
            </Col>
        </Row>
    }

    if (data.length === 0) {
        return <Row>
            <Col>
                <span className=""><i>No help entries for this module.</i></span>
            </Col>
        </Row>
    }

    return <>
        <Row>
            <Col>
                <UncontrolledAccordion stayOpen>
                    {
                        data.map(row => {
                            return <AccordionItem className="my-4 border-top">
                                <AccordionHeader targetId={row.id}>
                                    {row.title}
                                </AccordionHeader>
                                <AccordionBody accordionId={row.id}>
                                    <Help data={row}/>
                                </AccordionBody>
                            </AccordionItem>
                        })
                    }
                </UncontrolledAccordion>
            </Col>
        </Row>
    </>
}