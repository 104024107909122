
export const  MODULE_CRM_BROKERS = 'crm_brokers'
export const  MODULE_CRM_INVENTORY = 'crm_inventory'
export const  MODULE_CRM_USERS = 'crm_users'
export const  MODULE_CRM_MEDIA = 'crm_media'

export const  MODULE_CHOICES = {
    [MODULE_CRM_BROKERS]: 'Brokers',
    [MODULE_CRM_INVENTORY]: 'Inventory',
    [MODULE_CRM_USERS]: 'Users',
    [MODULE_CRM_MEDIA]: 'Media',
}