import React, {useMemo} from "react";
import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import moment from "moment";
import PropertyGroupSelector from "../../../../../propertyGroupSelector";
import AdditionalPropertySelector from "./additionalPropertySelector";
import {PROPERTY_GROUP_SOURCE_MODEL_NAME, PROPERTY_SOURCE_MODEL_NAME} from "../../../../../constants";


export default function TagAdditionalData({tag, onSuccess, isOpen, toggle, otherTagForms}) {
    const [localData, setLocalData] = React.useState({})

    const isExtraInfoDuplicate = otherTagForms.some(row => {
        if (!!tag?.multiple_source) {
            if (tag.multiple_source === 'text') {
                return row.source_text === localData.source_text
            } else if (tag.multiple_source === 'object') {
                return row.source_object_id === localData.source_object_id
            }
        }
        return false
    })
    const usedExtraInfo = otherTagForms.map(row => {
        if (!!tag?.multiple_source) {
            if (tag.multiple_source === 'text') {
                return row.source_text
            } else if (tag.multiple_source === 'object') {
                return row.source_object_id
            }
        }
        return null
    }).filter(Boolean)

    const showPropertySelector = tag?.multiple_source === 'object' && tag.source_model_name === PROPERTY_SOURCE_MODEL_NAME
    const showPropertyGroupSelector = tag?.multiple_source === 'object' && tag.source_model_name === PROPERTY_GROUP_SOURCE_MODEL_NAME

    const minHeight = useMemo(() => {
        if (showPropertySelector) {
            return "35rem"
        }
        if (showPropertyGroupSelector) {
            return "30rem"
        }
        return "20rem"
    }, [showPropertySelector, showPropertyGroupSelector])

    const isFutureSelected = useMemo(() => {
        if (!localData.tag_datetime) {
            return false
        }
        return moment(localData.tag_datetime).isAfter(moment())
    }, [localData.tag_datetime])

    const extraInfoIsFilled = useMemo(() => {
        if (tag?.multiple_source === 'text') {
            return !!localData.source_text
        }
        if (tag?.multiple_source === 'object') {
            return !!localData.source_object_id
        }
        if (tag?.accept_text) {
            return !!localData.tag_text
        }
        if (tag?.accept_datetime) {
            return !!localData.tag_datetime
        }
        return true
    }, [tag, localData])

    if (!tag) {
        return null
    }

    return <>
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            // size="lg"
            centered
            scrollable={true}
        >
            <ModalHeader toggle={toggle} className="activegroup-header">

            </ModalHeader>
            <ModalBody className="px-5 py-4">
                <div style={{minHeight: minHeight}}>
                    <Row className="border-bottom">
                        <Col>
                            <h5>Additional data</h5>
                        </Col>
                    </Row>
                    {tag.accept_datetime && <Row>
                        <Col>
                            <Label className="small">
                                Set tag datetime
                            </Label>
                            <Input
                                type={'datetime-local'}
                                value={localData.tag_datetime ? moment(localData.tag_datetime).format('YYYY-MM-DDTHH:mm:ss') : ''}
                                onChange={(e) => {
                                    const isFuture = moment(e.target.value).isAfter(moment())
                                    setLocalData({
                                        ...localData,
                                        tag_datetime: e.target.value,
                                        send_reminder: localData.send_reminder ? isFuture : false
                                    })
                                }}
                            />
                            <ButtonGroup size="sm text-muted">
                                <Button color={'link'} className="mx-1" onClick={() => {
                                    const timeString = moment(localData.tag_datetime).format('HH:mm:ss')
                                    const nextDay = moment().add(1, 'day').format('YYYY-MM-DD')
                                    setLocalData({...localData, tag_datetime: `${nextDay}T${timeString}`})
                                }}>Next day</Button>
                                <Button color={'link'} className="mx-1" onClick={() => {
                                    const timeString = moment(localData.tag_datetime).format('HH:mm:ss')
                                    const nextDay = moment().add(1, 'week').format('YYYY-MM-DD')
                                    setLocalData({...localData, tag_datetime: `${nextDay}T${timeString}`})
                                }}>1 week from now</Button>
                            </ButtonGroup>
                            |
                            <ButtonGroup size="sm text-muted">
                                <Button color={'link'} className="mx-1" onClick={() => {
                                    const timeString = '08:00:00'
                                    const nextDay = moment(localData.tag_datetime).format('YYYY-MM-DD')
                                    setLocalData({...localData, tag_datetime: `${nextDay}T${timeString}`})
                                }}>Morning</Button>
                                <Button color={'link'} className="mx-1" onClick={() => {
                                    const timeString = '13:00:00'
                                    const nextDay = moment(localData.tag_datetime).format('YYYY-MM-DD')
                                    setLocalData({...localData, tag_datetime: `${nextDay}T${timeString}`})
                                }}>Afternoon</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>}
                    {tag.send_reminder && <Row>
                        <Col>
                            <FormGroup switch inline>
                                <Input
                                    type="switch" role="switch"
                                    onChange={() => {
                                        setLocalData({...localData, send_reminder: !localData.send_reminder})
                                    }}
                                    checked={localData.send_reminder}
                                    disabled={!isFutureSelected}
                                />
                                <Label check>
                                    Send email reminder?
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>}
                    {(tag.accept_text) && <Row>
                        <Col>
                            <Label className="small">
                                Enter more information
                            </Label>
                            <Input
                                type={'textarea'}
                                value={localData.tag_text}
                                onChange={(e) => {
                                    setLocalData({...localData, tag_text: e.target.value.trimStart()})
                                }}
                            />
                            {isExtraInfoDuplicate && <small className="text-red">
                                Duplicate entry. Please enter a different value.
                            </small>}
                        </Col>
                    </Row>}
                    {(tag.multiple_source === 'text') && <Row>
                        <Col>
                            <Label className="small">
                                Enter more information
                            </Label>
                            <Input
                                type={'textarea'}
                                value={localData.source_text}
                                onChange={(e) => {
                                    setLocalData({...localData, source_text: e.target.value.trimStart()})
                                }}
                            />
                            {isExtraInfoDuplicate && <small className="text-red">
                                Duplicate entry. Please enter a different value.
                            </small>}
                        </Col>
                    </Row>}
                    {showPropertyGroupSelector && <Row>
                        <Col>
                            <Label className="small">
                                Select a property group
                            </Label>
                            <PropertyGroupSelector
                                isMulti={false}
                                readOnly={false}
                                setPropertyGroups={(propertyGroupId) => {
                                    setLocalData({...localData, source_object_id: propertyGroupId})
                                }}
                                propertyGroups={localData.source_object_id}
                            />

                            {isExtraInfoDuplicate && <small className="text-red">
                                Duplicate entry. Please enter a different value.
                            </small>}
                        </Col>
                    </Row>}
                    {showPropertySelector && <Row>
                        <Col>
                            <AdditionalPropertySelector
                                isExtraInfoDuplicate={isExtraInfoDuplicate}
                                sourceObjectId={localData.source_object_id}
                                setSourceObjectId={(propertyId) => {
                                    setLocalData({...localData, source_object_id: propertyId})
                                }}
                                usedPropertyIds={usedExtraInfo}
                                tag={tag}
                            />

                            {isExtraInfoDuplicate && <small className="text-red">
                                Duplicate entry. Please enter a different value.
                            </small>}
                        </Col>
                    </Row>}
                </div>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col className="text-end">
                        <Button
                            disabled={isExtraInfoDuplicate || !extraInfoIsFilled}
                            onClick={() => {
                                onSuccess(localData)
                                toggle()
                            }}
                            color="primary"
                        >
                            CONFIRM
                        </Button>
                        <Button
                            color={'secondary'}
                            onClick={toggle}
                        >
                            CANCEL
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    </>
}