import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import BrokerProfileForm from "../../../../components/brokerProfileForm";
import {useParams} from "react-router-dom";


export default function NewBroker(props) {
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Add New Broker</h3>
            </Col>
        </Row>

        <BrokerProfileForm
            isMe={false}/>
    </>
}