import React, {useEffect, useState} from "react";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import {Col, Row, Spinner} from "reactstrap";
import ChartProgressBar from "../../components/charts/chartProgressBar";
import {stringToColour} from "../util";
import {useQuery} from "react-query";

const DEFAULT_DATA = {
}


export default function PropertiesClicked(props) {
    const api = useLoggedInApi()

    const query = useQuery(['salesperson', 'tracking_clicked_properties', props.filters], () => {
        const resource = api.resource('salesperson')
        return resource.getItem('tracking_clicked_properties', {
            params: props.filters
        }).then(response => response.data)
    })

    const data = query.isSuccess ? query.data : {}

    const customLabels = {
    }

    const customColors = {
        'Mozzafiato': "#0198ad",
        'The Alcove at Mount Malarayat': "#006138"
    }

    return <>
        <Row className="mt-5">
            <Col className="pb-2">
                <h5>MOST CLICKED PROPERTIES</h5>
            </Col>
            <Col className="col-auto">
                { query.isLoading && <Spinner/>}
            </Col>
        </Row>

        <div className="border-bottom"/>

        <ChartProgressBar
            data={[
                ...Object.keys(data).map(k => {
                    const label = customLabels.hasOwnProperty(k) ? customLabels[k] : k
                    return {
                        label: !!label ? label.toUpperCase() : <i>UNKNOWN</i>,
                        value: data[k],
                        color: customColors.hasOwnProperty(k) ? customColors[k]: stringToColour(k)
                    }
                }).filter(row => !!row.value)
            ]}
        />
    </>
}