import React, {useState} from "react";
import {Badge, Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faEnvelope, faFileAlt, faNewspaper, faSms} from "@fortawesome/free-solid-svg-icons";
import {NavLink, useNavigate} from "react-router-dom";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import moment from "moment";
import DetailModal from "./detailModal";


const statusBadge = row => {
    if (row.is_sent) {
        return <Badge color="success" className="small">SENT</Badge>
    } else if (!row.is_active) {
        return <Badge color="gray" className="small">CANCELLED</Badge>
    } else {
        return <Badge color="primary" className="small">SCHEDULED</Badge>
    }
}

const typeBadge = broadcastType => {
    if (broadcastType === 'sms') {
        return <FontAwesomeIcon icon={faSms}/>
    } else if (broadcastType === 'email') {
        return <FontAwesomeIcon icon={faEnvelope}/>
    } else if (broadcastType === 'news') {
        return <FontAwesomeIcon icon={faNewspaper}/>
    }
}


export default function Broadcast(props) {
    const [editId, setEditId] = useState()
    const [detailModalOpen, setDetailModalOpen] = useState(false)

    const navigate = useNavigate()
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Broadcast To Brokers</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Send broadcasts to brokers via Email or SMS (*This will require an SMS Load wallet).
                Only super admins can schedule SMS broadcasts.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    setEditId(null)
                    setDetailModalOpen(true)
                }}>

                    <FontAwesomeIcon icon={faBullhorn}/>{" "}
                    SCHEDULE BROADCAST TO BROKERS
                </Button>
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Broadcast"}
            apiResourceName={'broadcast'}
            columnDefinition={[
                {
                    name: "Broadcast",
                    selector: row => <Button color='link' size='sm' onClick={() => {
                        setEditId(row.id);
                        setDetailModalOpen(true)
                    }}>
                        {typeBadge(row.broadcast_type)}{" "}
                        <strong>{row.title}</strong>
                    </Button>,
                    sortable: true,
                    sortField: 'title'
                },
                {
                    name: "Send Date",
                    selector: row => <>
                        {moment(row.send_datetime).format('LL')}
                    </>,
                    sortable: true,
                    sortField: 'send_datetime'
                },
                {
                    name: "Type",
                    selector: row => row.get_broadcast_type_display,
                    sortable: true,
                    sortField: 'broadcast_type'
                },
                {
                    name: 'Recipients',
                    selector: row => row.recipient_count?.toLocaleString(),
                    sortable: false,

                },
                {
                    name: 'Status',
                    selector: statusBadge,
                    sortable: true,
                    sortField: 'is_active,is_sent'

                }
            ]}/>

        <DetailModal
            dataId={editId}
            key={editId}
            isOpen={detailModalOpen}
            toggle={() => setDetailModalOpen(false)}
            setDataId={setEditId}
            />
    </>
}