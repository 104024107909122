import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import FileSelector from "../../../brokerManagement/brokers/bulkUpload/fileSelector";
import Review from "./review";
import Commit from "./commit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export default function BulkUpload() {
    const [fileName, setFileName] = useState('')
    const [fileData, setFileData] = useState([])
    const [isFileRead, setIsFileRead] = useState(false)

    const [processedData, setProcessedData] = useState([])
    const [isDataProcessed, setIsDataProcessed] = useState(false)


    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Manage Property Listings - Bulk Upload</h3>
            </Col>
        </Row>

        {
            !isFileRead && <FileSelector
                setFileData={(data, fileName) => {
                    setFileData(data)
                    setFileName(fileName)
                }}
                nextStep={() => {
                    setIsFileRead(true)
                }}
                header={
                    <Row className={"mt-4"}>
                        <Col>
                            <a href={"/docs/ActiveGroup Template - Bulk Property Listing.xlsx"} target={"_blank"}>
                                <FontAwesomeIcon icon={faDownload}/> Download the excel template for bulk listing creation
                            </a>
                        </Col>
                    </Row>
                }/>
        }


        {
            isFileRead && !isDataProcessed && <Review
                fileName={fileName}
                fileData={fileData}
                previousStep={() => {
                    setIsFileRead(false)
                    setFileName('')
                    setFileData([])
                }}
                onDataProcessed={(data) => {
                    setProcessedData(data)
                    setIsDataProcessed(true)
                }}
            />
        }
        {
            isDataProcessed && <Commit
                processedData={processedData}
                previousStep={() => {
                    setProcessedData([])
                    setIsDataProcessed(false)
                }}
            />
        }
    </>
}