import React, {useContext} from "react";
import {Col, Row} from "reactstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {ToggleSidebarContext} from "../contexts";
import Welcome from "./welcome";
import ProfileDisplay from "./profileDisplay";


export default function SideBarWrapper({showMainMenu=true, children}) {
    const {isOpen, close} = useContext(ToggleSidebarContext)

    return <Col className={"active-group-sidebar pe-5 sidebar " + (isOpen ? "sidebar-open" : "")} lg={4} xl={4} xxl={3}>
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        SIZE:*/}
        {/*        <span className="d-none d-xxl-inline">XXL </span>*/}
        {/*        <span className="d-none d-xl-inline d-xxl-none">XL </span>*/}
        {/*        <span className="d-none d-lg-inline d-xl-none d-xxl-none">LG </span>*/}
        {/*        <span className="d-none d-md-inline d-sm-none d-lg-none">MD </span>*/}
        {/*        <span className="d-none d-sm-inline d-xs-none d-lg-none d-md-none">SM </span>*/}
        {/*        <span className="d-sm-inline d-xs-none d-lg-none d-md-none d-sm-none">XS </span>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        <Welcome/>
        <ProfileDisplay/>

        {
            showMainMenu && <Row className="sidebar-nav">
                <Col>
                    <NavLink to={'/'} onClick={close} className="small">
                        <FontAwesomeIcon icon={faAngleDoubleLeft} className="p-0 m-0"/> Main Menu
                    </NavLink>
                </Col>
            </Row>
        }


        {children}

    </Col>
}