import React, {useEffect, useState} from "react";
import {Col, Row, Spinner} from "reactstrap";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import ChartProgressBar from "../../components/charts/chartProgressBar";
import {useQuery} from "react-query";

const DEFAULT_DATA = {
    'new': 0,
    'contacted': 0,
    'proposal': 0,
    'site_visit': 0,
    'closed_sold': 0,
    'inactive': 0,
    'total': 0
}


export default function LeadStatusBreakdown(props) {
    const api = useLoggedInApi()

    const query = useQuery(['table', 'lead', 'salesperson', 'lead_status_breakdown', props.filters], () => {
        const resource = api.resource('salesperson')
        return resource.getItem('lead_status_breakdown', {
            params: props.filters
        }).then(response => response.data)
    })

    const data = query.isSuccess ? query.data : {}

    return <>
        <Row className="mt-5">
            <Col className="pb-1">
                <h4><strong>TOTAL LEADS:</strong> {data.total}</h4>
            </Col>
            <Col className="col-auto">
                { query.isLoading && <Spinner/>}
            </Col>
        </Row>

        <div className="border-bottom"/>

        <ChartProgressBar
            data={[
                {label: "NEW LEADS", value: data.new, color: "#E02B20", title: `New Leads: ${data.new}`},
                {label: "CONTACTED", value: data.contacted, color: "#F0B800", title: `Contacted: ${data.contacted}`},
                {label: "PROPOSAL", value: data.proposal, color: "#0C71C3", title: `Proposal: ${data.proposal}`},
                {label: "SITE VISIT", value: data.site_visit, color: "#8300E9", title: `Site Visit: ${data.site_visit}`},
                {label: "CLOSED-SOLD", value: data.closed_sold, color: "#7CDA24", title: `Closed-Sold: ${data.closed_sold}`},
            ]}
            getDisplay={val => val && Number(val / data.total).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 0
            })}
            max={data.total}
        />

        <Row className="mt-3">
            <Col className="text-end">
                <small>
                    <strong>LEADS TAGGED AS INACTIVE:</strong> {data.inactive}
                </small>
            </Col>
        </Row>
    </>
}