import React, {useState} from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPencil, faPhotoVideo, faTrash} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import Detail from "./detail";
import moment from "moment";
import {isUrlImage} from "../../../../commonComponents/util";
import {useGroupPermissions} from "../../../../commonComponents/hooks";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {toast} from "react-toastify";


export default function LandingPage() {
    const [selectedKit, setSelectedKit] = useState()
    const [isDetailOpen, setIsDetailOpen] = useState(false)
    const {permissions, isLoading: permissionsLoading} = useGroupPermissions('properties')

    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const deleteMediaKitPromise = useMutation({
        mutationFn: (id) => {
            const resource = api.resource('mediakit')
            const deletePromise = resource.deleteItem(id)
            toast.promise(deletePromise, {
                loading: 'Deleting media kit...',
                success: 'Media kit deleted successfully',
                error: 'Error deleting media kit'
            })
            return deletePromise
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['mediakit']
            })
            queryClient.invalidateQueries({
                queryKey: ['table', 'mediakit']
            })
        }
    })

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Media Kits</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Upload official media kits to make them available for brokers to download. Official logos, photos,
                videos, brochures and site plans.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                {
                    permissionsLoading ?
                        <Spinner size="md"/> :
                        permissions.includes('can_add') && <Button color={"info-outline"} onClick={() => {
                            setIsDetailOpen(true);
                            setSelectedKit(null)
                        }}>
                            <FontAwesomeIcon icon={faPhotoVideo}/> UPLOAD MEDIA KIT
                        </Button>
                }
            </Col>
        </Row>

        <AdvancedTable
            friendlyResourceName={"Media Kits"}
            apiResourceName={'mediakit'}
            columnDefinition={[
                {
                    name: "Date",
                    selector: row => row.datetime_added,
                    format: row => <>
                        {moment(row.datetime_added).format('LL')}<br/>
                        <small>{moment(row.datetime_added).format('LT')}</small>
                    </>,
                    sortable: true,
                    sortField: 'datetime_added'
                },
                {
                    name: "Thumbnail",
                    selector: row => row.thumbnail_url,
                    format: row => {
                        if (row.thumbnail_url) {
                            if (isUrlImage(row.thumbnail_url)) {
                                return <img className="img img-fluid img-thumbnail" src={row.thumbnail_url}/>
                            } else {
                                return <span className="text-center">
                                <i>No thumbnail available.</i>
                            </span>
                            }

                        } else {
                            return <span className="text-center">
                                <i>No content</i>
                            </span>
                        }
                    },
                    sortable: false,
                    sortField: ''
                },
                {
                    name: "Description",
                    selector: row => row.description,
                    sortable: true,
                    sortField: 'description'
                },
                {
                    name: "Actions",
                    selector: row => row.id,
                    sortable: false,
                    format: row => <>
                        <Button size="sm" onClick={() => {
                            setSelectedKit(row.id);
                            setIsDetailOpen(true);
                        }}>
                            <FontAwesomeIcon icon={faPencil}/>
                        </Button> <br/>
                        <a className="btn btn-primary btn-sm" href={row.zip_url} target="_blank">
                            <FontAwesomeIcon icon={faDownload}/>
                        </a>
                        <br/>
                        <ConfirmButton onClick={() => {
                            deleteMediaKitPromise.mutate(row.id)
                        }} size={"sm"} color={"danger"} wrapInline={false}>
                            <FontAwesomeIcon icon={faTrash} width="1em"/>
                        </ConfirmButton>
                    </>
                }
            ]}
        />

        {
            isDetailOpen && <Detail
                key={selectedKit}
                isOpen={isDetailOpen}
                mediaKitId={selectedKit}
                toggle={() => {
                    setIsDetailOpen(false)
                    setSelectedKit(null)
                }}

                onCreate={newData => {
                    setSelectedKit(newData.id)
                }}
            />
        }
    </>
}