import React from "react";
import {Button, ButtonGroup, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import InputRow from "../../../../components/inputRow";
import {useLoggedInApi} from "../../../../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ClassicEditor from "../../../../components/classicEditor";
import {dictChangedValues} from "../../../../commonComponents/util";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import prettyBytes from "pretty-bytes";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import {MODULE_CHOICES} from "./constants";


export default function EditModal({isOpen, toggle, helpId, showModule, site}) {
    const [localData, setLocalData] = React.useState({
        title: "",
        content: "",
        module: showModule ? Object.keys(MODULE_CHOICES)[0] : "",
    })
    const [deletedAttachments, setDeletedAttachments] = React.useState([])
    const [newAttachments, setNewAttachments] = React.useState([])
    const newAttachmentFileRef = React.useRef(null)

    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const {isLoading, isError, data} = useQuery(['help', helpId], () => {
        const resource = api.resource('help')
        return resource.getItem(helpId).then(response => response.data)
    }, {
        enabled: !!helpId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const originalData = {...data}
            delete originalData["attachments"]  // we do not want to compare this
            setLocalData(data)
        }
    })

    const originalData = {...data}
    delete originalData["attachments"]  // we do not want to compare this

    const saveHelpChanges = (updatedData) => {
        const resource = api.resource('help')
        if (data?.id) {
            const changedData = dictChangedValues(data, updatedData)
            const updatePromise = resource.patchItem(data.id, changedData).then(response => response.data)

            return updatePromise
        } else {

            const createPromise = resource.create({
                ...updatedData,
                site: site
            }).then(response => response.data)
            return createPromise
        }
    }

    const saveMutation = useMutation((updatedData) => {
        const helpPromise = saveHelpChanges(updatedData)
        const uploadAttachmentsPromise = helpPromise.then((help) => {
            // upload new attachments
            return Promise.all(newAttachments.map((newAttachment) => {
                const form = new FormData()
                form.append("help", help.id)
                form.append('file', newAttachment.file)
                form.append('name', newAttachment.name)
                return api.resource('help/attachments')
                    .create(form)
                    .then(response => response.data)
            }))
        })
        const deleteAttachmentsPromise = Promise.all(deletedAttachments.map((attachmentId) => {
            return api.resource('help/attachments').deleteItem(attachmentId)
        }));
        const allPromise = Promise.all([helpPromise, uploadAttachmentsPromise, deleteAttachmentsPromise])
        if (data?.id) {
            toast.promise(allPromise, {
                loading: 'Saving changes...',
                success: 'Changes saved',
                error: 'Error saving changes'
            })
        } else {
            toast.promise(allPromise, {
                loading: 'Creating help entry...',
                success: 'Help entry created',
                error: 'Error creating help entry'
            })
        }
        return allPromise
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['table', 'help'])
            queryClient.invalidateQueries(['help', data.id])
            toggle()
        }
    });

    const hasChanges = !helpId ||
        (originalData && Object.keys(dictChangedValues(originalData, localData)).length > 0) ||
        deletedAttachments.length > 0 ||
        newAttachments.length > 0

    const isDataComplete = localData.title && localData.content
    const canSave = hasChanges && !isLoading && !isError && !saveMutation.isLoading && isDataComplete

    const getModalBody = () => {
        if (isLoading) {
            return <Row className="m-5">
                <Col className="text-center">
                    <Spinner/>
                </Col>
            </Row>
        }
        if (isError) {
            return <Row className="m-5">
                <Col className="text-center">
                    Cannot fetch help entry. Please try again. If problem persists, contact administrator.
                </Col>
            </Row>
        }
        return <>

            <Row className="">
                <Col className="">
                    <h3>Edit Help Entry</h3>
                </Col>
            </Row>
            <InputRow
                label={"Title"}
                value={localData.title}
                onChange={newTitle => {
                    setLocalData({...localData, title: newTitle})
                }}/>

            {
                showModule && <InputRow
                    type={"select"}
                    label={"Module"}
                    value={localData.module}
                    onChange={newModule => {
                        setLocalData({...localData, module: newModule})
                    }}>
                    {
                        Object.keys(MODULE_CHOICES).map((module) => {
                            return <option value={module}>{MODULE_CHOICES[module]}</option>
                        })
                    }
                </InputRow>
            }

            <ClassicEditor
                value={localData.content}
                onEditorChange={newContent => {
                    setLocalData({...localData, content: newContent})
                }}
                initialData={localData.content}
            />

            <Row className="pt-4">
                <Col>
                    <Row className="ps-4">
                        <Col>
                            <h5>Attachments</h5>
                        </Col>
                        <Col className="text-end small">
                            <Button color={"link"} className={"p-0"} onClick={() => {
                                const fileInput = newAttachmentFileRef.current
                                fileInput.click()
                            }}>
                                <FontAwesomeIcon icon={faAdd}/>{" "}
                                ADD ATTACHMENT
                            </Button>
                            <input ref={newAttachmentFileRef} type={"file"} className={"d-none"} onChange={e => {
                                const file = e.target.files[0]
                                const newAttachment = {
                                    id: newAttachments.length + 1,
                                    name: file.name,
                                    size: file.size,
                                    file: file
                                }
                                setNewAttachments([...newAttachments, newAttachment])
                            }}/>
                        </Col>
                    </Row>
                    {
                        data?.attachments?.filter(attachment => !deletedAttachments.includes(attachment.id))
                            .map(attachment => {
                                return <Row className={"ps-4"}>
                                    <Col>
                                        <a href={attachment.file} target={"_blank"}>
                                            {attachment.name}
                                        </a>
                                    </Col>
                                    <Col>

                                        <Row className="form-group ">
                                            <Label className={"col-form-label col-sm-2"}>URL</Label>
                                            <Col sm={10} className={"col-form-label"}>
                                                <Input readOnly value={attachment.file}
                                                       className={"form-control-plaintext form-control-sm"}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-end small">
                                        <ConfirmButton color="link" onClick={() => {
                                            setDeletedAttachments([...deletedAttachments, attachment.id])
                                        }}>
                                            <FontAwesomeIcon icon={faTrash} className={"text-danger"} onClick={() => {
                                                setDeletedAttachments([...deletedAttachments, attachment.id])
                                            }}/> DELETE
                                        </ConfirmButton>
                                    </Col>
                                </Row>
                            })
                    }

                    {
                        newAttachments.map(attachment => {
                            return <Row className="ps-4">
                                <Col>{attachment.name}</Col>
                                <Col>New attachment. Filesize: {prettyBytes(attachment.size)}</Col>
                                <Col className="text-end small">
                                    <Button color={"link"} className={"p-0"} onClick={() => {
                                        setNewAttachments(newAttachments.filter(a => a.id !== attachment.id))
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} className={"text-danger"}/>{" "}
                                        DELETE
                                    </Button>
                                </Col>
                            </Row>
                        })
                    }
                </Col>
            </Row>

            <Row className="pt-4">
                <Col className="text-end">
                    <ButtonGroup>
                        <Button color="secondary" onClick={toggle}>
                            CANCEL
                        </Button>
                        <Button color="primary" onClick={() => {
                            saveMutation.mutate(localData)
                        }} disabled={!canSave}>
                            <FontAwesomeIcon icon={faSave}/> SAVE
                        </Button>
                    </ButtonGroup>

                </Col>
            </Row>

        </>
    }

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        scrollable={true}
    >

        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            {getModalBody()}
        </ModalBody>
    </Modal>
}