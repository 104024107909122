import React from "react";
import {SketchPicker} from "react-color";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TagDisplay from "./tagDisplay";


export default function TagColorPickerModal({isOpen, toggle, color, setColor, setTextColor, textColor}) {
    const [localColor, setLocalColor] = React.useState(color)
    const [localTextColor, setLocalTextColor] = React.useState(textColor)

    React.useEffect(() => {
        setLocalColor(color)
    }, [color])
    React.useEffect(() => {
        setLocalTextColor(textColor)
    }, [textColor])

    return <Modal
        isOpen={isOpen}
        toggle={toggle}
        // size="sm"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header"></ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <h4>Background Color</h4>
                    <SketchPicker
                        width={'auto'}
                        disableAlpha
                        color={localColor}
                        onChangeComplete={(newColor) => {
                            setLocalColor(newColor.hex)
                        }}
                    />
                </Col>
                <Col>
                    <h4>Text Color</h4>
                    <SketchPicker
                        width={'auto'}
                        disableAlpha
                        color={localTextColor}
                        onChangeComplete={(newColor => {
                            setLocalTextColor(newColor.hex)
                        })}/>
                </Col>
            </Row>
            <Row className={"mt-3 text-center"}>
                <Col>
                    <TagDisplay style={{fontSize: "1rem"}} color={localColor} textColor={localTextColor}>
                        PREVIEW
                    </TagDisplay>
                    <TagDisplay style={{fontSize: "1rem"}} color={localColor} textColor={localTextColor} disabled>
                        DISABLED
                    </TagDisplay>

                    <TagDisplay style={{fontSize: "1rem"}} color={localColor} textColor={localTextColor} onDelete={() => {}}>
                        WITH REMOVE
                    </TagDisplay>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>

            <Button color="primary" onClick={() => {
                setColor(localColor)
                setTextColor(localTextColor)
                toggle()
            }}>Save</Button>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
    </Modal>
}