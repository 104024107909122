import React from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import TagGroup from "./tagGroup";
import EditModal from "./tagGroup/tagGroupEditModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PermissionRequiredComponent from "../../../../commonComponents/permissionRequiredComponent";
import {useTagGroups} from "../../../../commonComponents/hooks";

const TAG_GROUP_DEFAULT_DATA = {
    name: '',
    description: '',
    is_unique: null,
    is_sequence: null,
}

export default function TagManagement() {
    const [editModalOpen, setEditModalOpen] = React.useState(false)
    const [editId, setEditId] = React.useState(null)
    const toggleEditModal = () => setEditModalOpen(!editModalOpen)
    const createNewTagGroup = () => {
        setEditId(-(new Date()).getTime())
        toggleEditModal()
    }
    const editTagGroup = (id) => {
        setEditId(id)
        toggleEditModal()
    }

    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroups({})


    const selectedData = editId > 0 ? tagGroupsData?.find(row => row.id === editId) : TAG_GROUP_DEFAULT_DATA

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Tags Management</h3>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                Manage Tags for leads.
            </Col>
        </Row>

        {
            tagsGroupIsLoading ?
                <Row className="text-center my-5">
                    <Col>
                        <Spinner/>
                    </Col>
                </Row> :
                <Row className="">
                    <Col>
                        {
                            tagGroupsData?.map(row => {
                                return <TagGroup
                                    key={row.id}
                                    data={row}
                                    showEdit={() => {
                                        editTagGroup(row.id)
                                    }}
                                />
                            })
                        }
                    </Col>
                </Row>
        }
        <PermissionRequiredComponent requiredPermissions={['tagform.add_taggroup']}>
            <Row className="my-4">
                <Col className="text-end">
                    <Button color="primary text-white" onClick={createNewTagGroup}>
                        <FontAwesomeIcon icon={faPlus}/>{" "}
                        ADD TAG GROUP
                    </Button>
                </Col>
            </Row>
        </PermissionRequiredComponent>
        {
            editModalOpen &&
            <EditModal isOpen={editModalOpen} toggle={toggleEditModal} data={selectedData} key={editId}/>
        }
    </>
}