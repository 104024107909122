import React from "react";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import ExpandedLog from "./expandedLog";


export default function Logs() {
    return <>
        <h1>System Logs</h1>
        <AdvancedTable
            hideTotalCount={true}
            hideSearch={true}
            friendlyResourceName={"Logs"}
            apiResourceName={"log"}
            compact={true}
            customTableStyles={{
                rows: {
                    style: {
                        minHeight: '2em'
                    }
                }
            }}
            columnDefinition={[
                {
                    name: "Date", selector: row => row.datetime_added, sortable: true, sortField: 'datetime_added',
                    format: row => {
                        return new Date(row.datetime_added).toLocaleString()
                    },
                    width: "180px"
                },
                {
                    name: "Summary", selector: row => row.display_text, sortable: false,
                }
                ]}
            dataTableProps={{
                expandableRows: true,
                expandableRowsComponent: ExpandedLog,
            }}
            />
    </>
}