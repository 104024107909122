import React from "react";
import {Col, Row} from "reactstrap";


export default function AnswerChangeDisplay({answerHistory, formField}) {
    const fieldName = answerHistory?.field_display || formField?.name || "";

    const oldDisplay = answerHistory.old_value_display || answerHistory.old_value;
    const newDisplay = answerHistory.new_value_display || answerHistory.new_value;

    const fieldNameDisplay = fieldName?.toUpperCase();

    return <Row className="small">
        <Col>
            {
                oldDisplay ? <>
                    <strong>PREVIOUS {fieldNameDisplay}: </strong> {oldDisplay}<br/>
                    <strong>UPDATED {fieldNameDisplay}: </strong> {newDisplay}<br/>
                </> : <>
                    <strong>{fieldNameDisplay}: </strong> {newDisplay}<br/>
                </>
            }
        </Col>
    </Row>
}