import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import UpdatePasswordForm from "./updatePasswordForm";

export default function UpdatePasswordModal(props) {
    return <Modal
        isOpen={props.isOpen}
        size="lg"
        centered
        toggle={props.toggle}>
        <ModalHeader
            toggle={props.toggle}>
            <h2>Update Password</h2>

        </ModalHeader>
        <ModalBody className="px-5">
            {
                props.isOpen && <UpdatePasswordForm isMe={true} hideTitle={true}/>
            }
        </ModalBody>
    </Modal>
}