import React from "react";
import {Col, Row} from "reactstrap";
import TagDisplay from "../../../../commonComponents/tagDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDownUpAcrossLine, faArrowsUpDown,
    faAsterisk,
    faEye,
    faEyeSlash, faLock,
    faPencil,
    faSort
} from "@fortawesome/free-solid-svg-icons";
import EditLeadFieldModal from "./editLeadFieldModal";
import {useTagGroup} from "../../../../commonComponents/fetchers/tags";

import {usePermissionContextCheck} from "../../../../commonComponents/hooks";


export default function Field({field, setField, readOnly, usedTagGroups}) {
    const canEditFormField = usePermissionContextCheck('tagform.change_formfield')
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const {data: tagGroupsData, isLoading: isLoadingTagGroups} = useTagGroup({})
    const tagGroupById = tagGroupsData?.reduce((acc, row) => {
        acc[row.id] = row
        return acc
    }, {})

    return <Row
        className={readOnly ? "read-only  my-4" : " my-4"}
    >
        <Col>
            <div className="border rounded p-3">
                {readOnly && <FontAwesomeIcon icon={faLock} className={"me-2"}/>}
                {field.name}
                {
                    (field.field_type === "tag" && tagGroupById) && <>
                        {" "}<small className="text-muted">({tagGroupById[field.tag_group].name})</small>
                    </>
                }
                {
                    (!readOnly && canEditFormField) &&
                    <FontAwesomeIcon icon={faArrowsUpDown} className="float-end sort-icon pointer-move"/>
                }

                {
                    readOnly ?
                        <>
                            <hr/>
                            <TagDisplay
                                color={field.is_active ? "gray" : "light-gray"}>
                                <FontAwesomeIcon icon={field.is_active ? faEye : faEyeSlash}/>{" "}
                                {field.is_active ? "VISIBLE" : "HIDDEN"}
                            </TagDisplay>
                            <TagDisplay
                                color={field.is_required ? "green" : "light-gray"}>
                                <FontAwesomeIcon icon={faAsterisk}/>{" "}
                                REQUIRED
                            </TagDisplay>
                        </> :
                        <>

                            <hr/>
                            <TagDisplay
                                disabled={!canEditFormField}
                                onClick={() => setEditModalOpen(true)}
                                color="red">
                                <FontAwesomeIcon icon={faPencil}/>{" "}
                                EDIT
                            </TagDisplay>
                            <TagDisplay
                                onClick={canEditFormField ? () => setEditModalOpen(true) : undefined}
                                color={field.is_active ? "gray" : "light-gray"}>
                                <FontAwesomeIcon icon={field.is_active ? faEye : faEyeSlash}/>{" "}
                                {field.is_active ? "VISIBLE" : "HIDDEN"}
                            </TagDisplay>
                            <TagDisplay
                                onClick={canEditFormField ? () => setEditModalOpen(true) : undefined}
                                color={field.is_required ? "green" : "light-gray"}>
                                <FontAwesomeIcon icon={faAsterisk}/>{" "}
                                REQUIRED
                            </TagDisplay>
                        </>
                }
            </div>
            {
                editModalOpen && <EditLeadFieldModal
                    field={field}
                    setField={setField}
                    toggle={() => setEditModalOpen(!editModalOpen)}
                    isOpen={editModalOpen}
                    usedTagGroups={usedTagGroups}
                />
            }
        </Col>
    </Row>
}