import React from "react";
import TagDisplay from "../../../../tagDisplay";
import {COLOR_PRIMARY, COLOR_SELECTED} from "../constants";
import Compare from "./compare";
import {Col, Row, Spinner} from "reactstrap";


export default function Preview({latestData, isLoadingPreview, leadData, oldSalesProfile, newSalesProfile}) {

    if (isLoadingPreview) {
        return <Row>
            <Col className={"text-center"}>
                <Spinner/>
            </Col>

        </Row>
    }

    return <>
        <p>

            <TagDisplay color={COLOR_PRIMARY}>
                PRIMARY LEAD
            </TagDisplay> will be retained after the merge. Review changes in form fields and tags below.
        </p>

        <>
            <Compare
                primaryLead={leadData}
                secondaryLead={{
                    ...leadData,
                    ...latestData
                }}
                // showPrimaryInfo={true}
                // showUnchangedAnswers={true}
            />

            {
                (oldSalesProfile && newSalesProfile && oldSalesProfile.id !== newSalesProfile.id) && <Row
                    className="border-top mt-4 mx-1 py-2 text-muted"
                    style={{
                        backgroundColor: COLOR_SELECTED
                    }}>
                    <Row>
                        <Col><strong>REASSIGNED TO DIFFERENT BROKER/SALES CONTACT</strong></Col>
                    </Row>
                    <Row>
                        <Col>{newSalesProfile.name}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {newSalesProfile.contact_number} | {newSalesProfile.email_address}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            Broker Group: {newSalesProfile.group_name}
                        </Col>
                    </Row>
                </Row>
            }

        </>
    </>

}