import React from "react";
import FormField from "./formField";
import {Col, Label, Row} from "reactstrap";
import PropertyGroupSelectorAsTag from "../../../../propertyGroupSelectorAsTag";
import {useLeadForm} from "../../../../hooks";

export default function LeadForm({data, setData, unlockPermanentTags}) {

    const {data: formData, isLoading: isFormLoading} = useLeadForm()


    const orderedFields = formData?.form_fields.sort((a, b) => a.order - b.order).filter(field => {
        return field.is_active
    })

    return <>
        {
            orderedFields?.map((field, index) => {
                return <Row key={field.id} className="mb-2">
                    <Col>
                        <FormField key={field.id} field={field} data={data} setData={setData} unlockPermanentTags={unlockPermanentTags}/>
                    </Col>
                </Row>
            })
        }
        <Row className="mb-2">
            <Col>
                <Label className="text-muted lineheight-1">Property Groups</Label>
                <div className="tags">
                    <PropertyGroupSelectorAsTag
                        isMulti={true}
                        propertyGroups={data?.property_groups}
                        setPropertyGroups={propertyGroups => {
                            setData({...data, property_groups: propertyGroups})
                        }}
                        showNotSelected={true}
                    />
                </div>
            </Col>
        </Row>
    </>
}