import React from "react";
import {Button} from "reactstrap";
import {processResponseAsDownload} from "./util";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../../api";


export default function ExportList({params, count}) {
    const [onClickParams, setOnClickParams] = React.useState(params)
    const [isExporting, setIsExporting] = React.useState(false)

    const api = useLoggedInApi()
    const exportPage = () => {
        setIsExporting(true)
        const leadResource = api.resource('leads')
        const downloadPromise = leadResource.getItem('export_list_xlsx', {
            params: params,
            responseType: "blob"
        }).then(processResponseAsDownload)

        toast.promise(downloadPromise, {
            error: {
                render({data}) {
                    const reasonDetail = data?.response?.data?.detail
                    if (reasonDetail) {
                        return reasonDetail
                    }

                    return "Something went wrong. Please contact administrator"
                }
            },
            pending: "Generating export file",
            success: "Leads successfully exported!"
        }).finally(() => {
            setIsExporting(false)
        })
    }



    return <>
        <Button
            disabled={isExporting || !count}
            size="sm" color="primary" onClick={() => {
            setOnClickParams({...params})
            exportPage()
        }}>
            EXPORT {count} LEADS
        </Button>
    </>
}