import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import DisplayInfoRow from "./displayInfoRow";
import UploadSampleComputation from "./uploadSampleComputation";
import ActivityLog from "./activityLog";
import Status from "./status";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import {toast} from "react-toastify";


function CancelListing({data}) {
    const [showConfirm, setShowConfirm] = useState(false)
    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const cancelMutate = useMutation(id => {
        const resource = api.resource('properties')
        const promise = resource.deleteItem(id).then(response => response.data)
        toast.promise(promise, {
            pending: "Cancelling listing.",
            success: "Listing cancelled.",
            error: "Cannot cancel listing. Please contact administrator."
        })
        return promise;
    }, {
        onSuccess: responseData => {
            queryClient.setQueryData(['property', responseData.id], responseData)
            queryClient.invalidateQueries(['table', 'properties'])
        }
    })

    return <>
        <Button
            color="link"
            size="sm"
            onClick={() => {
                if (showConfirm) {
                    cancelMutate.mutate(data.id)
                } else {
                    setShowConfirm(true)
                }
            }}
            onBlur={() => {
                setShowConfirm(false)
            }}
        >
            <small>
                {!showConfirm ? "Cancel listing" : "Are you sure you want to cancel listing?"}
            </small>
        </Button>
    </>
}

function EnableListing({data}) {
    const [showConfirm, setShowConfirm] = useState(false)
    const api = useLoggedInApi()
    const queryClient = useQueryClient()
    const cancelMutate = useMutation(id => {
        const resource = api.resource('properties')
        const promise = resource.postItemAction(id, 'undestroy', {}).then(response => response.data)
        toast.promise(promise, {
            pending: "Enabling listing.",
            success: "Listing enabled.",
            error: "Cannot enable listing. Please contact administrator."
        })
        return promise;
    }, {
        onSuccess: responseData => {
            queryClient.setQueryData(['property', responseData.id], responseData)
            queryClient.invalidateQueries(['table', 'properties'])
        }
    })

    return <>
        <Button
            color="link"
            size="sm"
            onClick={() => {
                if (showConfirm) {
                    cancelMutate.mutate(data.id)
                } else {
                    setShowConfirm(true)
                }
            }}
            onBlur={() => {
                setShowConfirm(false)
            }}
        >
            <small>
                {!showConfirm ? "Enable listing" : "Are you sure you want to enable listing?"}
            </small>
        </Button>
    </>
}


export default function View({data, setIsEdit}) {
    return <Row>
        <Col lg={8} className="border-end pe-4">
            <Row>
                <Col>
                    <h2>Property Details</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        data.can_edit &&
                        <Button color="link" size="sm" onClick={() => setIsEdit(true)} className="me-4">
                            <small>Edit property</small>
                        </Button>
                    }

                    {
                        data.can_delete && data.is_active && <CancelListing data={data}/>
                    }
                    {
                        data.can_delete && !data.is_active && <EnableListing data={data}/>
                    }
                </Col>
            </Row>

            <DisplayInfoRow value={<strong>{data.label}</strong>} label={"Property Label"}/>
            <DisplayInfoRow value={<strong>{data.group_name}</strong>} label={"Property Group"}/>
            <DisplayInfoRow value={<strong>{data.unique_property_id}</strong>} label={"Property ID"}/>
            <DisplayInfoRow value={data.description} label={"Lot Description"}/>
            <br/>
            <DisplayInfoRow value={data.phase} label={"Phase"}/>
            <DisplayInfoRow value={data.block} label={"Block"}/>
            <DisplayInfoRow value={data.lot} label={"Lot Number"}/>
            <br/>
            <DisplayInfoRow value={data.area.toLocaleString()} label={"Lot Area"}/>
            <DisplayInfoRow value={'Php ' + data.price.toLocaleString()} label={"Unit Price (Price/SQM)"}/>
            <DisplayInfoRow value={'Php ' + (data.area * data.price).toLocaleString()} label={"Contract Price"}/>
            <DisplayInfoRow value={'Php ' + (data.area * data.price * 0.12).toLocaleString()} label={"12% EVAT"}/>
            <DisplayInfoRow
                value={'Php ' + ((data.area * data.price) + (data.area * data.price * 0.12)).toLocaleString()}
                label={"Selling Price"}/>

            <Row className="my-3">
                <Col>
                    <UploadSampleComputation propertyId={data.id} canEdit={data.can_edit}/>
                </Col>
            </Row>
        </Col>
        <Col className="m-3">


            <Status data={data}/>

            <Row className="mt-5">
                <Col className="border-bottom p-0">
                    <strong>ACTIVITIES</strong>
                </Col>
            </Row>


            {
                data.logs.sort((a, b) => {
                    if (a.datetime_added < b.datetime_added) return 1;
                    if (a.datetime_added > b.datetime_added) return -1;
                    return 0
                }).map(log => <ActivityLog log={log} key={"activity-"+log.id.toString()}/>)
            }

        </Col>
    </Row>
}