import React from "react";
import {useBrokerGroup, useBrokers} from "../../../../../commonComponents/hooks";
import {Badge, Button, Spinner} from "reactstrap";
import ProfilePicture from "../../../../../components/profilePicture";
import {useNavigate} from "react-router-dom";


export default function NestedEmployee({employee, employees}) {
    const navigate = useNavigate()
    const {data: brokers, isLoading: isLoadingBrokers} = useBrokers({params: {managed_by: employee.user_id}})

    const subEmployees = employees?.filter((subEmployee) => subEmployee.manager === employee.user_id);

    return <li key={employee.id} className="mb-3 border p-2 px-3">
        <Button color={'link'} onClick={() => {
            navigate("edit/" + employee.id)
        }}>
            <ProfilePicture url={employee.profile.profile_photo_url} width={30} height={30}
                            className="me-2 border-light"/>
            <code className="me-2">{employee.active_group_employee_id}</code>
            {employee.profile.name} <sup><Badge color="primary" className="rounded">{employee.access_display[0]}</Badge></sup>
        </Button>
        {isLoadingBrokers && <Spinner size="sm" className="ms-3"/>}

        {
            (subEmployees && subEmployees.length > 0) && <ul className="mt-3" style={{listStyleType: 'none'}}>
                {
                    subEmployees.map((subEmployee) => {
                        return <NestedEmployee key={subEmployee.id} employee={subEmployee} employees={employees}/>
                    })
                }
            </ul>
        }

        {brokers?.results.length > 0 && <ul>
            {
                brokers?.results.map((broker) => {
                    return <li key={broker.id}>
                        {broker.name} <sup><Badge color="secondary" className="rounded">BROKER GROUP</Badge></sup>
                    </li>
                })
            }
        </ul>
        }
    </li>
}