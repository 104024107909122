import React from "react";
import {useLoggedInApi} from "../../../api";
import {useQuery} from "react-query";
import {
    faBullhorn,
    faChartSimple,
    faCircleQuestion,
    faHome,
    faNoteSticky,
    faUserPlus,
    faUsers,
    faUsersGear,
    faUserTag
} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";
import FilterProvider from "../../../commonComponents/filterProvider";

export default function BrokerManagement() {
    // const api = useLoggedInApi();
    // const {data: hasRegistrationPermission, isLoading} = useQuery(
    //     'registration-permission',
    //     () => api.resource('registration').getItem('permission_check').then(response => response.data.status),
    //     {keepPreviousData: true}
    // );

    const sidebarItems = [
        {icon: faHome, text: "BROKER MANAGEMENT", to: '/broker-management/'},
        {icon: faUsersGear, text: "MANAGE BROKERS", to: 'brokers'},
        {icon: faUsers, text: "MANAGE SALESPEOPLE", to: 'salesperson'},
        {icon: faUserTag, text: "MANAGE SALES LEADS", to: 'leads'},
        {icon: faNoteSticky, text: "MANAGE SITE ANNOUNCEMENTS", to: 'announcements'},
        {icon: faBullhorn, text: "BROADCAST TO BROKERS", to: 'broadcast'},
        {icon: faChartSimple, text: "SUMMARY REPORTS", to: 'reports'},
        {icon: faCircleQuestion, text: "NEED HELP?", to: 'help'}
    ];

    // if (!isLoading && hasRegistrationPermission) {
    //     sidebarItems.splice(4, 0, {icon: faUserPlus, text: "NEW REGISTRATIONS", to: 'registration'});
    // }

    const subItems = ['broker', 'salesperson', 'leads'];
    const pathsWithNoSidebar = ['/broker-management/leads/edit/', '/broker-management/leads/import'];

    return <FilterProvider>
        <ModuleFactory sidebarItems={sidebarItems} subItems={subItems} pathsWithNoSidebar={pathsWithNoSidebar} />
    </FilterProvider>;
}