import React, {useState} from "react";
import readXlsxFile from "read-excel-file";
import xlsxParser from "xls-parser";
import {Button, FormFeedback, Input, InputGroup, Label} from "reactstrap";
import {toast} from "react-toastify";


const processFile = async (file) => {
    if (file) {
        const extension = file.name.toString().split('.').pop();
        if (extension === 'xlsx') {
            return readXlsxFile(file).then((rows) => {
                const labels = rows.shift()
                const fileData = rows.map(row => row.reduce((obj, val, idx) => {
                    obj[labels[idx]] = val
                    return obj
                }, {}))
                return fileData
            })
        } else if (extension === 'xls') {
            return xlsxParser.onFileSelection(file).then(data => {
                const firstSheetName = Object.keys(data)[0];
                return data[firstSheetName]
            })
        } else {
            console.error("extension not supported", extension);
            return null;
        }
    } else {
        console.error("No file");
        return null;
    }
}

export default function UploadReader({setProcessedData, setFileName, setIsProcessing}) {
    const [file, setFile] = useState(null)
    const [isProcessingFile, setIsProcessingFile] = useState(false)

    const process = () => {
        setIsProcessingFile(true)
        setIsProcessing(true)
        processFile(file).then(fileData => {
            setProcessedData(fileData)
            setFileName(file.name)
        }).catch(e => {
            toast.error("Error processing file")
        }).finally(() => {
            setIsProcessingFile(false)
            setIsProcessing(false)
        })
    }

    const extension = file?.name.toString().split('.').pop() || '';
    const isValidExtension = ['xlsx', 'xls'].includes(extension)

    return <>
        <Label>Select File</Label>
        <InputGroup>
            <Input
                type="file"
                disabled={isProcessingFile}
                onChange={e => setFile(e.target.files[0])}
                invalid={!!file && !isValidExtension}
                accept={".xlsx,.xls"}
            />

            <Button onClick={process} disabled={!file || !isValidExtension || isProcessingFile}>
                Process File
            </Button>
            <FormFeedback>
                {
                    !isValidExtension && "Invalid file type. Please upload an Excel file."
                }
            </FormFeedback>
        </InputGroup>

    </>
}