import {Badge} from "reactstrap";

const __version__ = '1.0.0'

const today = new Date()
export const SESSION_OPTIONS = {
    domain: window.location.hostname,
    expires: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
}
export const MICROSITE_URL = "https://sales.activegroup.com.ph/";

export const INQUIRY_TYPE_DISPLAY = {
    general: "More Information",
    site_visit: "Site Visit",
    sample_computation: "Sample Computation"
}

export const LEAD_STATUS_BADGE = {
    new: <Badge color="red">NEW</Badge>,
    contacted: <Badge color="orange">CONTACTED</Badge>,
    proposal: <Badge color="blue">PROPOSAL</Badge>,
    site_visit: <Badge color="purple">SITE VISIT</Badge>,
    closed_sold: <Badge color="green">CLOSED-SOLD</Badge>,
    inactive: <Badge color="gray">INACTIVE</Badge>,
    paid_up_sale: <Badge color="green">PAID-UP SALE</Badge>,
    option_sale: <Badge color="green">OPTION SALE</Badge>,
}

export const LEAD_STATUS_DISPLAY = {
    new: <span className="text-red">NEW</span>,
    contacted: <span className="text-orange">CONTACTED</span>,
    proposal: <span className="text-blue">PROPOSAL</span>,
    site_visit: <span className="text-purple">SITE VISIT</span>,
    closed_sold: <span className="text-green">CLOSED-SOLD</span>,
    inactive: <span className="text-gray">INACTIVE</span>,
    paid_up_sale: <span className="text-green">PAID-UP SALE</span>,
    option_sale: <span className="text-green">OPTION SALE</span>
}

export const LEAD_STATUS_SITE_VISIT_OPTION = {value: 'site_visit', label: 'SITE VISIT'};

export const LEAD_STATUS_OPTIONS = [
    {value: 'contacted', label: 'CONTACTED', allowReminder: true},
    {value: 'proposal', label: 'PROPOSAL', allowReminder: true},
    LEAD_STATUS_SITE_VISIT_OPTION,
    {
        value: 'closed_sold', disabled:true, label: 'CLOSED-SOLD', explanation: <>
            * For <strong>Closed-Sold</strong> Status updates,
            you will be required to select the Property Listing and
            an email will be sent to admin for verification.
        </>
    },
    {value: 'inactive', label: 'INACTIVE'}
]


export const REACT_SELECT_STYLES = {
    control: styles => ({
        ...styles,
        borderRadius: "0",
        borderColor: "rgb(238, 238, 238)",
        color: '#666'
    }),
    singleValue: styles => ({
        ...styles,
        color: "#666",
        fontSize: "0.8em"
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: "0.8em",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }),
    option: styles => ({
        ...styles,
        fontSize: "0.8em"
    }),
    menu: styles => ({
        ...styles,
        zIndex: 10000
    })
}

export const REF_DIRECT = 'direct'
export const REF_SMS = 'sms'
export const REF_FACEBOOK = 'facebook'
export const REF_INSTAGRAM = 'instagram'
export const REF_EMAIL = 'email'
export const REF_TWITTER = 'twitter'
export const REF_YOUTUBE = 'youtube'
export const REF_TIKTOK = 'tiktok'

export const COLOR_REF = {
    [REF_DIRECT]: "#002463",
    [REF_SMS]: "#8300E9",
    [REF_FACEBOOK]: "#0C71C3",
    [REF_INSTAGRAM]: "#ed1eca",
    [REF_EMAIL]: "#666",
    [REF_TWITTER]: "#007FE0",
    [REF_YOUTUBE]: "#E02B20",
    [REF_TIKTOK]: "#E09900"
}

export const REF_DISPLAY = {
    [REF_DIRECT]: "Direct",
    [REF_SMS]: "SMS",
    [REF_FACEBOOK]: "Facebook",
    [REF_INSTAGRAM]: "Instagram",
    [REF_DIRECT]: "Email",
    [REF_TWITTER]: "Twitter",
    [REF_YOUTUBE]: "YouTube",
    [REF_TIKTOK]: "TikTok"
}

export const PRIVACY_POLICY_ID = 'crm'