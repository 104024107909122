import React from "react";
import {Badge, Button} from "reactstrap";

import {useFindDuplicateLeads} from "../../../hooks";


export default function CheckDuplicateButton({params, children, setParameters}) {
    const {data: leadsResponse, isLoading: isLoadingLeads} = useFindDuplicateLeads(params)

    const totalLeads = leadsResponse?.count || 0

    if (!isLoadingLeads && totalLeads <= 1) {
        return <small><i>No duplicates found.</i></small>
    }

    return <Button size="sm" color="link" onClick={() => {
        setParameters(params)
    }} disabled={!isLoadingLeads && totalLeads <= 1}>
        {children}
        {
            !isLoadingLeads ? <sup className="ms-2">
                <Badge color="danger" className="rounded" title={"Number of leads found"}>{totalLeads}</Badge>
            </sup>: null
        }
    </Button>

}