import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import InputRow from "../../../../../components/inputRow";
import {useMutation, useQueryClient} from "react-query";
import PropertyGroupSelector from "../../../../../commonComponents/propertyGroupSelector";
import {useLoggedInApi} from "../../../../../api";
import {dictChangedValues} from "../../../../../commonComponents/util";
import {toast} from "react-toastify";


const DEFAULT_DATA = {
    area: 0,
    price: 0
}

export default function Edit({propertyId, originalData, setIsEdit, onSave, defaultData}) {
    const [data, setData] = useState({
        ...DEFAULT_DATA,
        ...defaultData,
        ...originalData,
    })

    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const saveMutation = useMutation(saveData => {
        const propertyResource = api.resource('properties')
        if (propertyId) {
            const promise = propertyResource.patchItem(propertyId, dictChangedValues(originalData, saveData))
                .then(response => response.data)
            toast.promise(promise, {
                pending: "Updating property...",
                error: "Something went wrong. Cannot update property.",
                success: "Property updated."
            })
            return promise;
        } else {
            const promise = propertyResource.create(saveData).then(response => response.data)
            toast.promise(promise, {
                pending: "Creating property...",
                error: "Something went wrong. Cannot create property.",
                success: "Property created."
            })
            return promise;
        }
    }, {
        onSuccess: responseData => {
            queryClient.setQueryData(['property', propertyId], responseData)
            queryClient.invalidateQueries(['table', 'properties'])
            onSave(responseData)
        }
    })

    const updateData = (key, value) => {
        setData({...data, [key]: value})
    }

    const canSave = !!data.unique_property_id && !!data.group && !!data.block && !!data.phase && !!data.lot;
    const isSaving = saveMutation.isLoading;

    const save = () => {
        saveMutation.mutate(data)
    }

    const saveBtn = <Button color="primary" disabled={!canSave || isSaving} onClick={save}>
        {
            !propertyId ?
                (isSaving ? "CREATING..." : "CREATE PROPERTY LISTING") :
                (isSaving ? "UPDATING..." : "UPDATE PROPERTY LISTING")
        }
    </Button>

    return <Row>
        <Col>
            <Row>
                <Col>
                    <h2>{!!propertyId ? "Edit Property Details" : "Create Property Listing"}</h2>
                </Col>
            </Row>
            {
                !!propertyId && <Row>
                    <Col>
                        <Button color="link" size="sm" onClick={() => setIsEdit(false)}>
                            <small>Cancel Editing</small>
                        </Button>

                    </Col>
                </Row>
            }

            <InputRow
                label={"Property Label"}
                value={data.label}
                onChange={newValue => updateData('label', newValue)}
            />

            <InputRow
                label={"Property ID"}
                value={data.unique_property_id}
                onChange={newValue => updateData('unique_property_id', newValue)}
            />

            <InputRow
                label={"Property Group"}
                renderer={(renderProps) => {
                    return <PropertyGroupSelector
                        {...renderProps}
                        isMulti={false}
                        setPropertyGroups={newValue => updateData('group', newValue)}
                        isSetSaving={false}
                        propertyGroups={data.group}
                    />
                }}
                />


            <br/>
            <InputRow
                label={"Phase"}
                value={data.phase}
                onChange={newValue => updateData('phase', newValue)}
            />
            <InputRow
                label={"Block"}
                value={data.block}
                onChange={newValue => updateData('block', newValue)}
            />

            <InputRow
                label={"Lot Number"}
                value={data.lot}
                onChange={newValue => updateData('lot', newValue)}
            />
            <br/>

            <InputRow
                label={"Lot Area"}
                value={data.area}
                type={"number"}
                onChange={newValue => updateData('area', newValue)}
            />
            <InputRow
                label={"Unit Price (Price/SQM)"}
                value={data.price}
                type={"number"}
                onChange={newValue => updateData('price', newValue)}
            />

            <InputRow
                label={"Lot Description"}
                value={data.description}
                onChange={newValue => updateData('description', newValue)}
                type={"textarea"}
            />
            <Row className="my-5">
                <Col className="justify-content-end d-flex">
                    {saveBtn}
                </Col>
            </Row>

        </Col>
    </Row>
}