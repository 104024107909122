import React from "react";
import {useTagGroup} from "../../../../../commonComponents/fetchers/tags";
import TagDisplay from "../../../../../commonComponents/tagDisplay";


export default function DefaultTagSelector({tagGroupId, selectedTag, setSelectedTag}) {
    const {data: tagGroup, isLoading} = useTagGroup({
        id: tagGroupId, useQueryOptions: {
            enabled: !!tagGroupId
        }
    })

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (!tagGroup) {
        return <div>Tag group not found</div>
    }
    const {tags} = tagGroup
    const selectableTags = tags.filter(tag => {
        return tag.is_active && !tag.accept_datetime && !tag.accept_text && !tag.multiple_source
    })

    if (selectableTags.length === 0) {
        return <div>No selectable tags available. Only tags without additional input can be selected.</div>
    }

    return <>
        {
            selectableTags.map(tag => {
                const isEnabled = tagGroup.is_sequence ? tag.order === Math.min(...tags.map(tag => tag.order)) : true
                return <TagDisplay
                    description={tag.description}
                    textColor={selectedTag === tag.id ? tag.text_color : null}
                    color={selectedTag === tag.id ? tag.color : 'light-gray'}
                    onClick={isEnabled ? () => {
                        setSelectedTag(tag.id)
                    } : undefined}
                >
                    {
                        isEnabled ? tag.name : <s>{tag.name}</s>
                    }

                </TagDisplay>
            })
        }
    </>
}