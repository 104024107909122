import React, {useState} from "react";
import "../../../commonComponents/assets/css/dashboard.css"
import {faHouseLaptop, faMessage, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";

const sidebarItems  = [
    {icon: faHouseLaptop, text: "ADMIN DASHBOARD HELP LIST", to: '/help-management/'},
    {icon: faPeopleGroup, text: "SALES DASHBOARD HELP LIST", to: 'sales_dashboard'},
    {icon: faMessage, text: "ADDITIONAL HELP INQUIRIES", to: 'inquiry'}
];

export default function HelpManagement() {
    return <ModuleFactory
        sidebarItems={sidebarItems}
        subItems={['help']}
        />
}