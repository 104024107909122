import React from "react";
import {useQuery} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import {Col, Row, Spinner} from "reactstrap";


export default function Statistics({groupId}) {
    const api = useLoggedInApi()

    const query = useQuery(['group-statistics', groupId.toString()], () => {
        const resource = api.resource('properties/groups')
        return resource.getItemAction(groupId, 'stats').then(response => {
            return response.data
        })
    })

    if (query.isLoading) {
        return <>
            <Row>
                <Col><strong>Property Group Summary</strong></Col>
            </Row>

            <Row className="m-3">
                <Col className="text-center">
                    <Spinner/>
                </Col>
            </Row>
        </>
    }

    return <>
        <Row>
            <Col><strong>Property Group Summary</strong></Col>
        </Row>

        <Row className="my-3">
            <Col className="border border-light py-2 px-3">
                Property Listings
            </Col>
            <Col className="border border-light py-2 px-3">
                <strong>{query.data.listing_count.toLocaleString()}</strong>
            </Col>
        </Row>
        <Row className="my-3">
            <Col className="border border-light py-2 px-3">
                Showing on <strong>{query.data.sites_visible_count.toLocaleString()}</strong> Broker Microsites
            </Col>
        </Row>
        <Row className="my-3">
            <Col className="border border-light py-2 px-3">
                <strong>{query.data.impressions.toLocaleString()}</strong> Total Impressions from Broker Microsites
            </Col>
        </Row>
    </>
}