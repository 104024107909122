import React from "react";
import {Col, Row} from "reactstrap";


export default function FormFieldDisplay({formField}) {
    const {answer, name} = formField
    return <>
        <Row className="mt-3 small">
            <Col className="text-muted">
                <small>
                    <strong>{name}</strong>
                </small>
            </Col>
        </Row>
        <Row className="small">
            {
                !!answer?.answer ? <Col className="text-muted">
                    {answer?.answer || '-'}
                </Col> : <Col className="text-muted lineheight-1">
                    <small>No value</small>
                </Col>
            }

        </Row>
    </>
}