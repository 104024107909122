import React, {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLoggedInApi} from "../../../../../api";
import {useMutation, useQueryClient} from "react-query";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import LeadForm from "./leadForm";
import {dictChangedValues, dictEqualShallow} from "../../../../util";
import {toast} from "react-toastify";
import {detectTagFormChanges} from "../../util";
import SalesPersonSelector from "./salesPersonSelector";
import {useLead, useLeadForm, useTagGroups} from "../../../../hooks";
import {ProfileContext} from "../../../../contexts";


export const DEFAULT_LEAD_DATA = {

    first_name: "",
    last_name: "",
    email_address: "",
    contact_number: "",
    form_answers: [],
    form_tags: [],
    property_groups: []
}

export default function EditModal(
    {
        isOpen, toggle, leadId,
        defaultData = DEFAULT_LEAD_DATA,
        saveToApi = true,
        partialUpdateFn = null,
        fullUpdateFn = null
    }
) {
    const [unlockPermanentTags, setUnlockPermanentTags] = React.useState(false)
    const {profile} = useContext(ProfileContext)
    const isSellerProfile = profile.hasOwnProperty('is_broker')


    const {leadId: leadIdFromParams} = useParams()
    const effectiveLeadId = leadId || leadIdFromParams
    const isNew = !effectiveLeadId;

    const {data: formData, isLoading: isFormLoading} = useLeadForm()
    const {data, isLoading} = useLead(effectiveLeadId,  {params: {show_disabled: true}}, {enabled: !isNew})
    const {data: tagGroupsData, isLoading: isLoadingTagGroups} = useTagGroups({})

    const [localData, setLocalData] = React.useState({
        ...JSON.parse(JSON.stringify(defaultData))
    })

    const [remarks, setRemarks] = React.useState("")

    const queryClient = useQueryClient()
    const queryKey = ['lead', effectiveLeadId]

    const api = useLoggedInApi()

    const tagsById = tagGroupsData?.reduce((acc, tagGroup) => {
        tagGroup.tags.forEach(tag => {
            acc[tag.id] = tag
        })
        return acc
    }, [])


    useEffect(() => {
        if (data && !dictEqualShallow(localData, data)) {
            setLocalData(JSON.parse(JSON.stringify(data)))
        }

        if (isNew && formData) {
            const formFieldsWithDefaults = formData.form_fields.filter(formField => formField.default_value);

            const defaultData = {};
            const defaultFormAnswers = (localData.form_answers && localData.form_answers.length > 0) ? localData.form_answers : [];
            const defaultFormTags = (localData.form_tags && localData.form_tags.length > 0) ? localData.form_tags :[];

            formFieldsWithDefaults.forEach(formField => {
                const fieldName = formField.name.toLowerCase().split(" ").join("_");
                const tagId = parseInt(formField.default_value);

                if (fieldName in DEFAULT_LEAD_DATA) {
                    defaultData[fieldName] = formField.default_value;
                } else if (formField.field_type !== "tag") {
                    // check if any answer with the same form field is already in defaultFormAnswers
                    if (!defaultFormAnswers.find(answer => answer.form_field === formField.id)) {
                        defaultFormAnswers.push({
                            form_field: formField.id,
                            answer: formField.default_value
                        });
                    }
                } else {
                    console.log("TagId", tagId,formField.default_value, tagsById)
                    const tagData = tagsById[tagId];
                    if (!tagData) {
                        return;
                    }
                    // check if any tag with the same tag group is already in defaultFormTags
                    console.log("defaultFormTags", defaultFormTags)
                    if (!defaultFormTags.find(tagForm => tagsById[tagForm.tag].tag_group === tagData.tag_group)) {
                        defaultFormTags.push({
                            id: Date.now(),
                            __isNew: true,
                            tag: tagId,
                            tag_data: tagData
                        });
                    }
                }
            });

            setLocalData(prevState => ({
                ...prevState,
                ...defaultData,
                form_answers: [...defaultFormAnswers],
                form_tags: [...defaultFormTags]
            }));
        }
    }, [data, isNew, formData])


    const changedData = React.useMemo(() => {
        if (!localData) {
            return {}
        }
        const tmp = data ? dictChangedValues(data, localData) : {...localData}
        if (isNew) {
            // cleanup form tags
            tmp.form_tags = tmp.form_tags.map(tagForm => {
                const tmp = {
                    ...tagForm
                }
                if (tmp.__isNew) {
                    delete tmp.id
                    delete tmp.__isNew
                }
                return tmp
            }).filter(tagForm => {
                return !tagForm._delete
            })
            return tmp;
        }
        delete tmp.form_answers
        delete tmp.form_tags

        // compare form answers
        const localAnswerById = (localData?.form_answers) ?
            localData.form_answers.filter(row => !!row.id).reduce((acc, row) => {
                acc[row.id] = row
                return acc
            }, {}) : {};
        const dataAnswerById = (data?.form_answers) ? data.form_answers.reduce((acc, row) => {
            acc[row.id] = row
            return acc
        }, {}) : {};

        const newAnswers = (localData?.form_answers) ? localData.form_answers.filter(row => !row.id) : [];

        const changedAnswers = Object.keys(localAnswerById).filter(key => {
            const dataAnswer = dataAnswerById[key]
            if (!dataAnswer) {
                return true
            }
            return !dictEqualShallow(dataAnswer, localAnswerById[key])
        }).map(key => {
            return localAnswerById[key]
        })
        if (changedAnswers.length > 0 || newAnswers.length > 0) {
            tmp.form_answers = [
                ...changedAnswers,
                ...newAnswers
            ]
        }

        // compare form tags
        const changedTagForms = detectTagFormChanges(
            localData ? JSON.parse(JSON.stringify(localData.form_tags)) : [],
            data ? JSON.parse(JSON.stringify(data.form_tags)) : []
        )
        if (changedTagForms.length > 0) {
            tmp.form_tags = changedTagForms
        }
        return tmp
    }, [localData, data, isNew])


    const saveMutation = useMutation((changedData) => {
        const leadResource = api.resource('leads')
        if (isNew) {
            const createPromise = leadResource.create(changedData).then(response => response.data)
            toast.promise(createPromise, {
                pending: "Creating...",
                success: "Created!",
                error: "Error while creating"
            })
            return createPromise
        } else {
            const patchPromise = leadResource.patchItem(effectiveLeadId, {
                ...changedData,
                remarks: remarks
            }).then(response => response.data)
            toast.promise(patchPromise, {
                pending: "Saving...",
                success: "Saved!",
                error: "Error while saving"
            })
            return patchPromise


        }
    }, {
        onSuccess: (data) => {
            setLocalData(data)
            queryClient.invalidateQueries(['table', 'leads'])
            queryClient.invalidateQueries(queryKey).then(() => {
                toggle()
            })
        }
    })

    const isRequiredComplete = React.useMemo(() => {
        if (!formData) {
            return true
        }

        const {form_fields} = formData;
        const {form_answers, form_tags, sales_profile} = localData || {};

        const requiredFields = form_fields.filter(field => field.is_required);
        const isFieldFilledUp = field => {
            const fieldName = field.name.toLowerCase().split(" ").join("_");
            const isDataField = fieldName in DEFAULT_LEAD_DATA;
            const isTagField = field.field_type === "tag";

            if (isDataField) {
                return !!localData[fieldName];
            } else if (isTagField) {
                const defaultTagData = tagsById[field.default_value];
                return !!form_tags?.find(tag => tag.tag_data.tag_group === field.tag_group) || !defaultTagData;
            } else {
                return !!form_answers?.find(answer => answer.form_field === field.id)?.answer;
            }

        }
        const isComplete = requiredFields.every(isFieldFilledUp);

        const result = isComplete && (!!sales_profile || isSellerProfile);
        if (!result) {
            console.error(
                "Missing fields", requiredFields.filter(field => !isFieldFilledUp(field)).map(field => field.name),
                " or no Sales Profile:", !!sales_profile, " and not a broker or sales agent "
            )
        }
        return result
    }, [formData, localData, isSellerProfile, tagsById])


    const hasUnlockableTags = localData.form_tags.some(tagFormData => {
        const isUsedInFields = formData.form_fields.some(field => {
            return field.field_type === "tag" && field.tag_group === tagFormData.tag_data.tag_group && field.is_active
        })
        return tagFormData.can_unlock && tagFormData.tag_data.is_permanent && isUsedInFields
    })

    return <Modal isOpen={isOpen} toggle={toggle} size="lg" centered scrollable>
        <ModalHeader toggle={toggle} className="activegroup-header">
        </ModalHeader>
        <ModalBody>
            <Row>
            <Col>
                <h3>{isNew ? "Create Lead" : "Edit Information"}</h3>
            </Col>
            {
                hasUnlockableTags && <Col className="col-auto">
                    <FormGroup switch>
                        <Input
                            checked={unlockPermanentTags}
                            onClick={() => {
                                setUnlockPermanentTags(!unlockPermanentTags)
                            }}
                            type="switch" role="switch"
                        />
                        <Label check>Unlock Permanent Tags</Label>
                    </FormGroup>
                </Col>
            }
            </Row>
            {
                (isNew && !isSellerProfile) && <SalesPersonSelector
                    selected={localData.sales_profile}
                    setSelected={(salesPerson) => {
                        setLocalData(prevState => {
                            return {
                                ...prevState,
                                sales_profile: salesPerson
                            }
                        })
                    }}
                />
            }
            <LeadForm
                unlockPermanentTags={unlockPermanentTags}
                data={localData} setData={(newData) => {
                    setLocalData(newData)
                }}
            />

            {
                !isNew && <Row className="pt-4 mt-4 border-top">
                    <Col>
                        <Label className="text-muted small lineheight-1">Remarks</Label>
                        <Input
                            type="textarea"
                            placeholder={"Enter reason for this update"}
                            value={remarks}
                            onChange={(e) => {
                                setRemarks(e.target.value)
                            }}
                        />
                    </Col>
                </Row>
            }

        </ModalBody>
        <ModalFooter>
            {
                changedData && Object.keys(changedData).length > 0 && !remarks && !isNew &&
                <div className="text-danger">
                    Please enter remarks
                </div>
            }
            {
                !isRequiredComplete && <div className="text-danger">
                    Please complete required fields
                </div>
            }
            <Button
                disabled={!isRequiredComplete || !changedData || Object.keys(changedData).length === 0 || (!remarks && !isNew) || saveMutation.isLoading}
                color="primary"
                onClick={() => {
                    if (saveToApi) {
                        saveMutation.mutate(changedData)
                    }
                    if (partialUpdateFn) {
                        partialUpdateFn(changedData)
                    }
                    if (fullUpdateFn) {
                        fullUpdateFn(localData)
                    }

                }}>
                {isNew ? "CREATE LEAD" : "SAVE UPDATE"}
            </Button>
        </ModalFooter>
    </Modal>
}