import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import AssetDisplay from "./assetDisplay";

export default function Asset({data, markDelete}) {
    const [showDelete, setShowDelete] = useState(false)

    const willDelete = !!data.willDelete;
    const setWillDelete = markDelete

    return <div
        className="p-2 text-center text-white" style={{position: "relative"}}
        onMouseOver={() => {
            setShowDelete(true)
        }} onMouseOut={() => {
        setShowDelete(false)
    }}
    >
        <AssetDisplay url={data.file} localFile={data.localFile}/>
        <span style={{position: "absolute", textShadow: "1px 2px 2px grey", right: "10%"}}>{data.status}</span>
        <div style={{
            position: "absolute",
            width: "100%", height: "100%",
            display: (showDelete || willDelete) ? "block" : "none",
            backgroundColor: willDelete ? "rgba(255,0,0,0.5)" : "rgba(255,255,255,0.5)",
            top: '0', left: '0'
        }}>
            <span
                onClick={() => {
                    setWillDelete(!willDelete)
                }}
                style={{
                    position: "absolute",
                    textShadow: "1px 2px 2px black",
                    left: "0",
                    top: "calc(50% - 8px)",
                    width: "100%",
                    cursor: "pointer"
                }}
            >
                {
                    willDelete ?
                        <></> :
                        <FontAwesomeIcon icon={faTrash} size={"lg"} className="text-danger"/>
                }
            </span>
        </div>
    </div>
}