import React, {useMemo} from "react";
import * as PropTypes from "prop-types";
import Item from "./item";
import {Col, Row} from "reactstrap";


function ChartProgressBar(props) {
    const max = useMemo(() => {
        if (props.max) return props.max
        const detectedMax = Math.max(...props.data.map(row => row.value))
        if (detectedMax) {
            return detectedMax
        } else {
            return 1
        }
    }, [props.max, props.data])
    if (!props.data || props.data.length === 0) {
        return <>
            <Row>
                <Col className="text-center">
                    <i>No Data</i>
                </Col>
            </Row>
        </>
    }
    return <>
        {
            props.data.map(row => {
                return <Item max={max} getDisplay={props.getDisplay} {...row}/>
            })
        }
    </>
}

ChartProgressBar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string
        ]),
        value: PropTypes.number,
        color: PropTypes.string
    })),
    max: PropTypes.number,
    getDisplay: PropTypes.func
}

export default ChartProgressBar