import React, {useState} from "react";
import {Button, ButtonGroup, Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../../api";
import {toast} from "react-toastify";
import {processResponseAsDownload} from "../../../../../commonComponents/modules/leads/util";


export default function UploadSampleComputation({propertyId, canEdit}) {
    const [localFile, setLocalFile] = useState()
    const [isOpen, setIsOpen] = useState(false)

    const [refetchCount, setRefetchCount] = useState(0)

    const [isDownloading, setIsDownloading] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
        setLocalFile(null)
    };

    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const query = useQuery(['property', 'sample-computation', propertyId, refetchCount], () => {
        const resource = api.resource('properties')
        return resource.getItemAction(propertyId, 'sample_computation')
            .then(response => response.data)
            .then(data => {
                if (data.is_loading_template) {
                    window.setTimeout(() => {
                        setRefetchCount(prev => prev + 1)
                    }, 10 * 1000)
                }
                return data
            })
    }, {
    });


    const uploadMutation = useMutation(data => {
        const resource = api.resource('properties')

        const formData = new FormData()
        formData.append('upload', data.upload)
        const promise = resource.patchItemAction(propertyId.toString(), 'sample_computation', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data
        })

        toast.promise(promise, {
            pending: "Uploading new template...",
            error: "Something went wrong. Cannot upload template.",
            success: "Template file had been updated."
        })
        return promise
    }, {
        onSuccess: data => {
            toggle()
            queryClient.setQueryData(['property', 'sample-computation', propertyId], data)
        }
    })

    const isSaving = uploadMutation.isLoading
    const canSave = !localFile;
    const save = () => {
        uploadMutation.mutate({upload: localFile})
    }

    const download = () => {
        setIsDownloading(true)
        const leadResource = api.resource('properties')
        const downloadPromise = leadResource.getItemAction(propertyId, 'generate_sample_computation', {
            responseType: "blob"
        }).then(processResponseAsDownload).finally(() => {
            setIsDownloading(false)
        })

        toast.promise(downloadPromise, {
            pending: 'Generating sample computation...',
            error: 'Error generating sample computation',
        })
    }

    if (query.isLoading) {
        return <>
            <Spinner size="sm"/> Loading sample computation template...
            <br/>
        </>
    }


    if (query.data?.is_loading_template) {
        return <>
            <Spinner size="sm"/> Computation template is still being applied. Please wait...
        </>
    }

    const modal = <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        scrollable={true}
    >
        <ModalHeader toggle={toggle} className="activegroup-header">

        </ModalHeader>
        <ModalBody className="px-5 py-4">
            <Row>
                <Col>
                    <h4>UPLOAD SAMPLE COMPUTATION TEMPLATE</h4>
                </Col>
            </Row>
            <Row className="m-5">
                <Col className="text-center">
                    <Input
                        type="file"
                        accept="application/pdf"
                        onChange={e => setLocalFile(e.target.files[0])}
                    />
                </Col>
            </Row>

            <Row className="m-5">
                <Col className="justify-content-end d-flex">
                    <Button color="primary" disabled={canSave} onClick={save}>
                        {
                            isSaving ? "Uploading..." : "UPLOAD PDF TEMPLATE"
                        }
                    </Button>
                </Col>
            </Row>
        </ModalBody>
    </Modal>

    return <>
        {
            query.isSuccess && query.data.sample_computation_template ?
                <>
                    Existing template found. <br/>
                    <ButtonGroup>
                        <Button color="info-outline" onClick={download} disabled={isDownloading}>
                            <FontAwesomeIcon icon={faFileDownload}/>{" "}
                            DOWNLOAD GENERATED SAMPLE COMPUTATION
                        </Button>
                        {
                            canEdit && <Button color="warning" onClick={() => setIsOpen(true)}>
                                <FontAwesomeIcon icon={faFileUpload}/>{" "}
                                CHANGE TEMPLATE
                            </Button>
                        }
                    </ButtonGroup>

                </> :
                <>
                    No uploaded sample computation template yet
                    <br/>
                    {
                        canEdit && <Button color="info-outline" className="mb-5" onClick={() => setIsOpen(true)}>
                            <FontAwesomeIcon icon={faFileUpload}/>{" "}
                            UPLOAD SAMPLE COMPUTATION TEMPLATE
                        </Button>
                    }
                </>
        }


        { modal }
    </>
}