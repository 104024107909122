import React from "react";
import blankProfilePicture from "../commonComponents/assets/images/blank-profile-picture.png";


export default function ProfilePicture(props) {
    const displayImage = props.url ? props.url : blankProfilePicture

    return <img
        {...props}
        src={displayImage}
        className={(props.className ? props.className : "") + " bg-white img-circle"}/>
}