import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ToggleSidebarContext} from "../contexts";


export default function SidebarItem({icon, text, to}) {
    const {close} = useContext(ToggleSidebarContext)

    return <Row className="sidebar-nav">
        <Col className="no-wrap text-ellipsis">
            <NavLink to={to} onClick={close} className="" title={text}>
                <FontAwesomeIcon icon={icon} className="fa-2x"/>
                {text}
            </NavLink>
        </Col>
    </Row>
}