import React, {useState} from "react";
import {Alert, Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faExclamationTriangle, faPencil} from "@fortawesome/free-solid-svg-icons";
import {useMutation, useQueryClient} from "react-query";
import {toast} from "react-toastify";
import {useLoggedInApi} from "../../../../api";


export default function EditTemplateFile({onEdit, data}) {
    const api = useLoggedInApi()
    const queryClient = useQueryClient()

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }


    const [localFile, setLocalFile] = useState(null)

    const hasChanges = !!localFile;
    const isFileExtensionXlsx = hasChanges && localFile.name.endsWith('.xlsx');
    const canSave = isFileExtensionXlsx;

    const saveMutation = useMutation((mutationData) => {
        const resource = api.resource('properties/templates')

        const formData = new FormData()
        formData.append('template', mutationData.file)
        return resource.patchItem(mutationData.id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => response.data)
    }, {
        disabled: !canSave,
        onSuccess: data => {
            queryClient.setQueryData(['properties/templates', data.id], data)
            queryClient.invalidateQueries(['table', 'properties/templates'])
            toast("Template file updated.", {type: "success"})
            onEdit && onEdit(data)

            toggle()
        },
        onError: error => {
            toast("Failed to upload new template.", {type: "error"})
        }
    })

    return <>
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            centered
            scrollable={true}>
            <ModalHeader toggle={toggle} className="activegroup-header">
                Edit Template File
            </ModalHeader>
            <ModalBody className="m-3">
                <Row>
                    <Col>
                        <Alert color="info" className="small p-2 ps-3">
                            Updating the template file will not affect the existing templates that were applied previously.
                            For the changes to take effect, you will need to re-apply the template.
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <input
                            required={true}
                            className="mt-3"
                            type="file"
                            accept=".xlsx"
                            onChange={e => setLocalFile(e.target.files[0])}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            (!isFileExtensionXlsx && hasChanges) &&
                            <Alert color="danger" className="small mt-2 p-2 ps-3">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-danger"/>{' '}
                                Only XLSX files are allowed.
                            </Alert>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col className={"text-end"}>
                        <Button
                            disabled={!hasChanges || !canSave} color={"primary"}
                            onClick={() => saveMutation.mutate({id: data.id, file: localFile})}>
                            UPLOAD TEMPLATE
                        </Button>
                    </Col>
                </Row>
                <Row className="small">
                    <Col>
                        <p>
                            File Guidelines
                            <ul>
                                <li>
                                    Only XLSX files are allowed.
                                </li>
                                <li>
                                    You can use the following placeholders in the template but are not required:
                                    <ul>
                                        <li><code>[PHASE]</code></li>
                                        <li><code>[BLOCK]</code></li>
                                        <li><code>[LOT]</code></li>
                                        <li><code>[AREA]</code></li>
                                        <li><code>[PRICE]</code></li>
                                        <li><code>[DESCRIPTION]</code></li>
                                    </ul>
                                    If no placeholders are used, the template will be used as is.
                                </li>
                            </ul>
                        </p>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>


        <Button color="link" onClick={toggle}>
            <FontAwesomeIcon icon={faPencil}/>{" "}
            Change Template File
        </Button>
    </>
}