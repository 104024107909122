import React from "react";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import ExpandedLog from "../logs/expandedLog";
import ConfirmButton from "../../../../commonComponents/confirmButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMutation, useQueryClient} from "react-query";
import {useLoggedInApi} from "../../../../api";
import {toast} from "react-toastify";
import {Col, Row} from "reactstrap";


export default function DisabledUsers() {
    const queryClient = useQueryClient()
    const api = useLoggedInApi()
    const restoreMutation = useMutation((id) => {
        const resource = api.resource('disabled-users')
        const result = resource.postItemAction(id, 'restore', {}).then(response => response.data)

        toast.promise(result, {
            pending: 'Restoring user...',
            success: 'User restored',
            error: 'Error restoring user'
        })

        return result
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['table', 'disabled-users'])
        }
    })

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Disabled Users</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Disabled employees, brokers and sales persons. These users will not be able to login.
                You can restore their login access by clicking the "Restore" button.
            </Col>
        </Row>
        <AdvancedTable
            // hideTotalCount={true}
            // hideSearch={true}
            friendlyResourceName={"Disabled Users"}
            apiResourceName={"disabled-users"}
            // compact={true}
            customTableStyles={{
                rows: {
                    style: {
                        minHeight: '2em'
                    }
                }
            }}
            columnDefinition={[
                {
                    name: "Name",
                    selector: row => row.name, sortable: true, sortField: 'last_name,first_name',
                },
                {
                    name: "User Type",
                    selector: row => row.user_type,
                    sortable: false,
                    format: row => {
                        const userType = row.user_type
                        const groups = row.group_names?.join(', ')
                        if (groups) {
                            return `${userType} (${groups})`
                        }
                        return userType
                    }
                },
                {
                    name: "Restore",
                    selector: row => row.id,
                    sortable: false,
                    format: row => {
                        return <ConfirmButton
                            size={"sm"}
                            onClick={() => {
                                restoreMutation.mutate(row.id)
                            }}
                        >
                            Restore
                        </ConfirmButton>
                    }
                }
            ]}
            dataTableProps={{
            }}
        />
    </>
}