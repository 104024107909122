import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Fade} from "reactstrap";

export default function BackToTop() {
    const [lastScrollY, setLastScrollY] = useState(0)

    const setScroll = () => {
        setLastScrollY(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', setScroll)

        return () => {
            window.removeEventListener('scroll', setScroll)
        }
    })

    // show only if it has scrolled at least one screen height
    const show = lastScrollY > window.innerHeight / 2

    return <Fade in={show}>
        <div
            onClick={() => {
                window.scrollTo(0, 0)
            }}
            className="position-fixed end-0 p-2 px-3 bg-light-gray rounded-start" style={{bottom: "20vh"}}>
            <FontAwesomeIcon icon={faChevronUp} className="text-white"/>
        </div>
    </Fade>
}