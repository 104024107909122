export const updateProfileTagData = {
    id: 'update_profile',
    tag: 'update_profile',
    tag_data: {
        is_active: true,
        id: 'update_profile',
        tag_group: 'special',
        name: 'UPDATED PROFILE',
        color: '#b0c4de',
        is_sequence: false,
        is_unique: false,
        tags: []
    }
}
export const mergeTagData = {
    id: 'merge',
    tag: 'merge',
    tag_data: {
        is_active: true,
        id: 'merge',
        tag_group: 'special',
        name: 'MERGE',
        color: '#b0c4de',
        is_sequence: false,
        is_unique: false,
        tags: []
    }
}
export const searchTagData = {
    id: 'search',
    tag: 'search',
    tag_data: {
        is_active: true,
        id: 'search',
        tag_group: 'special',
        name: 'SEARCH REMARKS',
        color: '#b0c4de',
        is_sequence: false,
        is_unique: false,
        accept_text: true,
        tags: []
    }
}
export const specialTagGroup = {
    id: 'special',
    name: 'User Activity Filters',
    is_active: true,
    tags: [
        updateProfileTagData.tag_data,
        mergeTagData.tag_data,
        // searchTagData.tag_data
    ]
}