import React, {useContext, useState} from "react";
import {Alert, Button, Col, Row, Spinner} from "reactstrap";
import {ProfileContext} from "../../commonComponents/contexts";
import InputRow from "../inputRow";
import UpdatePasswordForm from "../updatePasswordForm";
import ProfilePhotoEdit from "../profilePhotoEdit";
import PersonalInfo from "../profileForm/personalInfo";
import ContactInfo from "../profileForm/contactInfo";
import ReactSwitch from "react-switch";
import WebsiteUrlEdit from "../websiteUrlEdit";
import BrokerInfo from "../profileForm/brokerInfo";
import MicrositeInfo from "../profileForm/micrositeInfo";
import * as PropTypes from 'prop-types'
import SalesPersonInfo from "./salesPersonInfo";
import useDebounce, {toTitleCase} from "../../commonComponents/util";
import {useQuery} from "react-query";
import {useLoggedInApi} from "../../api";
import {useFieldErrorChecking, useProfileEdit, useProfileOptions} from "../../commonComponents/hooks";
import Manager from "./manager";
import DeleteProfileForm from "./deleteProfileForm";
import IdInput from "./idInput";


function getPostCreate(isEmployee, resourceName) {
    if (isEmployee) {
        return '/users/edit/'
    }
    if (resourceName === 'broker') return '/broker-management/brokers/edit/';
    else if (resourceName === 'salesperson') return '/broker-management/salesperson/edit/'
}

function getListPage(isEmployee, resourceName) {
    if (isEmployee) {
        return '/users/'
    }
    if (resourceName === 'broker') return '/broker-management/brokers/';
    else if (resourceName === 'salesperson') return '/broker-management/salesperson/'
}


function ProfileForm(props) {
    const {refreshProfile} = useContext(ProfileContext)


    const [
        isProfileLoading,
        isProfileSaving,
        isNew,
        profileData,
        originalProfileData,
        profilePhoto,
        hasChanges,
        saveProfile,
        setProfileData,
        saveProfileResult,
        saveProfileAsync,
    ] = useProfileEdit({
        profileId: props.profileId,
        isMe: props.isMe,
        resourceName: props.resourceName,
        defaultData: props.defaultProfileData,
        navigateToOnCreate: getPostCreate(props.isEmployee, props.resourceName),
        navigateToOnFailedLoad: getListPage(props.isEmployee, props.resourceName)
    })

    const profileOptions = useProfileOptions({getEmployeePosition: props.isEmployee && !props.isMe})
    const isLoadingOptions = profileOptions.isLoading;
    const profileOptionsData = profileOptions.options;

    const [updateErrorStatus, hasError, fieldsWithErrors, clearErrors] = useFieldErrorChecking()
    const [fieldErrors, setFieldErrors] = useState({})

    const debouncedEmail = useDebounce(profileData.user.email, 500)
    const api = useLoggedInApi()
    const emailExistsQuery = useQuery(['emailExists', debouncedEmail, profileData.user.id], () => {
        const emailUsedResource = api.resource('email_used')
        return emailUsedResource.list({
            params: {
                email: debouncedEmail,
                userId: profileData.user.id
            }
        }).then(response => response.data.result)
    }, {
        onSuccess: data => {
            if (data) {  // is used
                updateErrorStatus('email already exists', true)
            } else {
                updateErrorStatus('email already exists', false)
            }
        }
    })

    if (isProfileLoading || isLoadingOptions) {
        return <Row className="my-5">
            <Col className="text-center">
                <Spinner/>
            </Col>
        </Row>
    }

    const requiredFields = [
        'profile.mobile_number',
        'user.email',
        'user.last_name',
        'user.first_name',
        'profile.gender',
    ];
    if (props.canEditID) {
        requiredFields.push(props.isEmployee ? 'active_group_employee_id' : 'active_group_seller_id')
    }

    if (props.canEditPosition) {
        requiredFields.push('profile.position')
    }
    if (props.showBrokerInfo) {
        if (profileData.is_group || profileData.is_broker) {
            requiredFields.push('group_name')
        }
    }
    if (props.showMicroSite && profileData.is_broker) {
        requiredFields.push('licenses')
    }
    if (profileData.is_broker) {
        requiredFields.push('profile.tin_number')
    }
    if (props.showSalesPersonInfo && props.canEditAffiliation) {
        requiredFields.push('group')
    }

    const missingFields = requiredFields.filter((fieldName) => {
        if (fieldName.includes('.')) {
            const [k1, k2] = fieldName.split('.')
            return !profileData[k1][k2]
        } else {
            return !profileData[fieldName]
        }
    })
    const missingFieldNames = missingFields.map(field => {
        if (field.includes('.')) {
            return field.split('.')[1]
        } else {
            return field
        }
    })

    const visibleFieldsWithErrors = fieldsWithErrors.filter(field => !missingFieldNames.includes(field))

    const isComplete = missingFields.length === 0;

    const canSave = !hasError && hasChanges && isComplete
    const saveBtn = <Button color="primary" disabled={!canSave || isProfileSaving} onClick={() => {
        saveProfileAsync().catch((error) => {
            if (error?.response?.data) {
                clearErrors()
                const newFieldErrors = Object.keys(error.response.data).map((key) => {
                    updateErrorStatus(key, true)
                    return key
                }).reduce((obj, key) => {
                    obj[key] = error.response.data[key].join('\n ');
                    return obj
                }, {})
                setFieldErrors(newFieldErrors)
            }
        })
    }}>
        {
            isNew ?
                (isProfileSaving ? "CREATING..." : "CREATE PROFILE") :
                (isProfileSaving ? "UPDATING..." : "UPDATE " + (props.isMe ? "MY" : "") + " PROFILE")
        }
    </Button>

    const getPositionOptions = () => {
        if (!props.canEditPosition) return [profileData.profile.position]
        if (props.isEmployee) {
            return profileOptionsData['employee_position']
        } else {
            if (profileData.is_broker && profileOptionsData.hasOwnProperty('broker position')) {
                return profileOptionsData['broker position']
            } else if (profileOptionsData.hasOwnProperty('salesperson position')) {
                return profileOptionsData['salesperson position']
            }
            return profileOptionsData['position'].filter(o => {
                return !profileData.is_broker ? !o.toLowerCase().includes('broker') : true
            })
        }
    }

    const positionOptions = getPositionOptions()

    return <div className="mb-5 pb-5">
        {
            hasChanges && <div className="fixed-bottom text-end pe-5 pb-0 bg-none text-shadow">
                <span className={"new-item"}>
                    You have unsaved changes.
                </span>
            </div>
        }
        {
            props.hasActiveToggle && !originalProfileData.is_active && <Row>
                <Col>
                    <Alert color="danger">Profile is not active. Website is currently not available. Activate profile to
                        fix.</Alert>
                </Col>
            </Row>
        }

        {
            !isNew && <Row className="mb-5">
                <Col>
                    <ProfilePhotoEdit
                        resourceName={props.resourceName}
                        onChange={newUrl => {
                            if (props.isMe) {
                                refreshProfile()
                            }
                        }}
                        isNew={isNew}
                        isMe={props.isMe}
                        profileId={props.profileId}
                        profilePhotoUrl={profilePhoto}
                    />
                </Col>
            </Row>

        }

        {
            props.hasActiveToggle && <>
                <Row className={"input-row" + (profileData.is_active ? "" : " text-danger")}>
                    <Col md={4} className="ps-md-4 align-items-center justify-content-center d-flex py-2">
                        <ReactSwitch
                            checked={profileData.is_active}
                            onChange={newStatus => setProfileData({...profileData, is_active: newStatus})}
                            checkedIcon={null} uncheckedIcon={null}/>
                    </Col>
                    <Col className={"p-2 d-flex"}>
                        <strong>Status {profileData.is_active ? "ACTIVE" : "INACTIVE"}</strong>&nbsp;
                        {profileData.is_active !== originalProfileData.is_active && <>(UNSAVED)</>}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            profileData.is_broker && !profileData.is_active && originalProfileData.is_active && <>
                                <Alert color="danger">All salesperson's website under this account will be deactivated as
                                    well.</Alert>
                            </>
                        }
                    </Col>
                </Row>
            </>
        }

        {
            !isNew && profileData.hasOwnProperty('url_path') && <WebsiteUrlEdit
                profileId={props.profileId}
                onChange={newUrl => setProfileData({...profileData, url_path: newUrl})}
                websiteUrl={profileData.url_path}
                originalWebsiteUrl={originalProfileData.url_path}
            />
        }


        <h5 className="mt-5">Active Group Information</h5>
        <IdInput
            canEditID={props.canEditID}
            isEmployee={props.isEmployee}
            profileData={profileData}
            setProfileData={setProfileData}
            originalProfileData={originalProfileData}
            updateErrorStatus={updateErrorStatus}
            />

        {
            (props.isEmployee) && <Manager
            isProfileSaving={isProfileSaving}
            profileData={profileData}
            setProfileData={setProfileData}
            readOnly={props.isMe}
            />
        }

        {
            props.showBrokerInfo && <BrokerInfo
                profileData={profileData}
                isProfileSaving={isProfileSaving}
                updateErrorStatus={updateErrorStatus}
                setProfileData={setProfileData}
                profileOptions={profileOptionsData}
                fieldErrors={fieldErrors}
                setFieldErrors={setFieldErrors}
                fieldsWithErrors={fieldsWithErrors}
            />
        }
        {
            props.showSalesPersonInfo && <SalesPersonInfo
                profileData={profileData}
                isProfileSaving={isProfileSaving}
                updateErrorStatus={updateErrorStatus}
                setProfileData={setProfileData}
                canEditAffiliation={props.canEditAffiliation}
            />
        }


        <InputRow
            required
            disabled={!props.canEditPosition}
            label={"Position"}
            value={profileData.profile.position}
            setHasError={hasError => updateErrorStatus('position', hasError)}
            onChange={newValue => setProfileData({
                ...profileData,
                profile: {
                    ...profileData.profile,
                    position: newValue
                }
            })}
            type={"select"}
            children={[
                <option key="no-selected"></option>,
                ...positionOptions.map(o => <option key={o} value={o}>{o}</option>),
                ...((originalProfileData?.profile?.position && !positionOptions.includes(originalProfileData.profile.position)) ? [
                    <option key={originalProfileData.profile.position} value={originalProfileData.profile.position}>
                        {originalProfileData.profile.position} (Original)
                    </option>
                ] : [])
            ]}
        />
        <PersonalInfo
            requireTin={props.requireTin}
            profileData={profileData}
            isProfileSaving={isProfileSaving}
            updateErrorStatus={updateErrorStatus}
            setProfileData={setProfileData}
            profileOptions={profileOptionsData}
        />

        <ContactInfo
            profileData={profileData}
            isProfileSaving={isProfileSaving}
            updateErrorStatus={updateErrorStatus}
            setProfileData={setProfileData}
            emailAlreadyUsed={emailExistsQuery.data}
        />

        {
            props.showMicroSite && <MicrositeInfo
                profileData={profileData}
                originalProfileData={originalProfileData}
                isProfileSaving={isProfileSaving}
                updateErrorStatus={updateErrorStatus}
                setProfileData={setProfileData}
                showPropertyGroups={props.showPropertyGroups}
            />
        }
        <Row className="mt-5">
            <Col>

                {
                    missingFields.length > 0 && <Alert color={"danger"}>
                        Missing required fields:
                        <ul>
                            {missingFields.sort().map(field => <li>{
                                toTitleCase((field.includes('.') ? field.split('.')[1] : field).split('_').join(' '))
                            }</li>)}
                        </ul>
                    </Alert>
                }
            </Col>
        </Row>
        <Row className="mt-1">
            <Col>

                {
                    visibleFieldsWithErrors.length > 0 && <Alert color={"danger"}>
                        Invalid values found on the following fields:
                        <ul>
                            {
                                visibleFieldsWithErrors.sort().map(field => {
                                    return <li>
                                        {
                                            toTitleCase((field.includes('.') ? field.split('.')[1] : field).split('_').join(' '))
                                        }
                                        {
                                            (field === 'email' && emailExistsQuery.data) ? <> - Already Used</> : null
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    </Alert>
                }
            </Col>
        </Row>
        <Row>
            <Col className="justify-content-end d-flex">
                {saveBtn}
            </Col>
        </Row>

        <hr className="mt-5 mb-5"/>

        {
            !isNew && <UpdatePasswordForm
                isMe={props.isMe}
                resourceName={
                    props.isMe ?
                        null :
                        props.resourceName + '/' + props.profileId.toString() + '/change_password/'
                }
            />
        }

        {
            !props.isMe && !isNew && <DeleteProfileForm
                profileData={profileData} resourceName={props.resourceName} listPagePath={getListPage(props.isEmployee, props.resourceName)}/>
        }

    </div>
}

ProfileForm.propTypes = {
    isMe: PropTypes.bool.isRequired,
    profileId: PropTypes.number,
    showMicroSite: PropTypes.bool.isRequired,
    showBrokerInfo: PropTypes.bool.isRequired,
    showSalesPersonInfo: PropTypes.bool.isRequired,
    canEditAffiliation: PropTypes.bool.isRequired,
    canEditPosition: PropTypes.bool.isRequired,
    canEditID: PropTypes.bool.isRequired,
    isEmployee: PropTypes.bool.isRequired,
    hasActiveToggle: PropTypes.bool.isRequired,
    resourceName: PropTypes.string.isRequired,
    defaultProfileData: PropTypes.object.isRequired,
    showPropertyGroups: PropTypes.bool,
}

export default ProfileForm