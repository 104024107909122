import React from "react";
import InfoCards from "../../components/charts/infoCards";
import {useLeadCountPerPeriod} from "../hooks";


export default function LeadsByPeriod(props) {
    const {data, isLoading} = useLeadCountPerPeriod({})

    return <InfoCards data={[
        {
            label: "TODAY'S LEADS",
            value: data?.todays_leads,
            color: "#0169a6",
            isLoading: isLoading
        },
        {
            label: "LEADS THIS WEEK",
            value: data?.leads_this_week,
            color: "#006138",
            isLoading: isLoading
        },{
            label: "LEADS THIS MONTH",
            value: data?.leads_this_month,
            color: "#967126",
            isLoading: isLoading
        }
    ]}/>
}