import React from "react";
import {Card, CardBody} from "reactstrap";
import CommonInquiry from "../../../../../commonComponents/commonInquiry";

export default function Inquiry({inquiryId, defaultResolved}) {

    return <>
        <Card>
            <CardBody>
               <CommonInquiry inquiryId={inquiryId} defaultResolved={defaultResolved} canMarkResolved={true}/>
            </CardBody>
        </Card>
    </>
}