import {useLoggedInApi} from "../api";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import {Label, Spinner} from "reactstrap";
import Select from "react-select";
import React from "react";

import {useSalesPersonOptions} from "../commonComponents/hooks";

export function TableSalesGroupFilter({isCollapsed, value, setValue, renderProps, labelText, placeholderText}) {

    const api = useLoggedInApi()
    const brokersResult = useQuery(['broker-groups'], () => {
        const salesPeopleResource = api.resource('salesperson')
        return salesPeopleResource.get('broker_groups/').then(response => {
            return response.data
        })
    }, {
        onError: () => {
            toast.error("Something went wrong. Please refresh. If problem persists, please contact administrator.")
        },
        staleTime: 60 * 1000  // one minute
    })

    const affiliationOptions = brokersResult.isSuccess ? [
        ...brokersResult.data.map(row => ({label: row.name, value: row.id}))
    ] : []

    const selectedAffiliation = affiliationOptions.length > 0 ? affiliationOptions.filter(o => o.value === value)[0] : null;

    if (isCollapsed) {
        if (brokersResult.isLoading) {
            return <Spinner size="sm"/>
        }
        if (selectedAffiliation) {
            return <>{labelText ? labelText : "Affiliated to"} <strong>{selectedAffiliation?.label}</strong></>
        }
        return null
    }
    return <>
        <Label>{labelText ? labelText : "Affiliated to"}</Label>
        <Select
            {...renderProps}
            isClearable={true}
            placeholder={placeholderText ? placeholderText : "Select affiliation..."}
            options={affiliationOptions}
            isLoading={brokersResult.isLoading}
            value={selectedAffiliation}
            onChange={newOption => newOption ? setValue(newOption.value) : setValue(undefined)}
        />
    </>
}

export function TableSalesProfileFilter({isCollapsed, value, setValue, renderProps}) {
    const {isLoading, ungroupedOptions, groupedOptions} = useSalesPersonOptions()
    const selectedSalesProfile = !isLoading ? ungroupedOptions.filter(o => o.value === value)[0] : null;

    if (isCollapsed) {
        if (isLoading) {
            return <Spinner size="sm"/>
        }
        if (selectedSalesProfile) {
            return <>Leads of <strong>{selectedSalesProfile?.label}</strong></>
        }
        return null
    }
    return <div className={"mt-3"}>
        <Label>Leads of</Label>
        <Select
            {...renderProps}
            isClearable={true}
            placeholder={"Select broker/salesperson..."}
            options={groupedOptions}
            isLoading={isLoading}
            value={selectedSalesProfile}
            onChange={newOption => newOption ? setValue(newOption.value) : setValue(undefined)}
        />
    </div>
}


export function TablePropertyListGroupFilter({isCollapsed, value, setValue, renderProps}) {
    const api = useLoggedInApi();
    const propertyGroupsQuery = useQuery(['properties', 'all-property-groups'], () => {
        const resource = api.resource('properties/groups')
        return resource.list().then(response => response.data)
    }, {
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000
    })

    const propertyGroupOptions = propertyGroupsQuery.isSuccess ? propertyGroupsQuery.data.map(row => ({
        label: <>
            <strong>{row.name}</strong>
            <br/>
            <small>
                    {row.unique_property_group_id}
            </small>
        </>,
        searchData: (row.name + " " + row.unique_property_group_id).toLowerCase(),
        display: row.name,
        value: row.id
    })).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    }) : []

    const selectedProperty = propertyGroupOptions.length > 0 ?
        propertyGroupOptions.filter(row => row.value === value)[0] :
            null;

    if (isCollapsed) {
        if (propertyGroupsQuery.isLoading) {
            return <Spinner size="sm"/>
        }
        if (selectedProperty) {
            return <>Listings of <strong>{selectedProperty?.display}</strong></>
        }
        return null
    }

    return <div className={"mt-3"}>
        <Label>Listings of</Label>
        <Select
            {...renderProps}
            filterOption={(candidate, input) => {
                return candidate.data?.searchData?.includes(input.toString().toLowerCase())
            }}
            isClearable={true}
            placeholder={"Select property group"}
            options={propertyGroupOptions}
            isLoading={propertyGroupsQuery.isLoading}
            value={selectedProperty}
            onChange={newOption => newOption ? setValue(newOption.value) : setValue(undefined)}
        />
    </div>
}