import React from "react";
import InputRow from "../inputRow";


export default function BrokerInfo({profileData, isProfileSaving, updateErrorStatus, setProfileData, profileOptions, fieldErrors, fieldsWithErrors, setFieldErrors}) {

    return <>
        <InputRow
            required
            label={"Type"}
            value={profileData.is_group ? "Group" : "Individual"}
            type={"select"}
            disabled={isProfileSaving}
            children={[
                <option key="group">Group</option>,
                <option key="individual">Individual</option>,
            ]}
            onChange={newValue => {
                setProfileData({...profileData, is_group: newValue === 'Group'})
            }}
        />

        {
            (profileData.is_group || profileData.is_broker) && <InputRow
                required
                label={"Group Name"}
                value={profileData.group_name}
                disabled={isProfileSaving}
                setHasError={hasError => updateErrorStatus('group_name', hasError)}
                errorMessage={fieldErrors.group_name}
                onChange={newValue => {
                    setProfileData({...profileData, group_name: newValue})
                    updateErrorStatus('group_name', false)
                    setFieldErrors({...fieldErrors, group_name: null})
                }}
            />
        }

    </>
}