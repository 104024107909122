import React from "react";
import {processResponseAsDownload} from "../util";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import {useLoggedInApi} from "../../../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";


export default function DownloadTemplate() {
    const api = useLoggedInApi()
    const download = () => {
        const leadResource = api.resource('leads')
        const downloadPromise = leadResource.getItem('importer_file', {
            responseType: "blob"
        }).then(processResponseAsDownload);

        toast.promise(downloadPromise, {
            error: {
                render({data}){
                    const reasonDetail = data?.response?.data?.detail
                    if (reasonDetail) {
                        return reasonDetail
                    }

                    return "Something went wrong. Please contact administrator"
                }
            },
            pending: "Generating import template",
            success: "Import template downloaded!"
        })
    }

    return <Button color={"info-outline"} onClick={download}>
        <FontAwesomeIcon icon={faFileDownload} className="me-2"/>
        Download Template
    </Button>
}