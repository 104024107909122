import {useQuery, useQueryClient} from "react-query";
import {useLoggedInApi} from "./index";

export const useEmployees = ({params={}}) => {
    const api = useLoggedInApi()
    return useQuery(['table', 'employee', params], () => {
        const employeeResource = api.resource('employee')
        return employeeResource.list({
            params: params
        }).then(response => response.data)
    }, {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false
    })
}