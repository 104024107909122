import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {Alert, Button, Col, Label, Progress, Row, Spinner} from "reactstrap";
import {useLoggedInApi} from "../../../../../api";
import {chunkArray, chunkDict} from "../../../../../commonComponents/util";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "react-query";


function Commit({processedData, previousStep}) {
    console.log(processedData)
    const resourceName = 'properties';
    const [isProcessing, setIsProcessing] = useState(false)
    const [updatedCount, setUpdatedCount] = useState(0)
    const [createdCount, setCreatedCount] = useState(0)
    const [isComplete, setIsComplete] = useState(false)
    const editableListings = processedData.filter(row => row.status === 'changed' || row.status === 'new');

    const navigate = useNavigate()
    const api = useLoggedInApi()

    const update = data => {
        const dataToUpdate = data.filter(row => row.status === 'changed').reduce((acc, row) => {
            acc[row.id.toString()] = row.changedData
            return acc
        }, {})

        console.table(Object.values(dataToUpdate))

        const dataToInsert = data.filter(row => row.status === 'new').map(row => row.newApiData)

        const dataToUpdateChunks = chunkDict(dataToUpdate, 10).map(chunk => ({update: chunk, new: []}))
        const dataToInsertChunks = chunkArray(dataToInsert, 10).map(chunk => ({update: {}, new: chunk}))
        runPost([...dataToUpdateChunks, ...dataToInsertChunks])
    }

    const dataToUpdate = processedData.filter(row => row.status === 'changed').reduce((acc, row) => {
        acc[row.id.toString()] = row.changedData
        return acc
    }, {})

    const queryClient = useQueryClient()
    const resetPropertyListings = () => {
        queryClient.invalidateQueries(['table', 'properties'])
    }

    const runPost = (data) => {
        const postDataChunks = JSON.parse(JSON.stringify(data))
        let _updatedCount = 0;
        let _createdCount = 0
        let isOngoing = false;
        const intervalId = window.setInterval(() => {
            const summaryTextParts = [];
            if (_updatedCount > 0) {
                summaryTextParts.push("updated " + _updatedCount.toString())
            }
            if (_createdCount > 0) {
                summaryTextParts.push("created " + _createdCount.toString())
            }
            const summaryText = <>{summaryTextParts.length === 0 ? "No updated/created" : "Successfully " + summaryTextParts.join(" and ")} property listings.</>

            if (!isOngoing) {
                if (postDataChunks.length === 0) {
                    clearInterval(intervalId)
                    setIsProcessing(false)
                    setIsComplete(true)
                    toast.success(<>Data upload done! {summaryText}</>)
                    return false
                }

                isOngoing = true
                const postData = postDataChunks.pop()
                console.log("Remaining chunks", postDataChunks.length)
                const listingApi = api.resource(resourceName)
                listingApi.post('bulk_update/', postData)
                    .then(response => response.data)
                    .then(data => {
                        data.updated.forEach(row => {
                            resetPropertyListings(row)
                        })
                        data.new.forEach(row => {
                            resetPropertyListings(row)
                        })
                        _updatedCount += data.updated.length
                        _createdCount += data.new.length

                        setUpdatedCount(_updatedCount)
                        setCreatedCount(_createdCount)
                        isOngoing = false
                        return data
                    }).catch(() => {
                    toast.error(<>
                        Something went wrong during upload! {summaryText}
                        <br/>
                        If problem persists, contact administrator

                    </>)

                    clearInterval(intervalId)
                    setIsProcessing(false)
                    return false;
                })
            }
        }, 1000)
    }

    const changeableCount = editableListings.filter(row => row.status === 'changed').length;
    const creatableCount = editableListings.filter(row => row.status === 'new').length

    return <>
        <Row className={"mt-4"}>
            <Col><h4>Step 3 - Commit Changes</h4></Col>
        </Row>
        <Row>
            <Col>
                <Button color="link" onClick={previousStep}>
                    <small>Go Back To Step 2 - Review</small>
                </Button>
            </Col>
        </Row>
        <Row className={"mt-4"}>
            <Col>
                <Label>
                    <strong>Update Property Listings</strong>{" "}
                    {updatedCount} of {changeableCount}
                </Label>
                <Progress value={changeableCount ? (updatedCount / changeableCount * 100) : 100}>
                    {updatedCount} of {changeableCount}
                </Progress>
            </Col>
        </Row>
        <Row className={"mt-4"}>
            <Col>
                <Label>
                    <strong>Create Property Listings</strong>{" "}
                    {createdCount} of {creatableCount}
                </Label>
                <Progress value={creatableCount ? (createdCount / creatableCount * 100) : 100}>
                    {createdCount} of {creatableCount}
                </Progress>
            </Col>
        </Row>
        {
            (!isProcessing && !isComplete) && <Row className={"mt-4"}>
                <Col>
                    <Button color={'primary'} onClick={() => {
                        setIsProcessing(true)
                        update(editableListings)
                    }}>
                        Commit {editableListings.length} Property Listings
                    </Button>
                </Col>
            </Row>
        }
        {
            (isProcessing) && <Row className={"mt-4"}>
                <Col>
                    <Spinner size={"sm"}/> Processing
                </Col>
            </Row>
        }

        {
            isComplete && <>
                <Row className={"mt-4"}>
                    <Col>
                        <Alert color={"success"}>
                            Done! Updated {updatedCount} and created {createdCount} new property listings.
                        </Alert>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col>
                        <Button color="link" onClick={() => {
                            navigate('/property-management/listing/')
                        }}>
                            <small>Go To Properties List</small>
                        </Button>
                    </Col>
                </Row>
            </>
        }
    </>
}

Commit.propTypes = {
    processedData: PropTypes.array.isRequired,
    onStart: PropTypes.func,
    onComplete: PropTypes.func
}

export default Commit;

