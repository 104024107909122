import React from "react";
import {AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Col, Input, Row} from "reactstrap";
import CommonInquiry from "../commonInquiry";

export default function HelpTicket({row}) {

    const badge = row.is_resolved ?
        <Badge color="success">RESOLVED</Badge> :
        <Badge>Pending</Badge>;

    return <AccordionItem>
        <AccordionHeader targetId={row.id}>
            <strong  className="text-nowrap">Ticket #{row.id}</strong>&nbsp;-&nbsp;
            {badge}&nbsp;
            <span className="text-nowrap text-ellipsis">{row.message}</span>
        </AccordionHeader>
        <AccordionBody accordionId={row.id}>
            <CommonInquiry inquiryId={row.id} defaultResolved={row.is_resolved}/>
        </AccordionBody>
    </AccordionItem>
}