import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import PropertyGroupAsTag from "./propertyGroupAsTag";
import {usePropertyGroups} from "./hooks";


function PropertyGroupSelectorAsTag(props) {
    const [isSaving, setIsSaving] = useState(false)

    const {data, isLoading} = usePropertyGroups({})

    const save = (newValue) => {
        if (props.setPropertyGroups) {
            if (props.isSetSaving) {
                setIsSaving(true)
                const setValuePromise = props.setPropertyGroups(newValue)
                setValuePromise.finally(() => {
                    setIsSaving(false)
                })
            } else {
                props.setPropertyGroups(newValue)
            }
        }
    }

    if (isLoading) {
        return <span className="text-muted">Loading...</span>
    }

    const isReadOnly = props.readOnly || !props.showNotSelected

    return <>
        {
            data?.filter(row => {
                const isSelected = props.propertyGroups?.indexOf(row.id) > -1
                return (props.showNotSelected || isSelected) && row.is_active
            })?.map(row => {
                const isSelected = props.propertyGroups?.indexOf(row.id) > -1
                return <PropertyGroupAsTag
                    key={row.id}
                    propertyGroup={row}
                    isSelected={isSelected}
                    onClick={(isSelected || isReadOnly) ? () => {
                        const newValue = props.isMulti ? [...props.propertyGroups, row.id] : row.id
                        props.onTagClick(newValue)
                    } : () => {
                        const newValue = props.isMulti ? [...props.propertyGroups, row.id] : row.id
                        save(newValue)
                        props.onTagClick && props.onTagClick(newValue)
                    }}
                    onDelete={(!isSelected || isReadOnly) ? undefined : () => {
                        const newValue = props.isMulti ? props.propertyGroups.filter(id => id !== row.id) : null
                        save(newValue)
                    }}
                />
            })
        }
    </>


}

PropertyGroupSelectorAsTag.propTypes = {
    onTagClick: PropTypes.func,
    propertyGroups: PropTypes.arrayOf(PropTypes.number),
    setPropertyGroups: PropTypes.func,
    isSetSaving: PropTypes.bool,
    readOnly: PropTypes.bool,
    isMulti: PropTypes.bool,
    showNotSelected: PropTypes.bool
}

export default PropertyGroupSelectorAsTag