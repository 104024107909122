import React, {useContext} from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faFileUpload} from "@fortawesome/free-solid-svg-icons";
import {NavLink, useNavigate} from "react-router-dom";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {TableFilterContext} from "../../../../commonComponents/contexts";
import moment from "moment";
import Organization from "./organization";


export default function LandingPage() {
    const {setFilters: setAllFilters} = useContext(TableFilterContext)
    const navigate = useNavigate()

    const [currentView, setCurrentView] = React.useState('table')
    const views = ['table', 'list']
    const toggleView = () => {
        const currentIndex = views.indexOf(currentView)
        setCurrentView(views[(currentIndex + 1) % views.length])
    }

    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">User Administration</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Super admins can setup and manage access of other administrators.
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    navigate('new')
                }}>

                    <FontAwesomeIcon icon={faCirclePlus}/>{" "}
                    ADD ADMIN USER
                </Button>
            </Col>
        </Row>

        <Row>
            <Col className="text-end">
                <Button color="link" onClick={toggleView}>Switch View</Button>
            </Col>
        </Row>
        {currentView === 'list' && <Organization toggleView={toggleView}/>}

        {
            currentView === 'table' && <AdvancedTable
            friendlyResourceName={"Employee"}
            apiResourceName={'employee'}
            columnDefinition={[
                {
                    name: "Employee Code",
                    selector: row => <NavLink to={"edit/" + row.id}>
                        {row.active_group_employee_id ? row.active_group_employee_id : <i>ID Not Set</i>}
                    </NavLink>,
                    sortable: true,
                    sortField: 'active_group_employee_id'
                },
                {
                    name: "Name", selector: row => row.profile.name, sortable: true,
                    sortField: 'user__first_name,user__last_name'
                },
                {
                    name: "User Type", selector: row => <>
                        {Object.values(row.access_display).join(', ')}
                    </>, sortable: true, sortField: 'user__group__name'
                },
                {
                    name: "Last Login",
                    selector: row => <>
                        {
                            row.user.last_login ?
                                moment(row.user.last_login).format('LLL') :
                                <small><i>NEVER</i></small>
                        }
                    </>
                }
            ]}/>
        }
    </>
}