import React, {useContext} from "react";
import {Button, Col, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {ProfileContext} from "../../contexts";
import {useNavigate} from "react-router-dom";
import {useBrokerGroups} from "../../hooks";
import Header from "./header";
import LeadsList from "./leadsList";


function Leads() {
    const navigate = useNavigate()

    const {profile} = useContext(ProfileContext)
    const isSellerOrBroker = !!profile.active_group_seller_id || !!profile.affiliation || profile.hasOwnProperty('is_broker')

    const {data: brokerData, isLoading: brokerIsLoading} = useBrokerGroups({
        queryOptions: {
            enabled: !isSellerOrBroker
        }
    })

    if (brokerIsLoading) {
        return <>
            <Header/>

            <Row className="pt-4 text-center">
                <Col>
                    <Spinner/>
                </Col>
            </Row>
        </>
    }

    if ((!brokerData || brokerData.length === 0) && !isSellerOrBroker) {
        return <>
            <Header/>
            <Row className="pt-4">
                <Col>
                    <p>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-warning"/>{" "}
                        You must have at least one broker group to manage leads. You can {" "}
                        <Button color="link" className="" style={{verticalAlign: "baseline"}} onClick={() => {
                            navigate('/broker-management/brokers')
                        }}> create a broker group here</Button> or ask an admin to assign existing brokers to your account.
                    </p>
                    <p>
                        You can also view more information in the <a href={"https://docs.google.com/presentation/d/e/2PACX-1vQ3A_75v8g5u130ad_03KdZlD_g4eHK0WqBoyqVPxbIASzSFqbOhhTK0RFcwGbPRiznxV6gYKTBpJK-/pub?start=false&loop=false&delayms=3000"} target={"_blank"}>user manual</a>.
                    </p>
                </Col>
            </Row>
        </>
    }

    return <>
        <Header/>

        <LeadsList/>
    </>
}

export default Leads