import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import blankProfilePicture from "../../commonComponents/assets/images/blank-profile-picture.png"
import ProfilePicture from "../profilePicture";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import Compress from 'compress.js'


export default function ProfilePhotoModal(props) {
    const [localImage, setLocalImage] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const compress = new Compress()

    const close = () => {
        setLocalImage(null)
        props.toggle()
    }
    const api = useLoggedInApi()

    const getUpdateUrl = () => {
        if (props.resourceName === 'my-employee-profile') {
            return 'profile_picture/'
        } else {
            return props.profileId.toString() + '/profile_picture/'
        }
    }

    const save = () => {
        setIsSaving(true)
        const formData = new FormData();

        formData.append("photo", Compress.convertBase64ToFile(localImage.data, localImage.ext), localImage.alt);

        const salesProfileApi = api.resource(props.resourceName)
        const updatePromise = salesProfileApi.patch( getUpdateUrl(), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            props.onChange(response.data.url)
        }).finally( () => {
            setIsSaving(false)
            close()
        })

        toast.promise(updatePromise, {
            pending: "Updating profile photo",
            error: "Cannot update profile photo. Please try again. If problem persists, contact administrator.",
            success: "Updated profile photo"
        })
    }

    const displayImage = localImage ? URL.createObjectURL(Compress.convertBase64ToFile(localImage.data)) : (props.url ? props.url : blankProfilePicture);

    return <Modal isOpen={props.isOpen} centered toggle={isSaving ? () => {} : close} scrollable={true}>

        <ModalHeader>
            Profile Photo
        </ModalHeader>
        <ModalBody className="text-center p-5 mt-3 mb-3">

            <ProfilePicture url={displayImage} className="profile-picture-full profile-picture-border"/>

            <input
                className="mt-5"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={e => compress.compress([e.target.files[0]], {
                    size: 2, // the max size in MB, defaults to 2MB
                    quality: 1, // the quality of the image, max is 1,
                    maxWidth: 600, // the max width of the output image, defaults to 1920px
                    maxHeight: 600, // the max height of the output image, defaults to 1920px
                    resize: true // defaults to true, set false if you do not want to resize the image width and height
                }).then(resizedImages => {
                    setLocalImage(resizedImages[0])
                })}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" disabled={!localImage} onClick={save}>Save</Button>
            <Button color="info-outline" disabled={isSaving} onClick={close}>Cancel</Button>
        </ModalFooter>
    </Modal>
}