import React from "react";
import {Route} from "react-router-dom";
import TagAdmin from "./index";
import TagManagement from "./tagManagement";


const Routes = (<Route exact path="tag-admin" element={<TagAdmin/>}>
    <Route index element={<TagManagement/>}/>
</Route>)

export default Routes