import React, {useState} from "react";

import * as PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import PropertyGroupSelector from "../../../../propertyGroupSelector";
import PropertySelector from "../../../../propertySelector";

function PropertiesSelect(props) {
    const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null)

    return <>
        <Row className={props.removeMarginTop ? "" : "mt-4"}>
            <Col>
                <PropertyGroupSelector
                    propertyGroups={selectedPropertyGroup}
                    isMulti={false}
                    setPropertyGroups={newSelected => {
                        setSelectedPropertyGroup(newSelected)
                        props.setSelectedProperty(null)
                    }}
                    isSetSaving={false}
                    />

            </Col>
        </Row>
        <Row className="mt-3">
            <Col>

                <PropertySelector
                    properties={props.selectedProperty}
                    isSetSaving={false}
                    isMulti={false}
                    setProperties={newSelected => props.setSelectedProperty(newSelected)}
                    propertyGroupId={selectedPropertyGroup}
                    showAllProperties={props.showAllProperties}
                    ignoredPropertyIds={props.ignoredPropertyIds}
                    suggestedPropertyIds={props.suggestedPropertyIds}
                    templateRequired={props.templateRequired}
                    />
            </Col>
        </Row>
    </>

}

PropertiesSelect.propTypes = {
    propertyGroupId: PropTypes.number,
    isLoading: PropTypes.bool,
    selectedProperty: PropTypes.number,
    setSelectedProperty: PropTypes.func,
    showAllProperties: PropTypes.bool,
    removeMarginTop: PropTypes.bool
}

export default PropertiesSelect