import React, {useState} from "react";
import DashboardHeader from "./dashboardHeader";
import DashboardFooter from "../../commonComponents/dashboardFooter";
import {Col, Container, Row} from "reactstrap";
import {Outlet} from "react-router-dom";
import {ToggleSidebarContext} from "../../commonComponents/contexts";
import PermissionProvider from "../../commonComponents/providers/permissionProvider";


export default function Dashboard() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    return <div className="">

        <ToggleSidebarContext.Provider value={{
            isOpen: sidebarOpen,
            toggle: () => setSidebarOpen(!sidebarOpen),
            close: () => setSidebarOpen(false)
        }}>
            <PermissionProvider>
                <DashboardHeader/>
                <Container className="mt-5 pt-4 main-content-container px-5" fluid>
                    <Row>
                        <Col>
                            <Outlet/>
                        </Col>
                    </Row>
                </Container>
            </PermissionProvider>
        </ToggleSidebarContext.Provider>
        <DashboardFooter/>
    </div>
}