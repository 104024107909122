import InfoCards from "../../../../components/charts/infoCards";
import React from "react";
import {useLoggedInApi} from "../../../../api";
import {useQuery} from "react-query";

export default function SummaryCard({filters}) {
    const api = useLoggedInApi()
    const query = useQuery(['properties', 'report', 'summary', filters], () => {
        const resource = api.resource('properties/groups')
        return resource.getItem('group_summary', {
            params: filters
        }).then(response => response.data)
    })

    return <InfoCards
        data={[
            {
                label: "TOTAL PROPERTY GROUPS",
                value: query.data?.total_property_groups,
                color: "#0169a6",
                isLoading: query.isLoading
            },
            {
                label: "PROPERTY LISTINGS",
                value: query.data?.property_listings,
                color: "#967126",
                isLoading: query.isLoading
            },
            {
                label: "CLOSED-SOLD",
                value: query.data?.closed_sold,
                color: "#006138",
                isLoading: query.isLoading
            }
        ]}
    />
}