import React from "react";
import {dictEqualShallow} from "../../../../../commonComponents/util";
import {Col, Row} from "reactstrap";


function EmployeeInfo({name, email, contact_number}) {
    return <Row className="mt-2 mb-3 small">
        <Col>
            <strong>{name}</strong><br/>
            {email}<br/>
            {contact_number}
        </Col>
    </Row>
}


export default function CreatorAndEditor({createdBy, updatedBy}) {
    if (dictEqualShallow(createdBy, updatedBy)) {
        return <>
            <Row className="mt-5">
                <Col><strong>CREATED AND LAST UPDATED BY</strong></Col>
            </Row>
            <div className="border-bottom"/>
            <EmployeeInfo {...createdBy}/>
        </>
    } else {
        return <>
            <Row className="mt-5">
                <Col><strong>CREATED BY</strong></Col>
            </Row>
            <div className="border-bottom"/>
            <EmployeeInfo {...createdBy}/>

            <Row className="mt-5">
                <Col><strong>LAST UPDATED BY</strong></Col>
            </Row>
            <div className="border-bottom"/>
            <EmployeeInfo {...updatedBy}/>
        </>
    }
}