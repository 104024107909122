import React from "react";
import {FilterContext} from "./contexts";


export default function FilterProvider({children}) {
    const [filters, setFilters] = React.useState({})

    return <FilterContext.Provider value={{
        filters,
        setFilters,
        setFilter: (filterName, filterValue, group) => {
            if (group) {
                setFilters({
                    ...filters,
                    [group]: {
                        ...filters[group], [filterName]: filterValue
                    }
                })
                return
            }
            setFilters({...filters, [filterName]: filterValue})
        }
    }}>
        {children}
    </FilterContext.Provider>
}
