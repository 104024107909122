import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {MICROSITE_URL} from "../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import UrlPathModal from "./urlPathModal";

export default function WebsiteUrlEdit({websiteUrl, originalWebsiteUrl, onChange, profileId}) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return <>
        <Row className="input-row">
            <Col className="ps-4 overflow-hidden" style={{
                direction: "rtl",
                textAlign: "left",
                textOverflow: "ellipsis"
            }}>
                <strong>{MICROSITE_URL}</strong>
                <strong className="text-primary">{websiteUrl}</strong>
            </Col>
            <Col className="col-auto">
                <Button color={null} onClick={() => setIsModalOpen(true)}>
                    <FontAwesomeIcon icon={faPencil}/>
                </Button>


                {websiteUrl !== originalWebsiteUrl && <>(UNSAVED)</>}

                <UrlPathModal
                    profileId={profileId}
                    urlPath={websiteUrl}
                    onChange={onChange}
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                />
            </Col>
        </Row>
    </>
}