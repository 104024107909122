import React from "react";
import ProfileForm from "../profileForm";

import {DEFAULT_SALESPERSON_PROFILE} from "../../commonComponents/hooks";

export default function SalesProfileForm(props) {
    return <ProfileForm
        isMe={props.isMe}
        profileId={props.profileId}
        showMicroSite={true}
        showBrokerInfo={false}
        showSalesPersonInfo={true}
        canEditPosition={true}
        canEditAffiliation={true}
        isEmployee={false}
        hasActiveToggle={true}
        resourceName={'salesperson'}
        defaultProfileData={DEFAULT_SALESPERSON_PROFILE}
        canEditID={true}
        showPropertyGroups={false}
    />
}
