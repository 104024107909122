import React from "react";
import {Badge, Col, Input} from "reactstrap";


export default function PermissionCheckbox(
    {
        isEditable=true, isSuperUserOnly=false,
        isChecked, permKey, addPermissionByKey, removePermissionByKey
    }
) {
    return <Col lg={6} md={12}>
        <Input
            disabled={!isEditable}
            type={"checkbox"}
            checked={isChecked}
            onChange={e => {
                if (e.target.checked) {
                    addPermissionByKey(permKey)
                } else {
                    removePermissionByKey(permKey)
                }
            }}
        />{" "}
        {permKey}
        {isSuperUserOnly && <sup>
            <Badge color={"warning"} className="ms-2">
                <small>SUPERUSER ONLY</small>
            </Badge>
        </sup>}
    </Col>
}