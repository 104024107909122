import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import TeamLeads from "../../../../commonComponents/report/teamLeads";
import LeadStatusBreakdown from "../../../../commonComponents/report/leadStatusBreakdown";
import TrackingTrafficSources from "../../../../commonComponents/report/trackingTrafficSources";
import MostClicked from "../../../../commonComponents/report/mostClicked";
import PropertiesClicked from "../../../../commonComponents/report/propertiesClicked";
import Filter from "./filter";


export default function Report(props) {
    const [filters, setFilters] = useState({
        group_id: null,
        salesperson_id: null,
        from: null,
        to: null
    })

    return <>
        <TeamLeads/>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Brokers Reports</h3>
            </Col>
        </Row>

        <Row>
            <Col>
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                />
            </Col>
        </Row>


        <LeadStatusBreakdown filters={filters}/>
        <Row>
            <Col className="pe-4">
                <TrackingTrafficSources filters={filters}/>
            </Col>
            <Col className="ps-4">
                <MostClicked filters={filters}/>
            </Col>
        </Row>


        <Row className="mb-5 pb-5">
            <Col>
                <PropertiesClicked filters={filters}/>
            </Col>
        </Row>
    </>
}