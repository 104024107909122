import React from "react";
import * as PropTypes from "prop-types";
import {Col, Progress, Row} from "reactstrap";


function Item(props) {
    return <>
        <Row className="mt-3">
            <Col>
                <>{props.label}</>
            </Col>
        </Row>
        <Row>
            <Col>
                <Progress
                    title={props.title || `${props.label}: ${props.value}`}
                    max={props.max} min={0}
                    value={props.value} className="chart-progress"
                    barStyle={props.color && {backgroundColor: props.color}}>
                    {props.getDisplay && props.getDisplay(props.value)}
                </Progress>
            </Col>
        </Row>
    </>
}

Item.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    value: PropTypes.number,
    getDisplay: PropTypes.func,
    max: PropTypes.number,
    color: PropTypes.string
}

export default Item