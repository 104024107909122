import React, {useState} from "react";
import {Badge, Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import AdvancedTable from "../../../../commonComponents/AdvancedTable";
import {useNavigate} from "react-router-dom";
import {GroupDetail} from "./groupDetail";


export default function Groups() {
    const [showAdd, setShowAdd] = useState(false)
    const navigate = useNavigate()


    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">User Types</h3>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                Manage permissions of each User Type
            </Col>
        </Row>


        <Row className="mt-5">
            <Col>
                <Button color="info-outline" onClick={() => {
                    setShowAdd(true)
                }}>

                    <FontAwesomeIcon icon={faCirclePlus}/>{" "}
                    ADD USER TYPE
                </Button>
            </Col>
        </Row>

        {
            showAdd && <GroupDetail
                data={{
                    name: '',
                    permissions: []
                }}
                cancel={() => setShowAdd(false)}
                onSave={() => setShowAdd(false)}
            />
        }

        <AdvancedTable
            dataTableProps={{
                expandableRows: true,
                expandableRowsComponent: GroupDetail
            }}
            friendlyResourceName={"Employee Group"}
            apiResourceName={'employee-group'}
            columnDefinition={[
                {
                    name: "Employee Type",
                    selector: row => row.name,
                    sortable: true,
                    sortField: 'name',
                    format: row => {
                        return <>
                            <span>{row.name}</span>
                            {row.is_admin_restricted && <Badge color="warning" className="ms-2 rounded">SUPERUSER ONLY</Badge>}
                        </>
                    }
                },
            ]}/>
    </>
}