import React from "react";
import moment from "moment";
import {Col, Row} from "reactstrap";


export default function ActivityLog({log}) {
    return <>

        <Row className="mt-3">
            <Col className="border-bottom p-0 small">
                <strong>{log.title}</strong><br/>
                {log.message ? <blockquote className="border-start ps-2">{log.message}</blockquote> : null}
                {moment(log.datetime_added).format('LL')}<br/>
                <small>{moment(log.datetime_added).format('LT')}</small>
            </Col>
        </Row>
    </>
}