import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {Button, Col, Input, Label, Row} from "reactstrap";
import ClassicEditor from "../../../../../components/classicEditor";
import {useQueryClient} from "react-query";
import PhotoUpload from "../../../../../components/photoUpload";
import {useParams} from "react-router-dom";
import {dictChangedValues, dictEqualShallow, trimDict} from "../../../../../commonComponents/util";


function EditAdditionalInformation(props) {
    const [data, setData] = useState({
        'address': '',
        'description': '',
        'google_maps_embed_code': '',
        ...trimDict(props.query.data, [
            'address',
            'description',
            'google_maps_embed_code',
        ])
    })
    const hasChanges = props.query.data ? !dictEqualShallow(trimDict(props.query.data, [
        'address',
        'description',
        'google_maps_embed_code',
    ]), data) : true;

    const canSave = hasChanges;
    const isSaving = props.isSaving;

    const saveBtn = <Button color="secondary" disabled={!canSave || isSaving} onClick={() => {
        props.save(data)
    }}>
        {
            isSaving ? "UPDATING..." : "UPDATE ADDITIONAL INFORMATION"
        }
    </Button>

    const resourceName = 'properties/groups'

    const queryClient = useQueryClient()

    const params = useParams();


    return <>

        <Row className="mt-3">
            <Col>
                <Label className="small">
                    <strong>Property Group Address</strong>
                </Label>
                <Input
                    value={data.address}
                    onChange={e => {
                        setData({...data, address: e.target.value})
                    }}
                    placeholder="Property Group Address"
                    className="border-light input-sm"
                />
            </Col>
        </Row>


        <Row className="mt-3">
            <Col>
                <Label className="small">
                    <strong>Property Group Description</strong>
                </Label>
                <div className="p-3 border-light border">
                    <ClassicEditor
                        value={data.description}
                        initialValue={data.description}
                        onEditorChange={(newContent) => {
                            setData({...data, description: newContent})
                        }}
                        height={250}
                    />

                    <div className="mt-3" dangerouslySetInnerHTML={{__html: data.description}}/>
                </div>
            </Col>
        </Row>


        <Row className="mt-3">
            <Col>
                <Label className="small">
                    <strong>Google Maps Link</strong>
                </Label>
                <Input
                    value={data.google_maps_embed_code}
                    onChange={e => {
                        setData({...data, google_maps_embed_code: e.target.value})
                    }}
                    placeholder="Set google maps embed code"
                    className="border-light input-sm"
                />
            </Col>
        </Row>

        <Row className="small mt-3">
            <Col className="">

                <PhotoUpload
                    modalTitle={"Site Plan"}
                    resourceName={resourceName}
                    resourcePath={params.propertyGroupId.toString() + "/site_plan"}
                    toastDetail={{
                        error: "Something went wrong. Cannot upload site plan photo.",
                        pending: "Uploading property group site plan photo.",
                        success: "Updated property group site plan photo."
                    }}
                    defaultImage={props.query.data?.site_plan?.site_plan_photos[0]?.photo}
                    onSuccess={responseData => {
                        queryClient.setQueryData(['property-group', responseData.id.toString()], {
                            ...props.query.data,
                            ...responseData
                        })
                    }}
                >
                    <Button color="text" className="btn-sm">
                        <strong>Upload photo of Site Plan</strong>
                    </Button>
                </PhotoUpload>
            </Col>
        </Row>

        <Row className="small mt-3">
            <Col className="text-end">
                {saveBtn}
            </Col>
        </Row>
    </>
}

EditAdditionalInformation.propTypes = {
    save: PropTypes.func,
    query: PropTypes.object.isRequired,
}

export default EditAdditionalInformation;