import React, {useContext} from "react";
import {PermissionsContext} from "./contexts";


export default function PermissionRequiredComponent({children, requiredPermissions, noPermissionComponent}) {
    const {permissions} = useContext(PermissionsContext)
    const hasPermission = requiredPermissions.every(permission => permissions.includes(permission))

    if (hasPermission) {
        return children
    }
    return noPermissionComponent || null
}