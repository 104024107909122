import React, {useContext} from "react";
import {Col, Row} from "reactstrap";
import {ProfileContext} from "../contexts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareArrowUpRight} from "@fortawesome/free-solid-svg-icons";


export default function ProfileDisplay() {
    const {profile} = useContext(ProfileContext)
    const {
        active_group_employee_id,
        active_group_seller_id,
        email_address,
        contact_number,
        access_display,
        affiliation,
        licenses,
        url_path
    } = profile

    const access = access_display || []
    const active_group_id = active_group_seller_id || active_group_employee_id

    return <>
        <Row>
            <Col>
                <small>
                    Active Employee ID: <strong>{active_group_id}</strong>
                </small>
            </Col>
        </Row>
        <Row>
            <Col className="overflow-hidden text-ellipsis">
                <small className="no-wrap" title={email_address || undefined}>Email
                    Address: {email_address || <i>No email address</i>}</small>
            </Col>
        </Row>
        <Row>
            <Col>
                <small>Contact Number: {contact_number || <i>No contact info</i>}</small>
            </Col>
        </Row>

        {
            access.length > 0 && <Row>
                <Col>
                    <small>
                        Access: {access.join(', ')}
                    </small>
                </Col>
            </Row>
        }

        {
            affiliation && <Row>
                <Col>
                    <small>
                        <strong>Broker Affiliation</strong>: {affiliation}</small>
                </Col>
            </Row>
        }
        {
            licenses && <Row>
                <Col>
                    <small>{licenses}</small>
                </Col>
            </Row>
        }
        {
            url_path && <>

                <Row className="sidebar-nav"/>
                <Row className="sidebar-nav">
                    <Col className="d-flex align-items-center">
                        <a href={"/" + url_path} target="_blank">
                            <FontAwesomeIcon icon={faSquareArrowUpRight} className="fa-2x"/>
                            VIEW MY WEBSITE
                        </a>
                    </Col>
                </Row>
            </>
        }
    </>
}