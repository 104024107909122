import React, {useState} from "react";
import * as PropTypes from "prop-types"
import InputRow from "../../../../../components/inputRow";
import {Button, Col, Input, Label, Row} from "reactstrap";
import provinces from "./provinces.json"
import regions from "./regions.json"
import Select from "react-select";
import {REACT_SELECT_STYLES} from "../../../../../constants";
import useDebounce, {dictEqualShallow, trimDict} from "../../../../../commonComponents/util";
import TagDisplay from "../../../../../commonComponents/tagDisplay";
import {faEyeDropper} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TagColorPickerModal from "../../../../../commonComponents/tagColorPickerModal";

function EditBasicInformation(props) {
    const [data, setData] = useState({
        'name': '',
        'unique_property_group_id': '',
        'region': '',
        'province': '',

        color: '',
        text_color: '',
        ...trimDict(props.isNew ? {} : props.query.data, [
            'name',
            'unique_property_group_id',
            'region',
            'province',
            'color',
            'text_color'
        ]),
    })

    const debouncedName = useDebounce(data.name, 500)

    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false)

    const regionOptions = regions.map(row => ({
        label: row.long,
        value: row.long,
        id: row.key
    }));

    const regionOptionSelected = data.region ? regionOptions.filter(o => o.value === data.region)[0] : null;

    const provinceOptions = provinces.map(row => ({
        label: row.name,
        value: row.name,
        region: row.region
    })).filter(o => {
        if (regionOptionSelected) {
            return o.region === regionOptionSelected.id
        }
        return true;
    });

    const provinceOptionSelected = data.province ? provinceOptions.filter(o => o.value === data.province)[0] : null;

    const hasChanges = props.query.data ? !dictEqualShallow(trimDict(props.query.data, [
        'name',
        'unique_property_group_id',
        'region',
        'province',
        'color',
        'text_color',
    ]), data) : true;

    const canSave = data.name && data.unique_property_group_id && data.province && data.region && hasChanges;
    const isSaving = props.isSaving;

    const saveBtn = <Button color="primary" disabled={!canSave || isSaving} onClick={() => {
        props.save(data)
    }}>
        {
            props.isNew ?
                (isSaving ? "CREATING..." : "CREATE PROPERTY GROUP") :
                (isSaving ? "UPDATING..." : "UPDATE BASIC INFORMATION")
        }
    </Button>

    return <>
        <Row className="mt-3">
            <Col>
                <Label className="small">
                    <strong>Property Group Name</strong>
                </Label>
                <Input
                    value={data.name}
                    onChange={e => {
                        setData({...data, name: e.target.value})
                    }}
                    placeholder="Property Group Name"
                    className="border-light input-sm"
                />
            </Col>
            <Col className="col-auto">
                <Label className="small">
                    Set Tag Colors
                </Label>
                <br/>
                <TagDisplay
                    color={data.color || '#666666'}
                    textColor={data.text_color || '#FFFFFF'}
                    style={{
                        fontSize: '1rem'
                    }}
                    onClick={() => {
                        setColorPickerModalOpen(true)
                    }}>
                    <FontAwesomeIcon icon={faEyeDropper}/> {debouncedName}
                </TagDisplay>
                {
                    colorPickerModalOpen && <TagColorPickerModal
                        isOpen={colorPickerModalOpen}
                        toggle={() => {
                            setColorPickerModalOpen(false)
                        }}
                        setColor={(color) => {
                            setData(prevData => ({
                                ...prevData,
                                color: color
                            }))
                        }}
                        color={data.color || '#666666'}
                        setTextColor={(textColor) => {
                            setData(prevData => ({
                                ...prevData,
                                text_color: textColor
                            }))
                        }}
                        textColor={data.text_color || '#FFFFFF'}
                    />
                }
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <Label className="small">
                    <strong>Unique Property Group ID</strong>
                </Label>
                <Input
                    value={data.unique_property_group_id}
                    onChange={e => {
                        setData({...data, unique_property_group_id: e.target.value})
                    }}
                    placeholder="Unique Property Group ID"
                    className="border-light  input-sm"
                />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Select
                    options={regionOptions}
                    placeholder={"Choose Region"}
                    value={regionOptionSelected}
                    onChange={newOption => {
                        setData({...data, region: newOption.value})
                    }}
                    styles={REACT_SELECT_STYLES}
                />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Select
                    options={provinceOptions}
                    placeholder={"Choose Province"}
                    value={provinceOptionSelected}
                    onChange={newOption => {
                        setData({...data, province: newOption.value})
                    }}
                    styles={REACT_SELECT_STYLES}
                />
            </Col>
        </Row>

        <Row className="small mt-3">
            <Col className="text-end btn-sm">
                {saveBtn}
            </Col>
        </Row>
    </>
}

EditBasicInformation.propTypes = {
    save: PropTypes.func,
    isNew: PropTypes.bool
}

export default EditBasicInformation