import React from "react";
import PropertyManagement from "./index";
import {Route} from "react-router-dom";
import LandingPage from "./landingPage";
import PropertyGroups from "./propertyGroups";
import Detail from "./propertyGroups/detail";
import Properties from "./properties";
import BulkUpload from "./properties/bulk";
import HelpPage from "../../../commonComponents/helpPage";
import WorkInProgress from "../../../commonComponents/workInProgress";
import Reports from "./reports";
import SampleComputationTemplates from "./sampleComputationTemplates";
import {MODULE_CRM_INVENTORY} from "../helpManagement/helpList/constants";

const Routes = <Route exact path="property-management" element={<PropertyManagement/>}>
    <Route index element={<LandingPage/>}/>
    <Route path={"groups"}>
        <Route index element={<PropertyGroups/>}/>
        <Route path={'edit/:propertyGroupId'} element={<Detail/>}/>
        <Route path={'new'} element={<Detail isNew={true}/>}/>
    </Route>
    <Route path={"listing"}>
        <Route index element={<Properties/>}/>
        <Route path={'bulk'} element={<BulkUpload/>}/>
    </Route>

    <Route path={"reports"}>
        <Route index element={<Reports/>}/>
    </Route>

    <Route path="templates" element={<SampleComputationTemplates/>}/>

    <Route path="help" element={<HelpPage site={"crm"} module={MODULE_CRM_INVENTORY}/>}/>
</Route>

export default Routes;
