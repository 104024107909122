import React from "react";
import {useTagGroup} from "../../../fetchers/tags";
import {Input} from "reactstrap";


export default function TableTagGroupSelector({selected, setSelected}) {
    const {data: tagGroupsData, isLoading: tagsGroupIsLoading} = useTagGroup({params: {active_only: true}})

    const options = tagGroupsData?.filter(row => {
        return row.tags.length > 0
    }).map(row => {
        return {
            value: row.id,
            label: row.name
        }
    }).sort((a, b) => {
        if (a.label < b.label) {
            return -1
        }
        if (a.label > b.label) {
            return 1
        }
        return 0
    })

    return <Input
        type="select" size="sm" className="border-0 font-bold"
        value={selected || ""}
        onChange={e => {
            const selectedValue = e.target.value
            if (selectedValue === "") {
                setSelected(null)
            } else if (selectedValue === "others") {
                setSelected(selectedValue)
            } else {
                setSelected(parseInt(e.target.value))
            }

        }}
    >
        <option value={""}>Tag Group</option>
        {
            options?.map(option => {
                return <option key={option.value} value={option.value}>{option.label}</option>
            })
        }
        <option value="others">Others</option>
    </Input>
}