import React, {useState} from "react";
import readXlsxFile from "read-excel-file";
import xlsxParser from "xls-parser";

import {Button, Col, Input, Label, Row, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export default function FileSelector({setFileData, nextStep, header}) {
    const [file, setFile] = useState(null)
    const [isProcessingFile, setIsProcessingFile] = useState(false)

    const process = () => {
        if (file) {
            setFileData([])
            const extension = file.name.toString().split('.').pop();
            if (extension === 'xlsx') {

                readXlsxFile(file).then((rows) => {
                    const labels = rows.shift()
                    setFileData(
                        rows
                            .filter(row => {
                                return !row[0].toString().includes('EXPLANATION ROW')
                            })
                            .map(row => row.reduce(
                                (obj, val, idx) => {
                                    obj[labels[idx]] = val
                                    return obj
                                }, {})
                            ),
                        file.name
                    )
                    // `rows` is an array of rows
                    // each row being an array of cells.
                }).finally(() => {
                    setIsProcessingFile(false)
                    nextStep && nextStep()
                })
            } else if (extension === 'xls') {
                xlsxParser.onFileSelection(file).then(data => {
                    const firstSheetName = Object.keys(data)[0]
                    setFileData(
                        data[firstSheetName],
                        file.name
                    )
                    setIsProcessingFile(false)
                    nextStep && nextStep()
                })
            } else {
                console.log("extension not supported", extension)
            }
        } else {
            console.log("No file")
        }
    }

    return <>
        {header}
        <Row className={"mt-4"}>
            <Col><h4>Step 1 - Select File</h4></Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <Label>Select File</Label>
                <Input
                    type="file"
                    disabled={isProcessingFile}
                    onChange={e => setFile(e.target.files[0])}/>
            </Col>
        </Row>


        <Row className="mt-4">
            <Col>
                <Button
                    disabled={!file}
                    color={isProcessingFile ? "danger" : "primary"}
                    onClick={() => {
                        if (!isProcessingFile) process()
                        setIsProcessingFile(!isProcessingFile)
                    }}>
                    {isProcessingFile ? <><Spinner size={"sm"}/> READING</> : "READ FILE"}
                </Button>
            </Col>
        </Row>
    </>
}