import React from "react";
import {Col, Row} from "reactstrap";
import BrokerInfoCards from "./brokerInfoCards";
import Leaderboard from "./leaderboard";
import LatestActivities from "./latestActivities";


export default function LandingPage(props) {
    return <>
        <Row className="mb-0">
            <Col>
                <BrokerInfoCards/>
            </Col>
        </Row>

        <Leaderboard/>

        <LatestActivities/>
    </>
}