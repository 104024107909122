import React from "react";
import PropertyManagement from "./index";
import {Route} from "react-router-dom";
import LandingPage from "./landingPage";
import HelpPage from "../../../commonComponents/helpPage";
import {MODULE_CRM_MEDIA} from "../helpManagement/helpList/constants";

const Routes = <Route exact path="media-kit" element={<PropertyManagement/>}>
    <Route index element={<LandingPage/>}/>
    <Route path="help" element={<HelpPage site={"crm"} module={MODULE_CRM_MEDIA}/>}/>
</Route>

export default Routes;
