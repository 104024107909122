import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';
import './commonComponents/assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://d59c2051198f4e43b6ae425f4664b83c@o102310.ingest.sentry.io/6531625",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
    });
}

ReactDOM.render(
    <>
        <App/>
    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
