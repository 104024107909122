import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import InfoCards from "../../../../components/charts/infoCards";
import SummaryCard from "./summaryCard";
import TableChart from "../../../../components/charts/tableChart";
import TableClosedSold from "./tableClosedSold";
import TableProposal from "./tableProposal";
import TableNewLeads from "./tableNewLeads";
import TableSiteVisit from "./tableSiteVisit";
import TableContacted from "./tableContacted";
import Filter from "./filter";


export default function Reports() {
    const [filters, setFilters] = useState({})
    return <>
        <Row>
            <Col>
                <h3 className="page-header pb-2">Property Reports</h3>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                />
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <SummaryCard filters={filters}/>
            </Col>
        </Row>

        <Row className="mt-5">
            <Col>
                <h4 className="page-header pb-2">TOP PROPERTY GROUPS</h4>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col md={12} lg={6} className="mb-5">
                <TableClosedSold
                    filters={filters}
                />
            </Col>
            <Col md={12} lg={6} className="mb-5">
                <TableProposal
                    filters={filters}
                />
            </Col>


            <Col md={12} lg={6} className="mb-5">
                <TableNewLeads
                    filters={filters}
                />
            </Col>
            <Col md={12} lg={6} className="mb-5">
                <TableSiteVisit
                    filters={filters}
                />
            </Col>

            <Col md={12} lg={6} className="mb-5">
                <TableContacted
                    filters={filters}
                />
            </Col>
        </Row>
    </>
}