import React from "react";
import {Col, Row} from "reactstrap";
import LeadsTable from "../../../../commonComponents/modules/leads/leadsTable";


export default function LatestActivities() {
    return <>
        <Row className="mt-5">
            <Col>
                <h3>Latest Activities</h3>
            </Col>
        </Row>
        <div className="border-bottom mb-3"/>
        <LeadsTable defaultSort={"-datetime_updated"} leadsPath={'./leads'}/>
    </>
}