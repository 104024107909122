import React from "react";
import "../../../commonComponents/assets/css/dashboard.css"
import {faCircleQuestion, faClockRotateLeft, faGear, faTrash, faUsersGear} from "@fortawesome/free-solid-svg-icons";
import ModuleFactory from "../../../commonComponents/moduleFactory";
import {usePermissionContextCheck} from "../../../commonComponents/hooks";


export default function UserAdministration() {
    const hasAccessToUsers = usePermissionContextCheck([
        'employee.manage_own_employees',
        'employee.change_employeeprofile',
        'employee.add_employeeprofile',
    ], false)
    const hasAccessToGroups = usePermissionContextCheck([
        'auth.change_group',
        'auth.add_group'
    ], false)
    const hasAccessToLogs = usePermissionContextCheck('access.view_modellog')

    const hasAccessToEnableUsers = usePermissionContextCheck([
        'employee.add_employeeprofile',
    ])

    const sidebarItems = [];
    if (hasAccessToUsers) {
        sidebarItems.push({icon: faUsersGear, text: "USER ADMINISTRATION", to: '/users/'})
    }
    if (hasAccessToGroups) {
        sidebarItems.push({icon: faGear, text: "MANAGE USER TYPES", to: 'groups'})
    }
    if (hasAccessToLogs) {
        sidebarItems.push({icon: faClockRotateLeft, text: "VIEW LOGS", to: 'logs'})
    }

    if (hasAccessToEnableUsers) {
        sidebarItems.push({icon: faTrash, text: "DISABLED USERS", to: 'disabled-users'})
    }

    sidebarItems.push({icon: faCircleQuestion, text: "NEED HELP?", to: 'help'})

    return <ModuleFactory
        sidebarItems={sidebarItems}
        subItems={['employee', 'groups']}
    />
}