import React, {useEffect, useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Button,
    Col,
    FormFeedback,
    FormText,
    Input,
    InputGroup,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {useLoggedInApi} from "../../api";
import {toast} from "react-toastify";
import {MICROSITE_URL} from "../../constants";
import {faCircleCheck, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

export default function UrlPathModal(props) {
    const [newUrl, setNewUrl] = useState(null)
    const [runningCheckOn, setRunningCheckOn] = useState("")
    const [isNewUrlOk, setIsNewUrlOk] = useState(null)
    const [suggestions, setSuggestions] = useState({})

    const [isReporting, setIsReporting] = useState(false);
    const [isReported, setIsReported] = useState(false)

    const close = () => {
        setNewUrl(null)
        setRunningCheckOn("")
        setIsNewUrlOk(null)
        setSuggestions({})
        setIsReporting(false)
        setIsReported(false)
        props.toggle()
    }

    const api = useLoggedInApi()
    const save = () => {
        props.onChange(newUrl)
        close()
    }

    const check = () => {
        const checkUrl = newUrl;
        if (checkUrl === props.urlPath) {
            setIsNewUrlOk(true)
        }
        if (!checkUrl) return false;
        if (checkUrl === runningCheckOn) return false;
        setRunningCheckOn(checkUrl)
        const salesProfileApi = api.resource('auth-sales-profile')
        salesProfileApi.getItemAction(props.profileId, 'check_url', {
            params: {check: checkUrl}
        }).then((response) => {
            if (checkUrl === newUrl) {
                setIsNewUrlOk(response.data.is_ok)
                setSuggestions({
                    ...suggestions,
                    [checkUrl]: [...response.data.suggestions]
                })
            }
        }).finally(() => {
        })
    }

    useEffect(() => {
        if (newUrl !== runningCheckOn) {
            setIsNewUrlOk(null)
            setRunningCheckOn(null)

            const delayDebounceFn = setTimeout(() => {
                check()
            }, 5000)
            return () => clearTimeout(delayDebounceFn)
        }
        setIsReported(false)
    }, [newUrl])

    const report = () => {
        setIsReporting(true)
        const salesProfileApi = api.resource('auth-sales-profile')

        const reportPromise = salesProfileApi.postItemAction(
            props.profileId,
            'claim_url',
            {url_path: newUrl}
        ).then(response => {
            setIsReported(true)
        }).finally(() => {
            setIsReporting(false)
        })
        toast.promise(reportPromise, {
            pending: "Filing claim for " + MICROSITE_URL + newUrl + ".",
            error: "Cannot file claim. Please try again. If problem persists, contact administrator",
            success: "Claim filed. Coordinator will review your claim and we will get back to you."
        })
    }

    const url_path = newUrl !== null ? newUrl : props.urlPath
    const displaySuggestions = suggestions.hasOwnProperty(newUrl) ? suggestions[newUrl] : [];

    return <Modal
        isOpen={props.isOpen}
        centered toggle={close}
        scrollable={false}
        size="lg"
    >
        <ModalHeader>
            Microsite URL
        </ModalHeader>
        <ModalBody className="p-5 mt-3 mb-3">
            <Row>
                <Col>

                    <InputGroup>

                        <InputGroupText className="overflow-hidden" style={{
                        }}>{MICROSITE_URL}</InputGroupText>
                        <Input value={url_path} onChange={e => setNewUrl(e.target.value.replace(/[^0-9a-z\-_]/gi, ''))}
                               valid={isNewUrlOk}
                               invalid={isNewUrlOk === false}
                        />

                        <FormFeedback valid>
                            <FontAwesomeIcon icon={faCircleCheck} color="success"/>{" "}
                            <span className="text-break">{MICROSITE_URL}{url_path}</span> is available.
                        </FormFeedback>

                        <FormFeedback>
                            <FontAwesomeIcon icon={faCircleExclamation} color="danger"/>{" "}
                            <span className="text-break">{MICROSITE_URL}{url_path}</span>is already taken.
                        </FormFeedback>
                    </InputGroup>
                    <FormText>
                        {
                            (runningCheckOn && runningCheckOn === newUrl && isNewUrlOk === null) ?
                                <>
                                    Checking {MICROSITE_URL}{runningCheckOn}
                                </> :
                                (isNewUrlOk === null && newUrl) && <>
                                    <span className="text-decoration-underline" onClick={check}>
                                        Check <span className="text-break">{MICROSITE_URL}{newUrl}</span>
                                    </span>
                                </>
                        }
                    </FormText>
                </Col>
            </Row>

            {
                isNewUrlOk === false && !isReported && <Row className="mt-3">
                    <Col>
                        File a claim for {MICROSITE_URL}{newUrl}{" "}
                        <Button color="info-outline" size="sm" onClick={report} disabled={isReporting || isReported}>
                            {isReporting ? "Filing..." : "File Claim"}
                        </Button>
                    </Col>
                </Row>
            }

            {
                displaySuggestions && displaySuggestions.length > 0 && <Row>
                <Col><strong>Suggestions:</strong></Col>
                </Row>
            }
            {
                displaySuggestions.map(s => <Row key={s}>
                    <Col className="ps-5">
                        <Button
                            color={'link'}
                            onClick={() => {
                                setNewUrl(s)
                                setRunningCheckOn(s)
                                setIsNewUrlOk(true)
                            }}>
                            Use {MICROSITE_URL}{s}
                        </Button>
                    </Col>
                </Row>)
            }

        </ModalBody>
        <ModalFooter>
            <Button color="primary" disabled={!newUrl || !isNewUrlOk} onClick={save}>Save</Button>
            <Button color="info-outline" onClick={close}>Cancel</Button>
        </ModalFooter>
    </Modal>
}