import React from "react";
import {LeadTagFilterContext} from "../contexts";


export default function LeadTagFilterProvider({children}) {
    const [tags, setTags] = React.useState([])

    console.log("tags", tags)

    return <LeadTagFilterContext.Provider value={{tags, setTags}}>
        {children}
    </LeadTagFilterContext.Provider>
}